import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    List,
    Tooltip,
  } from "@mui/material";
  import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadOutreachFileRequest } from "../../../../actions";
import { IRootReducer } from "../../../../reducers";
import { IncidentOutreach } from "../../../../reducers/states/vcc-incident";
  
  interface OutreachAttachmentsProps {
    props: {
      incidentId: string;
      outreachId: string;
    };
  }
  
  const OutreachAttachmentsModal: FC<OutreachAttachmentsProps> = ({ props }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [outreach, setOutreach] = useState({} as IncidentOutreach);

    const outreachStore = useSelector(
      (state: IRootReducer) => state.vccIncidentReducer.outreaches
    );
  
    const handleOpen = () => {
      setOpen(true);
      let foundOutreach = outreachStore.find((o) => o.id === props.outreachId);
      if(foundOutreach){
        setOutreach(foundOutreach);
      }
    };

    const handleDownloadFile = (fileId: string) => {
      dispatch(downloadOutreachFileRequest(props.incidentId, props.outreachId, fileId));
    };
  
    const handleCancel = () => {
      setOpen(false);
    };
  
    return (
      <>
        <Tooltip title="View attachments of this scheduled outreach">
          <Box>
            <Button
              color="primary"
              onClick={handleOpen}
            >
              View
            </Button>
          </Box>
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Attachments for Scheduled Outreach:</DialogTitle>
          <DialogContent>
            <Grid container>
                <Grid container item xs={6}>
                    <Grid item xs={12}>
                        Links:
                    </Grid>
                    <Grid item xs={12}>
                      <List style={{ maxHeight: "25vh", overflow: "scroll" }}>
                        {outreach.links?.length ? (
                          outreach.links.map((l, index) => (
                            <Box key={l + index}>
                              <Link href={l} target="_blank">{l}</Link>
                            </Box>
                          ))
                        ) : (
                          <Grid>
                            <Box fontStyle="italic" fontSize="12px">
                              No links
                            </Box>
                          </Grid>
                        )}
                      </List>
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <Grid item xs={12}>
                        Files:
                    </Grid>
                    <Grid item xs={12}>
                      <List style={{ maxHeight: "25vh", overflow: "scroll" }}>
                        {outreach.fileIds?.length ? (
                          outreach.fileIds.map((f, index) => (
                            <Box key={f + index}>
                              {f} 
                              <Button onClick={() => handleDownloadFile(f)} data-rum-id={`scheduled-outreach-attachment__${f}`}>
                                Download
                              </Button>
                            </Box>
                          ))
                        ) : (
                          <Grid>
                            <Box fontStyle="italic" fontSize="12px">
                              No files
                            </Box>
                          </Grid>
                        )}
                      </List>
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={() => setOpen(false)} variant="contained" color="primary">
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default OutreachAttachmentsModal;
  