import { useState, useEffect } from "react";
import { useAuthContext } from "../../core";
import { RoleAction } from "../../enums/permission-actions";
import { CheckPermissionsUtil } from "../../utils/permission-utils";

function useUserPermissions(roleActionInit: RoleAction) {
  const [isDisabled, setIsDisabled] = useState(true);
  const { authState } = useAuthContext();

  useEffect(() => {
    if (roleActionInit) {
      setIsDisabled(CheckPermissionsUtil(roleActionInit, authState.userGroups));
    }
  }, [authState.userGroups, roleActionInit]);

  return [isDisabled] as const;
}

export default useUserPermissions;
