import { takeEvery, call, put, fork } from "redux-saga/effects";
import * as actions from "../../actions";
import {
  getUsersPayload,
  addUserToGroupPayload,
  createAndInviteUserPayload,
  listGroupsByUserPayload,
  AgencyByUserPayload,
  reinviteUserPayload,
  removeUserFromGroupPayload,
  types,
  updateUserPayload,
} from "../../actions";
import * as api from "../../api";
import {
  AdminStoreError,
  AdminStoreSuccess,
} from "../../enums/store-messages/admin";
import { UserModel } from "../../reducers/states/admin";
import { IsResponseValidUtil } from "../../utils/api-utils";

export function* getUsers(action: {
  type: typeof types.GET_USERS;
  payload: getUsersPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getUsers, action.payload.email);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: UserModel[];
        apiResultPayload = result.data;
        yield put(actions.getUsersSuccess(apiResultPayload));
      } else
        yield put(actions.getUsersFailure(AdminStoreError.GET_USERS_ERROR));
    } else yield put(actions.getUsersFailure(AdminStoreError.GET_USERS_ERROR));
  } catch (e) {
    //TODO: Implement proper logging and error handling
    console.log(e);
    yield put(actions.getUsersFailure(AdminStoreError.GET_USERS_ERROR));
  }
}
function* watchGetUsersEvent() {
  yield takeEvery(actions.types.GET_USERS, getUsers);
}



export function* getAgencyByUser(action: {
  type: typeof types.GET_AGENCY_BY_USER;
  payload: AgencyByUserPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getAgencyByUser, action.payload.email);
    if (result && result.status === 200) {
      //console.log(result.data);

      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: UserModel;
        apiResultPayload = result.data;
        yield put(actions.getAgencyByUserSuccess(apiResultPayload.agency));
      } else
        yield put(
          actions.getAgencyByUserFailure(
            AdminStoreError.GET_AGENCY_BY_USER_ERROR
          )
        );
    } else
      yield put(
        actions.getAgencyByUserFailure(
          AdminStoreError.GET_AGENCY_BY_USER_ERROR
        )
      );
  } catch (e) {
    yield put(
      actions.getAgencyByUserFailure(AdminStoreError.GET_AGENCY_BY_USER_ERROR)
    );
    console.log(e);
  }
}

function* watchListAgencyByUserEvent() {
  yield takeEvery(actions.types.GET_AGENCY_BY_USER, getAgencyByUser);
}


export function* createAndInviteUser(action: {
  type: typeof types.CREATE_AND_INVITE_USER;
  payload: createAndInviteUserPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.addUser,
      action.payload.reqUser,
      action.payload.email,
      action.payload.givenName,
      action.payload.familyName,
      action.payload.phoneNumber,
      action.payload.agency,
      action.payload.roles,
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        yield put(
          actions.createAndInviteUserSuccess(
            AdminStoreSuccess.CREATE_AND_INVITE_USER_SUCCESS
          )
        );
      } else
        yield put(
          actions.createAndInviteUserFailure(
            AdminStoreError.CREATE_AND_INVITE_USER_ERROR
          )
        );
    } else
      yield put(
        actions.createAndInviteUserFailure(
          AdminStoreError.CREATE_AND_INVITE_USER_ERROR
        )
      );
  } catch (e) {
    yield put(
      actions.createAndInviteUserFailure(
        AdminStoreError.CREATE_AND_INVITE_USER_ERROR
      )
    );
    console.log(e);
  }
}
function* watchCreateAndInviteUserEvent() {
  yield takeEvery(actions.types.CREATE_AND_INVITE_USER, createAndInviteUser);
}

export function* updateUser(action: {
  type: typeof types.UPDATE_USER;
  payload: updateUserPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.updateUser,
      action.payload.cognitoUsername,
      action.payload.email,
      action.payload.givenName,
      action.payload.familyName,
      action.payload.phoneNumber,
      action.payload.agency,
      action.payload.enabled,
      action.payload.roles,
      action.payload.rolesToAdd,
      action.payload.rolesToRemove,
      action.payload.reqUser,
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        yield put(
          actions.updateUserSuccess(AdminStoreSuccess.UPDATE_USER_SUCCESS)
        );
      } else
        yield put(actions.updateUserFailure(AdminStoreError.UPDATE_USER_ERROR));
    } else
      yield put(actions.updateUserFailure(AdminStoreError.UPDATE_USER_ERROR));
  } catch (e) {
    yield put(actions.updateUserFailure(AdminStoreError.UPDATE_USER_ERROR));
    console.log(e);
  }
}
function* watchUpdateUserEvent() {
  yield takeEvery(actions.types.UPDATE_USER, updateUser);
}

export function* reinviteUser(action: {
  type: typeof types.REINVITE_USER;
  payload: reinviteUserPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.reinviteUser, action.payload.email, action.payload.reqUser);

    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        yield put(
          actions.reinviteUserSuccess(AdminStoreSuccess.REINVITE_USER_SUCCESS)
        );
      } else
        yield put(
          actions.reinviteUserFailure(AdminStoreError.REINVITE_USER_ERROR)
        );
    } else
      yield put(
        actions.reinviteUserFailure(AdminStoreError.REINVITE_USER_ERROR)
      );
  } catch (e) {
    yield put(actions.reinviteUserFailure(AdminStoreError.REINVITE_USER_ERROR));
    console.log(e);
  }
}
function* watchReInviteUsersEvent() {
  yield takeEvery(actions.types.REINVITE_USER, reinviteUser);
}

export function* getGroupsByUser(action: {
  type: typeof types.GET_GROUPS_BY_USER;
  payload: listGroupsByUserPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getGroupsByUser, action.payload.email);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: string[];
        apiResultPayload = result.data.groups;
        // console.log(apiResultPayload);
        yield put(actions.getGroupsForUserSuccess(apiResultPayload));
      } else
        yield put(
          actions.getGroupsForUserFailure(
            AdminStoreError.GET_GROUPS_BY_USER_ERROR
          )
        );
    } else
      yield put(
        actions.getGroupsForUserFailure(
          AdminStoreError.GET_GROUPS_BY_USER_ERROR
        )
      );
  } catch (e) {
    yield put(
      actions.getGroupsForUserFailure(AdminStoreError.GET_GROUPS_BY_USER_ERROR)
    );
    console.log(e);
  }
}
function* watchListGroupsByUserEvent() {
  yield takeEvery(actions.types.GET_GROUPS_BY_USER, getGroupsByUser);
}

export function* addUserToGroup(action: {
  type: typeof types.ADD_USER_TO_GROUP;
  payload: addUserToGroupPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.addUserToGroup,
      action.payload.cognitoUsername,
      action.payload.group,
      action.payload.reqUser,
    );

    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        yield put(
          actions.addUserToGroupSuccess(
            AdminStoreSuccess.ADD_USER_TO_GROUP_SUCCESS
          )
        );
      } else
        yield put(
          actions.addUserToGroupFailure(AdminStoreError.ADD_USER_TO_GROUP_ERROR)
        );
    } else
      yield put(
        actions.addUserToGroupFailure(AdminStoreError.ADD_USER_TO_GROUP_ERROR)
      );
  } catch (e) {
    yield put(
      actions.addUserToGroupFailure(AdminStoreError.ADD_USER_TO_GROUP_ERROR)
    );
    console.log(e);
  }
}
function* watchAddUserToGroupEvent() {
  yield takeEvery(actions.types.ADD_USER_TO_GROUP, addUserToGroup);
}

export function* removeUserFromGroup(action: {
  type: typeof types.REMOVE_USER_FROM_GROUP;
  payload: removeUserFromGroupPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.removeUserFromGroup,
      action.payload.cognitoUsername,
      action.payload.group,
      action.payload.reqUser
    );

    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        yield put(
          actions.removeUserFromGroupSuccess(
            AdminStoreSuccess.REMOVE_USER_FROM_GROUP_SUCCESS
          )
        );
      } else
        yield put(
          actions.removeUserFromGroupFailure(
            AdminStoreError.REMOVE_USER_FROM_GROUP_ERROR
          )
        );
    } else
      yield put(
        actions.removeUserFromGroupFailure(
          AdminStoreError.REMOVE_USER_FROM_GROUP_ERROR
        )
      );
  } catch (e) {
    yield put(
      actions.removeUserFromGroupFailure(
        AdminStoreError.REMOVE_USER_FROM_GROUP_ERROR
      )
    );
    console.log(e);
  }
}
function* watchRemoveUserFromGroupEvent() {
  yield takeEvery(actions.types.REMOVE_USER_FROM_GROUP, removeUserFromGroup);
}

const adminSagas = [
  fork(watchGetUsersEvent),
  fork(watchCreateAndInviteUserEvent),
  fork(watchUpdateUserEvent),
  fork(watchReInviteUsersEvent),
  fork(watchListGroupsByUserEvent),
  fork(watchAddUserToGroupEvent),
  fork(watchRemoveUserFromGroupEvent),
  fork(watchListAgencyByUserEvent),
];

export default adminSagas;
