import {
  Box,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { FC, useState } from "react";
import { GetMapLayerColorUtil } from "../../../utils/map-utils";

interface VccControlPanelProps {
  props: {
    allAvailLayers: string[];
    allAvailMarkerSets: string[];
    selectedMarkerSets: string[];
    initLayers?: string[];
    initMarkerSets?: string[];
    handleLayerToggle: (layer: string) => void;
    handleMarkerToggle: (markerSets: string[]) => void;
  };
}

const useStyles = makeStyles(() => ({
  MapListItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  expand: {
    transform: "rotate(-90deg)",
  },
  panelListItemText: {
    paddingRight: 8,
  },
}));

const VccControlPanel: FC<VccControlPanelProps> = ({ props }) => {
  const classes = useStyles();
  const [selectedLayers, setSelectedLayers] = useState(props.initLayers)
  const [isExpanded, setExpanded] = useState(false);

  function isMarkerSetSelected(markerSet: string): boolean {
    return props.selectedMarkerSets
      ? props.selectedMarkerSets.indexOf(markerSet) > -1
      : false;
  }

  function isLayerSelected(layer: string): boolean {
    return selectedLayers
      ? selectedLayers.indexOf(layer) > -1
      : false;
  }

  const handleMarkerSetSelection = (markerSetSelected: string) => {
    if (props.selectedMarkerSets) {
      // Update layers shown on map
      let updatedMarkerSets = [...props.selectedMarkerSets];
      let index = updatedMarkerSets.indexOf(markerSetSelected);
      if (index > -1) {
        updatedMarkerSets.splice(index, 1);
      } else {
        updatedMarkerSets.push(markerSetSelected);
      }

      props.handleMarkerToggle(updatedMarkerSets)
    }
  };

  const handleLayerSelection = (layerSelected: string) => {
    if (selectedLayers) {
      // Update the layers shown on the map
      props.handleLayerToggle(layerSelected);

      // Update the Checkboxes on the legend
      let updatedLayers = [...selectedLayers];
      let index = updatedLayers.indexOf(layerSelected);
      if (index > -1) {
        updatedLayers.splice(index, 1);
      } else {
        updatedLayers.push(layerSelected);
      }

      setSelectedLayers(updatedLayers);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!isExpanded);
  };
  
  return (
    <>
      <Box overflow="auto">
        <Paper
          variant="outlined"
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <List>
            <ListItem
              button
              onClick={handleExpandClick}
              className={classes.MapListItem}
            >
              <Box m={1}>
                {isExpanded ? (
                  <ExpandLess className={classes.expand} />
                ) : (
                  <ExpandMore className={classes.expand} />
                )}
              </Box>
            </ListItem>
            {props.allAvailMarkerSets &&
              props.allAvailMarkerSets.map((markerSet) => (
                <ListItem key={markerSet} className={classes.MapListItem}>
                  <Tooltip title={markerSet}>
                    <Checkbox
                      key={markerSet + "checkbox"}
                      style={{ color: GetMapLayerColorUtil(markerSet) }}
                      checked={isMarkerSetSelected(markerSet)}
                      onClick={() => handleMarkerSetSelection(markerSet)}
                    />
                  </Tooltip>
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <ListItemText
                      primary={markerSet}
                      className={classes.panelListItemText}
                    />
                  </Collapse>
                </ListItem>
              ))}
            {props.allAvailLayers &&
              props.allAvailLayers.map((layer) => (
                <ListItem key={layer} className={classes.MapListItem}>
                  <Tooltip title={layer}>
                    <Checkbox
                      key={layer + "checkbox"}
                      style={{ color: GetMapLayerColorUtil(layer) }}
                      checked={isLayerSelected(layer)}
                      onClick={() => handleLayerSelection(layer)}
                    />
                  </Tooltip>
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <ListItemText
                      primary={layer}
                      className={classes.panelListItemText}
                    />
                  </Collapse>
                </ListItem>
              ))}
          </List>
        </Paper>
      </Box>
    </>
  );
};

export default VccControlPanel;
