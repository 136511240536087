import { Box, Checkbox, Chip, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC } from "react";

const useStyles = makeStyles(() => ({
  incChip: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}));

interface FactorChipProps {
  props: {
    label: string;
    isSelected: boolean;
    isEdit?: boolean;
    handleIncFactorSelected?: (newIncFactors: string) => void;
  };
}

const FactorChip: FC<FactorChipProps> = ({ props }) => {
  const classes = useStyles();
  return (
    <>
      <Box pl={0} ml={0} display="flex" justifyContent="center">
        <FormControlLabel
          control={
            <Checkbox
              style={{ left: "-10px" }}
              className={classes.incChip}
              disableRipple
              icon={
                <Chip
                  variant={props.isEdit ? "outlined" : "filled"}
                  label={props.label}
                />
              }
              checkedIcon={<Chip color="primary" label={props.label} />}
            />
          }
          onClick={() =>
            props.handleIncFactorSelected
              ? props.handleIncFactorSelected(props.label)
              : null
          }
          label=""
          labelPlacement="bottom"
          checked={props.isSelected}
        />
      </Box>
    </>
  );
};

export default FactorChip;
