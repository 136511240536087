import {
  Tooltip,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Typography,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ZoomOutMap, RemoveCircle } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  IncidentDispatch,
  UpdateIncidentDispatchRequest,
} from "../../../../../reducers/states/vcc-incident";
import { ElapsedTimeUtil, FinalElapsedTimeUtil } from "../../../../../utils/date-utils";
import { AgencyChip } from "../../../../common/agency-chip";
import RemoveAssociatedDispatchModal from "./remove-modal";
import { Agency } from "../../../../../enums/agency";

interface AssociatedDispatchProps {
  props: {
    incidentDispatch: IncidentDispatch;
    fromIncidentPage?: boolean;
    isEdit: boolean;
    associatedIdRequests: UpdateIncidentDispatchRequest[];
    handleAdditionalDetailsOpen: (incidentDispatchId: string) => void;
    handleRemoveDispatch: (dispatchId: string, removeReason: string) => void;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    flex: "1 1 55%",
  },
  removeButton: {
    color: theme.agency.SFD,
    padding: "3px",
    marginLeft: "10px",
  },
}));

function AssociatedDispatch({ props }: AssociatedDispatchProps) {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [elapsedTime, setElapsedTime] = useState("");
  const [removeDispatchesOpen, setRemoveDispatchesOpen] = useState(false);

  useEffect(() => {
    // Start a counter if the dispatch event is open, otherwise set it as a static duration
    if(props.incidentDispatch.isOpen){
      let secTimer = setInterval(() => {
        setElapsedTime(ElapsedTimeUtil(props.incidentDispatch.startTime));
      }, 1000);

      return () => clearInterval(secTimer);
    }
    else{
      setElapsedTime(FinalElapsedTimeUtil(props.incidentDispatch.startTime, props.incidentDispatch.lastUpdateTime)); 
    }
  }, [props.incidentDispatch.startTime, props.incidentDispatch.isOpen, props.incidentDispatch.lastUpdateTime]);

  const handleRemoveClick = () => {
    if (props.associatedIdRequests.some((x) => x.id === props.incidentDispatch.id)) {
      props.handleRemoveDispatch(props.incidentDispatch.id, "local_dissociate");
    }
    setRemoveDispatchesOpen(true);
  }

  const handleTooltip = (isOpen: boolean) => {
    setTooltipOpen(isOpen);
  };

  return (
    <>
      <ListItem
        onClick={() => {
          handleTooltip(false);
        }}
      >
        <AgencyChip props={{
          agency: props.incidentDispatch.agency,
          isClosed: !props.incidentDispatch.isOpen
          }}
         />
        {props.fromIncidentPage ? (
          <ListItemText
            primary={props.incidentDispatch.eventType}
            secondary={props.incidentDispatch.location}
            style={{ overflow: "auto" }}
            className={classes.listItem}
          />
        ) : (
          <ListItemText
            primary={"Duration: " + elapsedTime}
            secondary={
              props.incidentDispatch.agency !== Agency.KCM
                ? "Active Units: " + props.incidentDispatch.activeUnitCount
                : ""
            }
            style={{ overflow: "auto" }}
            className={classes.listItem}
          />
        )}
        {props.fromIncidentPage && (
          <>
            <Grid container direction="row" justifyContent="flex-end">
              {props.incidentDispatch.agency !== Agency.KCM && (
                <Grid item xl={4} lg={5} xs={6} container direction="column">
                  <Typography variant="body2">
                    Active Units:{" "}
                    {props.incidentDispatch.activeUnitCount ?? "None"}
                  </Typography>
                </Grid>
              )}
              <Grid item xl={4} lg={5} xs={6} container direction="column">
                <Grid>
                  <Typography variant="body2">
                    Duration: {elapsedTime}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {props.isEdit && (
          <Tooltip title="Remove Associated Dispatch">
            <IconButton
              className={classes.removeButton}
              onClick={() => handleRemoveClick()}
              size="large"
            >
              {" "}
              <RemoveCircle></RemoveCircle>
            </IconButton>
          </Tooltip>
        )}
        <ListItemSecondaryAction>
          <Tooltip
            open={tooltipOpen}
            title={"Click to Open Additional Details"}
          >
            <IconButton
              edge="end"
              onMouseEnter={() => {
                handleTooltip(true);
              }}
              onMouseLeave={() => {
                handleTooltip(false);
              }}
              onClick={() => {
                props.handleAdditionalDetailsOpen(props.incidentDispatch.id);
              }}
              size="large"
            >
              <ZoomOutMap />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <RemoveAssociatedDispatchModal
        props={{
          isOpen: removeDispatchesOpen,
          incidentDispatch: props.incidentDispatch,
          handleClose: () => setRemoveDispatchesOpen(false),
          handleRemoveDispatch: props.handleRemoveDispatch,
        }}
      />
    </>
  );
}

export default AssociatedDispatch;
