export function IsValidLatLongUtil(latitude?: number, longitude?: number) {
  // Lat Longs are to not be null, a number, and within the ranges of real lat longs
  if (
    latitude &&
    longitude &&
    !Number.isNaN(latitude) &&
    !Number.isNaN(longitude)
  ) {
    if (
      latitude > -90 &&
      latitude < 90 &&
      longitude > -180 &&
      longitude < 180
    ) {
      return true;
    }
  }

  return false;
}
