import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { createTheme, StyledEngineProvider, Theme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import "./index.css";
import App, { awsRum } from "./App";
import { configureStore, AuthConfig } from "./core";
import axios from "axios";

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
} else {
  // production code
  axios.defaults.baseURL = process.env.REACT_APP_VCC_APP_API;
}

axios.defaults.headers.common["x-api-key"] = process.env.REACT_APP_VCC_API_KEY;
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
axios.interceptors.request.use(async config => {
  try {
    let currentUser = await Auth.currentSession();
    config.headers.Authorization = currentUser.getAccessToken().getJwtToken();
    config.headers.identity = currentUser.getIdToken().getJwtToken();
    return config;
  } catch (e) {
    // Unable to successfully set auth headers from user token, have user resign in
    console.log(e);
    await Auth.signOut();
    window.open("/#/login", "_self");
    return Promise.reject(config);
  }
});
axios.interceptors.response.use(async response => {
  try{
    // On forbidden, check if their token is still valid 
    // If not send the user through the sign in process to get a session through SAW
    if(response.status === 403){
      let userSession = await Auth.currentSession();
      if(!userSession.isValid()){
        await Auth.signOut();
        window.open("/#/login", "_self");
      }
    }

    return response;
  }
  catch (e) {
    throw e;
  }
});

const store = configureStore();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AuthConfig.cognito.REGION,
    userPoolId: AuthConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: AuthConfig.cognito.APP_CLIENT_ID,
    oauth: {
      domain: AuthConfig.cognito.AUTH_DOMAIN,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: AuthConfig.authRedirects.SIGN_IN,
      redirectSignOut: AuthConfig.authRedirects.SIGN_OUT,
      responseType: "code",
    },
  },
});

// Declare our custom fields to add to the default theme
declare module "@mui/material/styles" {
  interface Theme {
    agency: {
      WSP: React.CSSProperties["color"];
      KCM: React.CSSProperties["color"];
      SPD: React.CSSProperties["color"];
      SFD: React.CSSProperties["color"];
      SDOT: React.CSSProperties["color"];
      WSDOT: React.CSSProperties["color"];
      ST: React.CSSProperties["color"];
      PORT: React.CSSProperties["color"];
      NWSA: React.CSSProperties["color"];
      Dark: React.CSSProperties["color"];
      Neutral: React.CSSProperties["color"];
    };
    marker: {
      ActiveIncident: React.CSSProperties["color"];
      SelectedIncident: React.CSSProperties["color"];
      SelectedIncidentDispatches: React.CSSProperties["color"];
      DispatchEvent: React.CSSProperties["color"];
      HighlightedDispatchEvent: React.CSSProperties["color"];
      ClosedDispatchMapIcon: React.CSSProperties["color"];
      ClosedDispatchEventIFD: React.CSSProperties["color"];
      TrafficLayer: React.CSSProperties["color"];
      CameraLayer: React.CSSProperties["color"];
    };
    pins: {
      PinHighlight: React.CSSProperties["color"];
    };
  }

  interface ThemeOptions {
    agency: {
      WSP: React.CSSProperties["color"];
      KCM: React.CSSProperties["color"];
      SPD: React.CSSProperties["color"];
      SFD: React.CSSProperties["color"];
      SDOT: React.CSSProperties["color"];
      WSDOT: React.CSSProperties["color"];
      ST: React.CSSProperties["color"];
      PORT: React.CSSProperties["color"];
      NWSA: React.CSSProperties["color"];
      Dark: React.CSSProperties["color"];
      Neutral: React.CSSProperties["color"];
    };
    marker: {
      ActiveIncident: React.CSSProperties["color"];
      SelectedIncident: React.CSSProperties["color"];
      SelectedIncidentDispatches: React.CSSProperties["color"];
      DispatchEvent: React.CSSProperties["color"];
      HighlightedDispatchEvent: React.CSSProperties["color"];
      ClosedDispatchMapIcon: React.CSSProperties["color"];
      ClosedDispatchEventIFD: React.CSSProperties["color"];
      TrafficLayer: React.CSSProperties["color"];
      CameraLayer: React.CSSProperties["color"];
    };
    pins: {
      PinHighlight: React.CSSProperties["color"];
    };
  }
}

// Review: Used for Reference in components when color values are needed directly
export const customTheme = {
  palette: {
    primary: {
      light: "#4791db",
      main: "#1976d2",
      dark: "#115293",
    },
    secondary: {
      light: "#DB9147",
      main: "#D27519",
      dark: "#935211",
    },
  },
  agency: {
    WSP: "#86a1c7",
    KCM: "#22181f",
    SFD: "#bc1817",
    SPD: "#102241",
    SDOT: "#2463b1",
    WSDOT: "#007962",
    ST: "#2c376f",
    PORT: "#00405c",
    Dark: "#115293",
    NWSA: "#ef6729",
    Neutral: "#dee2e6",
  },
  marker: {
    ActiveIncident: "#EF340C",
    SelectedIncident: "#0C39EF",
    SelectedIncidentDispatches: "#80499A",
    DispatchEvent: "#E3DC24",
    HighlightedDispatchEvent: 'black',
    ClosedDispatchMapIcon: "#A9A9A9",
    ClosedDispatchEventIFD: "#838383",
    TrafficLayer: "black",
    CameraLayer: "223b53",
  },
  pins: {
    PinHighlight: "#D3D3D3",
  },
};

// Defaults for premade MUI components and makeStyles for generating in component css classes
const theme = createTheme({
  palette: {
    primary: {
      light: "#4791db",
      main: "#1976d2",
      dark: "#115293",
    },
    secondary: {
      light: "#DB9147",
      main: "#D27519",
      dark: "#935211",
    },
  },
  agency: {
    WSP: "#86a1c7",
    KCM: "#22181f",
    SFD: "#bc1817",
    SPD: "#102241",
    SDOT: "#2463b1",
    WSDOT: "#007962",
    ST: "#2c376f",
    PORT: "#00405c",
    Dark: "#115293",
    NWSA: "#ef6729",
    Neutral: "#dee2e6",
  },
  marker: {
    ActiveIncident: "#EF340C",
    SelectedIncident: "#0C39EF",
    SelectedIncidentDispatches: "#80499A",
    DispatchEvent: "#E3DC24",
    HighlightedDispatchEvent: "black",
    ClosedDispatchMapIcon: "#A9A9A9",
    ClosedDispatchEventIFD: "#838383",
    TrafficLayer: "black",
    CameraLayer: "223b53",
  },
  pins: {
    PinHighlight: "#f0e518",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <HashRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </HashRouter>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
export { awsRum };

