import { Autocomplete, TextField } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserInfoRequest } from "../../../../actions";
import { IRootReducer } from "../../../../reducers";
import { AllUserInfoModel } from "../../../../reducers/states/all-user-info";

interface UserAutocompleteProps {
  props: {
    inputId: string;
    inputLabel: string;
    initialValue: string;
    handleSelection: (userSelection: AllUserInfoModel) => void;
  };
}

const UserAutocomplete: FC<UserAutocompleteProps> = ({ props }) => {
  const dispatch = useDispatch();
  const userOptions = useSelector( (state: IRootReducer) => state.allUserInfoReducer.allUserInfo);
  
  useEffect(() => {
    if(userOptions.length === 0){
      dispatch(getAllUserInfoRequest());
    }
  },[dispatch, userOptions])

  const getInitialValue = (email: string) => {
    let option = null;
    userOptions.forEach((user: AllUserInfoModel) => {
      if(user.email === email)
      {
        option = user;
      }
        
    })
    return option
  }

  return (
    <>
        <Autocomplete
          disablePortal
          onChange={(event: any, newValue: AllUserInfoModel | null) => {
            if (newValue != null){
              props.handleSelection(newValue);
            }
          }}
          id={props.inputId}
          options={userOptions}
          value={getInitialValue(props.initialValue)}
          renderInput={(params) => <TextField {...params} variant="outlined" margin="dense" fullWidth label={props.inputLabel} />
        }
        />
    </>
  );
};

export default UserAutocomplete;
