import moment from "moment";

export function UTCNowUtil() {
  return new Date(Date.now());
}

export function UTCToOneMinuteAheadUtil() {
  return new Date(Date.now() + 60000);
}

export function UTCFromTimeFrameUtil(timeFrame: number) {
  return new Date(Date.now() - timeFrame * 60000);
}
export function ElapsedTimeUtil(startDate: string | Date) {
  if (!startDate) {
    return "";
  }
  const startTime = new Date(startDate);
  const endTime = new Date(Date.now());
  let totalSeconds = (Number(endTime) - Number(startTime)) / 1000;

  let hours: number | string = Math.floor(totalSeconds / 3600);
  if (hours < 10) {
    hours = "0" + hours;
  }
  let minutes: number | string = Math.floor((totalSeconds % 3600) / 60);
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  let seconds: number | string = Math.floor((totalSeconds % 3600) % 60);
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return [hours, minutes, seconds].join(":");
}

export function FinalElapsedTimeUtil(startDate: string | Date, endDate: string | Date) {
  if (!startDate) {
    return "";
  }
  const startTime = new Date(startDate);
  const endTime = new Date(endDate);
  let totalSeconds = (Number(endTime) - Number(startTime)) / 1000;

  let hours: number | string = Math.floor(totalSeconds / 3600);
  if (hours < 10) {
    hours = "0" + hours;
  }
  let minutes: number | string = Math.floor((totalSeconds % 3600) / 60);
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  let seconds: number | string = Math.floor((totalSeconds % 3600) % 60);
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return [hours, minutes, seconds].join(":");
}

export const IsTimePlannedValidUtil = (fromTime: string | Date | undefined, toTime: string | Date | undefined) => {
  if(fromTime && fromTime !== "" && toTime && toTime !== ""){
    return moment(fromTime).isBefore(toTime);
  }
  else {
    return false;
  }
}

export const EpochMilliStringToSecondsUtil = (awsEpochDate: string | Date) =>{
  return +awsEpochDate/1000;
}
