import React, { FC } from "react";
import { IncidentDispatch } from "../../../../../../reducers/states/vcc-incident";
import ConfirmCaptureModal from "../../../../../common/confim-capture-modal";

interface RemoveAssociatedDispatchProps {
  props: {
    isOpen: boolean;
    incidentDispatch: IncidentDispatch;
    handleClose: () => void;
    handleRemoveDispatch: (dispatchId: string, removeReason: string) => void;
  };
}

const RemoveReason = {
  ADDED_IN_ERROR: "Added in error",
  REASSESSED: "Reassessed - No longer related",
};

const RemoveAssociatedDispatchModal: FC<RemoveAssociatedDispatchProps> = ({ props }) => {
  
  const handleRemoveDispatch = (selectedReason: string) => {
    props.handleRemoveDispatch(props.incidentDispatch.id, selectedReason);
    props.handleClose();
  };

  return (
    <>
      <ConfirmCaptureModal 
        props={{
          isOpen: props.isOpen,
          title: "Remove Dispatch",
          message: "Please select a reason for removing dispatch",
          choices: RemoveReason,
          cancelText: "Cancel",
          confirmText: "Remove",
          handleCancel: props.handleClose,
          handleConfirm: handleRemoveDispatch,
        }} 
      />
    </>
  );
};

export default RemoveAssociatedDispatchModal;
