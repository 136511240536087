import { DispatchEventFeedModel, FeedStatus, PagingResponse, PinEvent, TMCLog } from "../../reducers";
import { types } from "../types";

// Get Single Dispatch Event
export interface getDispatchFeedEvent {
  id: string;
}
export const getDispatchFeedEventRequest = (id: string) => ({
  type: types.GET_DISPATCH_FEED_EVENT,
  payload: { id } as getDispatchFeedEvent,
});
export const getDispatchFeedEventSuccess = (
  dispatchFeedEvent: DispatchEventFeedModel
) => ({
  type: types.GET_DISPATCH_FEED_EVENT_SUCCESS,
  payload: { dispatchFeedEvent },
});
export const clearCurrentDispatchEvent = () => ({
  type: types.CLEAR_CURRENT_DISPATCH_EVENT,
  payload: {},
});

// Get IDF Feed Event
export interface getDispatchFeedEventsPayload {
  fromTimeStamp: Date;
  toTimeStamp: Date;
  showOpenDispatchesOnly: boolean;
  region: string;
  area: string;
  setGeoData: boolean;
  pageResponse?: boolean;
}
export const getDispatchFeedEventsRequest = (
  fromTimeStamp: Date,
  toTimeStamp: Date,
  showOpenDispatchesOnly: boolean,
  region: string,
  area: string,
  setGeoData: boolean,
  pageResponse?: boolean,
) => ({
  type: types.GET_DISPATCH_FEED_EVENTS,
  payload: {
    fromTimeStamp,
    toTimeStamp,
    showOpenDispatchesOnly,
    region,
    area,
    setGeoData,
    pageResponse,
  } as getDispatchFeedEventsPayload,
});
export const getDispatchFeedEventsSuccess = (
  dispatchFeedEvents: PagingResponse<DispatchEventFeedModel[]>
) => ({
  type: types.GET_DISPATCH_FEED_EVENTS_SUCCESS,
  payload: { dispatchFeedEvents },
});
export const getDispatchFeedEventsFailure = (
  getDispatchFeedFailureMessage: string
) => ({
  type: types.GET_DISPATCH_FEED_EVENTS_FAILURE,
  payload: { getDispatchFeedFailureMessage },
});
export const clearDispatchFeed = () => ({
  type: types.CLEAR_DISPATCH_EVENT_FEED,
  payload: {},
});

// Get Noteworthy Dispatches
export const getNoteworthyEventsRequest = () => ({
  type: types.GET_NOTEWORTHY_DISPATCH_EVENTS,
  payload: {},
});
export const getNoteworthyEventsSuccess = (
  dispatchFeedEvents: DispatchEventFeedModel[]
) => ({
  type: types.GET_NOTEWORTHY_DISPATCH_EVENTS_SUCCESS,
  payload: { dispatchFeedEvents },
});
export const getNoteworthyEventsFailure = (
  getNoteworthyEventsFailureMessage: string
) => ({
  type: types.GET_NOTEWORTHY_DISPATCH_EVENTS_FAILURE,
  payload: { getNoteworthyEventsFailureMessage },
});

// Get TMC Log
export interface getTMCLogPayload {
  wspId: string;
}
export const getTMCLogRequest = ( wspId: string ) => ({
  type: types.GET_TMC_LOG,
  payload: { wspId } as getTMCLogPayload,
});
export const getTMCLogSuccess = (
  tmcLog: TMCLog
) => ({
  type: types.GET_TMC_LOG_SUCCESS,
  payload: { tmcLog },
});
export const getTMCLogFailure = (
  failureMessage: string
) => ({
  type: types.GET_TMC_LOG_FAILURE,
  payload: { failureMessage },
});
export const clearCurrentTMCLog = () => ({
  type: types.CLEAR_CURRENT_TMC_LOG,
  payload: {},
});

// Set Pins for User
export interface pinDispatchEventPayload {
  eventsToPin: PinEvent[];
}
export const pinDispatchEventRequest = (eventsToPin: PinEvent[]) => ({
  type: types.PIN_DISPATCH_EVENT,
  payload: { eventsToPin } as pinDispatchEventPayload,
});
export const pinDispatchEventSuccess = (pinEventSuccessMessage: string) => ({
  type: types.PIN_DISPATCH_EVENT_SUCCESS,
  payload: { pinEventSuccessMessage },
});
export const pinDispatchEventFailure = (pinEventFailureMessage: string) => ({
  type: types.PIN_DISPATCH_EVENT_FAILURE,
  payload: { pinEventFailureMessage },
});

// Remove Pin by dispatchId for User
export interface removePinDispatchEventPayload {
  dispatchId: string;
}
export const removePinDispatchEventRequest = ( dispatchId: string) => ({
  type: types.REMOVE_PIN_DISPATCH_EVENT,
  payload: { dispatchId } as removePinDispatchEventPayload,
});
export const removePinDispatchEventSuccess = (removePinEventSuccessMessage: string) => ({
  type: types.REMOVE_PIN_DISPATCH_EVENT_SUCCESS,
  payload: { removePinEventSuccessMessage },
});
export const removePinDispatchEventFailure = (removePinEventFailureMessage: string) => ({
  type: types.REMOVE_PIN_DISPATCH_EVENT_FAILURE,
  payload: { removePinEventFailureMessage },
});

// Get Pinned Dispatches for a user
export interface getPinnedDispatchEventsPayload {
  fromTimeStamp: Date;
  toTimeStamp: Date;
}
export const getPinnedDispatchEventsRequest = (
  fromTimeStamp: Date,
  toTimeStamp: Date,
) => ({
  type: types.GET_PINNED_DISPATCH_EVENTS,
  payload: {
    fromTimeStamp,
    toTimeStamp,
  } as getPinnedDispatchEventsPayload,
});
export const getPinnedDispatchEventsSuccess = (
  pinnedEvents: DispatchEventFeedModel[]
) => ({
  type: types.GET_PINNED_DISPATCH_EVENTS_SUCCESS,
  payload: { pinnedEvents },
});
export const getPinnedDispatchEventsFailure = (
  getPinnedEventsFailureMessage: string
) => ({
  type: types.GET_PINNED_DISPATCH_EVENTS_FAILURE,
  payload: { getPinnedEventsFailureMessage },
});

// Get Single Pinned Dispatch Event
export interface getPinnedDispatchEventPayload {
  dispatchId: string;
}
export const getPinnedDispatchEventRequest = (dispatchId: string) => ({
  type: types.GET_PINNED_DISPATCH_EVENT,
  payload: { dispatchId } as getPinnedDispatchEventPayload,
});
export const getPinnedDispatchEventSuccess = (
  pinnedEvent: PinEvent
) => ({
  type: types.GET_PINNED_DISPATCH_EVENT_SUCCESS,
  payload: { pinnedEvent },
});
export const getPinnedDispatchEventFailure = (
  getPinnedEventFailureMessage: string
) => ({
  type: types.GET_PINNED_DISPATCH_EVENT_FAILURE,
  payload: { getPinnedEventFailureMessage },
});

// Get Feed Status
export const getFeedStatusRequest = () => ({
  type: types.GET_DISPATCH_FEED_STATUS,
  payload: {},
});
export const getFeedStatusSuccess = (feedStatus: FeedStatus[]) => ({
  type: types.GET_DISPATCH_FEED_STATUS_SUCCESS,
  payload: { feedStatus },
});
export const getFeedStatusFailure = (getFeedStatusFailureMessage: string) => ({
  type: types.GET_DISPATCH_FEED_STATUS_FAILURE,
  payload: { getFeedStatusFailureMessage },
});

// Set timeframe
export interface setTimeFramePayload {
  newTimeFrame: number;
}
export const setTimeFrameRequest = (newTimeFrame: number) => ({
  type: types.SET_TIME_FRAME,
  payload: { newTimeFrame } as setTimeFramePayload,
});
export const setTimeFrameSuccess = (newTimeFrame: number) => ({
  type: types.SET_TIME_FRAME_SUCCESS,
  payload: { newTimeFrame },
});
export const initTimeFrame = () => ({
  type: types.INIT_TIME_FRAME,
  payload: {},
});

// Set Feed View
export interface setFeedViewPayload {
  newFeedView: string;
}
export const setFeedViewRequest = (newFeedView: string) => ({
  type: types.SET_FEED_VIEW,
  payload: { newFeedView } as setFeedViewPayload,
});
export const setFeedViewSuccess = (newFeedView: string) => ({
  type: types.SET_FEED_VIEW_SUCCESS,
  payload: { newFeedView },
});
export const initFeedView = () => ({
  type: types.INIT_FEED_VIEW,
  payload: {},
});

// Set ShowOpenOnly
export interface setShowOpenOnlyPayload {
  showOpenOnly: boolean;
}
export const setShowOpenOnlyRequest = (showOpenOnly: boolean) => ({
  type: types.SET_SHOW_OPEN_DISPATCHES_ONLY,
  payload: { showOpenOnly } as setShowOpenOnlyPayload,
});
export const setShowOpenOnlySuccess = (showOpenOnly: boolean) => ({
  type: types.SET_SHOW_OPEN_DISPATCHES_ONLY_SUCCESS,
  payload: { showOpenOnly },
});
export const initShowOpenOnly = () => ({
  type: types.INIT_SHOW_OPEN_DISPATCHES_ONLY,
  payload: {},
});

// Set Area
export interface setFeedAreaPayload {
  area: string;
}
export const setFeedAreaRequest = (area: string) => ({
  type: types.SET_FEED_AREA,
  payload: { area } as setFeedAreaPayload,
});
export const setFeedAreaSuccess = (area: string) => ({
  type: types.SET_FEED_AREA_SUCCESS,
  payload: { area },
});

// Set Region
export interface setFeedRegionPayload {
  region: string;
}
export const setFeedRegionRequest = (region: string) => ({
  type: types.SET_FEED_REGION,
  payload: { region } as setFeedRegionPayload,
});
export const setFeedRegionSuccess = (region: string) => ({
  type: types.SET_FEED_REGION_SUCCESS,
  payload: { region },
});

// Init Area and Region
export const initFeedAreaAndRegion = () => ({
  type: types.INIT_FEED_AREA_AND_REGION,
  payload: {},
});
