import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import PhotoIcon from "@mui/icons-material/Photo";
import moment from "moment";
import { Note } from "../../../../../reducers/states/vcc-incident";
import useUserInfo from "../../../../../hooks/user-info";
import { NameOrEmailUtil } from "../../../../../utils/user-info-utils";
import { isImageFileUtil } from "../../../../../utils/import-utils";

interface IncidentNoteProps {
  props: {
    note: Note;
    handleOpenFile: (fileId: string, isImageType: boolean) => void;
  };
}

const IncidentNote: FC<IncidentNoteProps> = ({ props }) => {
  const [user, setUser] = useUserInfo();

  useEffect(() => {
    setUser(props.note.createdBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.note.createdBy]);

  return (
    <>
      <Grid container alignItems="center" key={props.note.id}>
        <Grid item xs={9}>
          <Box ml={1} mr={1} pl={0} pr={0}>
            <Typography
              component={"span"}
              variant="body2"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {props.note.content}
            </Typography>
            {props.note.imageIds &&
              props.note.imageIds.length > 0 &&
              props.note.imageIds.map((fileId) => (
                <Box key={fileId}>
                  <Tooltip title={isImageFileUtil(fileId) ? "Click to view attachment" : "Click to download attachment"}>
                    <IconButton
                      onClick={() => props.handleOpenFile(fileId, isImageFileUtil(fileId))}
                      key={fileId}
                      data-rum-id={`note-attachment__${fileId}`}
                      size="large"
                    >
                      <PhotoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            title={
              "Added by " + user.firstName && user.lastName
                ? user.firstName + " " + user.lastName
                : user.email + " " + moment(props.note.createdDate).fromNow()
            }
          >
            <Box m={1}>
              <Box m={1} overflow="hidden">
                <Typography
                  component={"span"}
                  variant={"body2"}
                  display="block"
                >
                  <Box fontStyle="italic" fontSize="12px" display="inline">
                    Added by:
                  </Box>
                  <Box>
                    {NameOrEmailUtil(user)}
                    <br />
                    {moment(props.note.createdDate).fromNow()}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Box ml={1} mr={2}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default IncidentNote;
