export enum MapLayers {
  TRAFFIC_LAYER = "Traffic Layer",
  CAMERA_LAYER = "Traffic Cameras",
  DISPATCH_EVENTS = "Dispatch Events",
  ASSOC_DISPATCH_EVENTS = "Associated Dispatch Events",
  VCC_INCIDENTS = "VCC Incidents",
  CURRENT_INC = "Current VCC Incident",
  ALL_ANNOTATIONS = "Incident Map Annotations",
  ANNOTATIONS_PERIMETER = "ANNOTATIONS_PERIMETER",
  ANNOTATIONS_DETOUR = "ANNOTATIONS_DETOUR",
  ANNOTATIONS_BLOCKAGE = "ANNOTATIONS_BLOCKAGE",
  CONSTRUCTION_TRAFFIC_DATA = "Construction Events",
  HIGHWAY_ALERTS_DATA = "Construction Events",
}

export enum MapIcons {
  CAMERA = "Camera",
  CONSTRUCTION_TRAFFIC_DATA = "Construction Events",
  HIGHWAY_ALERT_DATA = "Highway Alerts",
}

export enum MarkerTypes {
  DISPATCH = "DISPATCH",
  INCIDENT = "INCIDENT",
  ASSOC_DISPATCH = "ASSOC_DISPATCH",
  CURRENT_INC = "CURRENT_INC",
}

export enum DrawTypes {
  PERIMETER = "Perimeter",
  DETOUR = "Detour",
  BLOCKAGE = "Blockage",
}
