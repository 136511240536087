import axios from "axios";

export const getUserInfo = (email: string): any => {
  return axios.get("/user/v1/email/" + email);
};

export const getAllReadyOpGroups = (): any => {
  return axios.get("/system/notifications/groups");
} 

export const getUserReadyOpGroups = (email: string): any => {
  return axios.get("/user/v1/email/" + email + "/notification/group");
};

export const updateUserReadyOpGroups = (email: string, groupIds: string[]): any => {
  return axios.put("/user/v1/email/" + email + "/notification/group", { groupIds });
};

export const updateUserProfile = (
  cognitoUsername: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
): any => {
  return axios.put("/user/v1/" + cognitoUsername + "/profile", {
    cognitoUsername: cognitoUsername,
    givenname: givenName,
    familyname: familyName,
    phonenumber: phoneNumber,
  });
};

