import { Box, Button, Divider, Grid, IconButton, Paper, Theme, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { renderCellExpand } from "../../common/data-grid/cell-expander";
import TimeDisplay from "../../common/time-display";
import useUserPermissions from "../../../hooks/user-permissions";
import { RoleAction } from "../../../enums/permission-actions";
import AddEditScheduledOutreachModal from "./add-edit-scheduled-outreach-modal";
import { getOutreachHistoryRequest, getOutreachRequest } from "../../../actions";
import moment from "moment";
import OutreachAttachmentsModal from "./outreach-attachments-modal";
import DataGridUserInfoCell from "../../common/data-grid/user-info";
import HistoryModal from "../../incident-model/incident-history";


const useStyles = makeStyles((theme: Theme) => ({
  removedAction: {
    color: theme.marker.ClosedDispatchEventIFD,
  },
  chipRemoved: {
    backgroundColor: theme.marker.ClosedDispatchEventIFD,
    color: "white",
  },
}));

interface OutreachGridRow {
  id: string;
  scheduledTime: string | Date;
  isRecurring: string;
  status: string;
  audience: string;
  agency: string;
  recipient: string;
  fromSender: string;
  message: string;
  links: string[];
}

interface ScheduledOutreachGridProps {
  props: {
    incidentId: string;
    isEditEnabled: boolean;
  }
}

const ScheduledOutreachGrid: FC<ScheduledOutreachGridProps> = ({props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [roleDenied] = useUserPermissions(RoleAction.ADD_EDIT_SCHEDULED_OUTREACH);
  const addEditEnabled = !roleDenied && props.isEditEnabled;
  const [gridData, setGridData] = useState([] as OutreachGridRow[]);
  const [isAddOutreach, setIsAddOutreach] = useState(true);
  const [addEditOutreachOpen, setAddEditOutreachOpen] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0
  })

  const incidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer
  );

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsGridLoading(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);


  useEffect(() => {
    setIsGridLoading(true);
    if (incidentStore.outreaches.length > 0) {
      let gridRows: OutreachGridRow[] = incidentStore.outreaches.reduce(function (
        results: OutreachGridRow[],
        r
      ) {
          results.push({
            id: r.id,
            scheduledTime: r.scheduledTime,
            isRecurring: r.isRecurring,
            status: r.status,
            audience: r.audience,
            agency: r.agency,
            recipient: r.recipient,
            fromSender: r.createdBy,
            message: r.message,
            links: r.links
          });
        return results;
      },
      []);

      setGridData(gridRows);
    }
    showLoadingAnimation();
  }, [showLoadingAnimation, incidentStore.outreaches]);

  const handleAddOutreach = () => {
    setIsAddOutreach(true);
    setAddEditOutreachOpen(true);
  }

  const handleUpdateOutreach = (outreachId: string) => {
    dispatch(
      getOutreachRequest(
        props.incidentId,
        outreachId
      )
    );
    setIsAddOutreach(false);
    setAddEditOutreachOpen(true);
  }

  const handleGetHistory = (outreachId: string) => {
    dispatch(getOutreachHistoryRequest(props.incidentId, outreachId));
    setHistoryOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: "editOutreach",
      headerName: "Edit",
      filterable: false,
      flex: .25,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <IconButton onClick={() => handleUpdateOutreach(params.row.id)}>
              <EditIcon />
            </IconButton>
          </Box>
        )
      }
    },
    {
      field: "scheduledTime",
      headerName: "Scheduled Time",
      flex: .75,
      renderCell: (params: GridRenderCellParams) => {
        if(params.row.scheduledTime !== ""){
          if(params.row.isRecurring as string === "Yes"){
            return <Tooltip title={"This is a recurring outreach event"}><Box>{moment(params.row.scheduledTime as string).format("HH:mm:ss")}</Box></Tooltip>
          }
          else{
            return <Box>{moment(params.row.scheduledTime as string).format("HH:mm:ss")}</Box>
          }
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: .65,
      renderCell: renderCellExpand,
    },
    {
      field: "audience",
      headerName: "Audience",
      flex: .75,
      renderCell: renderCellExpand,
    },
    {
      field: "agency",
      headerName: "Agency",
      flex: .75,
      renderCell: renderCellExpand,
    },
    {
      field: "recipient",
      headerName: "Recipient",
      flex: .75,
      renderCell: renderCellExpand,
    },
    {
      field: "fromSender",
      headerName: "From",
      flex: .65,
      renderCell: (params: GridRenderCellParams) =>
        renderCellExpand(
          params,
          <DataGridUserInfoCell props={{ users: [params.row.fromSender] }} />,
          true
        ),
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1.25,
      renderCell: renderCellExpand,
    },
    {
      field: "files",
      headerName: "Files",
      flex: 0.65,
      renderCell: (params: GridRenderCellParams) =>{
        return (
          <OutreachAttachmentsModal props={{
              incidentId: props.incidentId,
              outreachId: params.row.id
            }}
           />
        )
      }
    },
    {
      field: "history",
      headerName: "History",
      flex: .65,
      renderCell: (params: GridRenderCellParams) =>{
        return (
          <IconButton onClick={() => handleGetHistory(params.row.id)}>
            <HistoryIcon />
          </IconButton>
        )
      }
    },
  ];

  return (
    <>
      <Paper style={{height: "100%"}}>
        <Grid container style={{maxHeight: "50px"}}>
          <Grid item xs={3}>
            <Box mt={1} ml={1}>
              <Typography component={'span'} variant="body1"><Box style={{fontWeight: "bold"}}>Scheduled Outreach</Box></Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box m={1}>
              <TimeDisplay />
            </Box>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            {addEditEnabled && (
              <Grid item>
                <Box m={1} mr={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: "center" }}
                    size="small"
                    onClick={handleAddOutreach}
                  >
                    Schedule Outreach
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Box display="flex" style={{height: "101%", overflow: "auto"}}>
          <Grid container style={{height: "100%"}}>
            <Grid item xs={12} style={{height: "calc(100% - 50px)"}}>
              <DataGrid
                loading={isGridLoading}
                density="compact"
                rows={gridData}
                columns={columns}
                getRowClassName={(params) =>
                  params.row.status === "REMOVED" ? classes.removedAction : ""
                }
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableRowSelectionOnClick
                columnVisibilityModel={{
                  editOutreach: addEditEnabled,
                }}
                components={{
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      No Scheduled Outreach added - select "Schedule Outreach" to begin coordination of
                      Scheduled Outreaches.
                    </GridOverlay>
                  ),
                  NoResultsOverlay: () => (
                    <GridOverlay>No filtered outreach results</GridOverlay>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <AddEditScheduledOutreachModal
          props={{
            isOpen: addEditOutreachOpen,
            isAddOutreach: isAddOutreach,
            incidentId: incidentStore.incident.id,
            handleClose: () => setAddEditOutreachOpen(false),
          }}
        />
      <HistoryModal
        props={{
          isOpen: historyOpen,
          recordType: "Scheduled Outreach",
          handleClose: () => setHistoryOpen(false),
        }}
      />
      </Paper>
    </>
  );
};

export default ScheduledOutreachGrid;
