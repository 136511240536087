import { Typography } from "@mui/material";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";

const TimeDisplay: FC = () => {
  const [currentDatetime, setCurrentDateTime] = useState(
    moment().format("LL, HH:mm:ss")
  );
  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentDateTime(moment().format("LL, HH:mm:ss"));
    }, 1000);
    return () => clearInterval(secTimer);
  }, []);

  return (
    <>
      <Typography variant="subtitle1">
        {currentDatetime}
      </Typography>
    </>
    
  );
};

export default TimeDisplay;
