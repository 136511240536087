import { Box, Paper, Typography } from "@mui/material";
import React, { FC } from "react";

interface DataGridGeneralTextCellProps {
  props: {
    text: string;
  };
}

const DataGridGeneralTextCell: FC<DataGridGeneralTextCellProps> = ({
  props,
}) => {
  return (
    <>
      <Paper>
        <Box m={1}>
          <Typography variant="body2">{props.text}</Typography>
        </Box>
      </Paper>
    </>
  );
};

export default DataGridGeneralTextCell;
