import { Navigate, Route, Routes } from "react-router-dom";
import {
  AdminPage,
  DashboardPage,
  ErrorPage,
  IncidentDetailPage,
  IntegratedDispatchFeedPage,
  LoginPage,
  NotFoundPage,
  UnauthorizedPage,
  UserProfilePage,
} from "./containers";
import RecordsManagementPage from "./containers/records-management";
import { useAuthContext } from "./core";
import { ProtectedRoute, ProtectedRouteProps } from "./core/protected-route";
import { UserGroups } from "./enums/user-groups";

const defaultProtectedRouteProps: ProtectedRouteProps = {
  isAuthenticated: false,
  unAuthenticatedPath: "/login",
  unAuthorizedPath: "/unauthorized",
  jwtGroups: [],
  allowedGroups: [],
};

const VCCRoutes = () => {
  const { authState } = useAuthContext();
  defaultProtectedRouteProps.isAuthenticated = authState.isAuthenticated;
  defaultProtectedRouteProps.jwtGroups = authState.userGroups;

  return (
    <Routes>
      <Route path="/" element={
        <ProtectedRoute {...defaultProtectedRouteProps}>
          <Navigate to="/dashboard" />
        </ProtectedRoute>
      }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps}>
            <DashboardPage />
          </ProtectedRoute>
        } />
      <Route
        path="/feed"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps}>
            <IntegratedDispatchFeedPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/records-management"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps} allowedGroups={[UserGroups.INC_RECORDS_MANAGER]}>
            <RecordsManagementPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps} allowedGroups={[UserGroups.SITE_ADMIN, UserGroups.AGENCY_ADMIN]}>
            <AdminPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-profile"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps}>
            <UserProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/incident-model/:incid"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps}>
            <IncidentDetailPage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" Component={LoginPage} />
      <Route path="/unauthorized" Component={UnauthorizedPage} />
      <Route path="/error" Component={ErrorPage} />
      <Route Component={NotFoundPage} />
    </Routes>
  );
};

export default VCCRoutes;
