import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
  } from "@mui/material";
  import React, { FC, useEffect, useState } from "react";
import CustomSelectInput from "../custom-select-input";
  
  interface CorfirmCaptureModalProps {
    props: {
        isOpen: boolean;
        title: string;
        message: string;
        choices: Object;
        cancelText: string;
        confirmText: string;
        handleCancel: () => void;
        handleConfirm: (selectedReason: string) => void;
    };
  }
  
  const ConfirmCaptureModal: FC<CorfirmCaptureModalProps> = ({ props }) => {
    const [selectedChoice, setSelectedChoice] = useState("");
    const [confirmDisabled, setConfirmedDisabled] = useState(true);

    useEffect(() => {
      if(selectedChoice === ""){
        setConfirmedDisabled(true)
      }
      else{
        setConfirmedDisabled(false)
      }
    }, [selectedChoice])
    
    const handleSelectedChoice = (event: SelectChangeEvent<string>) => {
      const value = event.target.value as string;
      setSelectedChoice(value);
    };
  
    return (
      <>
        <Dialog open={props.isOpen} onClose={props.handleCancel} fullWidth maxWidth="sm">
            <DialogTitle id={"cofirm-capture-modal-" + props.title}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <Box ml={1} mr={1}>
                    <DialogContentText>{props.message}</DialogContentText>
                    <Select
                        value={selectedChoice}
                        onChange={handleSelectedChoice}
                        fullWidth
                        label="Select Reason"
                    >
                      {Object.entries(props.choices).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                          {value}
                          </MenuItem>
                      ))}
                      {!(Object.values(props.choices).includes(selectedChoice)) && (
                        <MenuItem value={selectedChoice}>{selectedChoice}</MenuItem>
                      )}
                    </Select>
                </Box>
                <DialogActions>
                    <Button onClick={props.handleCancel} variant="contained" color="inherit">
                    {props.cancelText}
                    </Button>
                    <Button disabled={confirmDisabled} onClick={() => props.handleConfirm(selectedChoice)} variant="contained" color="primary">
                    {props.confirmText}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
        <CustomSelectInput props={{
            isOpen: selectedChoice === "Other",
            title: "Enter Custom  Reason",
            fieldName: "",
            handleCancel: () => setSelectedChoice(""),
            handleUpdate: (inputUpdate: string) => setSelectedChoice(inputUpdate),
          }} />
      </>
    );
  };
  
  export default ConfirmCaptureModal;
  