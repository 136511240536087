import { Box, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { FC, useState } from "react";
import { UserInfoModel } from "../../../../reducers/states/user-info";
import UserInfo from "../user-info-display";

interface UserInfoTooltipProps {
  props: {
    user: UserInfoModel;
  };
}

const UserInfoTooltip: FC<UserInfoTooltipProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Box onClick={() => setIsOpen(true)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<UserInfo props={{ userInfo: props.user }} />}
          >
            <IconButton size="large">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default UserInfoTooltip;
