import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  List,
  Paper,
  Popper,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React, { FC, useRef, useState, useEffect } from "react";
import NotificationItem from "./notification-item";
import { useDispatch, useSelector } from "react-redux";
import { clearAllNotifs, clearBannerNotif, clearNotifRequest } from "../../../actions";
import BannerNotification from "./banner-notification";
import { VccNotification } from "../../../reducers/states/vcc-incident";
import { IRootReducer } from "../../../reducers";

const useStyles = makeStyles((theme: Theme) => ({
  notifMenu: {
    width: "450px",
    position: "relative",
    left: "-100px",
  },
  badge: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  },
}));

const NotificationMenu: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openNotifMenu, setOpenNotifMenu] = useState(false);
  const [sortedNotifs, setSortedNotifs] = useState([] as VccNotification[]);
  const [overflowActive, setOverflowActive] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const notifTrayRef = useRef<HTMLUListElement>(null);
  const prevOpen = useRef(openNotifMenu);

  const incidentNotifsStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.incidentNotifs
  );

  const bannerNotifsStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.bannerNotifs
  );

  useEffect(() => {
    setSortedNotifs(
      [...incidentNotifsStore].sort(
        (d1, d2) =>
          new Date(d2.timeStamp).getTime() - new Date(d1.timeStamp).getTime()
      )
    );
  }, [incidentNotifsStore]);

  useEffect(() => {
    if (prevOpen.current === true && openNotifMenu === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = openNotifMenu;
  }, [openNotifMenu]);

  useEffect(() => {
    if(openNotifMenu && notifTrayRef.current){
      if(notifTrayRef.current?.offsetHeight < notifTrayRef.current?.scrollHeight){
        setOverflowActive(true);
      }
      else{
        setOverflowActive(false);
      }
    }
  }, [incidentNotifsStore, openNotifMenu]);

  const handleClose = (event: TouchEvent | globalThis.MouseEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenNotifMenu(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        edge="start"
        aria-label="menu"
        onClick={() => setOpenNotifMenu((prev) => !prev)}
        size="large"
      >
        <Badge
          badgeContent={incidentNotifsStore.length}
          classes={{ badge: classes.badge }}
          max={9}
        >
          <NotificationsIcon style={{ color: "white" }} />
        </Badge>
      </IconButton>
      <Popper
        style={{ zIndex: 2500 }}
        open={openNotifMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.notifMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box m={1}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography variant="h6">Notifications</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box m={1} style={{ float: "right" }}>
                        <Button
                          onClick={() => dispatch(clearAllNotifs())}
                          variant="contained"
                          color="inherit"
                          size="small"
                        >
                          Clear All
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <List ref={notifTrayRef} style={{ maxHeight: "25vh", overflow: "scroll" }}>
                      {sortedNotifs?.length ? (
                        sortedNotifs.map((notification, index) => (
                          <NotificationItem
                            key={notification.id + index}
                            props={{
                              notification: notification,
                              handleClearNotification: () =>
                                dispatch(clearNotifRequest(notification)),
                            }}
                          />
                        ))
                      ) : (
                        <Grid>
                          <Box display="flex" justifyContent="center" fontStyle="italic" fontSize="12px">
                            No notifications
                          </Box>
                        </Grid>
                      )}
                    </List>
                  </Grid>
                  {(overflowActive && sortedNotifs.length > 0) && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center" m={1} pb={1} fontStyle="italic" fontSize="12px">Scroll to see additional notifications</Box>
                    </Grid>
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Box>
        {bannerNotifsStore.length > 0 &&
          bannerNotifsStore.map((bannerNotif) => (
            <BannerNotification
              props={{
                notification: bannerNotif,
                handleDismissBanner: () =>
                  dispatch(clearBannerNotif(bannerNotif.id)),
                handleClearBanner: () => dispatch(clearNotifRequest(bannerNotif)),
              }}
            />
          ))}
      </Box>
    </>
  );
};

export default NotificationMenu;
