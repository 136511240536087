import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { FC } from "react";

interface UpdateFailureProps {
  props: {
    isOpen: boolean;
    recordTypeContent: string;
    handleCloseAndDiscard: () => void;
    handleCloseAndEdit: () => void;
  };
}

const UpdateFailureModal: FC<UpdateFailureProps> = ({ props }) => {
  return (
    <>
      <Dialog open={props.isOpen}>
        <DialogTitle>Error Saving Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your edits cannot be saved because another user has made changes to
            the {props.recordTypeContent}. Consider 'Continue Editing' to copy your edits to
            a local text editor. Discard changes and refresh to see the most
            recent {props.recordTypeContent}.
          </DialogContentText>
          <DialogActions>
            <Box m={1}>
              <Button onClick={props.handleCloseAndEdit} variant="contained" color="inherit">
                Continue Editing
              </Button>
            </Box>
            <Box m={1}>
              <Button
                onClick={props.handleCloseAndDiscard}
                color="primary"
                variant="contained"
              >
                Discard and Refresh
              </Button>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateFailureModal;
