export enum AdminStoreError {
  GET_USERS_ERROR = "Error retrieving users",
  DISABLE_USER_ERROR = "Error disabling user account",
  ENABLE_USER_ERROR = "Error re-enabling user account",
  CREATE_AND_INVITE_USER_ERROR = "Error creating and inviting user",
  UPDATE_USER_ERROR = "Error updating user attributes",
  REINVITE_USER_ERROR = "Error re-inviting user",
  GET_AGENCY_BY_USER_ERROR = "Error retrieving agency for user",
  GET_GROUPS_BY_USER_ERROR = "Error retrieving groups for user",
  ADD_USER_TO_GROUP_ERROR = "Error adding user to group",
  REMOVE_USER_FROM_GROUP_ERROR = "Error removing user from group",
}

export enum AdminStoreSuccess {
  DISABLE_USER_SUCCESS = "Successfully disabled user account",
  ENABLE_USER_SUCCESS = "Successfully re-enabled user account",
  CREATE_AND_INVITE_USER_SUCCESS = "Successfully created and invited user",
  UPDATE_USER_SUCCESS = "Successfully update user attributes",
  REINVITE_USER_SUCCESS = "Successfully re-invited user",
  ADD_USER_TO_GROUP_SUCCESS = "Successfully added user to group",
  REMOVE_USER_FROM_GROUP_SUCCESS = "Successfully removed user from group",
}
