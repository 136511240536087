export enum PioAudience {
  EXECUTIVE_BRIEFING = "Executive Briefing",
  INTERVIEW_REQUEST = "Interview Request",
  MEDIA_BRIEFING = "Media Briefing",
  PRESS_RELEASE = "Press Release",
  OTHER = 'Other',
}
  
export enum PioStatus {
  COMPLETE = "Complete",
  IN_PROGRESS = "In Progress",
  REQUESTED = "Requested",
  SCHEDULED = "Scheduled",
  OTHER = "Other"
}

export enum PioRecipient {
  EXECUTIVES = "Executives",
  ELECTED_OFFICIALS = "Elected Officials",
  GEN_PUBLIC = "General Public",
  NATIONAL_MEDIA = "National Media",
  LOCAL_MEDIA = "Local Media",
  OTHER = "Other"
}