import { combineReducers } from "redux";

import dispatchEventFeedReducer from "./dispatch-event-feed";
import adminReducer from "./admin";
import vccIncidentReducer from "./vcc-incident";
import geoJsonReducer from "./geo-json";
import userInfoReducer from "./user-info";
import mapReducer from "./map";
import allUserInfoReducer from "./all-user-info";

export * from "./interfaces";
export * from "./states/dispatch-event-feed-state";
export * from "./states/admin";

export type IDispatchEventFeedReducer = ReturnType<
  typeof dispatchEventFeedReducer
>;
export type IVccIncidentReducer = ReturnType<typeof vccIncidentReducer>;
export type IRootReducer = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
  dispatchEventFeedReducer: dispatchEventFeedReducer,
  vccIncidentReducer: vccIncidentReducer,
  adminReducer: adminReducer,
  geoJsonReducer: geoJsonReducer,
  userInfoReducer: userInfoReducer,
  mapReducer: mapReducer,
  allUserInfoReducer: allUserInfoReducer,
});
