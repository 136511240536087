import {
  Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React, { FC, useEffect, useState } from "react";
import { PinEvent } from "../../../../reducers";
  
  interface PinEventDialogProps {
    props: {
      eventsToPin: PinEvent[];
      handlePinEvents: (finalizedEvents: PinEvent[]) => void;
      hanldeCancel: () => void;
    };
  }
  
  const PinEventDialog: FC<PinEventDialogProps> = ({ props }) => {
    // Review: Can reintroduce when a need for the pin reason is required
    // const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [finalizedEvents, setFinalizedEvents] = useState([] as PinEvent[]);
    
    useEffect(() => {
      setFinalizedEvents([...props.eventsToPin]);
    }, [props.eventsToPin])

    // Review: Can reintroduce when a need for the pin reason is required
    // useEffect(() => {
    //   if (finalizedEvents.length) {
    //     var pinReasons: string[] = finalizedEvents.map((e) => e.pinReason);
    //     pinReasons.length > 0 && !pinReasons.includes("")
    //       ? setSubmitBtnDisabled(false)
    //       : setSubmitBtnDisabled(true);
    //   } else {
    //     setSubmitBtnDisabled(true);
    //   }
    // }, [finalizedEvents]);
  
    // Review: Can reintroduce when a need for the pin reason is required
    // const handleInputChange = (value: string, id: string) => {
    //   var newEventsToPin: PinEvent[] = [...finalizedEvents];
    //   var pinToUpdate: PinEvent = newEventsToPin.find(
    //     (e) => e.dispatchId === id
    //   )!;
    //   pinToUpdate.pinReason = value;
    //   newEventsToPin[
    //     newEventsToPin.findIndex((e) => e.dispatchId === pinToUpdate.dispatchId)
    //   ] = pinToUpdate;
    //   setFinalizedEvents(newEventsToPin);
    // };
  
    const handleCancel = () => {
      setFinalizedEvents([] as PinEvent[]);
      props.hanldeCancel();
    };
  
    const handlePinAndClose = () => {
      props.handlePinEvents(finalizedEvents);
    };
  
    return (
      <>
        <DialogTitle id="form-dialog-title">Confirm Pin Dispatch Events</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Box style={{fontWeight: "bold"}}>Event Number</Box></TableCell>
                  <TableCell><Box style={{fontWeight: "bold"}}>Location</Box></TableCell>
                  {/* Review: Can reintroduce when a need for the pin reason is required */}
                  {/* <TableCell><Box style={{fontWeight: "bold"}}>Pin Reason</Box></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {finalizedEvents &&
                  finalizedEvents.map((e) => (
                    <TableRow key={e.dispatchId}>
                      <TableCell>{e.dispatchId}</TableCell>
                      <TableCell>{e.location}</TableCell>
                      {/* Review: Can reintroduce when a need for the pin reason is required */}
                      {/* <TableCell>
                        <TextField
                          id={e.dispatchId}
                          variant="outlined"
                          required
                          margin="dense"
                          label="Pin Reason"
                          type="string"
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          defaultValue=""
                          onChange={(e) =>
                            handleInputChange(e.target.value, e.target.id)
                          }
                        />
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions>
            <Button onClick={handleCancel} variant="contained" color="inherit">
              Cancel
            </Button>
            <Button
              onClick={handlePinAndClose}
              color="primary"
              variant="contained"
              data-rum-id={`pin-events__${props.eventsToPin.map(e => e.dispatchId).join('+')}`}
              // Review: Can reintroduce when a need for the pin reason is required
              // disabled={submitBtnDisabled}
            >
              Pin Events
            </Button>
          </DialogActions>
        </DialogContent>
      </>
    );
  };
  
  export default PinEventDialog;
  