import { RoleAction } from "../../enums/permission-actions";
import RoleConfig from "./RoleConfig.json";

export function CheckPermissionsUtil(
  actionId: RoleAction,
  userGroups: string[]
) {
  // Based on the context of the button passed in, allow or not allow the user to use the button or component
  // Working with disabled tags, true blocks access, false allows access
  var isDisabled: boolean = true;
  RoleConfig.Roles.forEach((role) => {
    if (userGroups.includes(role.RoleName)) {
      if (role.Policies.includes(actionId)) {
        isDisabled = false;
      }
    }
  });

  return isDisabled;
}
