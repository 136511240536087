import { ApiErrorCode } from "../../enums/api-errors";

// Future: Expand model to include a standard set of fields for each error response
interface ErrorResponse {
  message: string;
  errorCode: string;
}

export function IsResponseValidUtil(s: any) {
  return typeof s === "object";
}

export function IsResponseValidString(s: any) {
  return typeof s === "string";
}

export function IsConditionalCheckError(e: any) {
  try {
    let error = e as ErrorResponse;
    return error.errorCode === ApiErrorCode.CONDITIONAL_CHECK_FAILED;
  } catch (e) {
    // Unable to verify if conditional error, return false
    return false;
  }
}
