import { Box, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { trafficColors } from "../../../utils/map-utils";

const useStyles = makeStyles((theme?: any) => ({
  subtitle1: {
    fontSize: "1.05rem",
    textAlign: "center",
  },
  legendContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    bottom: "0",
    position: "absolute",
    marginBottom: "29px",
    zIndex: 1,
  },
  root: {
    flexGrow: 1,
  },
  colorCell: {
    height: "10px",
    width: "20px",
  },
  legendPaper: {
    padding: theme.spacing(1),
    display: "flex",
    flexGrow: 1,
    maxWidth: "290px",
    borderRadius: "8px",
  },
  legendText: {
    fontSize: "0.75rem",
    textAlign: "center",
  },
}));

export default function TrafficPanel() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.legendContainer}>
        <Paper className={classes.legendPaper} elevation={6}>
          <Grid item xs={12} container direction="row">
            <Grid container item xs={4} justifyContent="center">
              <Typography className={classes.subtitle1}>
                Live traffic
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2}>
                <Typography className={classes.legendText}>Low</Typography>
              </Grid>
              <Grid item xs={7} container>
                {Object.entries(trafficColors).length > 0 &&
                  Object.entries(trafficColors).map(([key, value]) => (
                    <Grid key={key} item xs={3}>
                      <Paper
                        className={classes.colorCell}
                        style={{ backgroundColor: value }}
                      ></Paper>
                    </Grid>
                  ))}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.legendText}>Severe</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
