import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  MenuItem,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVccIncidentHistoryRequest } from "../../../../actions/vcc-incident";
import { EstClearanceTime } from "../../../../enums/estimated-clearance-time";
import { IncidentStatus } from "../../../../enums/incident-status";
import useUserInfo from "../../../../hooks/user-info";
import { IRootReducer } from "../../../../reducers";
import {
  IncidentDispatch,
  UpdateIncidentDispatchRequest,
  VccIncidentModel,
} from "../../../../reducers/states/vcc-incident";
import { ElapsedTimeUtil, FinalElapsedTimeUtil } from "../../../../utils/date-utils";
import { ShortIdUtil } from "../../../../utils/id-utils";
import { NameOrEmailUtil } from "../../../../utils/user-info-utils";
import ViewEditSelect from "../../../common/view-edit-select";
import ViewEditTextField from "../../../common/view-edit-text-field";
import ViewEditUserAutocomplete from "../../../common/view-edit-user-autocomplete";
import IncidentFactors from "../../incident-factor";
import HistoryModal from "../../incident-history";
import useUserPermissions from "../../../../hooks/user-permissions";
import { RoleAction } from "../../../../enums/permission-actions";
import CustomSelectInput from "../../../common/custom-select-input";
import { Agency } from "../../../../enums/agency";
import { getAllUserInfoRequest, setMapEditIncLocationRequest } from "../../../../actions";
import AssociatedEvents from "../associated-events";
import UserInfoTooltip from "../../../common/user-info/user-info-click-tooltip";
import VccGeocoder from "../../../map/geocoder";

const useStyles = makeStyles(() => ({
  vccItalic: {
    fontStyle: "italic",
  },
  userInfoIconBox: {
    marginBottom: "-18px",
  },
  updatedText: {
    display: "inline-block",
    fontSize: "12px",
    marginLeft: "10px",
  },
  updatedTextEmphasis: {
    display: "inline-block",
    fontSize: "12px",
    marginLeft: "10px",
    fontStyle: "italic",
    fontWeight: "bold",
  },
}));

interface IncidentFormProps {
  props: {
    incident: VccIncidentModel;
    associatedIdRequests?: UpdateIncidentDispatchRequest[];
    isEdit: boolean;
    showEmphasizedText: boolean;
    handleInputChange: (e: any, field: string) => void;
    handleGeocoderSelect: (latitude?: number, longitude?: number, address?: string) => void;
    handleEdit: () => void;
    handleCancelInc: () => void;
    handleSaveInc: () => void;
    handleUpdateAssociatedDispatches: (dispatches: IncidentDispatch[], associatedEventRequestIds: UpdateIncidentDispatchRequest[]) => void;
  }
}

const IncidentForm: FC<IncidentFormProps> = ({ props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [elapsedTime, setElapsedTime] = useState("");
  const [isOver90Minutes, setIsOver90Mintues] = useState(false);
  const [showEmphasisUpdateText, setShowEmphasisUpdateText] = useState(false);
  const [createdBy, setCreatedBy] = useUserInfo();
  const [updatedBy, setUpdatedBy] = useUserInfo();
  const [estClearUpdatedBy, setEstClearUpdatedBy] = useUserInfo();
  const [editIncDisabled] = useUserPermissions(RoleAction.EDIT_INCIDENT);
  const [isIncFinalized, setIsIncFinalized] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  useEffect(() => {
    let secTimer = setInterval(() => {
      let statusList = [IncidentStatus.CLOSED.toString(), IncidentStatus.DELETED.toString()];
      if(!statusList.includes(props.incident.status)){
        setElapsedTime(ElapsedTimeUtil(props.incident.startTime));
      }else{
        setElapsedTime(FinalElapsedTimeUtil(props.incident.startTime, props.incident.updatedDate))
      }
      let start = moment(props.incident.startTime);
      let end = moment(); // Now
      moment.duration(end.diff(start)).asMinutes() > 90
        ? setIsOver90Mintues(true)
        : setIsOver90Mintues(false);
    }, 1000);
    return () => clearInterval(secTimer);
  }, [props.incident.startTime, props.incident.status, props.incident.updatedDate]);

  const startEmphasisTimer = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setShowEmphasisUpdateText(false);
    }, 5000);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if(props.incident.finalizedDate === undefined || props.incident.finalizedDate === ""){
      setIsIncFinalized(false);
    }
    else{
      setIsIncFinalized(true);
    }
  }, [props.incident.finalizedDate]);

  useEffect(() => {
    // Only show emphasis if updatedDate is within a refresh cycle
    if (props.showEmphasizedText) {
      if (
        props.incident.updatedDate !== props.incident.createdDate &&
        moment().diff(props.incident.updatedDate) < 30000
      ) {
        setShowEmphasisUpdateText(true);
        startEmphasisTimer();
      }
    }
  }, [
    props.incident.updatedDate,
    props.incident.createdDate,
    props.showEmphasizedText,
    startEmphasisTimer,
  ]);

  useEffect(
    () => {
      setCreatedBy(props.incident.createdBy);
      setUpdatedBy(props.incident.updatedBy);
      if (props.incident.estClearUpdatedBy) {
        setEstClearUpdatedBy(props.incident.estClearUpdatedBy);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.incident.createdBy,
      props.incident.updatedBy,
      props.incident.estClearUpdatedBy,
    ]
  );

  const userOptions = useSelector( (state: IRootReducer) => state.allUserInfoReducer.allUserInfo);
  
  useEffect(() => {
    dispatch(getAllUserInfoRequest());
  },[dispatch])

  const handleGetHistory = (incId: string) => {
    dispatch(getVccIncidentHistoryRequest(incId));
    setHistoryOpen(true);
  };

  const displayUpdatedText = () => {
    if (
      props.incident.updatedDate &&
      props.incident.createdDate !== props.incident.updatedDate
    ) {
      return showEmphasisUpdateText ? (
        <Typography className={classes.updatedTextEmphasis} variant="caption">
          Updated {moment(props.incident.updatedDate).fromNow()}
        </Typography>
      ) : (
        <Typography className={classes.updatedText} variant="caption">
          Updated {moment(props.incident.updatedDate).fromNow()}
        </Typography>
      );
    }
  };

  const handleSetMapEditLocation = () => {
    dispatch(setMapEditIncLocationRequest(true));
  }

  return (
    <>
      <Paper variant="outlined" style={{ height: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          wrap="nowrap"
          style={{ height: "100%", overflow: "auto" }}
        >
          <Paper id="incident-title-area">
            <Grid container direction="row" alignItems="flex-end">
              <Grid item xs={5}>
                <Box ml={1} mr={1} style={{ display: "inline" }}>
                  <Typography
                    component={"span"}
                    style={{ display: "inline-block" }}
                    variant="h5"
                  >
                    {ShortIdUtil(props.incident.id)}{" "}
                    <Typography
                      style={{ display: "inline-block", fontSize: "18px" }}
                      variant="subtitle1"
                    >
                      {moment(props.incident.startTime).format("LL, HH:mm:ss")}
                    </Typography>
                    {displayUpdatedText()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Box m={1} ml={3}>
                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={() => handleGetHistory(props.incident.id)}
                  >
                    See All Updates
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box mb={3}>
                  <ViewEditSelect
                    props={{
                      id: "status",
                      label: "Status",
                      tooltip:
                        "VCC Incident Status indicates the progress of resolving the VCC Incident through multiple states until the VCC Incident is closed",
                      initialValue: props.incident.status,
                      isEdit: props.isEdit,
                      handleInputChange: (newValue, field) =>
                        props.handleInputChange(newValue, field),
                    }}
                  >
                    <MenuItem value={IncidentStatus.OPEN}>
                      {IncidentStatus.OPEN}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.INCIDENT_VERIFIED}>
                      {IncidentStatus.INCIDENT_VERIFIED}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.RESPONSE_DISPATCHED}>
                      {IncidentStatus.RESPONSE_DISPATCHED}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.RESPONSE_ON_SCENE}>
                      {IncidentStatus.RESPONSE_ON_SCENE}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.CM_STRATEGY_IMPLEMENTED}>
                      {IncidentStatus.CM_STRATEGY_IMPLEMENTED}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.ROADWAY_CLEARED}>
                      {IncidentStatus.ROADWAY_CLEARED}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.INCIDENT_CLEARED}>
                      {IncidentStatus.INCIDENT_CLEARED}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.NORMAL_FLOW}>
                      {IncidentStatus.NORMAL_FLOW}
                    </MenuItem>
                    <MenuItem value={IncidentStatus.CLOSED}>
                      {IncidentStatus.CLOSED}
                    </MenuItem>
                  </ViewEditSelect>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <Grid container id="details">
            <Grid item xs={12}>
              <Box ml={1} mr={1} mt={1}>
                <IncidentFactors
                  props={{
                    selectedIncFactors: props.incident.factors,
                    isEdit: props.isEdit,
                    updateSelected: (newFactors) =>
                      props.handleInputChange(newFactors, "factors"),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={11}>
              <ViewEditTextField
                props={{
                  id: "location",
                  label: "Location",
                  initialValue: props.incident.location,
                  isEdit: props.isEdit,
                  multiline: true,
                  adornment: <VccGeocoder props={{
                    isEdit: props.isEdit,
                    handleSelect: props.handleGeocoderSelect
                  }}/>,
                  handleInputChange: (newValue, field) =>
                    props.handleInputChange(newValue, field),
                }}
              />
            </Grid>
            <Grid item xs={1}>
                <Tooltip title="Click to update Incident location on map">
                  <Box mt={props.isEdit ? 3 : 4} ml={1}>
                    <IconButton disabled={!props.isEdit} onClick={handleSetMapEditLocation}>
                      <EditLocationAltIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Box mt={4} mb={0} ml={1}>
                {" "}
                Lane Impacts
              </Box>
              <Box ml={1} mr={1}>
                <Paper variant="outlined" style={{ minHeight: "125px" }}>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={6}>
                      <ViewEditTextField
                        props={{
                          id: "laneCount",
                          label: "Number of Lanes Impacted",
                          tooltip:
                            "Use this field to enter the number of lanes impacted by incident",
                          initialValue: props.incident.laneCount,
                          isEdit: props.isEdit,
                          handleInputChange: (newValue, field) =>
                            props.handleInputChange(newValue, field),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ViewEditTextField
                        props={{
                          id: "laneDirectionAndBlockage",
                          label: "Lane Direction and Mileage of Blockage",
                          tooltip:
                            "Use this field to enter lane blockage details, such as # of lanes blocked, direction impacted, etc",
                          initialValue: props.incident.laneDirectionAndBlockage,
                          isEdit: props.isEdit,
                          handleInputChange: (newValue, field) =>
                            props.handleInputChange(newValue, field),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ViewEditTextField
                        props={{
                          id: "laneRoadwayDescription",
                          label: "Roadway Description",
                          tooltip:
                            "Use this field to describe the roadway such as freeway, arterial, etc",
                          initialValue: props.incident.laneRoadwayDescription,
                          isEdit: props.isEdit,
                          handleInputChange: (newValue, field) =>
                            props.handleInputChange(newValue, field),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ViewEditTextField
                        props={{
                          id: "laneOtherInformation",
                          label: "Other Information",
                          initialValue: props.incident.laneOtherInformation,
                          isEdit: props.isEdit,
                          handleInputChange: (newValue, field) =>
                            props.handleInputChange(newValue, field),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ViewEditTextField
                props={{
                  id: "type",
                  label: "VCC Incident Type",
                  initialValue: props.incident.type,
                  isEdit: props.isEdit,
                  handleInputChange: (newValue, field) =>
                    props.handleInputChange(newValue, field),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ViewEditTextField
                props={{
                  id: "incidentDetails",
                  label: "VCC Incident Details",
                  tooltip:
                    "Use this field to enter details about the VCC Incident, such as description of vehicles involved, number and types of injuries, etc.",
                  initialValue: props.incident.incidentDetails,
                  isEdit: props.isEdit,
                  multiline: true,
                  handleInputChange: (newValue, field) =>
                    props.handleInputChange(newValue, field),
                }}
              />
            </Grid>
            <Grid item xs={3}>
                <ViewEditUserAutocomplete
                  props={{
                    id: "incidentCommander",
                    correlatedId: "commanderAgency",
                    label: "Incident Commander",
                    tooltip:
                      "If known, add the VCC Incident Commander for this VCC Incident",
                    initialValue: props.incident.incidentCommander || "",
                    isEdit: props.isEdit,
                    userOptions: userOptions,
                    enableUserAutocomplete: true,
                    handleInputChange: (newValue, field) =>
                      props.handleInputChange(newValue, field),
                  }}
              />
            </Grid>
            <Grid item xs={3}>
              <ViewEditSelect
                props={{
                  id: "commanderAgency",
                  label: "Commander Agency",
                  tooltip:
                    "If known, add the agency of the VCC Incident Commander for this VCC Incident",
                  initialValue: props.incident.commanderAgency,
                  isEdit: props.isEdit,
                  handleInputChange: (newValue, field) =>
                    props.handleInputChange(newValue, field),
                }}
              >
                <MenuItem value={Agency.NOT_SELECTED}>(None)</MenuItem>
                <MenuItem value={Agency.SFD}>{Agency.SFD}</MenuItem>
                <MenuItem value={Agency.SPD}>{Agency.SPD}</MenuItem>
                <MenuItem value={Agency.WSP}>{Agency.WSP}</MenuItem>
                <MenuItem value={Agency.WSDOT}>{Agency.WSDOT}</MenuItem>
                <MenuItem value={Agency.SDOT}>{Agency.SDOT}</MenuItem>
                <MenuItem value={Agency.KCM}>{Agency.KCM}</MenuItem>
                <MenuItem value={Agency.ST}>{Agency.ST}</MenuItem>
                <MenuItem value={Agency.NWSA}>{Agency.NWSA}</MenuItem>
                <MenuItem value={Agency.Port}>{Agency.Port}</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
                { !(Object.values(Agency).includes(props.incident.commanderAgency as Agency)) && (
                    <MenuItem value={props.incident.commanderAgency}>{props.incident.commanderAgency}</MenuItem>
                  )}
              </ViewEditSelect>
              <CustomSelectInput props={{
                isOpen: props.incident.commanderAgency === "Other",
                title: "Enter New Commander Agency",
                fieldName: "Commander Agency",
                handleUpdate: (inputUpdate: string) => props.handleInputChange(inputUpdate, "commanderAgency"),
                handleCancel: () => props.handleInputChange("","commanderAgency"),
              }} />
            </Grid>
            <Grid item xs={3}>
              <ViewEditUserAutocomplete
                props={{
                  id: "leadPio",
                  correlatedId: "leadPioAgency",
                  label: "Lead PIO",
                  tooltip:
                    "If known, add the Lead PIO for this VCC Incident",
                  initialValue: props.incident.leadPio || "",
                  isEdit: props.isEdit,
                  userOptions: userOptions,
                  enableUserAutocomplete: true,
                  handleInputChange: (newValue, field) =>
                    props.handleInputChange(newValue, field),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <ViewEditSelect
                props={{
                  id: "leadPioAgency",
                  label: "Lead PIO Agency",
                  tooltip:
                    "If known, add the agency of the Lead PIO for this VCC Incident",
                  initialValue: props.incident.leadPioAgency,
                  isEdit: props.isEdit,
                  handleInputChange: (newValue, field) =>
                    props.handleInputChange(newValue, field),
                }}
              >
                <MenuItem value={Agency.NOT_SELECTED}>(None)</MenuItem>
                <MenuItem value={Agency.SFD}>{Agency.SFD}</MenuItem>
                <MenuItem value={Agency.SPD}>{Agency.SPD}</MenuItem>
                <MenuItem value={Agency.WSP}>{Agency.WSP}</MenuItem>
                <MenuItem value={Agency.WSDOT}>{Agency.WSDOT}</MenuItem>
                <MenuItem value={Agency.SDOT}>{Agency.SDOT}</MenuItem>
                <MenuItem value={Agency.KCM}>{Agency.KCM}</MenuItem>
                <MenuItem value={Agency.ST}>{Agency.ST}</MenuItem>
                <MenuItem value={Agency.NWSA}>{Agency.NWSA}</MenuItem>
                <MenuItem value={Agency.Port}>{Agency.Port}</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
                { !(Object.values(Agency).includes(props.incident.leadPioAgency as Agency)) && (
                    <MenuItem value={props.incident.leadPioAgency}>{props.incident.leadPioAgency}</MenuItem>
                  )}
              </ViewEditSelect>
              <CustomSelectInput props={{
                isOpen: props.incident.leadPioAgency === "Other",
                title: "Enter New Lead PIO Agency",
                fieldName: "Lead PIO Agency",
                handleUpdate: (inputUpdate: string) => props.handleInputChange(inputUpdate, "leadPioAgency"),
                handleCancel: () => props.handleInputChange("","leadPioAgency"),
              }} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <ViewEditSelect
                props={{
                  id: "estClearTime",
                  label: "Est. Clearance Time",
                  tooltip:
                    "Set the expected clearance time",
                  initialValue: props.incident.estClearTime,
                  isEdit: props.isEdit,
                  handleInputChange: (newValue, field) =>
                    props.handleInputChange(newValue, field),
                }}
              >
                <MenuItem value={EstClearanceTime.NOT_SELECTED}>
                  (None)
                </MenuItem>
                <MenuItem value={EstClearanceTime.TIME_90_TO_2_HOURS}>
                  {EstClearanceTime.TIME_90_TO_2_HOURS}
                </MenuItem>
                <MenuItem value={EstClearanceTime.TIME_2_TO_4_HOURS}>
                  {EstClearanceTime.TIME_2_TO_4_HOURS}
                </MenuItem>
                <MenuItem value={EstClearanceTime.TIME_4_TO_6_HOURS}>
                  {EstClearanceTime.TIME_4_TO_6_HOURS}
                </MenuItem>
                <MenuItem value={EstClearanceTime.TIME_6_TO_8_HOURS}>
                  {EstClearanceTime.TIME_6_TO_8_HOURS}
                </MenuItem>
                <MenuItem value={EstClearanceTime.TIME_MORE_THAN_8_HOURS}>
                  {EstClearanceTime.TIME_MORE_THAN_8_HOURS}
                </MenuItem>
                <MenuItem value={EstClearanceTime.OTHER}>
                  {EstClearanceTime.OTHER}
                </MenuItem>
                {!(Object.values(EstClearanceTime).includes(props.incident.estClearTime as EstClearanceTime)) && (
                  <MenuItem value={props.incident.estClearTime}>{props.incident.estClearTime}</MenuItem>
                )}
              </ViewEditSelect>
              <CustomSelectInput props={{
                isOpen: props.incident.estClearTime === EstClearanceTime.OTHER,
                title: "Enter New Est. Clearance Time",
                fieldName: "Est. Clearance Time",
                handleUpdate: (inputUpdate: string) => props.handleInputChange(inputUpdate, "estClearTime"),
                handleCancel: () => props.handleInputChange("","estClearTime"),
              }} />
              {props.incident.estClearUpdatedDate && (
                <Box mr={1} mt={1} style={{ float: "right" }}>
                  <Typography variant="caption" style={{ fontSize: "10px" }}>
                    Last Updated By: {NameOrEmailUtil(estClearUpdatedBy)}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <Box mb={2}>
                <ViewEditTextField
                  props={{
                    id: "responseDetails",
                    label: "Response Details",
                    tooltip:
                      "Add information related to the ongoing or planned response to the VCC Incident",
                    initialValue: props.incident.responseDetails,
                    isEdit: props.isEdit,
                    handleInputChange: (newValue, field) =>
                      props.handleInputChange(newValue, field),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1}>
            <AssociatedEvents
                props={{
                  fromIncidentPage: true,
                  isEdit: props.isEdit,
                  incident: props.incident,
                  associatedIdRequests: props.associatedIdRequests,
                  handleAddDispatches: () => null,
                  handleRemoveDispatch: () => null,
                  handleUpdateAssociatedDispatches: props.handleUpdateAssociatedDispatches
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Box m={1}>
                  {!props.isEdit ? (
                    <Tooltip title={isIncFinalized ? "This incident is finalized and cannot be reopened" : ""}>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={props.handleEdit}
                          disabled={editIncDisabled || isIncFinalized}
                          data-rum-id={`incident-model-edit-begin__${props.incident.id}`}
                        >
                          Edit
                        </Button>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={props.handleCancelInc}
                      disabled={!props.isEdit}
                      color="inherit"
                      data-rum-id={`incident-model-edit-cancel__${props.incident.id}`}
                    >
                      {" "}
                      Cancel
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <Button
                    variant="contained"
                    onClick={props.handleSaveInc}
                    color="primary"
                    disabled={!props.isEdit}
                    data-rum-id={`incident-model-edit-save__${props.incident.id}`}
                  >
                    {" "}
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box ml={1} mr={1}>
              <Divider />
            </Box>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={2}>
              <Box m={1}>
                <Typography variant="body2" className={classes.vccItalic}>
                  Elapsed Duration:
                </Typography>
                {isOver90Minutes ? (
                  <Typography style={{ color: "red" }} variant="body2">
                    {elapsedTime}
                  </Typography>
                ) : (
                  <Typography variant="body2">{elapsedTime}</Typography>
                )}
              </Box>
            </Grid>
            <Grid container item xs={10} justifyContent="space-around">
              <Grid item>
                <Tooltip
                  title={
                    "Created " + moment(props.incident.createdDate).fromNow()
                  }
                >
                  <Box m={1} display="flex" alignItems="center">
                    <Typography component={"span"} variant="body2">
                      <Box fontStyle="italic" fontSize="12px" display="inline">
                        Created By:
                      </Box>
                      <br />
                      {NameOrEmailUtil(createdBy)}
                    </Typography>
                    <Box className={classes.userInfoIconBox}>
                      <UserInfoTooltip props={{ user: createdBy }} />
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  title={
                    "Updated " + moment(props.incident.updatedDate).fromNow()
                  }
                >
                  <Box m={1} display="flex" alignItems="center">
                    <Typography component={"span"} variant="body2">
                      <Box fontStyle="italic" fontSize="12px" display="inline">
                        Updated By:
                      </Box>
                      <br />
                      {NameOrEmailUtil(updatedBy)}
                    </Typography>
                    <Box className={classes.userInfoIconBox}>
                      <UserInfoTooltip props={{ user: updatedBy }} />
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <HistoryModal
        props={{
          isOpen: historyOpen,
          recordType: "Incident",
          handleClose: () => setHistoryOpen(false),
        }}
      />
    </>
  );
};

export default IncidentForm;
