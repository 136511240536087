export interface EntityChangeEventModel {
  EntityType: string;
  VCCChangeType: EntityChangeType;
  Id: string;
  Status: string;
  CreatedBy: string;
  CreatedDate: string;
  UpdatedBy: string;
  UpdatedDate: string;
  FieldChanges: VCCFieldChangeDetail[];
  Message?: string;
  MessageType?: string;
}
export interface VCCFieldChangeDetail {
  FieldName: string;
  FieldDisplayName: string;
  FieldType: string;
  OldValue: string;
  NewValue: string;
}

export enum EntityChangeType {
  INSERT = 0,
  MODIFY = 1,
  DELETE = 2,
}
