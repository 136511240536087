import React, { FC } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DispatchEventFeedModel, TMCLog } from "../../../reducers";
import moment from "moment";
import { Link } from "react-router-dom";
import { ShortIdUtil } from "../../../utils/id-utils";
import { Agency } from "../../../enums/agency";

interface AdditionalDetailsProps {
  props: {
    dispatchEvent: DispatchEventFeedModel;
    tmcLog: TMCLog;
  }
}

const AdditionalDetails: FC<AdditionalDetailsProps> = ({ props }) => {
  function setFieldValue(fieldValue: string, fieldType?: string) {
    if (fieldType === "bool") {
      return <Checkbox checked={fieldValue === "True" ? true : false} />;
    } else {
      return (
        <Box fontStyle="italic" display="inline">
          {fieldValue}
        </Box>
      );
    }
  }
  const getFormattedDate = (dateString: string | Date) => {
    const eventDate = moment(dateString);
    if(!dateString){
      return "";
    }
    else if (eventDate.seconds() === 0) {
      return eventDate.format("YYYY-MM-DD HH:mm");
    } else {
      return eventDate.format("YYYY-MM-DD HH:mm:ss");
    }
  };
  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Box m={1}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h6">Event:</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Event Number: </TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {props.dispatchEvent.eventNumber}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Event Start Time:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {getFormattedDate(props.dispatchEvent?.eventDate)}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Updated:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {getFormattedDate(props.dispatchEvent?.lastUpdateDt)}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Agency:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {props.dispatchEvent?.agency}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Event Type:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {props?.dispatchEvent.eventType}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location/Beat:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {props?.dispatchEvent.location}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location Name:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {props?.dispatchEvent.locationName}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cross Street:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {props?.dispatchEvent.crossStreet}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Status:</TableCell>
                    <TableCell>
                      <Box fontStyle="italic" display="inline">
                        {props?.dispatchEvent.status}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Associated VCC Incident:</TableCell>
                    {props?.dispatchEvent.associatedIncId ? (
                      <TableCell>
                        <Link to={"/incident-model/" + props?.dispatchEvent.associatedIncId}>
                          {ShortIdUtil(props?.dispatchEvent.associatedIncId)}
                        </Link>
                        <Box ml={1} fontStyle="italic" display="inline">
                          {" "}
                          Go to VCC Incident
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Box fontStyle="italic" display="inline">
                          None
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                  {props.dispatchEvent.agency === Agency.WSP && (
                    <TableRow>
                      <TableCell>Comment Count:</TableCell>
                      <TableCell>
                        <Box fontStyle="italic" display="inline">
                          {props.dispatchEvent.comments?.length ? props.dispatchEvent.comments?.length : 0}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={1}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h6">Other Information:</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dispatchEvent.additionalDetails?.map((row) => (
                    <TableRow key={row.DisplayName}>
                      <TableCell>{row.DisplayName}</TableCell>
                      <TableCell>{setFieldValue(row.FieldValue)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>Latitude</TableCell>
                    <TableCell>{props?.dispatchEvent.latitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Longitude</TableCell>
                    <TableCell>{props?.dispatchEvent.longitude}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        {props?.dispatchEvent.agency !== Agency.KCM && (
          <Grid item xs={12}>
            <Box m={1}>
              <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h6">
                          Unit Response Details:
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell><Box style={{fontWeight: "bold"}}>Id</Box></TableCell>
                      <TableCell><Box style={{fontWeight: "bold"}}>Type</Box></TableCell>
                      <TableCell><Box style={{fontWeight: "bold"}}>Status</Box></TableCell>
                      <TableCell><Box style={{fontWeight: "bold"}}>Active</Box></TableCell>
                    </TableRow>
                    {props.dispatchEvent.units?.length ? (
                      props.dispatchEvent.units?.map((unit) => (
                        <TableRow key={unit.unitId}>
                          <TableCell>{unit.unitId}</TableCell>
                          <TableCell>{unit.unitType}</TableCell>
                          <TableCell>{unit.status}</TableCell>
                          <TableCell>
                            <Checkbox
                              checked={
                                String(unit.isActive) === "true" ? true : false
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={"noUnits"}>
                        <TableCell colSpan={4} align="center">
                          <Box fontStyle="italic" fontSize="12px">
                            No Units Involved
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        )}
        {props?.dispatchEvent.agency === Agency.WSP && (
          <Grid item xs={12}>
            <Box m={1}>
              <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant="h6">
                          Comments:
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width={"75%"}><Box style={{fontWeight: "bold"}}>Comment</Box></TableCell>
                      <TableCell><Box style={{fontWeight: "bold"}}>Timestamp</Box></TableCell>
                    </TableRow>
                    {props.dispatchEvent.comments?.length ? (
                      props.dispatchEvent.comments?.map((comment) => (
                        <TableRow key={comment.text}>
                          <TableCell>{comment.text}</TableCell>
                          <TableCell>{moment(comment.timestamp).format("LL, HH:mm:ss")}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={"noComments"}>
                        <TableCell colSpan={4} align="center">
                          <Box fontStyle="italic" fontSize="12px">
                            No Comments
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        )}
        {props?.dispatchEvent.agency === Agency.WSP && (
          <>
            <Grid item xs={6}>
              <Box m={1}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="h6">TMC Event Information:</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {props.tmcLog.logEventID ? (
                      <TableBody>
                      <TableRow>
                        <TableCell>Log Event Id: </TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props.tmcLog.logEventID}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Last Modified Time:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {getFormattedDate(props.tmcLog?.lastModifiedTime)}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Incident Type:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props.tmcLog.incidentType}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Problem Description:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props.tmcLog?.problemDescription}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Location:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.location}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status Description:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.statusDescription}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>State Route Id:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.stateRouteID}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>SRMP:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.srmp}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Roadway Compass Direction:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.roadwayCompassDirection}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    ):(
                      <TableBody>                        
                        <TableRow key={"noComments"}>
                          <TableCell colSpan={4} align="center">
                            <Box fontStyle="italic" fontSize="12px">
                              No Additional TMC Event Information
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box m={1}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="h6">TMC Other Information:</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {props.tmcLog.logEventID ? (<TableBody>
                      <TableRow>
                        <TableCell>WSDOT Region Name: </TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props.tmcLog.wsdotRegionName}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Log Event Date:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {getFormattedDate(props.tmcLog?.logEventDate)}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Log Event Remark:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props.tmcLog.logEventRemark}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>CAD Number:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props.tmcLog?.cadNumber}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Country Name:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.countyName}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Latitude:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.latitude}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Longitude:</TableCell>
                        <TableCell>
                          <Box fontStyle="italic" display="inline">
                            {props?.tmcLog.longitude}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ):(
                      <TableBody>                        
                        <TableRow key={"noComments"}>
                          <TableCell colSpan={4} align="center">
                            <Box fontStyle="italic" fontSize="12px">
                              No Additional TMC Other Information
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={1}>
                <TableContainer component={Paper}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="h6">
                            TMC Log Comments:
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell width={"75%"}><Box style={{fontWeight: "bold"}}>Comment</Box></TableCell>
                        <TableCell><Box style={{fontWeight: "bold"}}>Timestamp</Box></TableCell>
                      </TableRow>
                      {props.tmcLog.logComments?.length ? (
                        props.tmcLog.logComments?.map((tmcComment) => (
                          <TableRow key={tmcComment.entryText}>
                            <TableCell>{tmcComment.entryText}</TableCell>
                            <TableCell>{moment(tmcComment.entryTime).format("LL, HH:mm:ss")}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow key={"noComments"}>
                          <TableCell colSpan={4} align="center">
                            <Box fontStyle="italic" fontSize="12px">
                              No TMC Log Comments
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AdditionalDetails;
