import axios, { AxiosResponse } from "axios";
import { PagingKeys, PinEvent } from "../../reducers";

export const getDispatchEvent = (id: string): any => {
  return axios.get("/vccDispatchEvents/v1/" + id);
};

export const getDispatchEvents = (
  fromTimeStamp: Date,
  toTimeStamp: Date,
  showOpenDispatchesOnly: boolean,
  region: string,
  area: string,
  pageSize: string,
  pagingKeys: PagingKeys
): Promise<AxiosResponse> => {
  let queryParams: {
    fromTimeStamp: Date;
    toTimeStamp: Date;
    showOpenDispatchesOnly: boolean;
    region: string;
    area: string;
    pageSize: string;
    pk1: string;
    sk1: string;
    pk2: string;
    sk2: string;
  } = {
    fromTimeStamp,
    toTimeStamp,
    showOpenDispatchesOnly,
    region,
    area,
    pageSize,
    pk1: pagingKeys.pk1,
    sk1: pagingKeys.sk1,
    pk2: pagingKeys.pk2,
    sk2: pagingKeys.sk2,
  };
  return axios.get("/vccDispatchEvents/v2", { params: queryParams });
};

export const getNoteworthyEvents = () => {
  return axios.get("/vccDispatchEvents/v1/noteworthy");
};

export const getPinnedDispatchEvents = (
  username: string,
  fromTimeStamp: Date,
  toTimeStamp: Date
) => {
  let queryParams: { fromTimeStamp: Date; toTimeStamp: Date } = {
    fromTimeStamp: fromTimeStamp,
    toTimeStamp: toTimeStamp,
  };
  return axios.get("/vccDispatchEvents/v1/pins/" + username, {
    params: queryParams,
  });
};

export const getTMCLog = ( wspId: string ) => {
  return axios.get("/vccDispatchEvents/v1/" + wspId + "/tmclog")
}

export const getPinnedDispatchEvent = (
  username: string,
  dispatchId: string
) => {
  return axios.get("/vccDispatchEvents/v1/pins/" + username + "/" + dispatchId);
};

export const setPinnedDispatchEvents = (
  username: string,
  pinnedEvents: PinEvent[]
) => {
  return axios.post("/vccDispatchEvents/v1/pins", {
    userId: username,
    pinEvents: pinnedEvents,
    updateAction: "ADD",
  });
};

export const removePinnedDispatchEvent = (username: string, dispatchId: string ) => {
  return axios.delete("/vccDispatchEvents/v1/pins/" + username + "/" + dispatchId);
}

export const getFeedStatus = (): any => {
  return axios.get("/system/dispatchfeed/availability");
};
