import { Grid } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import { useSelector } from "react-redux";
import { Agency } from "../../../../enums/agency";
import { FeedStatusLevel } from "../../../../enums/feed-status";
import { IRootReducer } from "../../../../reducers";
import AgencyFilter from "./agency-filter";


interface AgencyFiltersProps {
  props: {
    agencyFilters: string[];
    handleAgencySelect: (event: ChangeEvent<HTMLInputElement>) => void;
  };
}

const AgencyFilters: FC<AgencyFiltersProps> = ({ props }) => {
  const feedStatus = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.feedStatus
  );

  function isFeedDown(agency: string) {
    let feed = feedStatus.find((f) => f.agency === agency);
    if (feed) {
      return feed.status !== FeedStatusLevel.OK;
    }

    // Default to not showing the indicator if we don't have the feed status retrieved
    return false;
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <AgencyFilter
            props={{
              agency: Agency.KCM,
              agencyFilters: props.agencyFilters,
              isFeedDown: isFeedDown(Agency.KCM),
              handleAgencySelect: props.handleAgencySelect,
            }}
          />
        </Grid>
        <Grid item>
          <AgencyFilter
            props={{
              agency: Agency.SFD,
              agencyFilters: props.agencyFilters,
              isFeedDown: isFeedDown(Agency.SFD),
              handleAgencySelect: props.handleAgencySelect,
            }}
          />
        </Grid>
        <Grid item>
          <AgencyFilter
            props={{
              agency: Agency.SPD,
              agencyFilters: props.agencyFilters,
              isFeedDown: isFeedDown(Agency.SPD),
              handleAgencySelect: props.handleAgencySelect,
            }}
          />
        </Grid>
        <Grid item>
          <AgencyFilter
            props={{
              agency: Agency.WSP_I5,
              agencyFilters: props.agencyFilters,
              isFeedDown: isFeedDown(Agency.WSP),
              tooltip: "Dispatch information for I-5 Corridor from Milepost 201 NB to Milepost 150 SB",
              handleAgencySelect: props.handleAgencySelect,
            }}
          />
        </Grid>
        <Grid item>
          <AgencyFilter
            props={{
              agency: Agency.WSP_NW,
              agencyFilters: props.agencyFilters,
              isFeedDown: isFeedDown(Agency.WSP),
              tooltip: "Dispatch information from King, Snohomish, Skagit, Island, and Whatcom counties",
              handleAgencySelect: props.handleAgencySelect,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AgencyFilters;