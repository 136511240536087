import { Box, Checkbox, Chip, FormControlLabel, Grid, Tooltip} from "@mui/material";

import React, { ChangeEvent, FC} from "react";

import { Agency } from "../../../enums/agency";

import MobilityStrategyAgencyFilter from "./mobility-strategey-filter";



interface MobilityStrategyAgencyFiltersProps {
  props: {
    agencyFilters: string[];
    handleSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleStatusSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
}

const MobilityStrategyAgencyFilters: FC<MobilityStrategyAgencyFiltersProps> = ({ props }) => {

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.KCM,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.SFD,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.SPD,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.WSP,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.WSDOT,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.SDOT,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.ST,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.Port,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.NWSA,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <MobilityStrategyAgencyFilter
            props={{
              agency: Agency.OTHER,
              agencyFilters: props.agencyFilters,
              handleSwitchChange: props.handleSwitchChange,
            }}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Displays only active mobility strategies when toggled">
              <Box ml={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Open Only"
                      
                      onChange={props.handleStatusSwitchChange}
                      checkedIcon={<Chip color={"primary"} variant="filled" label="Open Only"/>} 
                      icon={
                        <Chip
                          variant="outlined"
                          label="Open Only"
                        />
                      }
                    />
                  }
                  label=""
                />
              </Box>
              </Tooltip>
            </Grid>
      </Grid>
    </>
  );
};

export default MobilityStrategyAgencyFilters;