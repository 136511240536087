const config = {
  RUM_REGION: process.env.REACT_APP_RUM_REGION,
  RUM_IDENTITY_POOL: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
  RUM_ROLE_ARN: process.env.REACT_APP_RUM_ROLE_ARN,
  RUM_ID: process.env.REACT_APP_RUM_ID,
  RUM_ENDPOINT: process.env.REACT_APP_RUM_ENDPOINT,
  RUM_VERSION: '1.0.0'
};

export default config;
