import { AnyAction } from "redux";
import { IUserInfoState } from "..";
import { types } from "../../actions";
import { NotificationGroup, UserInfoModel, UserNotifGroups } from "../states/user-info";

export const initialUserState: IUserInfoState = {
  userInfo: {} as UserInfoModel,
  userCache: [] as UserInfoModel[],
  allReadyOpGroups: [] as NotificationGroup[],
  userReadyOpGroups: [] as UserNotifGroups[],
  successMessage: "",
  failureMessage: "",
};

function userInfoReducer(
  state = initialUserState,
  action: AnyAction
): IUserInfoState {
  switch (action.type) {
    case types.GET_USER_INFO_SUCCESS:
      var shouldAddToCache: boolean =
        state.userCache.find(
          (u) => u.email === action.payload.userInfo.email
        ) === undefined;
      return {
        ...state,
        userInfo: action.payload.userInfo,
        userCache: shouldAddToCache
          ? [...state.userCache, action.payload.userInfo]
          : [...state.userCache],
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_USER_INFO_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.GET_ALL_READYOP_GROUPS_SUCCESS:
      return {
        ...state,
        allReadyOpGroups: action.payload.notifGroups,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_ALL_READYOP_GROUPS_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.GET_USER_READYOP_GROUPS_SUCCESS:
      return {
        ...state,
        userReadyOpGroups: action.payload.userNotifGroups,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_USER_READYOP_GROUPS_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.UPDATE_USER_READYOP_GROUPS_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.UPDATE_USER_READYOP_GROUPS_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.updateUserProfileSuccessMessage,
        failureMessage: "",
      };
    case types.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.updateUserProfileFailureMessage,
      };
    default:
      return state;
  }
}

export default userInfoReducer;
