import { Auth } from "aws-amplify";
import { takeEvery, call, put, fork, select } from "redux-saga/effects";
import * as actions from "../../actions";
import {
  getDispatchFeedEvent,
  getDispatchFeedEventsPayload,
  getPinnedDispatchEventPayload,
  getPinnedDispatchEventsPayload,
  getTMCLogPayload,
  pinDispatchEventPayload,
  removePinDispatchEventPayload,
  setFeedAreaPayload,
  setFeedRegionPayload,
  setFeedViewPayload,
  setShowOpenOnlyPayload,
  setTimeFramePayload,
  types,
} from "../../actions";
import * as api from "../../api";
import { LocalStorage } from "../../enums/local-storage";
import {
  DispatchFeedStoreError,
  DispatchFeedStoreSuccess,
} from "../../enums/store-messages/dispatch";
import { IRootReducer } from "../../reducers";
import {
  DispatchEventFeedModel,
  FeedStatus,
  PagingKeys,
  PagingResponse,
  PinEvent,
  TMCLog,
} from "../../reducers/states/dispatch-event-feed-state";
import { IsResponseValidUtil } from "../../utils/api-utils";

export const DEFAULT_TIME_FRAME: number = 5;
export const DEFAULT_FEED_VIEW: string = "All";
export const DEFAULT_SHOW_OPEN_DISPATCHES_ONLY = true;
export const DEFAULT_FEED_AREA: string = "I5";
export const DEFAULT_FEED_REGION: string = "";

export function* getSingleDispatchEvent(action: {
  type: typeof types.GET_DISPATCH_FEED_EVENT;
  payload: getDispatchFeedEvent;
}): Generator<any, void, unknown> {
  try {
    // Clear any previous data from appearing before the latest data loads
    yield put(actions.clearCurrentDispatchEvent());
    const result: any = yield call(api.getDispatchEvent, action.payload.id);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: DispatchEventFeedModel = result.data;
        yield put(actions.getDispatchFeedEventSuccess(apiResultPayload));
      }
    }
  } catch (e) {
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchDispatchFeedEvent() {
  yield takeEvery(
    actions.types.GET_DISPATCH_FEED_EVENT,
    getSingleDispatchEvent
  );
}

export function* getDispatchFeedEvents(action: {
  type: typeof types.GET_DISPATCH_FEED_EVENTS;
  payload: getDispatchFeedEventsPayload;
}): Generator<any, void, unknown> {
  try {
    let pagingKeys = {pk1: "", sk1: "", pk2: "", sk2: ""} as PagingKeys
    if(action.payload.pageResponse){
      let existingPagingKeys: any = yield select((state: IRootReducer) =>state.dispatchEventFeedReducer.dispatchEvents.pagingKeys);
      pagingKeys = existingPagingKeys;
    }

    const result: any = yield call(
      api.getDispatchEvents,
      action.payload.fromTimeStamp,
      action.payload.toTimeStamp,
      action.payload.showOpenDispatchesOnly,
      action.payload.region,
      action.payload.area,
      "", // Defaulting to not use page size
      pagingKeys, 
    );
    
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: PagingResponse<DispatchEventFeedModel[]> = result.data;
        if(action.payload.pageResponse){
          // If a paged response - append the new data to the existing data in the redux store
          let existingDispatchEvents = yield select((state: IRootReducer) => state.dispatchEventFeedReducer.dispatchEvents.data);
          yield put(actions.getDispatchFeedEventsSuccess({
            pagingKeys: apiResultPayload.pagingKeys, 
            data: [...apiResultPayload.data, ...existingDispatchEvents as DispatchEventFeedModel[]]
          }));
          if(action.payload.setGeoData){
            yield put(actions.setDispatchAPIGeoRequest(apiResultPayload.data))
          }
        }
        else{
          // Otherwise supply the normal API response
          yield put(actions.getDispatchFeedEventsSuccess(apiResultPayload));
          if(action.payload.setGeoData){
            yield put(actions.setDispatchAPIGeoRequest(apiResultPayload.data))
          }
        }
      } else {
        yield put(
          actions.getDispatchFeedEventsFailure(
            DispatchFeedStoreError.DISPATCH_EVENT_FEED_GET
          )
        );
      }
    } else {
      yield put(
        actions.getDispatchFeedEventsFailure(
          DispatchFeedStoreError.DISPATCH_EVENT_FEED_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getDispatchFeedEventsFailure(
        DispatchFeedStoreError.DISPATCH_EVENT_FEED_GET
      )
    );
  }
}
function* watchDispatchFeedEvents() {
  yield takeEvery(
    actions.types.GET_DISPATCH_FEED_EVENTS,
    getDispatchFeedEvents
  );
}

export function* getNoteworthyEvents(): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getNoteworthyEvents);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        // Review: Move this filter and a sort into the API Lambda
        let apiResultPayload: DispatchEventFeedModel[] = result.data;
        let onlyOpen = yield select(
          (state: IRootReducer) =>
            state.dispatchEventFeedReducer.showOpenDispatchesOnly
        );
        if (onlyOpen) {
          let openEvents = apiResultPayload.filter((r) => r.status === "OPEN");
          yield put(
            actions.getNoteworthyEventsSuccess(
              [...openEvents].sort(
                (d1, d2) =>
                  new Date(d2.lastUpdateDt).getTime() -
                  new Date(d1.lastUpdateDt).getTime()
              )
            )
          );
        } else {
          yield put(
            actions.getNoteworthyEventsSuccess(
              [...apiResultPayload].sort(
                (d1, d2) =>
                  new Date(d2.lastUpdateDt).getTime() -
                  new Date(d1.lastUpdateDt).getTime()
              )
            )
          );
        }
      } else {
        yield put(
          actions.getNoteworthyEventsFailure(
            DispatchFeedStoreError.NOTEWORTHY_EVENT_FEED_GET
          )
        );
      }
    } else {
      yield put(
        actions.getNoteworthyEventsFailure(
          DispatchFeedStoreError.NOTEWORTHY_EVENT_FEED_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getNoteworthyEventsFailure(
        DispatchFeedStoreError.NOTEWORTHY_EVENT_FEED_GET
      )
    );
  }
}
function* watchGetNoteworthyEvents() {
  yield takeEvery(
    actions.types.GET_NOTEWORTHY_DISPATCH_EVENTS,
    getNoteworthyEvents
  );
}

export function* getTMCLog(action: {
  type: typeof types.GET_TMC_LOG;
  payload: getTMCLogPayload;
}): Generator<any, void, unknown> {
  try {
    // Clear the current TMCLog loaded in
    yield put(actions.clearCurrentTMCLog());
    const result: any = yield call(api.getTMCLog, action.payload.wspId);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: TMCLog = result.data;
        yield put(actions.getTMCLogSuccess(apiResultPayload));
      }
    }
    else{
      yield put(actions.getTMCLogFailure(DispatchFeedStoreError.TMC_LOG_GET));
    }
  } catch (e) {
    yield put(actions.getTMCLogFailure(DispatchFeedStoreError.TMC_LOG_GET));
  }
}
function* watchGetTMCLog() {
  yield takeEvery(
    actions.types.GET_TMC_LOG,
    getTMCLog
  );
}


export function* getPinnedDispatchEvents(action: {
  type: typeof types.GET_PINNED_DISPATCH_EVENTS;
  payload: getPinnedDispatchEventsPayload;
}): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const email = currentUser.getIdToken().payload["custom:vccEmail"];
    const result: any = yield call(
      api.getPinnedDispatchEvents,
      email,
      action.payload.fromTimeStamp,
      action.payload.toTimeStamp
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let pinnedEvents: DispatchEventFeedModel[] = result.data;
        yield put(actions.getPinnedDispatchEventsSuccess(pinnedEvents));
      } else {
        yield put(
          actions.getPinnedDispatchEventsFailure(
            DispatchFeedStoreError.DISPATCH_EVENTS_PIN_GET
          )
        );
      }
    } else {
      yield put(
        actions.getPinnedDispatchEventsFailure(
          DispatchFeedStoreError.DISPATCH_EVENTS_PIN_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getPinnedDispatchEventsFailure(
        DispatchFeedStoreError.DISPATCH_EVENTS_PIN_GET
      )
    );
  }
}
function* watchGetPinnedDispatchEvents() {
  yield takeEvery(
    actions.types.GET_PINNED_DISPATCH_EVENTS,
    getPinnedDispatchEvents
  );
}

export function* getPinnedDispatchEvent(action: {
  type: typeof types.GET_PINNED_DISPATCH_EVENT;
  payload: getPinnedDispatchEventPayload;
}): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const email = currentUser.getIdToken().payload["custom:vccEmail"];
    const result: any = yield call(
      api.getPinnedDispatchEvent,
      email,
      action.payload.dispatchId,
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let pinnedEvent: PinEvent = result.data;
        yield put(actions.getPinnedDispatchEventSuccess(pinnedEvent));
      } else {
        yield put(
          actions.getPinnedDispatchEventFailure(
            DispatchFeedStoreError.DISPATCH_EVENT_PIN_GET
          )
        );
      }
    } else {
      yield put(
        actions.getPinnedDispatchEventFailure(
          DispatchFeedStoreError.DISPATCH_EVENT_PIN_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getPinnedDispatchEventFailure(
        DispatchFeedStoreError.DISPATCH_EVENT_PIN_GET
      )
    );
  }
}
function* watchGetPinnedDispatchEvent() {
  yield takeEvery(
    actions.types.GET_PINNED_DISPATCH_EVENT,
    getPinnedDispatchEvent
  );
}

export function* pinDispatchEvents(action: {
  type: typeof types.PIN_DISPATCH_EVENT;
  payload: pinDispatchEventPayload;
}): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const email = currentUser.getIdToken().payload["custom:vccEmail"];
    const result: any = yield call(
      api.setPinnedDispatchEvents,
      email,
      action.payload.eventsToPin
    );
    if (result && result.status === 200) {
      yield put(
        actions.pinDispatchEventSuccess(
          DispatchFeedStoreSuccess.DISPATCH_EVENT_PIN_SAVE
        )
      );
    } else {
      yield put(
        actions.pinDispatchEventFailure(
          DispatchFeedStoreError.DISPATCH_EVENT_PIN_SAVE
        )
      );
    }
  } catch (e) {
    yield put(
      actions.pinDispatchEventFailure(
        DispatchFeedStoreError.DISPATCH_EVENT_PIN_SAVE
      )
    );
  }
}
function* watchPinDispatchEvents() {
  yield takeEvery(actions.types.PIN_DISPATCH_EVENT, pinDispatchEvents);
}


export function* removePinDispatchEvent(action: {
  type: typeof types.REMOVE_PIN_DISPATCH_EVENT;
  payload: removePinDispatchEventPayload;
}): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const email = currentUser.getIdToken().payload["custom:vccEmail"];
    const result: any = yield call(
      api.removePinnedDispatchEvent,
      email,
      action.payload.dispatchId
    );
    if (result && result.status === 200) {
      yield put(
        actions.removePinDispatchEventSuccess(
          DispatchFeedStoreSuccess.DISPATCH_EVENT_PIN_REMOVE
        )
      );
    } else {
      yield put(
        actions.removePinDispatchEventFailure(
          DispatchFeedStoreError.DISPATCH_EVENT_PIN_SAVE
        )
      );
    }
  } catch (e) {
    yield put(
      actions.removePinDispatchEventFailure(
        DispatchFeedStoreError.DISPATCH_EVENT_PIN_SAVE
      )
    );
  }
}
function* watchRemovePinDispatchEvent() {
  yield takeEvery(actions.types.REMOVE_PIN_DISPATCH_EVENT, removePinDispatchEvent);
}


export function* getDispatchFeedStatus(): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getFeedStatus);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: FeedStatus[] = result.data;
        yield put(actions.getFeedStatusSuccess(apiResultPayload));
      }
    }
  } catch (e) {
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchDispatchFeedStatus() {
  yield takeEvery(
    actions.types.GET_DISPATCH_FEED_STATUS,
    getDispatchFeedStatus
  );
}

export function* initTimeFrame(): Generator<any, void, unknown> {
  try {
    let localTimeFrame: number | null = +localStorage.getItem(
      LocalStorage.TIME_FRAME
    )!;
    if (localTimeFrame) {
      yield put(actions.setTimeFrameSuccess(localTimeFrame));
    } else {
      localStorage.setItem(
        LocalStorage.TIME_FRAME,
        JSON.stringify(DEFAULT_TIME_FRAME)
      );
      yield put(actions.setTimeFrameSuccess(DEFAULT_TIME_FRAME));
    }
  } catch (e) {
    yield put(actions.setTimeFrameSuccess(DEFAULT_TIME_FRAME));
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchInitTimeFrame() {
  yield takeEvery(actions.types.INIT_TIME_FRAME, initTimeFrame);
}

export function* setTimeFrame(action: {
  type: typeof types.SET_TIME_FRAME;
  payload: setTimeFramePayload;
}): Generator<any, void, unknown> {
  try {
    localStorage.setItem(
      LocalStorage.TIME_FRAME,
      "" + action.payload.newTimeFrame
    );
    yield put(actions.setTimeFrameSuccess(action.payload.newTimeFrame));
  } catch (e) {
    yield put(actions.setTimeFrameSuccess(action.payload.newTimeFrame));
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchSetTimeFrame() {
  yield takeEvery(actions.types.SET_TIME_FRAME, setTimeFrame);
}

export function* initFeedView(): Generator<any, void, unknown> {
  try {
    let localFeedView: string | null = localStorage.getItem(
      LocalStorage.FEED_VIEW
    )!;

    if (localFeedView) {
      yield put(actions.setFeedViewSuccess(localFeedView));
    } else {
      localStorage.setItem(LocalStorage.FEED_VIEW, DEFAULT_FEED_VIEW);
      yield put(actions.setFeedViewSuccess(DEFAULT_FEED_VIEW));
    }
  } catch (e) {
    yield put(actions.setFeedViewSuccess(DEFAULT_FEED_VIEW));
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchInitFeedView() {
  yield takeEvery(actions.types.INIT_TIME_FRAME, initFeedView);
}

export function* setFeedView(action: {
  type: typeof types.SET_FEED_VIEW;
  payload: setFeedViewPayload;
}): Generator<any, void, unknown> {
  try {
    localStorage.setItem(
      LocalStorage.FEED_VIEW,
      "" + action.payload.newFeedView
    );
    yield put(actions.setFeedViewSuccess(action.payload.newFeedView));
  } catch (e) {
    yield put(actions.setFeedViewSuccess(action.payload.newFeedView));
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchSetFeedView() {
  yield takeEvery(actions.types.SET_FEED_VIEW, setFeedView);
}

export function* initShowOpenDispatchesOnly(): Generator<any, void, unknown> {
  try {
    let sLocalShowOpenOnly: string | null = localStorage.getItem(
      LocalStorage.SHOW_OPEN_DISPATCHES_ONLY
    )!;

    if (sLocalShowOpenOnly) {
      sLocalShowOpenOnly === "true"
        ? yield put(actions.setShowOpenOnlySuccess(true))
        : yield put(actions.setShowOpenOnlySuccess(false));
    } else {
      localStorage.setItem(
        LocalStorage.SHOW_OPEN_DISPATCHES_ONLY,
        String(DEFAULT_SHOW_OPEN_DISPATCHES_ONLY)
      );
      yield put(
        actions.setShowOpenOnlySuccess(DEFAULT_SHOW_OPEN_DISPATCHES_ONLY)
      );
    }
  } catch (e) {
    yield put(
      actions.setShowOpenOnlySuccess(DEFAULT_SHOW_OPEN_DISPATCHES_ONLY)
    );
  }
}
function* watchInitShowOpenDispatchesOnly() {
  yield takeEvery(
    actions.types.INIT_SHOW_OPEN_DISPATCHES_ONLY,
    initShowOpenDispatchesOnly
  );
}

export function* setShowOpenDispatchOnly(action: {
  type: typeof types.SET_SHOW_OPEN_DISPATCHES_ONLY;
  payload: setShowOpenOnlyPayload;
}): Generator<any, void, unknown> {
  try {
    localStorage.setItem(
      LocalStorage.SHOW_OPEN_DISPATCHES_ONLY,
      "" + action.payload.showOpenOnly
    );
    yield put(actions.setShowOpenOnlySuccess(action.payload.showOpenOnly));
  } catch (e) {
    yield put(actions.setShowOpenOnlySuccess(action.payload.showOpenOnly));
  }
}
function* watchShowOpenDispatchesOnly() {
  yield takeEvery(
    actions.types.SET_SHOW_OPEN_DISPATCHES_ONLY,
    setShowOpenDispatchOnly
  );
}

// Review: Need to also store Agency Filters to leverage default area and region 
// since WSP is split into two buttons - Currently, defaulting to KCM, SPD, SFD, WSP_I5 on and I5
export function* initFeedAreaAndRegion(): Generator<any, void, unknown> {
  try {

    yield put(actions.setFeedAreaSuccess(DEFAULT_FEED_AREA));
    yield put(actions.setFeedRegionSuccess(DEFAULT_FEED_REGION));

    localStorage.setItem(LocalStorage.FEED_AREA, DEFAULT_FEED_AREA);
    localStorage.setItem(LocalStorage.FEED_REGION,DEFAULT_FEED_REGION);

  } catch {
    yield put(actions.setFeedAreaSuccess(DEFAULT_FEED_AREA));
    yield put(actions.setFeedRegionSuccess(DEFAULT_FEED_REGION));
  }
}
function* watchInitFeedAreaAndRegion() {
  yield takeEvery(
    actions.types.INIT_FEED_AREA_AND_REGION,
    initFeedAreaAndRegion
  );
}

export function* setFeedArea(action: {
  type: typeof types.SET_FEED_AREA;
  payload: setFeedAreaPayload;
}): Generator<any, void, unknown> {
  try {
    localStorage.setItem(
      LocalStorage.FEED_AREA,
      "" + action.payload.area
    );
    yield put(actions.setFeedAreaSuccess(action.payload.area));
  } catch (e) {
    yield put(actions.setFeedAreaSuccess(action.payload.area));
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchSetFeedArea() {
  yield takeEvery(actions.types.SET_FEED_AREA, setFeedArea);
}

export function* setFeedRegion(action: {
  type: typeof types.SET_FEED_REGION;
  payload: setFeedRegionPayload;
}): Generator<any, void, unknown> {
  try {
    localStorage.setItem(
      LocalStorage.FEED_REGION,
      "" + action.payload.region
    );
    yield put(actions.setFeedRegionSuccess(action.payload.region));
  } catch (e) {
    yield put(actions.setFeedRegionSuccess(action.payload.region));
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchSetFeedRegion() {
  yield takeEvery(actions.types.SET_FEED_REGION, setFeedRegion);
}

const dispatchEventFeedSagas = [
  fork(watchDispatchFeedEvent),
  fork(watchDispatchFeedEvents),
  fork(watchGetNoteworthyEvents),
  fork(watchGetTMCLog),
  fork(watchPinDispatchEvents),
  fork(watchRemovePinDispatchEvent),
  fork(watchGetPinnedDispatchEvent),
  fork(watchGetPinnedDispatchEvents),
  fork(watchDispatchFeedStatus),
  fork(watchInitTimeFrame),
  fork(watchSetTimeFrame),
  fork(watchSetFeedView),
  fork(watchInitFeedView),
  fork(watchInitShowOpenDispatchesOnly),
  fork(watchShowOpenDispatchesOnly),
  fork(watchInitFeedAreaAndRegion),
  fork(watchSetFeedArea),
  fork(watchSetFeedRegion),
];

export default dispatchEventFeedSagas;
