import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RoomIcon from "@mui/icons-material/Room";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Link } from "react-router-dom";
import React, { FC, useEffect, useState } from "react";
import { VccIncidentModel } from "../../../../reducers/states/vcc-incident";
import moment from "moment";
import IncidentCardHeader from "./incident-card-header";
import useUserInfo from "../../../../hooks/user-info";
import { NameOrEmailUtil } from "../../../../utils/user-info-utils";
import AssociatedEvents from "../../vcc-incident-detail/associated-events";
import { DispatchEventFeedModel } from "../../../../reducers";
import IncidentCardFactors from "./incident-card-factors";
import { updateViewportRequest } from "../../../../actions/map";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  incidentCard: {
    borderColor: theme.palette.primary.main,
    borderWidth: "2px",
  },
}));

interface IncidentCardProps {
  props: {
    vccIncident: VccIncidentModel;
    selectedIncId?: string;
    dispatchAddtlInfo: DispatchEventFeedModel;
  };
}

const IncidentCard: FC<IncidentCardProps> = ({ props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [auditBy, setAuditBy] = useUserInfo();
  const [auditDate, setAuditDate] = useState("");

  useEffect(() => {
    props.vccIncident.updatedBy
      ? setAuditBy(props.vccIncident.updatedBy)
      : setAuditBy(props.vccIncident.createdBy);
    if (props.vccIncident.updatedDate) {
      setAuditDate(props.vccIncident.updatedDate.toString());
    } else if (props.vccIncident.createdDate) {
      setAuditDate(props.vccIncident.createdDate.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vccIncident.updatedBy, props.vccIncident.createdBy]);

  return (
    <>
      <Paper variant="outlined" className={classes.incidentCard}>
        <Box ml={1} mr={1}>
          {/* Header */}
          <IncidentCardHeader
            props={{
              vccIncident: props.vccIncident,
            }}
          />
        </Box>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container direction="row" alignItems="stretch">
          {/* Main Info */}
          <Grid item container xs={4}>
            <Box borderRight={1} style={{ borderColor: "lightGray" }}>
              <Grid item xs={12}>
                <Box ml={1} mr={1}>
                  <TextField
                    id="type"
                    label="Type"
                    size="small"
                    variant="standard"
                    margin="dense"
                    type="text"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={props.vccIncident.type}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box ml={1} mr={1}>
                  <TextField
                    id="location"
                    label="Location"
                    size="small"
                    margin="dense"
                    variant="standard"
                    type="text"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={props.vccIncident.location}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
          {/* Detail Info */}
          <Grid item container xs={8}>
            <Grid item xs={12}>
              <IncidentCardFactors
                props={{
                  selectedIncFactors: props.vccIncident.factors,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <AssociatedEvents
                  props={{
                    dispatchAddtlInfo: props.dispatchAddtlInfo,
                    isEdit: false,
                    incident: props.vccIncident,
                    associatedIdRequests: [],
                    handleAddDispatches: () => null,
                    handleRemoveDispatch: () => null,
                    handleUpdateAssociatedDispatches: () => null
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Footer */}
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs>
            <Box ml={1} mr={1}>
              <TextField
                id="updatedAuditInfo"
                label="Last Updated"
                variant="standard"
                size="small"
                margin="dense"
                type="text"
                fullWidth
                multiline
                maxRows={4}
                value={
                  moment(auditDate).format("LL, HH:mm:ss") +
                  " by " +
                  NameOrEmailUtil(auditBy)
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Tooltip title="Zoom to VCC Incident on Map">
              <IconButton
                onClick={() =>
                  dispatch(updateViewportRequest(props.vccIncident.latitude, props.vccIncident.longitude, 14))
                }
                size="large"
              >
                <RoomIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Box mr={1} display="flex" justifyContent="start">
              <Tooltip title="Navigate to VCC Incident Management Page">
                <IconButton
                  component={Link}
                  to={"/incident-model/" + props.vccIncident.id}
                  size="large"
                >
                  <LaunchOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default IncidentCard;
