  export enum UserInfoStoreError {
    GET_USER_INFO = "Error fetching user information",
    GET_ALL_READYOP_GROUPS = "Error fetching available ReadyOp Notification Groups",
    GET_USER_READYOP_GROUPS = "Error retrieving ReadyOp Notification Groups",
    UPDATE_USER_READYOP_GROUPS = "Error updating ReadyOp Notification Groups for User",
    UPDATE_USER_PROFILE = "Error updating Profile for User",
  }
  
  export enum UserInfoStoreSuccess {
    UPDATE_USER_READYOP_GROUPS = "Successfully update user's ReadyOp Notification Groups",
    UPDATE_USER_PROFILE = "Successfully updated user's Profile",
  }
  