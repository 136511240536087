import _ from "lodash";

export function AddObjectToLocalStorageArrayUtil(key: string, value: object) {
  try {
    let sLocalStorage: string | null = localStorage.getItem(key);
    if (sLocalStorage) {
      let aLocalStorage: object[] = JSON.parse(sLocalStorage);
      aLocalStorage.push(value);
      localStorage.setItem(key, JSON.stringify(aLocalStorage));
    } else {
      localStorage.setItem(key, JSON.stringify([value]));
    }
  } catch (e) {
    // Future, add error logging
    throw e;
  }
}

export function RemoveObjectFromStorageArrayUtil(key: string, value: object) {
  try {
    let sLocalStorage: string | null = localStorage.getItem(key);
    if (sLocalStorage) {
      let aLocalStorage: object[] = JSON.parse(sLocalStorage);
      aLocalStorage = aLocalStorage.filter((item) => !_.isEqual(item, value));
      localStorage.setItem(key, JSON.stringify(aLocalStorage));
    }
  } catch (e) {
    // Future, add error logging
    throw e;
  }
}

export function AddStringToLocalStorageArrayUtil(key: string, value: string) {
  try {
    let sLocalStorage: string | null = localStorage.getItem(key);
    if (sLocalStorage) {
      let aLocalStorage: string[] = JSON.parse(sLocalStorage);
      aLocalStorage.push(value);
      localStorage.setItem(key, JSON.stringify(aLocalStorage));
    } else {
      localStorage.setItem(key, JSON.stringify([value]));
    }
  } catch (e) {
    // Future, add error logging
    throw e;
  }
}

export function RemoveStringFromStorageArrayUtil(key: string, value: string) {
  try {
    let sLocalStorage: string | null = localStorage.getItem(key);
    if (sLocalStorage) {
      let aLocalStorage: string[] = JSON.parse(sLocalStorage);
      aLocalStorage = aLocalStorage.filter((item) => item !== value);
      localStorage.setItem(key, JSON.stringify(aLocalStorage));
    }
  } catch (e) {
    // Future, add error logging
    throw e;
  }
}
