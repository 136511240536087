export function ValidatePasswordUtil(password: string) {
  // Password Criteria: Between 8 and 16 chars, at least 1 capital, at least 1 special character
  let regularExpression: RegExp =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&]).{8,25}/;
  if (regularExpression.test(password)) {
    return true;
  }
  return false;
}

export function ValidateEmailUtil(email: string) {
  // Email criteria: Text segmented before, between, and after an @ sign and . | ex: text@address.url
  let regularExpression: RegExp = /^\S+@\S+\.\S+$/;
  if (regularExpression.test(email)) {
    return true;
  }
  return false;
}

export function ValidatePhoneNumberUtil(phoneNumber: string) {
  // Phone Number Criteria: All general 10 formats accepted, with or without country code
  let regularExpression: RegExp =
    /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  if (regularExpression.test(phoneNumber)) {
    return true;
  }
  return false;
}

export function CleanPhoneNumberUtil(phoneNumber: string) {
  // Cleans an inputted phone number to strip out any non digit characters
  // and prepends the +1 country code required by cognito if not supplied
  let cleanNumber = phoneNumber.replaceAll("(", "");
  cleanNumber = cleanNumber.replaceAll(")", "");
  cleanNumber = cleanNumber.replaceAll("-", "");
  cleanNumber = cleanNumber.replaceAll(" ", "");
  if (cleanNumber.substr(0, 2) !== "+1") cleanNumber = "+1" + cleanNumber;

  return cleanNumber;
}

export function ParsePhoneNumberUtil(phoneNumber: string) {
  let parsedNumber = ("" + phoneNumber).replace(/\D/g, "");
  let match = parsedNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumber;
}

export function TruncateSourceIdUtil(sourceId: string, agency: string) {
  // Future - load this json in from a config
  let config = {
    WSP: {
      truncateAmount: 8,
    },
    SPD: {
      truncateAmount: 4,
    },
    SFD: {
      truncateAmount: 0,
    },
    KCM: {
      truncateAmount: 4,
    },
  };

  try {
    if (Object.keys(config).includes(agency))
      // @ts-ignore
      return sourceId.substr(config[agency].truncateAmount);
    else return sourceId;
  } catch (e) {
    return sourceId;
  }
}
