import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, useEffect } from "react";
import { DispatchEventFeedModel, IRootReducer } from "../../../reducers";
import CreateIncident from "../../incident-model/create-incident-model";
import AdditionalDetails from "../dispatch-event-additional-details";
import { useDispatch, useSelector } from "react-redux";
import { Agency } from "../../../enums/agency";
import { getTMCLogRequest } from "../../../actions";

interface AdditionalDetailsProps {
  props: {
    isOpen: boolean;
    idfEvent: DispatchEventFeedModel;
    hideCreate?: boolean;
    handleClose: () => void;
  };
}

const AdditionalDetailsModal: FC<AdditionalDetailsProps> = ({ props }) => {
  const dispatch = useDispatch();

  const tmcLogStore = useSelector((state: IRootReducer) => state.dispatchEventFeedReducer.tmcLog);

  useEffect(() => {
    if(props.idfEvent.agency === Agency.WSP && props.isOpen){
      dispatch(getTMCLogRequest(props.idfEvent.eventNumber));
    }
  }, [dispatch, props.idfEvent, props.isOpen]);

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        fullWidth
        maxWidth="lg"
      >
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Box mt={1} mb={0}>
                <DialogTitle>Event Information</DialogTitle>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={3}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box m={1}>
                {!(props.idfEvent.associatedIncId || props.hideCreate) && (
                  <CreateIncident
                    props={{
                      mostRecentSelectedEvent: props.idfEvent,
                      selectedEvents: [props.idfEvent],
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={props.handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent dividers>
              {props.idfEvent && props.idfEvent.eventNumber ? (
                <AdditionalDetails props={{
                  dispatchEvent: props.idfEvent,
                  tmcLog: tmcLogStore,
                }} />
              ) : (
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Fade
                    in={
                      props.idfEvent !== undefined &&
                      props.idfEvent?.eventNumber !== ""
                    }
                    style={{
                      transitionDelay: "500ms",
                    }}
                    unmountOnExit
                  >
                    <CircularProgress />
                  </Fade>
                </Box>
              )}
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default AdditionalDetailsModal;
