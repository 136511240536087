import React, { FC, useState } from "react";
import {
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

const UpdatePasswordModal: FC = () => {
  const [open, setOpen] = useState(false);

  const handleResetPassword = () => {
    window.open(process.env.REACT_APP_BASE_SAW_URL, "_self");
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Update your password">
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          color="inherit"
          style={{ minWidth: "150px", alignSelf: "center" }}
        >
          Update Password
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will be directed to Secure Access Washington to allow changing
            your password. Do you want to continue?
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              onClick={handleResetPassword}
              color="primary"
              variant="contained"
            >
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdatePasswordModal;
