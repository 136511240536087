import axios from "axios";

export const addUser = (
  reqUser: string,
  email: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
  agency: string,
  roles: string[],
): any => {
  return axios.post("/user/v1", {
    reqUser,
    email: email.toLowerCase(),
    givenname: givenName,
    familyname: familyName,
    phonenumber: phoneNumber,
    agency: agency,
    enabled: "Y", // Defaulting to Y for enabled in cognito
    roles: roles
  });
};

export const updateUser = (
  cognitoUsername: string,
  email: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
  agency: string,
  enabled: string,
  roles: string[],
  rolesToAdd: string[],
  rolesToRemove: string[],
  reqUser: string,

): any => {
  return axios.put("/user/v1", {
    reqUser,
    cognitoUsername: cognitoUsername,
    email: email.toLowerCase(),
    givenname: givenName,
    familyname: familyName,
    phonenumber: phoneNumber,
    agency: agency,
    enabled: enabled,
    roles: roles,
    rolesToAdd: rolesToAdd,
    rolesToRemove: rolesToRemove,
  });
};

export const reinviteUser = (email: string, reqUser: string): any => {
  return axios.put("/user/account/reinvite/", { email: email, reqUser });
};

// // Review: Eventually depracate when updateUser handles this, have this data returned on GET user
// export const getGroupsByUser = (cognitoUsername: string): any => {
//   return axios.get("/user/v1/cognitoUsername/" + cognitoUsername + "/groups");
// };


// Review: Eventually depracate when updateUser handles this, have this data returned on GET user
export const getGroupsByUser = (email: string): any => {
  return axios.get("/user/v1/email/" + email + "/groups");
};


// Review: Eventually depracate when updateUser handles this
export const addUserToGroup = (cognitoUsername: string, group: string, reqUser: string): any => {
  return axios.post("/user/v1/cognitoUsername/" + cognitoUsername + "/groups/add", {
    cognitoUsername,
    groupname: group,
    reqUser,
  });
};

// Review: Eventually depracate when updateUser handles this
export const removeUserFromGroup = (cognitoUsername: string, group: string, reqUser: string): any => {
  return axios.put("/user/v1/cognitoUsername/" + cognitoUsername + "/groups/remove", {
    cognitoUsername,
    groupname: group,
    reqUser,
  });
};

export const getUsers = ((email: string): any => {
  return axios.get("/user/email/" + email + "/all");
  //return axios.get("/user");
});


export const getAgencyByUser = ((email: string): any => {
  return axios.get("/user/v1/email/"+ email +"/agency");
  //return axios.get("/user");
});