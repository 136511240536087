import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { Box, Button, Fade, Grid, IconButton, Paper, Popper, Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import makeStyles from "@mui/styles/makeStyles";
import mapboxgl from 'mapbox-gl';
import React, { FC, useEffect, useRef, useState } from "react";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;

const useStyles = makeStyles(() => ({
    mapboxglctrlgeocoder: {
        "& .mapboxgl-ctrl-geocoder": {
            width: "100%",
            zIndex: "5001"
        }
    }
  }));
interface VccGeocoderProps {
    props: { 
        isEdit: boolean;
        handleSelect: (latitude?: number, longitude?: number, placeName?: string) => void;
    }
}

const VccGeocoder: FC<VccGeocoderProps> = ({ props }) => {
    const classes = useStyles();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const geocoderRef = useRef(null);
    const [geocoder, setGeocoder] = useState({} as unknown as MapboxGeocoder);
    const [geocoderInitted, setGeocoderInitted] = useState(false);
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        mapboxgl.accessToken = MAPBOX_API_KEY || "";
        const initGeocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            types: 'address,poi',
            limit: 5,
            minLength: 3,
            bbox: [-122.48, 47.41, -122.16, 47.79],
        });

        setGeocoder(initGeocoder);
    }, []) 

    const handleToggle = () => {
        setOpen((prev) => !prev);
        if(geocoderRef.current != null && !geocoderInitted){
            // Set the Geocoder on the element set aside for it
            geocoder.addTo('#geocoder');

            // Add geocoder result to container
            geocoder.on('result', (e: any) => {
                const geoResult: MapboxGeocoder.Result = e.result;
                props.handleSelect(geoResult.center[1], geoResult.center[0], geoResult.place_name);
            });
            
            // Clear results container when search is cleared.
            geocoder.on('clear', () => {
                props.handleSelect();
            });

            // Set the Geocoder as initted to avoid creating multiple instances
            setGeocoderInitted(true);
        }
    }

    const handleConfirm = () => {
        setOpen(false)
    }

    const handleCancel = () => {
        if(geocoder){
            geocoder.clear();
        }
    }

    return (
        <>
            <Box m={0} p={0}>
                <IconButton size="small" onClick={handleToggle} ref={anchorRef} disabled={!props.isEdit}>
                    <SearchIcon />
                </IconButton>
            </Box>
            <Popper style={{zIndex: 5001}} ref={geocoderRef} open={open} anchorEl={anchorRef.current} placement="bottom-end" keepMounted={true} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper style={{zIndex: 5001}}>
                        <Grid container maxWidth={360}>
                            <Grid item xs={12}>
                                <Box m={1}><Typography variant='body1'>Search for a location</Typography></Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box id="geocoder" m={1} className={classes.mapboxglctrlgeocoder} display="flex"/> 
                            </Grid>
                            <Grid container justifyContent="flex-end" alignItems="flex-end" item xs={12}>
                                <Grid item>
                                    <Box m={1}>
                                        <Button onClick={handleCancel} variant="contained" color="inherit">
                                            Clear
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box m={1}>
                                        <Button onClick={handleConfirm} variant="contained" color="primary">
                                            Confirm
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
                )}
            </Popper>
        </>
    );
};
  
export default VccGeocoder;
  