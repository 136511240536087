import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";

const IDFOverview: FC = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box m={1} display="flex" justifyContent="center">
            <Typography variant="h5">Integrated Dispatch Feed</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={2}>
            <Typography align="center" variant="body1">
              The Integrated Dispatch Feed provides an up to date view of all
              source agency dispatch events and can be used to create VCC
              Incidents
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} mb={0} pb={0} display="flex" justifyContent="center">
            <Typography variant="h6">Filtering</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={2} mr={2} mb={2}>
            <Typography align="justify" variant="body2">
              Each column can be filtered by selecting the vertical ellipsis or
              sorted by clicking the column header. When filtering, choose a
              column, operation, and value to search against. Filters are not
              case sensitive. Additionlly, the feed can be filtered to specific
              agencies by toggling the agency filters.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mb={0} pb={0} display="flex" justifyContent="center">
            <Typography variant="h6">Interactive Fields</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} ml={1} mb={0} pb={0} display="flex" justifyContent="left">
            <Typography variant="subtitle1">Event Number:</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={2} mr={2} mb={2}>
            <Typography align="justify" variant="body2">
              An ID generated from each agency's source system. Clicking an
              event number will display additional information found on the
              event.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} ml={1} mb={0} pb={0} display="flex" justifyContent="left">
            <Typography variant="subtitle1">
              VCC Incident:
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={2} mr={2} mb={2}>
            <Typography align="justify" variant="body2">
              If an Integrated Dispatch Event is associated to a VCC Incident,
              the corresponding VCC Incident ID will be displayed. Clicking the
              ID will direct to that VCC Incident Management page.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default IDFOverview;
