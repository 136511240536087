import { AnyAction } from "redux";

import { types } from "../../actions";
import { UTCNowUtil } from "../../utils/date-utils";
import { IVccIncidentState } from "../interfaces";
import { PagingKeys, PagingResponse } from "../states/dispatch-event-feed-state";
import {
  IncidentAction,
  IncidentOutreach,
  IncidentTalkingPoint,
  Note,
  VccHistory,
  VccIncidentModel,
  VccNotification,
} from "../states/vcc-incident";
import { VCCFieldChangeDetail } from "../states/ws-event";

export const initialVccIncidentState: IVccIncidentState = {
  incident: {} as VccIncidentModel,
  openIncidents: { pagingKeys: {} as PagingKeys, data: [] as VccIncidentModel[] } as PagingResponse<VccIncidentModel[]>,
  closedIncidents: { pagingKeys: {} as PagingKeys, data: [] as VccIncidentModel[] } as PagingResponse<VccIncidentModel[]>,
  deletedIncidents: { pagingKeys: {} as PagingKeys, data: [] as VccIncidentModel[] } as PagingResponse<VccIncidentModel[]>,
  notes: [] as Note[],
  action: {} as IncidentAction,
  actions: [] as IncidentAction[],
  talkingPoint: {} as IncidentTalkingPoint,
  talkingPoints: [] as IncidentTalkingPoint[], 
  outreach: {} as IncidentOutreach,
  outreaches: [] as IncidentOutreach[],
  presignedUrl: "",
  history: [] as VccHistory[],
  isIncUpdateAvailable: false,
  incidentNotifs: [] as VccNotification[],
  bannerNotifs: [] as VccNotification[],
  incidentUpdates: [] as VCCFieldChangeDetail[],
  successMessage: "",
  failureMessage: "",
};

function vccIncidentReducer(
  state = initialVccIncidentState,
  action: AnyAction
): IVccIncidentState {
  switch (action.type) {
    case types.CREATE_VCC_INCIDENT_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.createSuccessMessage,
        failureMessage: "",
        isIncUpdateAvailable: false,
      };
    case types.CREATE_VCC_INCIDENT_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.createFailureMessage,
        successMessage: "",
      };
    case types.UPDATE_VCC_INCIDENT_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.updateSuccessMessage,
        failureMessage: "",
        isIncUpdateAvailable: false,
      };
    case types.UPDATE_VCC_INCIDENT_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.updateFailureMessage,
        successMessage: "",
      };
    case types.GET_VCC_INCIDENT_SUCCESS:
      return {
        ...state,
        incident: action.payload.vccIncident,
        isIncUpdateAvailable: false,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_VCC_INCIDENT_FAILURE:
      return {
        ...state,
        incident: {} as VccIncidentModel,
        failureMessage: action.payload.getIncidentFailureMessage,
      };
    case types.GET_OPEN_INCIDENTS_SUCCESS:
      return {
        ...state,
        openIncidents: action.payload.openIncidents,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_OPEN_INCIDENTS_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.getIncidentsFailureMessage,
      };
    case types.GET_CLOSED_DELETED_INCIDENTS_SUCCESS:
      return {
        ...state,
        closedIncidents: action.payload.closedIncidents,
        deletedIncidents: action.payload.deletedIncidents,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_CLOSED_DELETED_INCIDENTS_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.getClosedIncidentsFailureMessage,
      };
    case types.GET_VCC_INCIDENT_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload.notes,
        failureMessage: "",
        successMessage: "",
      };
    case types.GET_VCC_INCIDENT_NOTES_FAILURE:
      return {
        ...state,
        notes: [] as Note[],
        failureMessage: action.payload.getNotesFailureMessage,
        successMessage: "",
      };
    case types.CREATE_VCC_INCIDENT_NOTE_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.createNoteSuccessMessage,
        failureMessage: "",
      };
    case types.CREATE_VCC_INCIDENT_NOTE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.createNoteFailureMessage,
        successMessage: "",
      };
    case types.GET_VCC_INCIDENT_ACTION_SUCCESS:
      return {
        ...state,
        action: action.payload.incidentAction,
        failureMessage: "",
        successMessage: "",
      };
    case types.GET_VCC_INCIDENT_ACTION_FAILURE:
      return {
        ...state,
        action: {} as IncidentAction,
        failureMessage: action.payload.getActionFailureMessage,
        successMessage: "",
      };
    case types.GET_VCC_INCIDENT_ACTIONS_SUCCESS:
      return {
        ...state,
        actions: action.payload.incidentActions,
        failureMessage: "",
        successMessage: "",
      };
    case types.GET_VCC_INCIDENT_ACTIONS_FAILURE:
      return {
        ...state,
        actions: [] as IncidentAction[],
        failureMessage: action.payload.getActionsFailureMessage,
        successMessage: "",
      };
    case types.CREATE_VCC_INCIDENT_ACTION_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.createIncidentActionSuccessMessage,
        failureMessage: "",
      };
    case types.CREATE_VCC_INCIDENT_ACTION_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.createIncidentActionFailureMessage,
        successMessage: "",
      };
    case types.MODIFY_VCC_INCIDENT_ACTION_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.modifyIncidentActionSuccessMessage,
        failureMessage: "",
      };
    case types.MODIFY_VCC_INCIDENT_ACTION_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.modifyIncidentActionFailureMessage,
        successMessage: "",
      };
    case types.GET_TALKING_POINT_SUCCESS:
        return {
          ...state,
          talkingPoint: action.payload.talkingPoint,
          successMessage: "",
          failureMessage: ""
        }
    case types.GET_TALKING_POINT_FAILURE:
        return {
          ...state,
          talkingPoint: {} as IncidentTalkingPoint,
          failureMessage: action.payload.failureMessage,
          successMessage: ""
        }
    case types.GET_TALKING_POINTS_SUCCESS:
      return {
        ...state,
        talkingPoints: action.payload.talkingPoints,
        successMessage: "",
        failureMessage: ""
      }
    case types.GET_TALKING_POINTS_FAILURE:
        return {
          ...state,
          talkingPoints: [],
          failureMessage: action.payload.failureMessage,
          successMessage: ""
        }
    case types.CREATE_TALKING_POINT_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.CREATE_TALKING_POINT_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.UPDATE_TALKING_POINT_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.UPDATE_TALKING_POINT_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.GET_TALKING_POINT_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload.talkingPointHistory,
        failureMessage: ""
      }
    case types.GET_TALKING_POINT_HISTORY_FAILURE:
      return {
        ...state,
        history: [] as VccHistory[],
        failureMessage: action.payload.failureMessage,
        successMessage: ""
      }
    case types.UPLOAD_TALKING_POINT_FILE_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.UPLOAD_TALKING_POINT_FILE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.DOWNLOAD_TALKING_POINT_FILE_SUCCESS:
      return {
        ...state,
        presignedUrl: action.payload.downloadPresignedUrl,
        failureMessage: "",
        successMessage: "",
      };
    case types.DOWNLOAD_TALKING_POINT_FILE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
        presignedUrl: "",
      };
    case types.GET_OUTREACH_SUCCESS:
      return {
        ...state,
        outreach: action.payload.outreach,
        successMessage: "",
        failureMessage: ""
      }
    case types.GET_OUTREACH_FAILURE:
        return {
          ...state,
          outreach: {} as IncidentOutreach,
          failureMessage: action.payload.failureMessage,
          successMessage: ""
        }
    case types.GET_OUTREACHES_SUCCESS:
      return {
        ...state,
        outreaches: action.payload.outreaches,
        successMessage: "",
        failureMessage: ""
      }
    case types.GET_OUTREACHES_FAILURE:
        return {
          ...state,
          outreaches: [] as IncidentOutreach[],
          failureMessage: action.payload.failureMessage,
          successMessage: ""
      }
    case types.CREATE_OUTREACH_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.CREATE_OUTREACH_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.UPDATE_OUTREACH_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.UPDATE_OUTREACH_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.UPLOAD_OUTREACH_FILE_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.UPLOAD_OUTREACH_FILE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.DOWNLOAD_OUTREACH_FILE_SUCCESS:
      return {
        ...state,
        presignedUrl: action.payload.downloadPresignedUrl,
        failureMessage: "",
        successMessage: "",
      };
    case types.DOWNLOAD_OUTREACH_FILE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
        presignedUrl: "",
      };
    case types.GET_OUTREACH_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload.outreachHistory,
        failureMessage: ""
      }
    case types.GET_OUTREACH_HISTORY_FAILURE:
      return {
        ...state,
        history: [] as VccHistory[],
        failureMessage: action.payload.failureMessage,
        successMessage: ""
      }
    case types.CLEAR_PRESIGNED_URL:
      return {
        ...state,
        presignedUrl: "",
        failureMessage: "",
        successMessage: "",
      };
    case types.UPLOAD_INC_IMAGE_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.uploadIncImageSuccessMessage,
        failureMessage: "",
      };
    case types.UPLOAD_INC_IMAGE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.uploadIncImageFailureMessage,
        successMessage: "",
      };
    case types.DOWNLOAD_NOTE_FILE_SUCCESS:
      return {
        ...state,
        presignedUrl: action.payload.downloadPresignedUrl,
        failureMessage: "",
        successMessage: "",
      };
    case types.DOWNLOAD_NOTE_FILE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
        presignedUrl: "",
      };
    case types.GET_INC_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload.incHistory,
        successMessage: "",
      };
    case types.GET_INC_HISTORY_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.getIncHistoryFailureMessage,
        successMessage: "",
      };
    case types.CLEAR_CURRENT_INC_HISTORY:
      return {
        ...state,
        history: [] as VccHistory[],
      };
    case types.IS_INC_UPDATE_AVAILABLE_SUCCESS:
      return {
        ...state,
        isIncUpdateAvailable: action.payload.isIncUpdateAvailable,
      };
    case types.IS_INC_UPDATE_AVAILABLE_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.isIncUpdateAvailableFailureMessage,
      };
    case types.CREATE_INC_NOTIF_SUCCESS:
      return {
        ...state,
        incidentNotifs: [...state.incidentNotifs, action.payload.incidentNotif],
        bannerNotifs: [...state.bannerNotifs, action.payload.incidentNotif],
      };
    case types.CREATE_INC_NOTIF_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.incidentNotifFailureMessage,
      };
    case types.INIT_INC_NOTIFS:
      return {
        ...state,
        incidentNotifs: action.payload.incidentNotifs,
      };
    case types.CLEAR_ALL_NOTIFS_SUCCESS:
      return {
        ...state,
        incidentNotifs: [],
        bannerNotifs: [],
      };
    case types.CLEAR_ALL_NOTIFS_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.clearAllNotifsFailureMessage,
      };
    case types.CLEAR_NOTIF_SUCCESS:
      return {
        ...state,
        incidentNotifs: state.incidentNotifs.filter(
          (item) => item !== action.payload.incidentNotif
        ),
        bannerNotifs: state.bannerNotifs.filter(
          (item) => item !== action.payload.incidentNotif
        ),
      };
    case types.CLEAR_NOTIF_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.clearNotifFailureMessage,
      };
    case types.CLEAR_BANNER_NOTIF:
      return {
        ...state,
        bannerNotifs: state.bannerNotifs.filter(
          (item) => item !== action.payload.id
        ),
      };
    case types.DOWNLOAD_VCC_INCIDENT_REPORT_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.getIncidentReportFailureMessage,
      };
    case types.FINALIZE_VCC_INCIDENT_REPORT_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.finalizeSuccessMessage,
        openIncidents: {
          pagingKeys: state.openIncidents.pagingKeys,
          data: state.openIncidents.data.map((i) =>
            i.id === action.payload.incidentId
              ? {
                  ...i,
                  recordRetWfToken: undefined,
                  finalizedBy: action.payload.email,
                  finalizedDate: UTCNowUtil(),
                }
              : i
          ),
        }
      };
    case types.FINALIZE_VCC_INCIDENT_REPORT_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.finalizeFailureMessage,
      };
    case types.FINALIZE_VCC_INCIDENT_REPORT:
      return {
        ...state,
        failureMessage: "",
        successMessage: "",
      };
    case types.SET_INC_LOC_LAT_LONG_FROM_MAP_SUCCESS:
      return {
        ...state,
        incident: {...state.incident, latitude: action.payload.latitude, longitude: action.payload.longitude},
        failureMessage: "",
        successMessage: "",
      };
    case types.SET_INC_LOC_LAT_LONG_FROM_MAP_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.SET_INC_ADDRESS_FROM_MAP:
      return {
        ...state,
        incident: {...state.incident, location: action.payload.address},
        successMessage: "",
        failureMessage: "",
      };
    default:
      return state;
  }
}

export default vccIncidentReducer;
