export enum RoleAction {
  // Incident Permission Actions
  CREATE_INCIDENT = "CREATE_INCIDENT",
  EDIT_INCIDENT = "EDIT_INCIDENT",
  CLOSE_INCIDENT = "CLOSE_INCIDENT",
  DELETE_INCIDENT = "DELETE_INCIDENT",
  ADD_NOTE = "ADD_NOTE",
  
  // Mobility Strategies Permission Actions
  ADD_AND_EDIT_MODBILITY_STRATEGIES = "ADD_AND_EDIT_MODBILITY_STRATEGIES",

  // PIO Permission Actions
  ADD_EDIT_TALKING_POINTS = "ADD_EDIT_TALKING_POINTS",
  ADD_EDIT_SCHEDULED_OUTREACH = "ADD_EDIT_SCHEDULED_OUTREACH",

  // Dispatch Permission Actions
  PIN_DISPATCH = "PIN_DISPATCH",

  // Records Management Permission Actions
  VIEW_RECORDS_MGMT = "VIEW_RECORDS_MGMT",

  // Admin Permission Actions
  VIEW_ADMIN = "VIEW_ADMIN",
}
