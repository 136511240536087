import React, { FC } from "react";
import {
  Button,
  Avatar,
  Paper,
  Box,
  Grid,
  Typography,
  Theme,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import makeStyles from "@mui/styles/makeStyles";
import img from "../../../assets/login_image.jpg";
import { Auth } from "aws-amplify";
import Copyright from "../copyright";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "90vh",
  },
  signIn: {
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  image: {
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  inputField: {
    minWidth: "325px",
  },
}));

const LoginForm: FC = () => {
  const classes = useStyles();

  const handleSignIn = () => {
    Auth.federatedSignIn({ customProvider: 'SecureAccessWashington' });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={8}
          className={classes.signIn}
        >
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography color="inherit" variant="h5" component="h1">
            Welcome to the VCC
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item xs={12}>
              <Box m={2} display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSignIn}
                >
                  Sign In
                </Button>
              </Box>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
