import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { FC } from "react";

interface ConfirmationModalProps {
  props: {
    isOpen: boolean;
    title: string;
    message: string;
    cancelText: string;
    confirmText: string;
    handleCancel: () => void;
    handleConfirm: () => void;
  };
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ props }) => {
  return (
    <>
      <Dialog open={props.isOpen} onClose={props.handleCancel}>
        <DialogTitle id={"cofirmation-modal-" + props.title}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
          <DialogActions>
            <Button onClick={props.handleCancel} variant="contained" color="inherit">
              {props.cancelText}
            </Button>
            <Button onClick={props.handleConfirm} variant="contained" color="primary">
              {props.confirmText}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
