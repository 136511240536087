import { Chip, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ActionStatus } from "../../../enums/action-status";

const useStyles = makeStyles((theme: Theme) => ({
  planned: {
    marginRight: 10,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  },
  complete: {
    marginRight: 10,
    backgroundColor: "green",
    color: "white",
  },
  inProgress: {
    marginRight: 10,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  removed: {
    marginRight: 10,
    backgroundColor: theme.marker.ClosedDispatchEventIFD,
    color: "white",
  },
  listItem: {
    flex: "1 1 55%",
  },
}));

type ChipProps = {
  value: string;
};
export const ActionStatusChip = ({ value }: ChipProps) => {
  const classes = useStyles();

  switch (value) {
    case ActionStatus.COMPLETE: {
      return (
        <Chip label={ActionStatus.COMPLETE} className={classes.complete} />
      );
    }
    case ActionStatus.PLANNED: {
      return <Chip label={ActionStatus.PLANNED} className={classes.planned} />;
    }
    case ActionStatus.IN_PROGRESS: {
      return (
        <Chip label={ActionStatus.IN_PROGRESS} className={classes.inProgress} />
      );
    }
    default: {
      return <div>{value}</div>;
    }
  }
};
