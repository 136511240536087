import { AnyAction } from "redux";
import { UserModel } from "..";

import { types } from "../../actions";
import { IAdminState } from "../interfaces";

export const initialAdminState: IAdminState = {
  users: [] as UserModel[],
  groupsForUser: [] as string[],
  userAgency: "",
  successMessage: "",
  failureMessage: "",
};

function adminReducer(state = initialAdminState, action: AnyAction): IAdminState {
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_USERS_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.getUsersFailureMessage,
      };
    case types.CREATE_AND_INVITE_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.CREATE_AND_INVITE_USER_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.updateUserSuccessMessage,
        failureMessage: "",
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.updateUserFailureMessage,
        successMessage: "",
      };
    case types.REINVITE_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.reinviteSuccessEmail,
        failureMessage: "",
      };
    case types.REINVITE_USER_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.reinviteFailureEmail,
        successMessage: "",
      };
    case types.GET_AGENCY_BY_USER_SUCCESS:
      return {
        ...state,
        userAgency: action.payload.agency,
        failureMessage: "",
        successMessage: "",
      };
    case types.GET_GROUPS_BY_USER_SUCCESS:
      return {
        ...state,
        groupsForUser: action.payload.groupsForUser,
        failureMessage: "",
        successMessage: "",
      };
    case types.GET_GROUPS_BY_USER_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.listGroupsByUserFailureMessage,
        successMessage: "",
      };
    case types.ADD_USER_TO_GROUP_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.addUserToGroupSuccessEmail,
        failureMessage: "",
      };
    case types.ADD_USER_TO_GROUP_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.addUserToGroupFailureEmail,
        successMessage: "",
      };
    case types.REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.removeUserFromGroupSuccessMessage,
        failureMessage: "",
      };
    case types.REMOVE_USER_FROM_GROUP_FAILURE:
      return {
        ...state,
        failureMessage: action.payload.removeUserFromGroupFailureMessage,
        successMessage: "",
      };
    default:
      return state;
  }
}

export default adminReducer;
