import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";

export interface FileModalDetails {
  isOpen: boolean;
  fileName: string;
  isImageType: boolean;
}

interface ImageModalProps {
  props: {
    fileModalDetails: FileModalDetails,
    handleClose: () => void;
  };
}

const FileModal: FC<ImageModalProps> = ({ props }) => {
  const filePresignedUrl = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.presignedUrl
  );

  return (
    <>
      <Dialog open={props.fileModalDetails.isOpen} onClose={props.handleClose} fullWidth maxWidth="md">
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={11}>
              <Box mt={1} mb={0}>
                <DialogTitle>{props.fileModalDetails.isImageType ? "Image" : "Download File" }</DialogTitle>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={props.handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent dividers>
              {props.fileModalDetails.isImageType ? (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={filePresignedUrl}
                    alt=""
                    style={{ width: "100%", maxWidth: "500px" }}
                  />
                </Box>
              ):(
              <Box display="flex" justifyContent="center">
                <Box fontStyle="italic" display="flex" alignItems="center" m={1}>
                  {props.fileModalDetails.fileName}
                </Box>
                <Button variant="contained" size="small" onClick={() => window.open(filePresignedUrl, "_blank")}>
                  Click to Download File
                </Button>
              </Box>
              )}
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default FileModal;
