import { takeEvery, call, put, fork } from "redux-saga/effects";

import * as actions from "../../actions";
import * as api from "../../api";
import { AllUserInfoModel } from "../../reducers/states/all-user-info";

export function* getAllUserInfo(): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getAllUserInfo);
    if (result && result.status === 200) {
        let apiResultPayload: AllUserInfoModel[] = result.data;
        yield put(actions.getAllUserInfoSuccess(apiResultPayload));
    } else {
      yield put(
        actions.getAllUserInfoFailure("Error fetching user information")
      );
    }
  } catch (e) {
    // Future: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchGetAllUserInfoEvent() {
  yield takeEvery(actions.types.GET_ALL_USER_INFO, getAllUserInfo);
}

const allUserInfoSagas = [fork(watchGetAllUserInfoEvent)];

export default allUserInfoSagas;