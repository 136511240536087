import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLoadingOverlay,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { FC, useState, useEffect, useCallback } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  downloadVccIncidentReport,
  getClosedAndDeletedIncidentsRequest,
} from "../../../actions/vcc-incident";
import { ShortIdUtil } from "../../../utils/id-utils";
import { IRootReducer } from "../../../reducers";

const useStyles = makeStyles((theme: Theme) => ({
  finalizeButton: {
    color: theme.palette.secondary.main,
  },
}));

export interface ReportGridRow {
  id: string;
  closedDate: string | Date;
  location: string;
  eventType: string;
  RecordsManagementStatus?: string;
  token?: string;
  link: string;
  finalizedBy?: string;
  finalizedDate?: string | Date;
  purgeDate?: string | Date;
}

interface ReportGridProps {
  props: {
    handleReportFinalize: (
      incidentId: string,
      recordRetWfToken: string
    ) => void;
  };
}

interface IncidentToFinalizeState {
  id: string;
  token: string;
}

const ReportGrid: FC<ReportGridProps> = ({ props }) => {
  const [gridData, setGridData] = useState([] as ReportGridRow[]);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [incidentToFinalized, setIncidentToFinalize] =
    useState<IncidentToFinalizeState>();
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0
  })
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const closedIncidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.closedIncidents
  );

  const deletedIncidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.deletedIncidents
  );

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsGridLoading(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setIsGridLoading(true);
    let apiRows: ReportGridRow[] = [...closedIncidentStore.data, ...deletedIncidentStore.data]
      .filter((i) => i.hasReport === true)
      .sort(
        (d1, d2) =>
          new Date(d2.updatedDate).getTime() -
          new Date(d1.updatedDate).getTime()
      )
      .reduce(function (results: ReportGridRow[], r, index) {
        results.push({
          id: r.id,
          closedDate: r.updatedDate,
          location: r.location,
          eventType: r.type,
          RecordsManagementStatus: "",
          link: "link",
          token: r.recordRetWfToken,
          finalizedBy: r.finalizedBy,
          finalizedDate: r.finalizedDate,
          purgeDate: r.purgeDate,
        });

        return results;
      }, []);
    setGridData([...apiRows]);
    showLoadingAnimation();
  }, [closedIncidentStore.data, deletedIncidentStore.data, showLoadingAnimation]);

  const handleFinalizeClick = (id: string, token: string) => {
    setDisplayConfirmation(true);
    setIncidentToFinalize({ id, token });
  };

  const handleFinalizeConfirm = () => {
    if (incidentToFinalized) {
      props.handleReportFinalize(
        incidentToFinalized.id,
        incidentToFinalized.token
      );
    }
    setIncidentToFinalize(undefined);
    setDisplayConfirmation(false);
  };

  const handleFinalizeCancel = () => {
    setIncidentToFinalize(undefined);
    setDisplayConfirmation(false);
  };

  const handleReportDownload = (
    id?: string,
    closedDate?: string,
    finalizedBy?: string
  ): void => {
    if (id) {
      if (closedDate) {
        const dateString = moment(closedDate).format("YYYY-MM-DD");
        let fileDisplayName = `${id.substring(0, 3)}-${id.substring(
          3,
          5
        )} Incident Report ${dateString}`;
        fileDisplayName += finalizedBy ? "FINAL.xlsx" : "DRAFT.xlsx";
        dispatch(downloadVccIncidentReport(id, fileDisplayName));
      } else dispatch(downloadVccIncidentReport(id));
    }
  };

  const handleIncidentPaging = () => {
    dispatch(getClosedAndDeletedIncidentsRequest(true));
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const id = params.id;
        return (
          <Box>
            <Button
              type="button"
              color="primary"
              onClick={() => navigate(`/incident-model/${id}`)}
            >
              {ShortIdUtil(id as string)}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      flex: 0.5,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const updatedDate = params.row.closedDate; //JCK Review: We should probably add an offical closed data, instead of trying to guess at it this way. Add a Closed Date to the VCC Incidents that is set in the API.
        return (
          <Box>{moment(updatedDate as string).format("YYYY-MM-DD HH:mm")}</Box>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 0.5,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const location = params.row.location;
        return <Box>{location}</Box>;
      },
    },
    {
      field: "eventType",
      headerName: "Type",
      flex: 0.5,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const eventType = params.row.eventType;
        return <Box>{eventType}</Box>;
      },
    },
    {
      field: "finalizedBy",
      headerName: "Finalized By",
      flex: 0.5,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const finalizedBy = params.row.finalizedBy;
        return (
          finalizedBy && (
            <Box>
              <Typography variant="subtitle2">{finalizedBy}</Typography>
            </Box>
          )
        );
      },
    },
    {
      field: "finalizedDate",
      headerName: "Finalized Date",
      flex: 0.5,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const finalizedDate = params.row.finalizedDate;
        return (
          finalizedDate && (
            <Box>
              <Typography variant="subtitle2">
                {moment(finalizedDate).format("YYYY-MM-DD HH:mm")}
              </Typography>
            </Box>
          )
        );
      },
    },
    {
      field: "purgeDate",
      headerName: "Deletion Date",
      flex: 0.4,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const purgeDate = params.row.purgeDate;
        if (purgeDate) {
          return (
            <Tooltip title={"Deletion Date: " + moment(purgeDate).format("YYYY-MM-DD HH:mm")}>
                <Box>{moment(purgeDate).fromNow()}</Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "reports",
      headerName: "Reports",
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const id = params.row.id;
        const closedDate = params.row.closedDate;
        const finalizedBy = params.row.finalizedBy;
        const token = params.row.token;
        return (
          <Box>
            <Button
              type="button"
              color="primary"
              onClick={() =>
                handleReportDownload(`${id}`, `${closedDate}`, `${finalizedBy}`)
              }
            >
              Download {finalizedBy !== "" ? "Finalized" : "Draft"} Report
            </Button>
            {token && (
              <Button
                type="button"
                color="inherit"
                onClick={() => handleFinalizeClick(`${id}`, `${token}`)}
                className={classes.finalizeButton}
              >
                Finalize Report
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box style={{ height: "85vh" }}>
      <Box style={{ height: "15%" }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="left"
              alignItems="center"
              gap="20px"
            >
              <Typography variant="h6">Closed VCC Incidents</Typography>
              <Box m={1}>
                <Button
                  aria-label="Reload Records"
                  variant="contained"
                  endIcon={<RefreshIcon />}
                  size="medium"
                  onClick={() => dispatch(getClosedAndDeletedIncidentsRequest())}
                >
                  Refresh
                </Button>
              </Box>
            </Box>
            <Box display="flex" justifyContent="left">
              <Typography variant="subtitle2">
                To request corrections to a draft report, please contact
                vccs@wsdot.wa.gov and include the incident ID in the subject
                line.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: "85%" }}>
        <Paper variant="outlined" style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} style={{ height: "90%" }}>
              <Box style={{ height: "100%" }}>
                <DataGrid
                  rows={gridData}
                  columns={columns}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  disableRowSelectionOnClick
                  density={"standard"}
                  disableColumnSelector={true}
                  components={{
                    LoadingOverlay: GridLoadingOverlay,
                  }}
                  loading={isGridLoading}
                />
              </Box>
              <Grid item xs={12}>
                {(closedIncidentStore.pagingKeys || deletedIncidentStore.pagingKeys) && (
                  <Box mt={1} mb={1} style={{ float: "right" }}>
                    <Button variant="contained" color="inherit" size="small" onClick={handleIncidentPaging}>
                      Load More Events
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Dialog
        open={displayConfirmation}
        onClose={() => setDisplayConfirmation(false)}
      >
        <DialogTitle id="form-dialog-title">
          Finalize VCC Incident:{" "}
          {ShortIdUtil(incidentToFinalized?.id as string)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clicking "Submit" below will mark VCC Incident{" "}
            {ShortIdUtil(incidentToFinalized?.id as string)} as finalized, email
            the report to a designated distribution list, and delete the
            incident report from the VCC system 30 days from now on{" "}
            {moment().add(1, "M").format("LL")}.
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => handleFinalizeCancel()} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleFinalizeConfirm()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default ReportGrid;
