import { Box, Grid, Paper, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { IncidentFactor } from "../../../enums/incident-factor";
import FactorChip from "./factor-chip";

interface IncidentFactorProps {
  props: {
    selectedIncFactors: string[];
    isEdit?: boolean;
    updateSelected?: (newIncFactors: string[]) => void;
  };
}

const IncidentFactors: FC<IncidentFactorProps> = ({ props }) => {
  function isSelected(incFactor: string): boolean {
    if (props.selectedIncFactors && props.selectedIncFactors !== undefined) {
      let index = props.selectedIncFactors.indexOf(incFactor);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const handleIncFactorSelected = (incFactor: string) => {
    if (props.isEdit) {
      if (props.selectedIncFactors && props.selectedIncFactors !== undefined) {
        let currentSelected = [...props.selectedIncFactors];
        let index = currentSelected.indexOf(incFactor);
        if (index > -1) {
          currentSelected.splice(index, 1);
        } else {
          currentSelected.push(incFactor);
        }
        if (props.updateSelected) {
          props.updateSelected(currentSelected);
        }
      } else {
        if (props.updateSelected) {
          props.updateSelected([incFactor]);
        }
      }
    }
  };

  return (
    <>
      <Box>Contributing Factors</Box>
      <Paper variant="outlined">
        <Grid container direction="row">
          <Grid item xs={2}>
            <FactorChip
              props={{
                label: IncidentFactor.CRIME_SCENE,
                isSelected: isSelected(IncidentFactor.CRIME_SCENE),
                isEdit: props.isEdit,
                handleIncFactorSelected: handleIncFactorSelected,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FactorChip
              props={{
                label: IncidentFactor.HAZMAT,
                isSelected: isSelected(IncidentFactor.HAZMAT),
                isEdit: props.isEdit,
                handleIncFactorSelected: handleIncFactorSelected,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FactorChip
              props={{
                label: IncidentFactor.FIRE,
                isSelected: isSelected(IncidentFactor.FIRE),
                isEdit: props.isEdit,
                handleIncFactorSelected: handleIncFactorSelected,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FactorChip
              props={{
                label: IncidentFactor.FATALITY,
                isSelected: isSelected(IncidentFactor.FATALITY),
                isEdit: props.isEdit,
                handleIncFactorSelected: handleIncFactorSelected,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FactorChip
              props={{
                label: IncidentFactor.ROLLOVER,
                isSelected: isSelected(IncidentFactor.ROLLOVER),
                isEdit: props.isEdit,
                handleIncFactorSelected: handleIncFactorSelected,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Commercial Vehicle">
              <Box>
                <FactorChip
                  props={{
                    label: IncidentFactor.COMMERCIAL_VEHICLE,
                    isSelected: isSelected(IncidentFactor.COMMERCIAL_VEHICLE),
                    isEdit: props.isEdit,
                    handleIncFactorSelected: handleIncFactorSelected,
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default IncidentFactors;
