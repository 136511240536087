import {
  Box,
  Button,
  Dialog,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { Alert } from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DispatchEventFeedModel, IRootReducer, PinEvent } from "../../../reducers";
import {
  DispatchFeedStoreError,
  DispatchFeedStoreSuccess,
} from "../../../enums/store-messages/dispatch";
import {
  getPinnedDispatchEventRequest,
  getPinnedDispatchEventsRequest,
  pinDispatchEventRequest,
  removePinDispatchEventRequest,
} from "../../../actions";
import { UTCFromTimeFrameUtil, UTCToOneMinuteAheadUtil } from "../../../utils/date-utils";
import moment from "moment";
import useUserPermissions from "../../../hooks/user-permissions";
import { RoleAction } from "../../../enums/permission-actions";
import PinEventDialog from "./pin-event-dialog";
import RemovePinEventDialog from "./remove-pin-event-dialog";

interface PinEventProps {
  props: {
    selectedIDFEvents: DispatchEventFeedModel[];
    selectedPinnedEvents: DispatchEventFeedModel[];
  };
}

const PinEventModal: FC<PinEventProps> = ({ props }) => {
  const [open, setOpen] = useState(false);
  const [pinButtonDisabled, setPinButtonDisabled] = useState(true);
  const [pinDisabledViaPermissions] = useUserPermissions(RoleAction.PIN_DISPATCH);
  const [isPinAction, setIsPinAction] = useState(false);
  const [eventsToPin, setEventsToPin] = useState([] as PinEvent[]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const store = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer
  );

  const validateIsRemovePinAction = useCallback( () => {
      return (
        props.selectedPinnedEvents.length === 1 && props.selectedIDFEvents.length === 0
      );
    },
    [props.selectedIDFEvents.length, props.selectedPinnedEvents.length]
  );

  const validateAndSetIsPinAction = useCallback( () => {
      if (props.selectedIDFEvents.length > 0) {
        var openEvents = props.selectedIDFEvents.filter(
          (e) =>
            e.status === "OPEN" &&
            moment(e.lastUpdateDt).isAfter(UTCFromTimeFrameUtil(store.timeFrame))
        );
        let isPinningAction: boolean = openEvents.length > 0 && props.selectedPinnedEvents.length === 0;
        setIsPinAction(isPinningAction);
        return isPinningAction;
      }
      
      setIsPinAction(false);
      return false;
    },
    [props.selectedIDFEvents, props.selectedPinnedEvents.length, store.timeFrame]
  );
  
  useEffect(() => {
    setPinButtonDisabled(validateIsRemovePinAction() === validateAndSetIsPinAction());
  }, [validateAndSetIsPinAction, validateIsRemovePinAction, props.selectedIDFEvents, props.selectedPinnedEvents])

  useEffect(() => {
    switch (store.successMessage){
      case DispatchFeedStoreSuccess.DISPATCH_EVENT_PIN_SAVE:
      case DispatchFeedStoreSuccess.DISPATCH_EVENT_PIN_REMOVE:
        setSuccessMessage(store.successMessage);
        dispatch(
        getPinnedDispatchEventsRequest(
          UTCFromTimeFrameUtil(store.timeFrame),
          UTCToOneMinuteAheadUtil()
        ));
        break;
    }
  }, [dispatch, store.timeFrame, store.successMessage]);

  useEffect(()=> {
    switch(store.failureMessage){
      case DispatchFeedStoreError.DISPATCH_EVENT_PIN_SAVE:
      case DispatchFeedStoreError.DISPATCH_EVENT_PIN_REMOVE:
        setErrorMessage(store.failureMessage);
        break;  
    }
  }, [store.failureMessage])

  const handleOpen = () => {
    if (isPinAction) {
      var pinInit: PinEvent[] = [];
      var openEvents = props.selectedIDFEvents.filter(
        (e) =>
          e.status === "OPEN" &&
          moment(e.lastUpdateDt).isAfter(UTCFromTimeFrameUtil(store.timeFrame))
      );
      openEvents.forEach((e) =>
        pinInit.push({
          dispatchId: e.eventNumber,
          location: e.location ?? "",
          pinReason: "",
        })
      );

      setEventsToPin(pinInit);
    }
    else{
      dispatch(getPinnedDispatchEventRequest(props.selectedPinnedEvents[0].eventNumber));
    }

    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setEventsToPin([]);
  };

  const handlePinAndClose = (finalizedEvents: PinEvent[]) => {
    dispatch(pinDispatchEventRequest(finalizedEvents));
    setOpen(false);
  };

  const handleRemovePinAndClose = (finalizedEvent: PinEvent) => {
    dispatch(removePinDispatchEventRequest(finalizedEvent.dispatchId));
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Pin open Dispatch Events to the top of the IDF. Only pins within the selected Time Frame will be displayed">
        <Box>
          <Button
            variant="contained"
            size="small"
            onClick={handleOpen}
            style={{ alignSelf: "center" }}
            disabled={pinButtonDisabled || pinDisabledViaPermissions}
            endIcon={<PushPinIcon />}
            color="inherit"
          >
            {props.selectedPinnedEvents.length === 1 && props.selectedIDFEvents.length === 0 ? "Unpin" : "Pin"}
          </Button>
        </Box>
      </Tooltip>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        { isPinAction ? ( 
          <PinEventDialog props={{
            eventsToPin: eventsToPin,
            handlePinEvents: handlePinAndClose,
            hanldeCancel: handleCancel
          }} />
        ):(
          <RemovePinEventDialog props={{
            eventToRemove: store.pinnedEvent,
            handleRemoveEvent: handleRemovePinAndClose,
            hanldeCancel: handleCancel
          }} />
        ) }
        
      </Dialog>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PinEventModal;
