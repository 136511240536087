import { Auth } from "aws-amplify";
import { takeEvery, call, put, fork, select } from "redux-saga/effects";

import * as actions from "../../actions";
import {
  actionLogPayload,
  downloadOutreachFilePayload,
  getClosedAndDeletedIncidentsPayload,
  getOpenIncidentsPayload,
  getVccActionPayload,
  outreachHistoryPayload,
  outreachPayload,
  setIncLatLongFromMapPayload,
  talkingPointHistoryPayload,
  talkingPointPayload,
  types,
  uploadImgRequestPayload,
  uploadOutreachFilePayload,
  uploadTalkingPointFilePayload,
  downloadTalkingPointFilePayload
} from "../../actions";
import * as api from "../../api";
import { IncidentStatus } from "../../enums/incident-status";
import { LocalStorage } from "../../enums/local-storage";
import {
  IncidentStoreError,
  IncidentStoreSuccess,
} from "../../enums/store-messages/incident";
import { IRootReducer, PagingKeys, PagingResponse } from "../../reducers";
import {
  IncidentAction,
  IncidentOutreach,
  IncidentTalkingPoint,
  Note,
  VccHistory,
  VccIncidentModel,
} from "../../reducers/states/vcc-incident";
import {
  IsConditionalCheckError,
  IsResponseValidUtil,
} from "../../utils/api-utils";
import {
  AddObjectToLocalStorageArrayUtil,
  RemoveObjectFromStorageArrayUtil,
} from "../../utils/local-storage-utils";

export const incidentStore = (state: IRootReducer) => state.vccIncidentReducer.incident;
export const openIncidentStore = (state: IRootReducer) => state.vccIncidentReducer.openIncidents;
export const closedIncidentStore = (state: IRootReducer) => state.vccIncidentReducer.closedIncidents;
export const deletedIncidentStore = (state: IRootReducer) => state.vccIncidentReducer.deletedIncidents;

function* createVccIncident(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.createVccIncident,
      action.payload.vccIncident
    );
    if (result && result.status === 200) {
      yield put(
        actions.createVccIncidentSuccess(IncidentStoreSuccess.INCIDENT_SAVE)
      );
    } else {
      yield put(
        actions.createVccIncidentFailure(IncidentStoreError.VCC_INCIDENT_SAVE)
      );
    }
  } catch (e) {
    yield put(
      actions.createVccIncidentFailure(IncidentStoreError.VCC_INCIDENT_SAVE)
    );
  }
}
function* watchCreateVccIncident() {
  yield takeEvery(actions.types.CREATE_VCC_INCIDENT, createVccIncident);
}

function* updateVccIncident(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.updateVccIncident,
      action.payload.incident,
      action.payload.associatedIds
    );
    if (result && result.status === 200) {
      yield put(
        actions.updateVccIncidentSuccess(
          IncidentStoreSuccess.INCIDENT_UPDATE
        )
      );
    } else {
      if (IsConditionalCheckError(result.data)) {
        yield put(
          actions.updateVccIncidentFailure(
            IncidentStoreError.VCC_INCIDENT_OVERWRITE_ERROR
          )
        );
      } else {
        yield put(
          actions.updateVccIncidentFailure(
            IncidentStoreError.VCC_INCIDENT_UPDATE
          )
        );
      }
    }
  } catch (e) {
    yield put(
      actions.updateVccIncidentFailure(IncidentStoreError.VCC_INCIDENT_UPDATE)
    );
  }
}
function* watchUpdateVccIncident() {
  yield takeEvery(actions.types.UPDATE_VCC_INCIDENT, updateVccIncident);
}

function* getVccIncident(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getVccIncident, action.payload.id);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: VccIncidentModel = result.data;
        yield put(actions.getVccIncidentSuccess(apiResultPayload));
        yield put(actions.setCurrentIncidentGeoRequest(apiResultPayload));
        yield put(actions.setAssociatedDispatchGeoRequest(apiResultPayload.dispatchEvents))
      } else {
        yield put(
          actions.getVccIncidentFailure(IncidentStoreError.VCC_INCIDENT_GET)
        );
      }
    } else {
      yield put(
        actions.getVccIncidentFailure(IncidentStoreError.VCC_INCIDENT_GET)
      );
    }
  } catch (e) {
    yield put(
      actions.getVccIncidentFailure(IncidentStoreError.VCC_INCIDENT_GET)
    );
  }
}
function* watchGetVccIncident() {
  yield takeEvery(actions.types.GET_VCC_INCIDENT, getVccIncident);
}

function* downloadVccIncidentReport(
  action: any
): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.downloadIncidentReport,
      action.payload.id,
      action.payload.fileDisplayName
    );
    if (result && result.status === 200) {
      window.open(result.data, "_blank");
    } else {
      yield put(
        actions.getIncidentReportFailure(
          IncidentStoreError.VCC_INCIDENT_REPORT_DOWNLOAD
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getIncidentReportFailure(
        IncidentStoreError.VCC_INCIDENT_REPORT_DOWNLOAD
      )
    );
  }
}
function* watchDownloadVccIncidentReport() {
  yield takeEvery(
    actions.types.DOWNLOAD_VCC_INCIDENT_REPORT,
    downloadVccIncidentReport
  );
}

function* getOpenIncidents(action: {
    type: typeof types.GET_OPEN_INCIDENTS;
    payload: getOpenIncidentsPayload;
  }): Generator<any, void, unknown> {  
  try {
    let pagingKeys = {pk1: "", sk1: "", pk2: "", sk2: ""} as PagingKeys
    if(action.payload.pageResponse){
      let existingPagingKeys: any = yield(select(openIncidentStore));
      pagingKeys = existingPagingKeys.pagingKeys;
    }
    
    const result: any = yield call(
      api.getVccIncidents, 
      IncidentStatus.OPEN,
      "5", // Default to 5 incidents at a time
      pagingKeys
    );
    
    if (result && result.status === 200 && result.data) {
      if (IsResponseValidUtil(result.data)) {
        if(action.payload.pageResponse){
          // If a paged response - append the new data to the existing data in the redux store
          let existingOpenIncidents: any = yield(select(openIncidentStore));
          let apiResultPayload: PagingResponse<VccIncidentModel[]> = result.data;
          yield put(actions.getOpenIncidentsSuccess({
            pagingKeys: apiResultPayload.pagingKeys,
            data: [...existingOpenIncidents.data as VccIncidentModel[], ...apiResultPayload.data]
          }));
          yield put(actions.setIncidentGeoRequest([...existingOpenIncidents.data as VccIncidentModel[], ...apiResultPayload.data]));
        }
        else{
          let apiResultPayload: PagingResponse<VccIncidentModel[]> = result.data;
          yield put(actions.getOpenIncidentsSuccess(apiResultPayload));
          yield put(actions.setIncidentGeoRequest(apiResultPayload.data));
        }
      } else {
        yield put(
          actions.getOpenIncidentsFailure(IncidentStoreError.VCC_INCIDENTS_GET)
        );
      }
    } else {
      yield put(
        actions.getOpenIncidentsFailure(IncidentStoreError.VCC_INCIDENTS_GET)
      );
    }
  } catch (e) {
    yield put(
      actions.getOpenIncidentsFailure(IncidentStoreError.VCC_INCIDENTS_GET)
    );
  }
}
function* watchGetOpenIncidents() {
  yield takeEvery(actions.types.GET_OPEN_INCIDENTS, getOpenIncidents);
}
function* getClosedAndDeletedVccIncidents(action: {
    type: typeof types.GET_CLOSED_DELETED_INCIDENTS;
    payload: getClosedAndDeletedIncidentsPayload;
  }): Generator<any, void, unknown> {  
  try {
    let closedPagingKeys = {pk1: "", sk1: "", pk2: "", sk2: ""} as PagingKeys;
    let deletedPagingKeys = {pk1: "", sk1: "", pk2: "", sk2: ""} as PagingKeys;
    if(action.payload.pageResponse){
      let existingClosedKeys: any = yield(select(closedIncidentStore));
      let existingDeletedKeys: any = yield(select(deletedIncidentStore));
      closedPagingKeys = existingClosedKeys.pagingKeys ?? closedPagingKeys;
      deletedPagingKeys = existingDeletedKeys.pagingKeys ?? deletedPagingKeys;
    }
    const closedAPIResult: any = yield call(
      api.getVccIncidents, 
      IncidentStatus.CLOSED,
      "50", // Default to 50 for closed incidents
      closedPagingKeys
    );

    const deletedAPIResult: any = yield call(
      api.getVccIncidents, 
      IncidentStatus.DELETED,
      "50", // Default to 50 for deleted incidents
      deletedPagingKeys
    );

    if (closedAPIResult && closedAPIResult.status === 200 && closedAPIResult.data
      && deletedAPIResult && deletedAPIResult.status === 200 && deletedAPIResult.data) {
      if (IsResponseValidUtil(closedAPIResult.data.data) && IsResponseValidUtil(deletedAPIResult.data.data)) {
        if(action.payload.pageResponse){
          // If a paged response - append the new data to the existing data in the redux store
          let existingClosedIncidents: any = yield(select(closedIncidentStore));
          let existingDeletedIncidents: any = yield(select(deletedIncidentStore));
          let closedPayload: PagingResponse<VccIncidentModel[]> = closedAPIResult.data;
          let deletedPayload: PagingResponse<VccIncidentModel[]> = deletedAPIResult.data;
          yield put(actions.getClosedAndDeletedIncidentsSuccess(
            {
              pagingKeys: closedPayload.pagingKeys, 
              data: [...existingClosedIncidents.data, ...closedPayload.data]
            } as PagingResponse<VccIncidentModel[]>,
            {
              pagingKeys: deletedPayload.pagingKeys,
              data: [...existingDeletedIncidents.data, ...deletedPayload.data]
            } as PagingResponse<VccIncidentModel[]>,
          ));
        }
        else{
          let closedPayload: PagingResponse<VccIncidentModel[]> = closedAPIResult.data;
          let deletedPayload: PagingResponse<VccIncidentModel[]> = deletedAPIResult.data;
          yield put(actions.getClosedAndDeletedIncidentsSuccess(
            {
              pagingKeys: closedPayload.pagingKeys, 
              data: closedPayload.data
            } as PagingResponse<VccIncidentModel[]>,
            {
              pagingKeys: deletedPayload.pagingKeys,
              data: deletedPayload.data
            } as PagingResponse<VccIncidentModel[]>,
          ));
        }
      } else {
        yield put(
          actions.getClosedAndDeletedIncidentsFailure(
            IncidentStoreError.VCC_INCIDENTS_GET
          )
        );
      }
    } else {
      yield put(
        actions.getClosedAndDeletedIncidentsFailure(IncidentStoreError.VCC_INCIDENTS_GET)
      );
    }
  } catch (e) {
    console.log("Error: ", e);
    yield put(
      actions.getClosedAndDeletedIncidentsFailure(IncidentStoreError.VCC_INCIDENTS_GET)
    );
  }
}
function* watchGetClosedAndDeletedVccIncidents() {
  yield takeEvery(actions.types.GET_CLOSED_DELETED_INCIDENTS, getClosedAndDeletedVccIncidents);
}

function* getVccIncidentNotes(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getVccIncidentNotes,
      action.payload.incidentId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: Note[] = result.data;
        yield put(actions.getVccIncidentNotesSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getOpenIncidentsFailure(IncidentStoreError.VCC_NOTES_GET)
        );
      }
    } else {
      yield put(
        actions.getVccIncidentFailure(IncidentStoreError.VCC_NOTES_GET)
      );
    }
  } catch (e) {
    yield put(actions.getVccIncidentFailure(IncidentStoreError.VCC_NOTES_GET));
  }
}
function* watchGetIncidentNotes() {
  yield takeEvery(actions.types.GET_VCC_INCIDENT_NOTES, getVccIncidentNotes);
}

function* createVccIncidentNote(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.createVccIncidentNote,
      action.payload.note,
      action.payload.id
    );
    if (result && result.status === 200) {
      yield put(
        actions.createVccIncidentNoteSuccess(
          IncidentStoreSuccess.NOTES_SAVE
        )
      );
    } else {
      yield put(
        actions.createVccIncidentNoteFailure(IncidentStoreError.VCC_NOTES_SAVE)
      );
    }
  } catch (e) {
    yield put(
      actions.createVccIncidentNoteFailure(IncidentStoreError.VCC_NOTES_SAVE)
    );
  }
}
function* watchCreateVccIncidentNote() {
  yield takeEvery(
    actions.types.CREATE_VCC_INCIDENT_NOTE,
    createVccIncidentNote
  );
}

export function* getVccIncidentAction(action: {
  type: typeof types.GET_VCC_INCIDENT_ACTION;
  payload: getVccActionPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getVccIncidentAction,
      action.payload.incidentId,
      action.payload.actionId,
      action.payload.timeAdded
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: IncidentAction = result.data;
        yield put(actions.getVccIncidentActionSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getVccIncidentActionFailure(
            IncidentStoreError.VCC_INCIDENT_ACTION_GET
          )
        );
      }
    } else {
      yield put(
        actions.getVccIncidentActionFailure(
          IncidentStoreError.VCC_INCIDENT_ACTION_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getVccIncidentActionFailure(
        IncidentStoreError.VCC_INCIDENT_ACTION_GET
      )
    );
  }
}
function* watchGetIncidentAction() {
  yield takeEvery(
    actions.types.GET_VCC_INCIDENT_ACTION,
    getVccIncidentAction
  );
}

export function* getVccIncidentActions(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getVccIncidentActions,
      action.payload.incidentId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: IncidentAction[] = result.data;
        yield put(actions.getVccIncidentActionsSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getVccIncidentActionsFailure(
            IncidentStoreError.VCC_INCIDENT_ACTIONS_GET
          )
        );
      }
    } else {
      yield put(
        actions.getVccIncidentActionsFailure(
          IncidentStoreError.VCC_INCIDENT_ACTIONS_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getVccIncidentActionsFailure(
        IncidentStoreError.VCC_INCIDENT_ACTIONS_GET
      )
    );
  }
}
function* watchGetIncidentActions() {
  yield takeEvery(
    actions.types.GET_VCC_INCIDENT_ACTIONS,
    getVccIncidentActions
  );
}

export function* createVccIncidentAction(action: {
  type: typeof types.CREATE_VCC_INCIDENT_ACTION;
  payload: actionLogPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.createVccIncidentAction,
      action.payload.incidentAction,
      action.payload.incidentId
    );

    if (result && result.status === 200) {
      yield put(
        actions.createVccIncidentActionSuccess(
          IncidentStoreSuccess.COLLAB_ACTION_SAVE
        )
      );
    } else {
      yield put(
        actions.createVccIncidentActionFailure(
          IncidentStoreError.VCC_INCIDENT_ACTIONS_SAVE
        )
      );
    }
  } catch (e) {
    yield put(
      actions.createVccIncidentActionFailure(
        IncidentStoreError.VCC_INCIDENT_ACTIONS_SAVE
      )
    );
  }
}
function* watchCreateVccIncidentAction() {
  yield takeEvery(
    actions.types.CREATE_VCC_INCIDENT_ACTION,
    createVccIncidentAction
  );
}

export function* modifyVccIncidentAction(action: {
  type: typeof types.MODIFY_VCC_INCIDENT_ACTION;
  payload: actionLogPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.modifyVccIncidentAction,
      action.payload.incidentAction,
      action.payload.incidentId
    );
    if (result && result.status === 200) {
      yield put(
        actions.modifyVccIncidentActionSuccess(
          IncidentStoreSuccess.COLLAB_ACTION_MODIFY
        )
      );
    } else {
      if (IsConditionalCheckError(result.data)){
        yield put(actions.modifyVccIncidentActionFailure(
          IncidentStoreError.VCC_INCIDENT_ACTIONS_OVERWRITE_ERROR
        ));
      } else{
        yield put(
          actions.modifyVccIncidentActionFailure(
            IncidentStoreError.VCC_INCIDENT_ACTIONS_MODIFY
          )
        );
      }
    }
  } catch (e) {
    yield put(
      actions.modifyVccIncidentActionFailure(
        IncidentStoreError.VCC_INCIDENT_ACTIONS_MODIFY
      )
    );
  }
}
function* watchModifyVccIncidentAction() {
  yield takeEvery(
    actions.types.MODIFY_VCC_INCIDENT_ACTION,
    modifyVccIncidentAction
  );
}

export function* getTalkingPoint(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getTalkingPoint,
      action.payload.incId,
      action.payload.talkingPointId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: IncidentTalkingPoint = result.data;
        yield put(actions.getTalkingPointSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getTalkingPointFailure(
            IncidentStoreError.TALKING_POINT_GET
          )
        );
      }
    } else {
      yield put(
        actions.getTalkingPointFailure(
          IncidentStoreError.TALKING_POINT_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getTalkingPointFailure(
        IncidentStoreError.TALKING_POINT_GET
      )
    );
  }
}
function* watchGetTalkingPoint() {
  yield takeEvery(
    actions.types.GET_TALKING_POINT,
    getTalkingPoint
  );
}

export function* getTalkingPoints(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getTalkingPoints,
      action.payload.incId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: IncidentTalkingPoint[] = result.data;
        yield put(actions.getTalkingPointsSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getTalkingPointsFailure(
            IncidentStoreError.TALKING_POINTS_GET
          )
        );
      }
    } else {
      yield put(
        actions.getTalkingPointsFailure(
          IncidentStoreError.TALKING_POINTS_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getTalkingPointsFailure(
        IncidentStoreError.TALKING_POINTS_GET
      )
    );
  }
}
function* watchGetTalkingPoints() {
  yield takeEvery(
    actions.types.GET_TALKING_POINTS,
    getTalkingPoints
  );
}

export function* createTalkingPoint(action: {
  type: typeof types.CREATE_TALKING_POINT;
  payload: talkingPointPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.createTalkingPoint,
      action.payload.talkingPoint,
      action.payload.incId
    );

    if (result && result.status === 200) {
      yield put(
        actions.createTalkingPointSuccess(
          IncidentStoreSuccess.TALKING_POINT_SAVE
        )
      );
    } else {
      yield put(
        actions.createTalkingPointFailure(
          IncidentStoreError.TALKING_POINT_SAVE
        )
      );
    }
  } catch (e) {
    yield put(
      actions.createTalkingPointFailure(
        IncidentStoreError.TALKING_POINT_SAVE
      )
    );
  }
}
function* watchCreateTalkingPoint() {
  yield takeEvery(
    actions.types.CREATE_TALKING_POINT,
    createTalkingPoint
  );
}

export function* updateTalkingPoint(action: {
  type: typeof types.UPDATE_TALKING_POINT;
  payload: talkingPointPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.updateTalkingPoint,
      action.payload.talkingPoint,
      action.payload.incId
    );

    if (result && result.status === 200) {
      yield put(
        actions.updateTalkingPointSuccess(
          IncidentStoreSuccess.TALKING_POINT_UPDATE
        )
      );
    } else {
      if (IsConditionalCheckError(result.data)){
        yield put(actions.updateTalkingPointFailure(
          IncidentStoreError.TALKING_POINT_OVERWRITE_ERROR
        ));
      } else{
        yield put(
          actions.updateTalkingPointFailure(
            IncidentStoreError.TALKING_POINT_UPDATE
          )
        );
      }
    }
  } catch (e) {
    yield put(
      actions.updateTalkingPointFailure(
        IncidentStoreError.TALKING_POINT_UPDATE
      )
    );
  }
}
function* watchUpdateTalkingPoint() {
  yield takeEvery(
    actions.types.UPDATE_TALKING_POINT,
    updateTalkingPoint
  );
}

export function* uploadTalkingPointFile(action: {
  type: typeof types.UPLOAD_TALKING_POINT_FILE;
  payload: uploadTalkingPointFilePayload;
}): Generator<any, void, unknown> {
  try {
    const presignedUrlResult: any = yield call(
      api.getUploadTalkingPointFilePresignedUrl,
      action.payload.incId,
      action.payload.talkingPointId,
      action.payload.fileId
    );
    if (presignedUrlResult && presignedUrlResult.status === 200) {
      const s3Result: any = yield call(
        api.uploadToS3,
        presignedUrlResult.data,
        action.payload.blobData,
        action.payload.fileType
      );

      if (s3Result && s3Result.status === 200) {
        yield put(
          actions.uploadTalkingPointFileSuccess(
            IncidentStoreSuccess.TALKING_POINT_FILE_SAVE
          )
        );
      } else {
        yield put(
          actions.uploadTalkingPointFileFailure(
            IncidentStoreError.TALKING_POINT_FILE_UPLOAD
          )
        );
      }
    } else {
      yield put(
        actions.uploadTalkingPointFileFailure(
          IncidentStoreError.TALKING_POINT_FILE_UPLOAD
        )
      );
    }
  } catch (e) {
    yield put(
      actions.uploadTalkingPointFileFailure(
        IncidentStoreError.TALKING_POINT_FILE_UPLOAD
      )
    );
  }
}
function* watchUploadTalkingPointFile() {
  yield takeEvery(actions.types.UPLOAD_TALKING_POINT_FILE, uploadTalkingPointFile);
}

function* dowloadTalkingPointFile(action: {
  type: typeof types.DOWNLOAD_TALKING_POINT_FILE;
  payload: downloadTalkingPointFilePayload;
}): Generator<any, void, unknown> {
  try {
    // Clear the existing photo before retreiving the next
    yield put(actions.clearPresignedUrl());

    const presignedUrlResult: any = yield call(
      api.getDownloadTalkingPointFilePresignedUrl,
      action.payload.incId,
      action.payload.talkingPointId,
      action.payload.fileId
    );
    if (presignedUrlResult && presignedUrlResult.status === 200) {
      // Download the S3 result by opening in a new tab
      window.open(presignedUrlResult.data, "_blank");
    } else {
      yield put(
        actions.downloadTalkingPointFileFailure(
          IncidentStoreError.TALKING_POINT_FILE_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.downloadTalkingPointFileFailure(IncidentStoreError.TALKING_POINT_FILE_GET)
    );
  }
}
function* watchDownloadTalkingPointFile() {
  yield takeEvery(actions.types.DOWNLOAD_TALKING_POINT_FILE, dowloadTalkingPointFile);
}

export function* getTalkingPointHistory(action: {
  type: typeof types.GET_TALKING_POINT_HISTORY;
  payload: talkingPointHistoryPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getTalkingPointHistory,
      action.payload.incId,
      action.payload.talkingPointId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: VccHistory[] = result.data;
        yield put(actions.getTalkingPointHistorySuccess(apiResultPayload));
      } else {
        yield put(
          actions.getTalkingPointHistoryFailure(
            IncidentStoreError.TALKING_POINT_HISTORY_GET
          )
        );
      }
    } else {
      yield put(
        actions.getTalkingPointHistoryFailure(
          IncidentStoreError.TALKING_POINT_HISTORY_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getTalkingPointHistoryFailure(
        IncidentStoreError.TALKING_POINT_HISTORY_GET
      )
    );
  }
}
function* watchGetTalkingPointHistory() {
  yield takeEvery(
    actions.types.GET_TALKING_POINT_HISTORY,
    getTalkingPointHistory
  );
}

export function* getOutreach(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getOutreach,
      action.payload.incId,
      action.payload.outreachId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: IncidentOutreach = result.data;
        yield put(actions.getOutreachSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getOutreachFailure(
            IncidentStoreError.OUTREACH_GET
          )
        );
      }
    } else {
      yield put(
        actions.getOutreachFailure(
          IncidentStoreError.OUTREACH_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getOutreachFailure(
        IncidentStoreError.OUTREACH_GET
      )
    );
  }
}
function* watchGetOutreach() {
  yield takeEvery(
    actions.types.GET_OUTREACH,
    getOutreach
  );
}

export function* getOutreaches(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getOutreaches,
      action.payload.incId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: IncidentOutreach[] = result.data;
        yield put(actions.getOutreachesSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getOutreachesFailure(
            IncidentStoreError.OUTREACHES_GET
          )
        );
      }
    } else {
      yield put(
        actions.getOutreachesFailure(
          IncidentStoreError.OUTREACHES_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getOutreachesFailure(
        IncidentStoreError.OUTREACHES_GET
      )
    );
  }
}
function* watchGetOutreaches() {
  yield takeEvery(
    actions.types.GET_OUTREACHES,
    getOutreaches
  );
}

export function* createOutreach(action: {
  type: typeof types.CREATE_OUTREACH;
  payload: outreachPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.createOutreach,
      action.payload.outreach,
      action.payload.incId
    );

    if (result && result.status === 200) {
      yield put(
        actions.createOutreachSuccess(
          IncidentStoreSuccess.OUTREACH_SAVE
        )
      );
    } else {
      yield put(
        actions.createOutreachFailure(
          IncidentStoreError.OUTREACH_SAVE
        )
      );
    }
  } catch (e) {
    yield put(
      actions.createOutreachFailure(
        IncidentStoreError.OUTREACH_SAVE
      )
    );
  }
}
function* watchCreateOutreach() {
  yield takeEvery(
    actions.types.CREATE_OUTREACH,
    createOutreach
  );
}

export function* updateOutreach(action: {
  type: typeof types.UPDATE_OUTREACH;
  payload: outreachPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.updateOutreach,
      action.payload.outreach,
      action.payload.incId
    );

    if (result && result.status === 200) {
      yield put(
        actions.updateOutreachSuccess(
          IncidentStoreSuccess.OUTREACH_UPDATE
        )
      );
    } else {
      if (IsConditionalCheckError(result.data)){
        yield put(actions.updateOutreachFailure(
          IncidentStoreError.OUTREACH_OVERWRITE_ERROR
        ));
      } else{
        yield put(
          actions.updateOutreachFailure(
            IncidentStoreError.OUTREACH_UPDATE
          )
        );
      }
    }
  } catch (e) {
    yield put(
      actions.updateOutreachFailure(
        IncidentStoreError.OUTREACH_UPDATE
      )
    );
  }
}
function* watchUpdateOutreach() {
  yield takeEvery(
    actions.types.UPDATE_OUTREACH,
    updateOutreach
  );
}

export function* uploadOutreachFile(action: {
  type: typeof types.UPLOAD_OUTREACH_FILE;
  payload: uploadOutreachFilePayload;
}): Generator<any, void, unknown> {
  try {
    const presignedUrlResult: any = yield call(
      api.getUploadOutreachFilePresignedUrl,
      action.payload.incId,
      action.payload.outreachId,
      action.payload.fileId
    );
    if (presignedUrlResult && presignedUrlResult.status === 200) {
      const s3Result: any = yield call(
        api.uploadToS3,
        presignedUrlResult.data,
        action.payload.blobData,
        action.payload.fileType
      );

      if (s3Result && s3Result.status === 200) {
        yield put(
          actions.uploadOutreachFileSuccess(
            IncidentStoreSuccess.OUTREACH_FILE_SAVE
          )
        );
      } else {
        yield put(
          actions.uploadOutreachFileFailure(
            IncidentStoreError.OUTREACH_FILE_UPLOAD
          )
        );
      }
    } else {
      yield put(
        actions.uploadOutreachFileFailure(
          IncidentStoreError.OUTREACH_FILE_UPLOAD
        )
      );
    }
  } catch (e) {
    yield put(
      actions.uploadOutreachFileFailure(
        IncidentStoreError.OUTREACH_FILE_UPLOAD
      )
    );
  }
}
function* watchUploadOutreachFile() {
  yield takeEvery(actions.types.UPLOAD_OUTREACH_FILE, uploadOutreachFile);
}

function* dowloadOutreachFile(action: {
  type: typeof types.DOWNLOAD_OUTREACH_FILE;
  payload: downloadOutreachFilePayload;
}): Generator<any, void, unknown> {
  try {
    // Clear the existing photo before retreiving the next
    yield put(actions.clearPresignedUrl());

    const presignedUrlResult: any = yield call(
      api.getDownloadOutreachFilePresignedUrl,
      action.payload.incId,
      action.payload.outreachId,
      action.payload.fileId
    );
    if (presignedUrlResult && presignedUrlResult.status === 200) {
      // Download the S3 result by opening in a new tab
      window.open(presignedUrlResult.data, "_blank");
    } else {
      yield put(
        actions.downloadOutreachFileFailure(
          IncidentStoreError.OUTREACH_FILE_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.downloadOutreachFileFailure(IncidentStoreError.OUTREACH_FILE_GET)
    );
  }
}
function* watchDownloadOutreachFile() {
  yield takeEvery(actions.types.DOWNLOAD_OUTREACH_FILE, dowloadOutreachFile);
}

export function* getOutreachHistory(action: {
  type: typeof types.GET_OUTREACH_HISTORY;
  payload: outreachHistoryPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(
      api.getOutreachHistory,
      action.payload.incId,
      action.payload.outreachId
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: VccHistory[] = result.data;
        yield put(actions.getOutreachHistorySuccess(apiResultPayload));
      } else {
        yield put(
          actions.getOutreachHistoryFailure(
            IncidentStoreError.OUTREACH_HISTORY_GET
          )
        );
      }
    } else {
      yield put(
        actions.getOutreachHistoryFailure(
          IncidentStoreError.OUTREACH_HISTORY_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.getOutreachHistoryFailure(
        IncidentStoreError.OUTREACH_HISTORY_GET
      )
    );
  }
}
function* watchGetOutreachHistory() {
  yield takeEvery(
    actions.types.GET_OUTREACH_HISTORY,
    getOutreachHistory
  );
}

export function* uploadIncImage(action: {
  type: typeof types.UPLOAD_INC_IMAGE;
  payload: uploadImgRequestPayload;
}): Generator<any, void, unknown> {
  try {
    const presignedUrlResult: any = yield call(
      api.getImgUploadPresignedUrl,
      action.payload.incId,
      action.payload.fileId
    );
    if (presignedUrlResult && presignedUrlResult.status === 200) {
      const s3Result: any = yield call(
        api.uploadToS3,
        presignedUrlResult.data,
        action.payload.blobData,
        action.payload.fileType
      );

      if (s3Result && s3Result.status === 200) {
        yield put(
          actions.uploadIncidentImageSuccess(
            IncidentStoreSuccess.NOTES_IMAGE_SAVE
          )
        );
      } else {
        yield put(
          actions.uploadIncidentImageFailure(
            IncidentStoreError.VCC_NOTES_IMAGE_UPLOAD
          )
        );
      }
    } else {
      yield put(
        actions.uploadIncidentImageFailure(
          IncidentStoreError.VCC_NOTES_IMAGE_UPLOAD
        )
      );
    }
  } catch (e) {
    yield put(
      actions.uploadIncidentImageFailure(
        IncidentStoreError.VCC_NOTES_IMAGE_UPLOAD
      )
    );
  }
}
function* watchUploadIncImage() {
  yield takeEvery(actions.types.UPLOAD_INC_IMAGE, uploadIncImage);
}

function* dowloadIncImage(action: any): Generator<any, void, unknown> {
  try {
    // Clear the existing photo before retreiving the next
    yield put(actions.clearPresignedUrl());

    const presignedUrlResult: any = yield call(
      api.getImgDownloadPresignedUrl,
      action.payload.incId,
      action.payload.imgId
    );
    if (presignedUrlResult && presignedUrlResult.status === 200) {
      yield put(actions.downloadNoteFileSuccess(presignedUrlResult.data));
    } else {
      yield put(
        actions.uploadIncidentImageFailure(
          IncidentStoreError.VCC_NOTES_IMAGE_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.uploadIncidentImageFailure(IncidentStoreError.VCC_NOTES_IMAGE_GET)
    );
  }
}
function* watchDownloadIncImage() {
  yield takeEvery(actions.types.DOWNLOAD_NOTE_FILE, dowloadIncImage);
}

function* getVccIncidentHistory(action: any): Generator<any, void, unknown> {
  try {
    // Clear any previous data from appearing before loading the latest data
    yield put(actions.clearCurrentVccIncidentHistory());
    const result: any = yield call(
      api.getVccIncidentHistory,
      action.payload.id
    );
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: VccHistory[] = result.data;
        yield put(actions.getVccIncidentHistorySuccess(apiResultPayload));
      } else {
        yield put(
          actions.getOpenIncidentsFailure(IncidentStoreError.VCC_HISTORY_GET)
        );
      }
    } else {
      yield put(
        actions.getVccIncidentFailure(IncidentStoreError.VCC_HISTORY_GET)
      );
    }
  } catch (e) {
    yield put(
      actions.getVccIncidentFailure(IncidentStoreError.VCC_HISTORY_GET)
    );
  }
}
function* watchGetIncidentHistory() {
  yield takeEvery(actions.types.GET_INC_HISTORY, getVccIncidentHistory);
}

function* isIncidentUpdateAvailable(
  action: any
): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getVccIncident, action.payload.id);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: VccIncidentModel = result.data;
        if (apiResultPayload.updatedDate > action.payload.currentUpdateDate) {
          yield put(actions.isIncidentUpdateAvailableSuccess(true));
        } else {
          yield put(actions.isIncidentUpdateAvailableSuccess(false));
        }
      } else {
        yield put(
          actions.isIncidentUpdateAvailableFailure(
            IncidentStoreError.IS_INC_UPDATE_AVAIL_GET
          )
        );
      }
    } else {
      yield put(
        actions.isIncidentUpdateAvailableFailure(
          IncidentStoreError.IS_INC_UPDATE_AVAIL_GET
        )
      );
    }
  } catch (e) {
    yield put(
      actions.isIncidentUpdateAvailableFailure(
        IncidentStoreError.IS_INC_UPDATE_AVAIL_GET
      )
    );
  }
}
function* watchIsIncidentUpdateAvailable() {
  yield takeEvery(
    actions.types.IS_INC_UPDATE_AVAILABLE,
    isIncidentUpdateAvailable
  );
}

// Review: Should split out Notifications into their own saga and reducer
function* createIncidentNotification(
  action: any
): Generator<any, void, unknown> {
  try {
    AddObjectToLocalStorageArrayUtil(
      LocalStorage.INC_NOTIFICATIONS,
      action.payload.notification
    );
    yield put(actions.createIncidentNotifSuccess(action.payload.notification));
  } catch (e) {
    yield put(
      actions.createIncidentNotifFailure(
        IncidentStoreError.NEW_INC_CREATED_FAILURE
      )
    );
  }
}
function* watchCreateIncidentNotification() {
  yield takeEvery(actions.types.CREATE_INC_NOTIF, createIncidentNotification);
}

function* clearAllNotifs(): Generator<any, void, unknown> {
  try {
    localStorage.removeItem(LocalStorage.INC_NOTIFICATIONS);
    yield put(actions.clearAllNotifsSuccess());
  } catch (e) {
    yield put(
      actions.clearAllNotifsFailure(IncidentStoreError.CLEAR_ALL_NOTIFS_FAILURE)
    );
  }
}
function* watchClearAllNotifs() {
  yield takeEvery(actions.types.CLEAR_ALL_NOTIFS, clearAllNotifs);
}

function* clearNotif(action: any): Generator<any, void, unknown> {
  try {
    RemoveObjectFromStorageArrayUtil(
      LocalStorage.INC_NOTIFICATIONS,
      action.payload.incidentNotif
    );
    yield put(actions.clearNotifSuccess(action.payload.incidentNotif));
  } catch (e) {
    yield put(
      actions.clearNotifFailure(IncidentStoreError.CLEAR_NOTIF_FAILURE)
    );
  }
}
function* watchClearNotif() {
  yield takeEvery(actions.types.CLEAR_NOTIF, clearNotif);
}

function* finalizeVccIncidentReport(
  action: any
): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const email = currentUser.getIdToken().payload["custom:vccEmail"];
    const result: any = yield call(
      api.finalizeIncidentReport,
      action.payload.incidentId,
      email,
      action.payload.token
    );
    if (result && result.status === 200) {
      yield put(
        actions.finalizeVccIncidentReportSuccess(
          IncidentStoreSuccess.VCC_INCIDENT_REPORT_FINALIZE,
          action.payload.incidentId,
          email
        )
      );
    } else {
      yield put(
        actions.finalizeVccIncidentReportFailure(
          IncidentStoreError.VCC_INCIDENT_REPORT_FINALIZE
        )
      );
    }
  } catch (e) {
    yield put(
      actions.finalizeVccIncidentReportFailure(
        IncidentStoreError.VCC_INCIDENT_REPORT_FINALIZE
      )
    );
  }
}
function* watchFinalizeVccIncidentReport() {
  yield takeEvery(
    actions.types.FINALIZE_VCC_INCIDENT_REPORT,
    finalizeVccIncidentReport
  );
}

export function* setIncLocFromMap(action: {
  type: typeof types.SET_INC_LOC_LAT_LONG_FROM_MAP;
  payload: setIncLatLongFromMapPayload;
}): Generator<any, void, unknown> {
  try {
    // Update the incident 
    yield put(
      actions.setIncLatLongFromMapSuccess(
        action.payload.latitude,
        action.payload.longitude,
      )
    );

    // Update the Incident markerObject
    var currentIncident: any = yield(select(incidentStore));
    yield put(actions.setCurrentIncidentGeoRequest({...currentIncident, latitude: action.payload.latitude, longitude: action.payload.longitude}));

  } catch (e) {
    yield put(
      actions.finalizeVccIncidentReportFailure(
        IncidentStoreError.VCC_INCIDENT_REPORT_FINALIZE
      )
    );
  }
}
function* watchSetIncLocFromMap() {
  yield takeEvery(
    actions.types.SET_INC_LOC_LAT_LONG_FROM_MAP,
    setIncLocFromMap
  );
}

const vccIncidentSagas = [
  fork(watchCreateVccIncident),
  fork(watchGetVccIncident),
  fork(watchUpdateVccIncident),
  fork(watchDownloadVccIncidentReport),
  fork(watchGetOpenIncidents),
  fork(watchGetClosedAndDeletedVccIncidents),
  fork(watchGetIncidentNotes),
  fork(watchCreateVccIncidentNote),
  fork(watchGetIncidentAction),
  fork(watchGetIncidentActions),
  fork(watchCreateVccIncidentAction),
  fork(watchModifyVccIncidentAction),
  fork(watchGetTalkingPoint),
  fork(watchGetTalkingPoints),
  fork(watchCreateTalkingPoint),
  fork(watchUpdateTalkingPoint),
  fork(watchGetTalkingPointHistory),
  fork(watchUploadTalkingPointFile),
  fork(watchDownloadTalkingPointFile),
  fork(watchGetOutreach),
  fork(watchGetOutreaches),
  fork(watchCreateOutreach),
  fork(watchUpdateOutreach),
  fork(watchUploadOutreachFile),
  fork(watchDownloadOutreachFile),
  fork(watchGetOutreachHistory),
  fork(watchUploadIncImage),
  fork(watchDownloadIncImage),
  fork(watchGetIncidentHistory),
  fork(watchIsIncidentUpdateAvailable),
  fork(watchCreateIncidentNotification),
  fork(watchClearAllNotifs),
  fork(watchClearNotif),
  fork(watchFinalizeVccIncidentReport),
  fork(watchSetIncLocFromMap),
];

export default vccIncidentSagas;
