import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { FC } from "react";

export interface UploadErrorMessage {
  errorMessage: string;
  errorFile: string;
}

interface UploadRulesModalProps {
  props: {
    isOpen: boolean;
    importErrors: UploadErrorMessage[];
    handleClose: () => void;
  };
}

const UploadRulesModal: FC<UploadRulesModalProps> = ({ props }) => {
  return (
    <>
      <Dialog open={props.isOpen}>
        <DialogTitle>Error Uploading Files</DialogTitle>
        <DialogContent>
          {props.importErrors &&
            props.importErrors.map((err) => (
              <DialogContentText>
                {err.errorMessage} | File: {err.errorFile}
              </DialogContentText>
            ))}
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Dismiss
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadRulesModal;
