const config = {
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  },
  authRedirects: {
    SIGN_IN: process.env.REACT_APP_VCC_AUTH_REDIRECT_SIGNIN,
    SIGN_OUT: process.env.REACT_APP_VCC_AUTH_REDIRECT_SIGNOUT,
  },
};

export default config;
