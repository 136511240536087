import {
  Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React, { FC, useEffect, useState } from "react";
import {  PinEvent } from "../../../../reducers";
  
  interface RemovePinEventDialogProps {
    props: {
      eventToRemove: PinEvent;
      handleRemoveEvent: (finalizedEvent: PinEvent) => void;
      hanldeCancel: () => void;
    };
  }
  
  const RemovePinEventDialog: FC<RemovePinEventDialogProps> = ({ props }) => {
    const [finalizedEvent, setFinalizedEvent] = useState({} as PinEvent);
    
    useEffect(() => {
        setFinalizedEvent({...props.eventToRemove});
    }, [props.eventToRemove])
    
    const handleCancel = () => {
      setFinalizedEvent({} as PinEvent);
      props.hanldeCancel();
    };
  
    const handlePinAndClose = () => {
      props.handleRemoveEvent(finalizedEvent);
    };
  
    return (
      <>
        <DialogTitle id="form-dialog-title">Unpin Dispatch Event</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Box style={{fontWeight: "bold"}}>Event Number</Box></TableCell>
                  <TableCell><Box style={{fontWeight: "bold"}}>Location</Box></TableCell>
                  {/* Review: Can reintroduce when a need for the pin reason is required */}
                  {/* <TableCell><Box style={{fontWeight: "bold"}}>Pinned Reason</Box></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {finalizedEvent && (
                    <TableRow key={finalizedEvent.dispatchId}>
                      <TableCell>{finalizedEvent.dispatchId}</TableCell>
                      <TableCell>{finalizedEvent.location}</TableCell>
                      {/* Review: Can reintroduce when a need for the pin reason is required */}
                      {/* <TableCell>{finalizedEvent.pinReason}</TableCell> */}
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions>
            <Button onClick={handleCancel} variant="contained" color="inherit">
              Cancel
            </Button>
            <Button
              onClick={handlePinAndClose}
              color="primary"
              variant="contained"
              data-rum-id={`remove-pin-event__${props.eventToRemove.dispatchId}`}
            >
              Unpin Event
            </Button>
          </DialogActions>
        </DialogContent>
      </>
    );
  };
  
  export default RemovePinEventDialog;
  