import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { FC, useEffect } from "react";
import { VccIncidentModel } from "../../../reducers/states/vcc-incident";
import { ShortIdUtil } from "../../../utils/id-utils";
import ViewEditTextField from "../../common/view-edit-text-field";
import { IncidentStatus } from "../../../enums/incident-status";
import moment from "moment";
import useUserInfo from "../../../hooks/user-info";
import { NameOrEmailUtil } from "../../../utils/user-info-utils";

interface IncidentSummaryAccordionProps {
  props: {
    vccIncident: VccIncidentModel
  };
}

const IncidentSummaryAccordion: FC<IncidentSummaryAccordionProps> = ({ props }) => {
  const [user, setUser] = useUserInfo();

  useEffect(() => {
    if(props.vccIncident.updatedBy){
        setUser(props.vccIncident.updatedBy)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vccIncident.updatedBy])

  return (
    <>
      <Box m={1} sx={{ whiteSpace: "normal" }}>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={12} ml={2}>
                    <Typography
                        component={"span"}
                        style={{ display: "inline-block", marginRight: "2vh" }}
                        variant="h6"
                    >
                        {ShortIdUtil(props.vccIncident.id)}
                    </Typography>
                    <Typography
                        component={"span"}
                        style={{ display: "inline-block", marginRight: "2vh" }}
                        variant="subtitle1"
                    >
                        Status: {props.vccIncident.status}
                    </Typography>
                    {(props.vccIncident.status === IncidentStatus.CLOSED || props.vccIncident.status === IncidentStatus.DELETED) && (
                        <>
                            <Typography
                                component={"span"}
                                style={{ display: "inline-block", marginRight: "2vh" }}
                                variant="subtitle1"
                            >
                                {props.vccIncident.status === IncidentStatus.CLOSED ? (
                                    <Box>Closed At: {moment(props.vccIncident.updatedDate).format("LL, HH:mm:ss")}</Box>
                                ):(
                                    <Box>Deleted At: {moment(props.vccIncident.updatedDate).format("LL, HH:mm:ss")}</Box>
                                )}
                            </Typography>
                            <Typography
                                component={"span"}
                                style={{ display: "inline-block", marginRight: "2vh" }}
                                variant="subtitle1"
                            >
                                {props.vccIncident.status === IncidentStatus.CLOSED ? (
                                    <Box>Closed By: {NameOrEmailUtil(user)} {user.agency? ("- " + user.agency) : ""}</Box>
                                ):(
                                    <Box>Deleted By: {NameOrEmailUtil(user)} {user.agency? ("- " + user.agency) : ""}</Box>
                                )}
                            </Typography>

                        </>
                    )}
                </Grid>
            </AccordionSummary>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <AccordionDetails>
                {(props.vccIncident.status === IncidentStatus.CLOSED || props.vccIncident.status === IncidentStatus.DELETED) && (
                    <Grid item xs={12} mb={2}>
                        {props.vccIncident.status === IncidentStatus.CLOSED ? (
                            <ViewEditTextField
                                props={{
                                id: "Incident-Dropdown-Closed-Comment",
                                label: "Closed Comment",
                                tooltip: "",
                                initialValue: props.vccIncident.closedComment,
                                isEdit: false,
                                }}
                            />
                        ):(
                            <ViewEditTextField
                            props={{
                                id: "Incident-Dropdown-Deleted-Comment",
                                label: "Deleted Comment",
                                tooltip: "",
                                initialValue: props.vccIncident.deletedComment,
                                isEdit: false,
                            }}
                        />
                        )}
                    </Grid>
                )}
                <Grid item xs={12} mb={2}>
                    <ViewEditTextField
                        props={{
                        id: "Incident-Dropdown-Type",
                        label: "Incident Type",
                        tooltip: "",
                        initialValue: props.vccIncident.type,
                        isEdit: false,
                        }}
                    />
                </Grid>
                <Grid item xs={12} mb={2}>
                    <ViewEditTextField
                        props={{
                        id: "Incident-Dropdown-Location",
                        label: "Incident Location",
                        tooltip: "",
                        initialValue: props.vccIncident.location,
                        isEdit: false,
                        }}
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>
    </Box>
    </>
  );
};

export default IncidentSummaryAccordion;
