import { all } from "redux-saga/effects";

import dispatchEventFeedSagas from "./dispatch-event-feed";
import vccIncidentSagas from "./vcc-incident";
import adminSagas from "./admin";
import geoJsonSagas from "./geo-json";
import userInfoSagas from "./user-info";
import mapSagas from "./map";
import allUserInfoSagas from "./all-user-info";

export default function* rootSaga() {
  yield all([
    ...dispatchEventFeedSagas,
    ...vccIncidentSagas,
    ...adminSagas,
    ...geoJsonSagas,
    ...userInfoSagas,
    ...mapSagas,
    ...allUserInfoSagas,
  ]);
}
