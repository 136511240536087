import { Grid, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import { IRootReducer } from "../../reducers";
import {
  finalizeVccIncidentReport,
  getClosedAndDeletedIncidentsRequest,
} from "../../actions";
import { Alert } from "@mui/material";
import ReportGrid from "../../components/incident-model/records-management";
import {
  IncidentStoreError,
  IncidentStoreSuccess,
} from "../../enums/store-messages/incident";
import { awsRum } from "../..";
import { RUMPage } from "../../enums/rum";

export default function RecordsManagementPage() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const incidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer
  );

  const handleCloseSuccessAlert = () => {
    setSuccessMessage("");
    dispatch(getClosedAndDeletedIncidentsRequest());
  };

  useEffect(() => {
    try{
      awsRum.recordPageView(RUMPage.RECORD_MGMT);
    }
    catch{
      // Swallow rum errors
    }
  }, [])

  useEffect(() => {
    dispatch(getClosedAndDeletedIncidentsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (
      incidentStore.failureMessage ===
        IncidentStoreError.VCC_INCIDENT_REPORT_DOWNLOAD ||
      incidentStore.failureMessage ===
        IncidentStoreError.VCC_INCIDENT_REPORT_FINALIZE
    ) {
      setErrorMessage(incidentStore.failureMessage);
    }
  }, [incidentStore.failureMessage]);

  useEffect(() => {
    if (
      incidentStore.successMessage ===
      IncidentStoreSuccess.VCC_INCIDENT_REPORT_FINALIZE
    ) {
      setSuccessMessage(incidentStore.successMessage);
    }
  }, [incidentStore.successMessage]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportGrid
            props={{
              handleReportFinalize: (incidentId: string, token: string) =>
                dispatch(finalizeVccIncidentReport(incidentId, token)),
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSuccessAlert}
      >
        <Alert onClose={handleCloseSuccessAlert} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
