import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createVccIncidentNoteRequest,
  createVccIncidentRequest,
} from "../../../actions";
import { IncidentStatus } from "../../../enums/incident-status";
import {
  IncidentStoreError,
  IncidentStoreSuccess,
} from "../../../enums/store-messages/incident";
import { DispatchEventFeedModel, IRootReducer } from "../../../reducers";
import {
  Note,
  VccIncidentModel,
  IncidentDispatch,
} from "../../../reducers/states/vcc-incident";
import { UTCNowUtil } from "../../../utils/date-utils";
import { ShortIdUtil } from "../../../utils/id-utils";
import { v4 as uuidv4 } from "uuid";
import { WebSocketContext } from "../../../websocket";
import IncidentFactors from "../incident-factor";
import { useAuthContext } from "../../../core";
import { RoleAction } from "../../../enums/permission-actions";
import useUserPermissions from "../../../hooks/user-permissions";
import VccGeocoder from "../../map/geocoder";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers";

interface CreateIncidentProps {
  props: {
    mostRecentSelectedEvent: DispatchEventFeedModel;
    selectedEvents?: DispatchEventFeedModel[];
  };
}

const CreateIncident: FC<CreateIncidentProps> = ({ props }) => {
  const [open, setOpen] = useState(false);
  const [incident, setIncident] = useState({} as VccIncidentModel);
  const [note, setNote] = useState("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [errorExists, setErrorExists] = useState(false);
  const [createIncButtonTxt, setCreateIncButtonTxt] = useState("");
  const [createDisabled] = useUserPermissions(RoleAction.CREATE_INCIDENT);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setWebSocketIncId } = React.useContext(WebSocketContext);
  const { authState } = useAuthContext();
  const store = useSelector((state: IRootReducer) => state.vccIncidentReducer);

  useEffect(() => {
    if (incident.location !== "") setSubmitBtnDisabled(false);
    else setSubmitBtnDisabled(true);
  }, [incident.location]);

  useEffect(() => {
    if (
      store &&
      store.failureMessage === IncidentStoreError.VCC_INCIDENT_SAVE
    ) {
      setErrorExists(true);
    }
    if (
      store &&
      store.successMessage === IncidentStoreSuccess.INCIDENT_SAVE &&
      incident.id
    ) {
      setErrorExists(false);
      setOpen(false);
      resetForm();
      navigate(`/incident-model/${incident.id}`);
    }
  }, [store.failureMessage, store.successMessage, navigate, incident.id, store]);

  useEffect(() => {

    if (props.selectedEvents?.length || props.mostRecentSelectedEvent) {
      setCreateIncButtonTxt("Create VCC incident from selected dispatch event(s)")
    }else {
      setCreateIncButtonTxt("Create VCC incident from selected dispatch event(s) or without any events")
    }
  }, [props.mostRecentSelectedEvent, props.selectedEvents])

  const resetForm = () => {
    setIncident({
      id: "",
      factors: [] as string[],
      factorNote: "",
      location: "",
      type: "",
      startTime: "",
      incidentDetails: "",
      dispatchEvents: [] as IncidentDispatch[],
      status: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
      mostRecentUpdatedDate: "",
    } as VccIncidentModel);
  };

  const handleInputChange = (e: any, field: string) => {
    let value: string = e as string;
    return setIncident((current) => ({ ...current, [field]: value }));
  };

  const handleGeocoderSelect = (latitude?: number, longitude?:  number, placeName?: string) => {
    setIncident((current) => ({ ...current, "latitude": latitude, "longitude": longitude, "location": placeName ?? "" }));
  }
  
  const handleOpen = () => {
    // Handle filling in form if a dispatchRow was selected
    if (
      props.mostRecentSelectedEvent?.location !== "" &&
      props.mostRecentSelectedEvent?.location !== undefined
    ) {
      handleInputChange(props.mostRecentSelectedEvent?.location, "location");
      setSubmitBtnDisabled(false);
    } else {
      handleInputChange("", "location");
      setSubmitBtnDisabled(true);
    }
    if (props.mostRecentSelectedEvent?.latitude)
      handleInputChange(props.mostRecentSelectedEvent?.latitude, "latitude");
    if (props.mostRecentSelectedEvent?.longitude)
      handleInputChange(props.mostRecentSelectedEvent?.longitude, "longitude");
    if (
      props.mostRecentSelectedEvent?.eventType !== "" &&
      props.mostRecentSelectedEvent?.eventType !== undefined
    )
      handleInputChange(props.mostRecentSelectedEvent?.eventType, "type");
    else handleInputChange("", "type");

    handleInputChange(
      getIncidentStartTimeFromDispatches(props.selectedEvents),
      "startTime"
    );

    // Capture the Associated Dispatch Events to put on the incident
    if (props.selectedEvents?.length) {
      let incDispatches: IncidentDispatch[] = [];
      props.selectedEvents.forEach((d) => {
        incDispatches.push({
          id: d.eventNumber,
          agency: d.agency,
          eventType: d.eventType,
          location: d.location,
          latitude: d.latitude,
          longitude: d.longitude,
          startTime: d.eventDate,
          activeUnitCount: d.activeUnitCount ?? 0,
          isOpen: d.status === "OPEN" ? true : false,
          lastUpdateTime: d.lastUpdateDt,
        });
      
      });

      setIncident((current) => ({ ...current, dispatchEvents: incDispatches }));
    }

    let newIncId = uuidv4();
    handleInputChange(newIncId, "id");
    handleInputChange(IncidentStatus.INCIDENT_VERIFIED, "status");
    setOpen(true);

    // set wsContext openId to not show notif for this id when created
    setWebSocketIncId(newIncId);
  };

  const getIncidentStartTimeFromDispatches = (
    selectedEvents?: DispatchEventFeedModel[]
  ) => {
    // Grab the earliest dispatchEvent eventTime if any are selected, otherwise use the current time
    if (selectedEvents !== undefined && selectedEvents?.length > 0) {
      let dispatchEventDates: string[] = selectedEvents.map((e) => e.eventDate);
      let retDate = [...dispatchEventDates].sort(
        (d1, d2) => new Date(d1).getTime() - new Date(d2).getTime()
      )[0];
      // Check if latest date is something other than a minimum, otherwise use current time
      if (new Date(retDate).getUTCFullYear() > 1) {
        return retDate;
      } else {
        return UTCNowUtil();
      }
    } else {
      return UTCNowUtil();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    resetForm();
  };

  const handleCreateAndClose = () => {
    let createDate = UTCNowUtil();
    let newIncident = { ...incident };
    newIncident.createdBy = authState.email ?? "";
    newIncident.createdDate = createDate;
    newIncident.updatedBy = authState.email ?? "";
    newIncident.updatedDate = createDate;
    dispatch(createVccIncidentRequest(newIncident));

    if (note) {
      dispatch(
        createVccIncidentNoteRequest(
          {
            id: uuidv4(),
            type: "Note",
            content: note,
            createdBy: authState.email ?? "",
            createdDate: createDate,
            updatedBy: authState.email ?? "",
            updatedDate: createDate,
          } as Note,
          incident.id
        )
      );
    }
  };

  const handleCloseErrorAlert = () => {
    setErrorExists(false);
  };

  return (
    <>
      <Tooltip title={createIncButtonTxt}>
        <Box>
          <Button
            variant="contained"
            size="small"
            onClick={handleOpen}
            color="primary"
            style={{ alignSelf: "center" }}
            disabled={createDisabled}
          >
            Create VCC Incident
          </Button>
        </Box>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Create VCC Incident: {ShortIdUtil(incident.id)}
        </DialogTitle>
        <DialogContent>
          <IncidentFactors
            props={{
              selectedIncFactors: incident.factors,
              isEdit: true,
              updateSelected: (newFactors) =>
                handleInputChange(newFactors, "factors"),
            }}
          />
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="location"
                variant="outlined"
                autoFocus
                autoComplete="off"
                required
                margin="dense"
                label="Location"
                type="string"
                fullWidth
                inputProps={{ maxLength: 100 }}
                value={incident.location}
                onChange={(e) => handleInputChange(e.target.value, e.target.id)}
                InputProps={{
                  endAdornment: <VccGeocoder props={{
                    isEdit: true,
                    handleSelect: handleGeocoderSelect
                  }}/>
                }}
              />
            </Grid>
          </Grid> 
          <TextField
            id="type"
            variant="outlined"
            margin="dense"
            label="Type"
            fullWidth
            autoComplete="off"
            defaultValue={props.mostRecentSelectedEvent?.eventType}
            onChange={(e) => handleInputChange(e.target.value, e.target.id)}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Start Time"
              inputFormat="MM/DD/YYYY HH:mm"
              mask="__/__/____ __:__"
              value={moment(incident.startTime).format("MM/DD/YYYY HH:mm")}
              onChange={(e) => handleInputChange(e, "startTime")}
              renderInput={(params) => <TextField
                {...params}
                margin="dense"
                fullWidth
                />}
            />
          </LocalizationProvider>
          <TextField
            variant="outlined"
            margin="dense"
            id="notes"
            label="Notes"
            type="string"
            autoComplete="off"
            fullWidth
            multiline
            onChange={(e) => setNote(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="createdBy"
            label="Created By"
            type="string"
            autoComplete="off"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            defaultValue={authState.email ?? ""}
          />
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              id="status"
              fullWidth
              value={incident.status}
              onChange={(e) => handleInputChange(e.target.value, "status")}
              label="Status"
              defaultValue={IncidentStatus.INCIDENT_VERIFIED}
            >
              <MenuItem value={IncidentStatus.OPEN}>
                {IncidentStatus.OPEN}
              </MenuItem>
              <MenuItem value={IncidentStatus.INCIDENT_VERIFIED}>
                {IncidentStatus.INCIDENT_VERIFIED}
              </MenuItem>
            </Select>
          </FormControl>
          <DialogActions>
            <Button data-rum-id="create-incident-cancel" onClick={handleCancel} color="inherit" variant="contained">
              Cancel
            </Button>
            <Button
              data-rum-id="create-incident-confirm"
              onClick={handleCreateAndClose}
              color="primary"
              variant="contained"
              disabled={submitBtnDisabled}
            >
              Create VCC Incident
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={errorExists}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseErrorAlert}
      >
        <Alert onClose={handleCloseErrorAlert} severity="error">
          Error Creating VCC Incident: {ShortIdUtil(incident.id)}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateIncident;
