import { Box, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { FC, useEffect, useState } from "react";
import UserInfo from "../user-info-display";
import useUserInfo from "../../../../hooks/user-info";

interface UserInfoTooltipHookProps {
  props: {
    email: string;
  };
}

const UserInfoTooltipHook: FC<UserInfoTooltipHookProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useUserInfo();

  useEffect(() => {
    setUser(props.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.email]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Box onClick={() => setIsOpen(true)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<UserInfo props={{ userInfo: user }} />}
          >
            <IconButton size="large">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default UserInfoTooltipHook;
