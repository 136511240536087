import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { IncidentTalkingPoint } from "../../../reducers/states/vcc-incident";
import useUserPermissions from "../../../hooks/user-permissions";
import { RoleAction } from "../../../enums/permission-actions";
import TalkingPointCard from "./talking-point-card";
import AddEditTalkingPointModal from "./add-edit-talking-point-modal";
import { getTalkingPointRequest } from "../../../actions";

interface ApprovedTalkingPointsProps {
  props: {
    incidentId: string;
    isEditEnabled: boolean;
  }
}

const ApprovedTalkingPoints: FC<ApprovedTalkingPointsProps> = ({props}) => {
  const dispatch = useDispatch();
  const [roleDenied] = useUserPermissions(RoleAction.ADD_EDIT_TALKING_POINTS);
  const addEditEnabled = !roleDenied && props.isEditEnabled;
  const [isAddTalkingPoint, setIsAddTalkingPoint] = useState(true);
  const [addEditTalkingPointOpen, setAddEditTalkingPointOpen] = useState(false);

  const talkingPointsStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.talkingPoints
  );


  const handleAddTalkingPoint = () => {
    setIsAddTalkingPoint(true);
    setAddEditTalkingPointOpen(true);
  }

  const handleEditTalkingPoint = (talkingPoint: IncidentTalkingPoint) => {
    dispatch(
      getTalkingPointRequest(
        props.incidentId,
        talkingPoint.id
      )
    );
    setIsAddTalkingPoint(false);
    setAddEditTalkingPointOpen(true);
  }

  const handleAddEditClose = () => {
    setIsAddTalkingPoint(true);
    setAddEditTalkingPointOpen(false);
  }

  return (
    <>
      <Box mt={1} style={{height: "100%"}}> 
        <Paper style={{height: "100%"}}>
          {/* Header */}
          <Grid container style={{maxHeight: "50px"}}>
            <Grid item xs={4}>
              <Box mt={1} ml={1}>
                <Typography component={'span'} variant="body1"><Box style={{fontWeight: "bold"}}>Approved Talking Points</Box></Typography>
              </Box>
            </Grid>
            <Grid container item xs={8} justifyContent="flex-end">
              {addEditEnabled && (
                <Grid item>
                  <Box m={1} mr={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ alignSelf: "center" }}
                      size="small"
                      onClick={handleAddTalkingPoint}
                    >
                      Add Talking Point
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {/* Talking Points Component */}
          <Box m={1} display="flex" style={{height: "calc(100% - 50px)", overflow: "auto"}}>
            <Grid container style={{height: "100%"}} justifyItems="center">
              <Grid item xs={12} style={{maxHeight: "50px"}}>
                <Grid container direction="row" alignItems="stretch">
                  <Grid item xs={1} />
                  <Grid item xs={4}> 
                      <Typography component={'span'} variant="body2"><Box ml={2} style={{fontWeight: "bold"}}>Talking Point Description</Box></Typography>
                  </Grid>
                  <Grid item xs={1}> 
                      <Typography component={'span'} variant="body2"><Box style={{fontWeight: "bold"}}>Agency</Box></Typography>
                  </Grid>
                  <Grid item xs={2}> 
                      <Typography component={'span'} variant="body2"><Box style={{fontWeight: "bold"}}>Updated By</Box></Typography>
                  </Grid>
                  <Grid item xs={2}> 
                      <Typography component={'span'} variant="body2"><Box style={{fontWeight: "bold"}}>Last Updated Time</Box></Typography>
                  </Grid>
                  <Grid item xs={1}> 
                      <Typography component={'span'} variant="body2"><Box style={{fontWeight: "bold"}}>Files</Box></Typography>
                  </Grid>
                  <Grid item xs={1}> 
                      <Typography component={'span'} variant="body2"><Box style={{fontWeight: "bold"}}>History</Box></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{height: "calc(100% - 50px)", overflow: "auto"}}>
                {talkingPointsStore.length > 0 ? (
                  <Box>
                    {talkingPointsStore.map(
                      (tp: IncidentTalkingPoint) => (
                        <TalkingPointCard
                          props={{
                            talkingPoint: tp,
                            incidentId: props.incidentId,
                            isEditHidden: !addEditEnabled,
                            handleEdit: handleEditTalkingPoint
                          }}
                        />
                      )
                    )}
                  </Box>
                ):(
                  <Box m={1} display="flex" justifyContent="center"><Typography variant="body2">No Approved Talking Points - Click Add Talking Point to add</Typography></Box>
              )}
              </Grid>
            </Grid>
          </Box>
          <AddEditTalkingPointModal
            props={{
              isOpen: addEditTalkingPointOpen,
              isAddTalkingPoint: isAddTalkingPoint,
              incidentId: props.incidentId,
              handleClose: handleAddEditClose,
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ApprovedTalkingPoints;
