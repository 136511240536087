import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { DispatchEventFeedModel, IRootReducer } from "../../../../../reducers";
import { VccIncidentModel } from "../../../../../reducers/states/vcc-incident";
import AssociatedDispatchesGrid from "./grid";

interface AddAssociatedDispatchProps {
  props: {
    isOpen: boolean;
    incident: VccIncidentModel;
    handleClose: () => void;
    handleAddDispatches: (dispatches: DispatchEventFeedModel[]) => void;
  };
}

const AddAssociatedDispatchModal: FC<AddAssociatedDispatchProps> = ({
  props,
}) => {
  const dispatchStore = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer
  );
  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        fullWidth
        maxWidth="lg"
      >
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Box mt={1} mb={0}>
                <DialogTitle>Add Dispatches</DialogTitle>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={3}
              alignItems="center"
              justifyContent="flex-end"
            ></Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={props.handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent dividers>
              <AssociatedDispatchesGrid
                props={{
                  dispatchEvents: dispatchStore.dispatchEvents.data,
                  incident: props.incident,
                  timeFrame: dispatchStore.timeFrame,
                  dispatchEvent: dispatchStore.dispatchEvent,
                  feedStatus: dispatchStore.feedStatus,
                  handleClose: props.handleClose,
                  handleAddDispatches: props.handleAddDispatches,
                }}
              />
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default AddAssociatedDispatchModal;
