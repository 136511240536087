import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { FC, useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createVccIncidentNoteRequest,
  downloadNoteFileRequest,
  uploadIncidentImageRequest,
} from "../../../../actions";
import { IRootReducer } from "../../../../reducers";
import { Note } from "../../../../reducers/states/vcc-incident";
import { UTCNowUtil } from "../../../../utils/date-utils";
import IncidentNote from "./incident-note";
import FileModal, { FileModalDetails } from "../../file-modal";
import UploadRulesModal, { UploadErrorMessage } from "../../upload-rules-modal";
import useUserPermissions from "../../../../hooks/user-permissions";
import { RoleAction } from "../../../../enums/permission-actions";
import { FilesPrepareResponse, importFilesUtil, S3FileData } from "../../../../utils/import-utils";

interface IncidentNotesProps {
  props: {
    currentUser: string;
    isEditEnabled: boolean;
    handleIsEdit: (edit: boolean) => void;
  };
}

const IncidentNotes: FC<IncidentNotesProps> = ({ props }) => {
  const dispatch = useDispatch();
  const params = useParams<{ incid: string }>();
  const [isEdit, setIsEdit] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState("");
  const [imageData, setImageData] = useState([] as S3FileData[]);
  const [sortedNotes, setSortedNotes] = useState([] as Note[]);
  const [isLoading, setIsLoading] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [roleDenied] = useUserPermissions(RoleAction.ADD_NOTE);
  const addNoteEnabled = !roleDenied && props.isEditEnabled;
  const [errorRuleOpen, setErrorRuleOpen] = useState(false);
  const [fileModalDetails, setFileModalDetails] = useState({} as FileModalDetails);
  const [importErrors, setImportErrors] = useState([] as UploadErrorMessage[]);

  const storeNotes = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.notes
  );

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1250);
    
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  const updateIsEdit = useCallback((edit: boolean) => {
    setIsEdit(edit);
    props.handleIsEdit(edit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSortedNotes(
      [...storeNotes].sort(
        (d1, d2) =>
          new Date(d2.createdDate).getTime() -
          new Date(d1.createdDate).getTime()
      )
    );
  }, [storeNotes]);

  useEffect(() => {
    if (newNoteContent !== "") setSaveBtnDisabled(false);
    else setSaveBtnDisabled(true);
  }, [newNoteContent]);

  const handleNewNote = () => {
    updateIsEdit(true);
  };

  const handleInputChange = (e: any) => {
    let value: string = e as string;
    return setNewNoteContent(value);
  };

  // Notes
  const handleSave = () => {
    if (newNoteContent) {
      setIsLoading(true);
      showLoadingAnimation();
      let regex = /[^a-zA-Z0-9/!%"'‘’“”~@#$^*()_+=[\]{}|\\,.? :-]/g;

      let newNote: Note = {
        id: uuidv4(),
        type: "Note",
        content: newNoteContent.replace(regex, ''),
        createdBy: props.currentUser,
        createdDate: UTCNowUtil(),
        updatedBy: props.currentUser,
        updatedDate: UTCNowUtil(),
      };
      if (imageData && imageData.length > 0) {
        // Review: Tie together the note content and images in one action
        newNote.imageIds = imageData.map((img) => img.id);
        dispatch(createVccIncidentNoteRequest(newNote, params.incid!));

        // For now, just dispatch multiple upload requests
        imageData.forEach((img: S3FileData) =>
          dispatch(
            uploadIncidentImageRequest(
              params.incid!,
              img.id,
              img.blobData,
              img.fileType
            )
          )
        );
      } else {
        // Create a regular text only note
        dispatch(createVccIncidentNoteRequest(newNote, params.incid!));
      }

      // Clear the form for the next operation
      updateIsEdit(false);
      setNewNoteContent("");
      setImageData([]);
      setImportErrors([]);
      props.handleIsEdit(false);
    }
  };

  const handleCancel = () => {
    updateIsEdit(false);
    setNewNoteContent("");
    setImageData([]);
    setImportErrors([]);
  };

  const handleFileImport = async (event: any) => {
    let fileList = event.target.files as FileList;
    let result: FilesPrepareResponse = await importFilesUtil(fileList);
    if (result.importErrors && result.importErrors.length > 0) {
      setImportErrors(result.importErrors);
      setErrorRuleOpen(true);
    } else {
      setImageData(result.importData);
    }
  };

  const handleClearImage = (imgPreviewToRemove: string) => {
    setImageData(
      imageData.filter((img) => img.imgPreview !== imgPreviewToRemove)
    );
  };

  const handleOpenFile = (fileId: string, isImageType: boolean) => {
    dispatch(downloadNoteFileRequest(params.incid!, fileId));
    setFileModalDetails({isOpen: true, fileName: fileId, isImageType } as FileModalDetails)
  };

  const handleCloseErrorModal = () => {
    setImportErrors([]);
    setErrorRuleOpen(false);
  };

  return (
    <>
      <Box m={1}>{isLoading && <LinearProgress />}</Box>
      <Box m={1} style={{ height: "100%" }}>
        <Paper variant="outlined" style={{ height: "100%" }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item xs={1}>
              <Box mt={1} ml={1}>
                <Tooltip title="Add a new note to update information about the incident">
                  <Typography variant="subtitle1">Notes</Typography>
                </Tooltip>
              </Box>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              item
              xs={11}
            >
              {addNoteEnabled && (
                <>
                  <Grid item>
                    <Box mb={2} mr={1}>
                      {!isEdit && (
                        <Typography
                          variant={"body2"}
                          style={{ fontStyle: "italic" }}
                        >
                          Click to add a new note
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item>
                    {!isEdit && (
                      <Box m={0.25}>
                        <IconButton
                          onClick={handleNewNote}
                          size="large"
                          disabled={!addNoteEnabled}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            {isEdit && (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Box m={1}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="new-note"
                      label="New Note"
                      type="string"
                      variant="outlined"
                      fullWidth
                      multiline
                      inputProps={{ maxLength: 1000 }}
                      onChange={(e) => handleInputChange(e.target.value)}
                      value={newNoteContent || ""}
                    />
                  </Box>
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="flex-end">
                  <Grid item>
                    <Box mt={1.5} mb={0}>
                      <input
                        accept="*"
                        multiple
                        style={{ display: "none" }}
                        id="note-file-picker"
                        name="testName"
                        type="file"
                        onChange={handleFileImport}
                      />
                      <label htmlFor="note-file-picker">
                        <IconButton component="span" size="large">
                          <AttachFileIcon />
                        </IconButton>
                      </label>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box m={1}>
                      <Button
                        onClick={handleCancel}
                        variant="contained"
                        color="inherit"
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box m={1}>
                      <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                        disabled={saveBtnDisabled}
                      >
                        Save Note
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {imageData && imageData.length > 0 && (
                      <Typography variant="body2">Attachments:</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {imageData &&
                      imageData.length > 0 &&
                      imageData.map((img) => (
                        <Box m={1} key={img.id}>
                          <img
                            src={img.imgPreview}
                            alt={img.id}
                            style={{ maxHeight: "30px", maxWidth: "30px" }}
                          />
                          <IconButton
                            onClick={() => handleClearImage(img.imgPreview)}
                            style={{ marginTop: "-10px" }}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {sortedNotes.length > 0 && (
              <Grid item xs={12}>
                <Box ml={1} mr={1}>
                  <Divider />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} style={{ height: "90%", overflow: "auto" }}>

              {sortedNotes.map((note) => (
                <IncidentNote
                  key={note.id}
                  props={{
                    note: note,
                    handleOpenFile: (fileId: string, isImageType: boolean) => handleOpenFile(fileId, isImageType),
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <FileModal
        props={{
          fileModalDetails: fileModalDetails,
          handleClose: () => setFileModalDetails({} as FileModalDetails),
        }}
      />
      <UploadRulesModal
        props={{
          isOpen: errorRuleOpen,
          importErrors: importErrors,
          handleClose: handleCloseErrorModal,
        }}
      />
    </>
  );
};

export default IncidentNotes;
