import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Theme,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { ChangeEvent, FC } from "react";
import { Agency } from "../../../../../enums/agency";
import { customTheme } from "../../../../..";

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    paddingRight: "0px",
    marginRight: "0px",
  },
  notAvailable: {
    color: theme.palette.error.main,
  },
}));

interface AgencyFilterProps {
  props: {
    agency: string;
    agencyFilters: string[];
    isFeedDown: boolean;
    tooltip?: string;
    handleAgencySelect: (event: ChangeEvent<HTMLInputElement>) => void;
  };
}

const AgencyFilter: FC<AgencyFilterProps> = ({ props }) => {
  const classes = useStyles();

  const getChipColor = (agency: string) => {
    switch (agency) {
      case Agency.WSP:
      case Agency.WSP_I5:
      case Agency.WSP_NW: {
        return customTheme.agency.WSP;
      }
      case Agency.KCM: {
        return customTheme.agency.KCM;
      }
      case Agency.SFD: {
        return customTheme.agency.SFD;
      }
      case Agency.SPD: {
        return customTheme.agency.SPD;
      }
    }
  }

  return (
    <>
      <Box ml={1}>
        <FormControlLabel
          className={classes.filter}
          control={
            <Tooltip title={props.tooltip ?? ""}>
              <Checkbox
                name={props.agency}
                checked={props.agencyFilters.includes(props.agency)}
                onChange={props.handleAgencySelect}
                checkedIcon={<Chip style={{backgroundColor: getChipColor(props.agency), color: 'white'}}  variant="filled" label={props.agency}/>} 
                icon={
                  <Chip
                    variant="outlined"
                    label={props.agency}
                  />
                }
              />
            </Tooltip>
          }
          label={
            <Box display="flex" mr={2}>
              {props.isFeedDown && (
                <Tooltip
                  title={
                    props.agency +
                    "'s data feed is currently down or experiencing problems. The system will check for updates in 30 seconds"
                  }
                >
                  <HighlightOffIcon className={classes.notAvailable} />
                </Tooltip>
              )}
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default AgencyFilter;
