import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { Box, Grid, Typography } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import React, { FC, useEffect, useRef, useState } from "react";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;

interface VccReverseGeocoderProps {
    props: { 
        isEdit: boolean;
        latLong: {latitude: number | undefined, longitude: number | undefined};
        handleUpdateTextAddress: (placeName: string) => void;
    }
}

const VccReverseGeocoder: FC<VccReverseGeocoderProps> = ({ props }) => {
	const geocoderRef = useRef(null);
	const [geocoder, setGeocoder] = useState({} as unknown as MapboxGeocoder);
	const [geoResult, setGeoResult] = useState("");
	const [geocoderInstantiated, setGeocoderInstantiated] = useState(false);
	const [geocoderInitted, setGeocoderInitted] = useState(false);
    
	useEffect(() => {
		mapboxgl.accessToken = MAPBOX_API_KEY || "";
		const initGeocoder = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			reverseGeocode: true,
			types: 'address,poi',
			limit: 5,
			minLength: 3,
			bbox: [-122.48, 47.41, -122.16, 47.79],
		});

		setGeocoder(initGeocoder);
		setGeocoderInstantiated(true);
	}, [])

	useEffect(() => {
		if( geocoderInstantiated ){
			if(geocoderRef.current != null && !geocoderInitted){
				// Set the Reverse Geocoder on the element set aside for it
				geocoder.addTo('#reverse-geocoder');

				// Add geocoder result to container and capture result
				geocoder.on('result', (e: any) => {
					const queryResult: MapboxGeocoder.Result = e.result;
					setGeoResult(queryResult.place_name);
					props.handleUpdateTextAddress(queryResult.place_name);
				});

				// Set the Geocoder as initted to avoid creating multiple instances
				setGeocoderInitted(true);
			}
		}
	}, [geocoderInstantiated, geocoder, geocoderInitted, props]);

	useEffect(() => {
		if(geocoderRef.current != null && geocoderInitted){
			geocoder.query( props.latLong.latitude + ", " + props.latLong.longitude);
		}
	}, [geocoder, geocoderInitted, props.latLong])

	return (
			<>
				<Box style={{zIndex: 5001}}>
					<Grid container>
						<Grid item xs={12}>
							<Box><Typography variant='body2'>Located Address: <Box fontStyle="italic" display="inline">{geoResult}</Box></Typography></Box>
						</Grid>
						<Grid item xs={12}>
							<Box ref={geocoderRef} style={{height: "0px"}}>
								{/* The geocoder package requires an element defined for it */}
								{/* Setting to hidden to use our own display instead */}
								<Box visibility={"hidden"} id="reverse-geocoder"/> 
							</Box>
						</Grid>
					</Grid>
				</Box>
			</>
	);
};
  
export default VccReverseGeocoder;
  