import { Box, IconButton, ListItemText, Paper, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { VccNotification } from "../../../../reducers/states/vcc-incident";

const useStyles = makeStyles(() => ({
  bannerNotif: {
    position: "absolute",
    top: "60px",
  },
  bannerBox: {
    minWidth: "350px",
    display: "inline-flex",
  },
}));

interface BannerNotificationProps {
  props: {
    notification: VccNotification;
    handleDismissBanner: (notifId: string) => void;
    handleClearBanner: (notifId: string) => void;
  };
}

const BannerNotification: FC<BannerNotificationProps> = ({ props }) => {
  const classes = useStyles();
  const [openBannerNotif, setOpenBannerNotif] = useState(true);

  const handleDismissBanner = (id: string) => {
    setOpenBannerNotif(false);
    props.handleDismissBanner(id);
  };

  const handleClearBanner = (id: string) => {
    setOpenBannerNotif(false);
    props.handleClearBanner(id);
  };

  return (
    <>
      <Snackbar
        className={classes.bannerNotif}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openBannerNotif}
        onClose={() => handleDismissBanner(props.notification.id)}
        key={props.notification.id}
      >
        <Box m={1} component={Paper} className={classes.bannerBox}>
          <Box ml={1} mr={1} display="flex">
            <ListItemText primary={props.notification.message} />
          </Box>
          <Box ml={1} mr={1} display="flex" style={{ alignItems: "center" }}>
            <Link
              style={{ fontSize: "12px" }}
              to={"/incident-model/" + props?.notification.id}
              onClick={() => props.handleClearBanner(props.notification.id)}
            >
              Go to Incident
            </Link>
          </Box>
          <Box display="flex" style={{ float: "right" }}>
            <IconButton
              edge="end"
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClearBanner(props.notification.id)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Snackbar>
    </>
  );
};

export default BannerNotification;
