import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createVccIncidentActionRequest } from "../../../actions";
import { useAuthContext } from "../../../core/auth-context";
import {
  IncidentAction,
} from "../../../reducers/states/vcc-incident";
import { IsTimePlannedValidUtil, UTCNowUtil } from "../../../utils/date-utils";
import { ShortIdUtil } from "../../../utils/id-utils";
import { v4 as uuidv4 } from "uuid";
import { AgencyChip } from "../../common/agency-chip";
import { ActionStatus } from "../../../enums/action-status";
import { ActionStatusChip } from "../action-status-chip";
import { ActionType } from "../../../enums/action-type";
import CollaboratorsInput from "./collaborators-input";
import moment from "moment";

interface AddActionProps {
  props: {
    isOpen: boolean;
    incidentId: string;
    handleClose: () => void;
  };
}

// Review: This component and EditActionModal should be merged into one component
// that hit either the post or put depending on add or edit
const AddActionModal: FC<AddActionProps> = ({ props }) => {
  const [errorExists, setErrorExists] = useState(false);
  const [action, setAction] = useState({
    status: ActionStatus.PLANNED,
    type: ActionType.TRAFFIC_FLOW_MANAGEMENT,
  } as IncidentAction);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const { authState } = useAuthContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (action.description !== "") {
      if (action.status === ActionStatus.PLANNED){
        if(IsTimePlannedValidUtil(action.fromTimePlanned, action.toTimePlanned)){
          setSubmitBtnDisabled(false);
        }
        else{
          setSubmitBtnDisabled(true);
        }
      }
      else{
        setSubmitBtnDisabled(false);
      }
    }
    else {
      setSubmitBtnDisabled(true);
    }
  }, [action.agency, action.description, action.fromTimePlanned, action.status, action.toTimePlanned]);

  useEffect(() => {
    setAction((current) => ({
      ...current,
      collaborators: [authState.email ?? ""],
      lead: authState.email ?? "",
    }));
  }, [authState.email]);

  useEffect(() => {
    if (action.status === ActionStatus.PLANNED && action.fromTimePlanned === "" && action.toTimePlanned === "") {
      let currentTime = moment();
      setAction((current) => (
        { ...current,
          "fromTimePlanned": currentTime.format("HH:mm"),
          "toTimePlanned": currentTime.add(1, "hours").format("HH:mm")
         }));
    } else if(action.status !== ActionStatus.PLANNED) {
      setAction((current) => (
        { ...current, 
          "fromTimePlanned": "", 
          "toTimePlanned": ""
        }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action.status]);

  const resetForm = () => {
    setAction({
      id: "",
      description: "",
      type: ActionType.TRAFFIC_FLOW_MANAGEMENT,
      agency: "",
      createdBy: "",
      lead: authState.email,
      status: ActionStatus.PLANNED,
      timeAdded: "",
      fromTimePlanned: "",
      toTimePlanned: "",
      collaborators: [authState.email],
      mentions: "",
      removalReason: "",
      ccId: ""
    } as IncidentAction);
  };

  const handleCloseErrorAlert = () => {
    setErrorExists(false);
  };
  const handleInputChange = (e: any, field: string) => {
    let value: string = e as string;
    return setAction((current) => ({ ...current, [field]: value }));
  };
  const handleCancel = () => {
    resetForm();
    props.handleClose();
  };
  const handleSelectAgency = (event: SelectChangeEvent<string>) => {
    const newAgency = event.target.value as string;
    setAction((current) => ({ ...current, agency: newAgency }));
  };
  
  const handleCollaboratorChange = useCallback(
    (value: string[]) => {
      setAction((current) => ({ ...current, collaborators: value }));
    },
    [setAction]
  );

  const handleLeadChange = useCallback(
    (index: number) => {
      setAction((current) => ({
        ...current,
        lead: action.collaborators[index],
      }));
    },
    [action.collaborators]
  );

  const handleCreateAndClose = () => {
    let newAction = { ...action };

    let regex = /[^a-zA-Z0-9/!%"'‘’“”~@#$^*()_+=[\]{}|\\,.? :-]/g;
    newAction.description = newAction.description.replace(regex, '');
    newAction.removalReason = newAction.removalReason.replace(regex, '');

    newAction.timeAdded = UTCNowUtil();
    newAction.id = uuidv4();
    dispatch(createVccIncidentActionRequest(props.incidentId, newAction));
    resetForm();
    props.handleClose();
  };
  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Box mt={1} mb={0}>
                <DialogTitle id="form-dialog-title">
                  Create Mobility Strategy for VCC Incident:{" "}
                  {ShortIdUtil(props.incidentId)}
                </DialogTitle>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={3}
              alignItems="center"
              justifyContent="flex-end"
            ></Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={6} direction="column">
                  <TextField
                    id="description"
                    variant="outlined"
                    autoFocus
                    required
                    margin="dense"
                    label="Description"
                    type="string"
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 300 }}
                    value={action.description || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, e.target.id)
                    }
                  />
                  <CollaboratorsInput
                    props={{
                      collaborators: action.collaborators,
                      handleCollaboratorChange: handleCollaboratorChange,
                      handleLeadChange: handleLeadChange,
                    }}
                  />
                </Grid>
                <Grid container item xs={12} md={6} direction="column">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          id="status"
                          fullWidth
                          value={action.status}
                          onChange={(e) =>
                            handleInputChange(e.target.value, "status")
                          }
                          label="Status"
                          defaultValue={action.status ?? ActionStatus.PLANNED}
                        >
                          <MenuItem value={ActionStatus.PLANNED}>
                            <ActionStatusChip
                              value={ActionStatus.PLANNED}
                            ></ActionStatusChip>
                          </MenuItem>
                          <MenuItem value={ActionStatus.COMPLETE}>
                            <ActionStatusChip
                              value={ActionStatus.COMPLETE}
                            ></ActionStatusChip>
                          </MenuItem>
                          <MenuItem value={ActionStatus.IN_PROGRESS}>
                            <ActionStatusChip
                              value={ActionStatus.IN_PROGRESS}
                            ></ActionStatusChip>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel>Agency</InputLabel>
                        <Select
                          value={action.agency ?? ""}
                          onChange={handleSelectAgency}
                          label="Agency"
                        >
                          <MenuItem value={""}>(None)</MenuItem>
                          <MenuItem value={"KCM"}>
                            <AgencyChip props={{ agency: "KCM" }} />
                          </MenuItem>
                          <MenuItem value={"NWSA"}>
                            <AgencyChip props={{ agency:"NWSA" }} />
                          </MenuItem>
                          <MenuItem value={"Port"}>
                            <AgencyChip props={{ agency:"Port" }} />
                          </MenuItem>
                          <MenuItem value={"SDOT"}>
                            <AgencyChip props={{ agency:"SDOT" }} />
                          </MenuItem>
                          <MenuItem value={"SFD"}>
                            <AgencyChip props={{ agency:"SFD" }} />
                          </MenuItem>
                          <MenuItem value={"SPD"}>
                            <AgencyChip props={{ agency:"SPD" }} />
                          </MenuItem>
                          <MenuItem value={"ST"}>
                            <AgencyChip props={{ agency:"ST" }} />
                          </MenuItem>
                          <MenuItem value={"WSDOT"}>
                            <AgencyChip props={{ agency:"WSDOT" }} />
                          </MenuItem>
                          <MenuItem value={"WSP"}>
                            <AgencyChip props={{ agency:"WSP" }} />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel>Mobility Strategy Type</InputLabel>
                    <Select
                      id="type"
                      fullWidth
                      value={action.type || ActionType.TRAFFIC_FLOW_MANAGEMENT}
                      onChange={(e) =>
                        handleInputChange(e.target.value, "type")
                      }
                      label="Mobility Strategy Type"
                    >
                      <MenuItem value={ActionType.TRAFFIC_FLOW_MANAGEMENT}>
                        {ActionType.TRAFFIC_FLOW_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.EQUIPMENT_MANAGEMENT}>
                        {ActionType.EQUIPMENT_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.MESSAGE_SIGN_MANAGEMENT}>
                        {ActionType.MESSAGE_SIGN_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.LANE_MANAGEMENT}>
                        {ActionType.LANE_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.OTHER}>
                        {ActionType.OTHER}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="createdBy"
                    label="Added By"
                    type="string"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={authState.email ?? ""}
                  />
                  {action.status === ActionStatus.PLANNED && (
                    <Paper variant="outlined">
                      <Box m={1}>
                        If Status is Planned - Enter Planned Time Frame
                      </Box>
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                label="From"
                                ampm={false}
                                value={action.fromTimePlanned || ""}
                                onChange={(e) =>
                                  handleInputChange(e, "fromTimePlanned")
                                }
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                label="To"
                                ampm={false}
                                value={action.toTimePlanned || ""}
                                onChange={(e) =>
                                  handleInputChange(e, "toTimePlanned")
                                }
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  )}
                </Grid>
              </Grid>

              <DialogActions>
                <Button
                  onClick={handleCancel}
                  color="inherit"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCreateAndClose}
                  color="primary"
                  variant="contained"
                  disabled={submitBtnDisabled}
                >
                  Create Action
                </Button>
              </DialogActions>
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
      <Snackbar
        open={errorExists}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseErrorAlert}
      >
        <Alert onClose={handleCloseErrorAlert} severity="error">
          Error Creating Mobility Strategy for VCC Incident:{" "}
          {ShortIdUtil(props.incidentId)}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddActionModal;
