import { AnyAction } from "redux";
import { types } from "../../actions";
import { IMapState } from "../interfaces";
import { LayerGeoData, MapAnnotationData, MarkerGeoData, Viewport } from "../states/map";

export const defaultView: Viewport = {
  latitude: 47.6062,
  longitude: -122.3321,
  zoom: 10,
};


export const initialMapState: IMapState = {
    incidentGeo: [] as MarkerGeoData[],
    currentIncidentGeo: [] as MarkerGeoData[],
    isEditIncLocation: false,
    dispatchGeo: [] as MarkerGeoData[],
    associatedDispatchGeo: [] as MarkerGeoData[],
    TravelerInfoLayer: {} as LayerGeoData,
    selectedEventIds: {},
    viewport: defaultView,
    mapAnnotations: [] as MapAnnotationData[],
    successMessage: "",
    failureMessage: "",
};

function mapReducer( state = initialMapState, action: AnyAction ): IMapState {
  switch (action.type) {
    case types.UPDATE_MAP_VIEWPORT:
      return {
        ...state,
        viewport: action.payload.viewport,
        successMessage: "",
        failureMessage: "",
      };
    case types.SET_DISPATCH_GEO_SUCCESS:
      return {
        ...state,
        dispatchGeo: action.payload.dispatchGeo,
        successMessage: "",
        failureMessage: "",
      };
    case types.SET_DISPATCH_GEO_FAILURE:
      return {
        ...state,
        dispatchGeo: [] as MarkerGeoData[],
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.SET_ASSOCIATED_DISPATCH_GEO_SUCCESS:
      return {
        ...state,
        associatedDispatchGeo: action.payload.dispatchGeo,
        successMessage: "",
        failureMessage: "",
      };
    case types.SET_ASSOCIATED_DISPATCH_GEO_FAILURE:
      return {
        ...state,
        associatedDispatchGeo: [] as MarkerGeoData[],
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.SET_SELECTED_DISPATCH_IDS_SUCCESS:
      return {
        ...state,
        selectedEventIds: action.payload.selectedEventIds,
        successMessage: "",
        failureMessage: "",
      };
    case types.SET_SELECTED_DISPATCH_IDS_FAILURE:
      return {
        ...state,
        selectedEventIds: {},
        failureMessage: action.payload.failureMessage,
      };
    case types.SET_INCIDENT_GEO_SUCCESS:
      return {
        ...state,
        incidentGeo: action.payload.incidentGeo,
        successMessage: "",
        failureMessage: "",
      };
    case types.SET_INCIDENT_GEO_FAILURE:
      return {
        ...state,
        incidentGeo: [] as MarkerGeoData[],
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.SET_CURRENT_INCIDENT_GEO_SUCCESS:
      return {
        ...state,
        currentIncidentGeo: action.payload.incidentGeo,
        successMessage: "",
        failureMessage: "",
      };
    case types.SET_CURRENT_INCIDENT_GEO_FAILURE:
      return {
        ...state,
        incidentGeo: [] as MarkerGeoData[],
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.CREATE_MAP_ANNOTATION_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.CREATE_MAP_ANNOTATION_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.UPDATE_MAP_ANNOTATION_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.UPDATE_MAP_ANNOTATION_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.DELETE_MAP_ANNOTATION_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.successMessage,
        failureMessage: "",
      };
    case types.DELETE_MAP_ANNOTATION_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
    case types.GET_MAP_ANNOTATIONS_SUCCESS:
      return {
        ...state,
        mapAnnotations: action.payload.geojson,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_MAP_ANNOTATIONS_FAILURE:
      return {
        ...state,
        successMessage: "",
        failureMessage: action.payload.failureMessage,
      };
      case types.GET_TRAVELER_INFORMATION_INCIDENT_LAYER_SUCCESS:
        return {
          ...state,
          TravelerInfoLayer: action.payload.TravelerInfoLayer,
          successMessage: "",
          failureMessage: "",
        };
      case types.GET_TRAVELER_INFORMATION_INCIDENT_LAYER_FAILURE:
        return {
          ...state,
          successMessage: "",
          failureMessage: action.payload.failureMessage,
        };
    case types.SET_MAP_EDIT_INC_LOC:
      return {
        ...state,
        isEditIncLocation: action.payload.isEdit,
        successMessage: "",
        failureMessage: "",
      };
      case types.GET_AGENCY_DEFAULT_VIEW_SUCCESS:
        return {
          ...state,
          viewport: action.payload.viewport as Viewport,
          successMessage: "",
          failureMessage: "",
        };
    default:
      return state;
  }
}

export default mapReducer;
