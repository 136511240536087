import { types } from "../types";

export const getTrafficCamLayerRequest = () => ({
  type: types.GET_TRAFFIC_CAM_GEO_JSON,
  payload: {},
});

export const getGeoJsonSuccess = (
  geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry>
) => ({
  type: types.GET_TRAFFIC_CAM_GEO_JSON_SUCCESS,
  payload: { geoJson },
});

export const getGeoJsonFailure = (getGeoJsonFailureMessage: string) => ({
  type: types.GET_TRAFFIC_CAM_GEO_JSON_FAILURE,
  payload: { getGeoJsonFailureMessage },
});
