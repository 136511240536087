
import { DispatchGridRow } from "../../components/integrated-dispatch-feed/dispatch-event-feed-grid";
import { DispatchEventFeedModel } from "../../reducers";
import { defaultView } from "../../reducers/map";
import { LayerGeoData, MapAnnotationData, MarkerGeoData, Viewport } from "../../reducers/states/map";
import { IncidentDispatch, VccIncidentModel } from "../../reducers/states/vcc-incident";
import { types } from "../types";

// Update Zoom and position of map
export const updateViewportRequest = (latitude?: number, longitude?: number, zoom?: number) => ({
  type: types.UPDATE_MAP_VIEWPORT,
  payload: { viewport: {
    latitude: latitude ? latitude : defaultView.latitude,
    longitude: longitude ? longitude : defaultView.longitude,
    zoom: (longitude && latitude ) ? zoom :  defaultView.zoom,
  } as Viewport },
});

// Set Dispatch Geo Data via Api Result
export interface setDispatchAPIGeoPayload {
  dispatchEvents: DispatchEventFeedModel[];
}
export const setDispatchAPIGeoRequest = (dispatchEvents: DispatchEventFeedModel[]) => ({
  type: types.SET_DISPATCH_GEO,
  payload: { dispatchEvents },
});
export const setDispatchAPIGeoSuccess = (dispatchGeo: MarkerGeoData[]) => ({
  type: types.SET_DISPATCH_GEO_SUCCESS,
  payload: { dispatchGeo },
});
export const setDispatchAPIGeoFailure = (failureMessage: string) => ({
  type: types.SET_DISPATCH_GEO_FAILURE,
  payload: { failureMessage },
});

// Set Dispatch Geo Data - success and failure share GEO_SUCCESS/FAILURE from above
export interface setDispatchDataGridGeoPayload {
  dispatchEvents: DispatchGridRow[];
}
export const setDispatchDataGridGeoRequest = (dispatchEvents: DispatchGridRow[]) => ({
  type: types.SET_DISPATCH_GEO_VIA_GRID,
  payload: { dispatchEvents },
});

// Set Associated Dispatch Geo Data
export interface setAssociatedDispatchGeoPayload {
  dispatchEvents: IncidentDispatch[];
}
export const setAssociatedDispatchGeoRequest = (dispatchEvents: IncidentDispatch[]) => ({
  type: types.SET_ASSOCIATED_DISPATCH_GEO,
  payload: { dispatchEvents },
});
export const setAssociatedDispatchGeoSuccess = (dispatchGeo: MarkerGeoData[]) => ({
  type: types.SET_ASSOCIATED_DISPATCH_GEO_SUCCESS,
  payload: { dispatchGeo },
});
export const setAssociatedDispatchGeoFailure = (failureMessage: string) => ({
  type: types.SET_ASSOCIATED_DISPATCH_GEO_FAILURE,
  payload: { failureMessage },
});

// Set Selected Dispatch Event Ids for the map
export interface setSelectedEventIdsPayload {
  selectedEventIds: { [key: string]: string };
}
export const setSelectedEventIdsRequest = (selectedEventIds: {
  [key: string]: string;
}) => ({
  type: types.SET_SELECTED_DISPATCH_IDS,
  payload: { selectedEventIds } as setSelectedEventIdsPayload,
});
export const setSelectedEventIdsSuccess = (selectedEventIds: {
  [key: string]: string;
}) => ({
  type: types.SET_SELECTED_DISPATCH_IDS_SUCCESS,
  payload: { selectedEventIds } as setSelectedEventIdsPayload,
});
export const setSelectedEventIdsFailure =  (failureMessage: string) => ({
  type: types.SET_SELECTED_DISPATCH_IDS_FAILURE,
  payload: { failureMessage },
});

// Set Incident Geo Data
export interface setIncidentGeoPayload {
  incidents: VccIncidentModel[];
}
export const setIncidentGeoRequest = (incidents: VccIncidentModel[]) => ({
  type: types.SET_INCIDENT_GEO,
  payload: { incidents },
});
export const setIncidentGeoSuccess = (incidentGeo: MarkerGeoData[]) => ({
  type: types.SET_INCIDENT_GEO_SUCCESS,
  payload: { incidentGeo },
});
export const setIncidentGeoFailure = (failureMessage: string) => ({
  type: types.SET_INCIDENT_GEO_FAILURE,
  payload: { failureMessage },
});

// Set Current Incident Geo Data
export interface setCurrentIncidentGeoPayload {
  incident: VccIncidentModel;
}
export const setCurrentIncidentGeoRequest = (incident: VccIncidentModel) => ({
  type: types.SET_CURRENT_INCIDENT_GEO,
  payload: { incident },
});
export const setCurrentIncidentGeoSuccess = (incidentGeo: MarkerGeoData[]) => ({
  type: types.SET_CURRENT_INCIDENT_GEO_SUCCESS,
  payload: { incidentGeo },
});
export const setCurrentIncidentGeoFailure = (failureMessage: string) => ({
  type: types.SET_CURRENT_INCIDENT_GEO_FAILURE,
  payload: { failureMessage },
});

// Create Map Annotation:
export interface createMapAnnotationPayload {
  incId: string;
  geoId: string;
  geojsonString: string;
  note: string;
  primaryLocation: string;
  perimeterRadius: string;
}
export const createMapAnnotationRequest = (incId: string, geoId: string, geojsonString: string, note: string, primaryLocation: string, perimeterRadius: string) => ({
  type: types.CREATE_MAP_ANNOTATION,
  payload: { incId, geoId, geojsonString, note, primaryLocation, perimeterRadius } as createMapAnnotationPayload,
});
export const createMapAnnotationSuccess = (successMessage: string) => ({
  type: types.CREATE_MAP_ANNOTATION_SUCCESS,
  payload: { successMessage },
});
export const createMapAnnotationFailure = (failureMessage: string) => ({
  type: types.CREATE_MAP_ANNOTATION_FAILURE,
  payload: { failureMessage },
});

// Update Map Annotation:
export interface updateMapAnnotationPayload {
  incId: string;
  geoId: string;
  geojsonString: string;
  note: string;
  primaryLocation: string;
  perimeterRadius: string;
}
export const updateMapAnnotationRequest = (incId: string, geoId: string, geojsonString: string, note: string,  primaryLocation: string, perimeterRadius: string) => ({
  type: types.UPDATE_MAP_ANNOTATION,
  payload: { incId, geoId, geojsonString, note ,  primaryLocation, perimeterRadius} as updateMapAnnotationPayload,
});
export const updateMapAnnotationSuccess = (successMessage: string) => ({
  type: types.UPDATE_MAP_ANNOTATION_SUCCESS,
  payload: { successMessage },
});
export const updateMapAnnotationFailure = (failureMessage: string) => ({
  type: types.UPDATE_MAP_ANNOTATION_FAILURE,
  payload: { failureMessage },
});

// Delete Map Annotation:
export interface deleteMapAnnotationPayload {
  incId: string;
  geoId: string;
}
export const deleteMapAnnotationRequest = (incId: string, geoId: string) => ({
  type: types.DELETE_MAP_ANNOTATION,
  payload: { incId, geoId } as deleteMapAnnotationPayload,
});
export const deleteMapAnnotationSuccess = (successMessage: string) => ({
  type: types.DELETE_MAP_ANNOTATION_SUCCESS,
  payload: { successMessage },
});
export const deleteMapAnnotationFailure = (failureMessage: string) => ({
  type: types.DELETE_MAP_ANNOTATION_FAILURE,
  payload: { failureMessage },
});

// Get Map Annotations
export interface getMapAnnotationPayload {
  incId: string,
}
export const getMapAnnotationsRequest = (incId: string) => ({
  type: types.GET_MAP_ANNOTATIONS,
  payload: { incId } as getMapAnnotationPayload,
});
export const getMapAnnotationSuccess = (geojson: MapAnnotationData[]) => ({
  type: types.GET_MAP_ANNOTATIONS_SUCCESS,
  payload: { geojson },
});
export const getMapAnnotationsFailure = (failureMessage: string) => ({
  type: types.GET_MAP_ANNOTATIONS_FAILURE,
  payload: { failureMessage },
});

// Set Incident Location On Map Edit Mode
export const setMapEditIncLocationRequest = ( isEdit: boolean) => ({
  type: types.SET_MAP_EDIT_INC_LOC,
  payload: { isEdit },
});


// Get Traveler Information API Highway Alert Data
export const getTravelerInformationRequest = () => ({
  type: types.GET_TRAVELER_INFORMATION_INCIDENT_LAYER,
  payload: {},
});
export const getTravelerInformationSuccess = (TravelerInfoLayer: LayerGeoData) => ({
  type: types.GET_TRAVELER_INFORMATION_INCIDENT_LAYER_SUCCESS,
  payload: { TravelerInfoLayer },
});
export const getTravelerInformationFailure = (failureMessage: string) => ({
  type: types.GET_TRAVELER_INFORMATION_INCIDENT_LAYER_FAILURE,
  payload: { failureMessage },
});





// Get Agency default view for the map on load
export const getAgencyDefaultViewRequest = (Agency: string) => ({
  type: types.GET_AGENCY_DEFAULT_VIEW,
  payload: {Agency},
});
export const getAgencyDefaultViewSuccess = (viewport: Viewport) => ({
  type: types.GET_AGENCY_DEFAULT_VIEW_SUCCESS,
  payload: { viewport },
});
export const getAgencyDefaultViewFailure = (failureMessage: string) => ({
  type: types.GET_AGENCY_DEFAULT_VIEW_FAILURE,
  payload: { failureMessage },
});





// Update Incident Lat/Long from Map
export interface setIncLatLongFromMapPayload {
  latitude: number,
  longitude: number,
}
export const setIncLatLongFromMapRequest = ( latitude: number, longitude: number) => ({
  type: types.SET_INC_LOC_LAT_LONG_FROM_MAP,
  payload: { latitude, longitude } as setIncLatLongFromMapPayload,
});
export const setIncLatLongFromMapSuccess = ( latitude: number, longitude: number) => ({
  type: types.SET_INC_LOC_LAT_LONG_FROM_MAP_SUCCESS,
  payload: { latitude, longitude } as setIncLatLongFromMapPayload,
});
export const setIncLatLongFromMapFailure = ( failureMessage: string ) => ({
  type: types.SET_INC_LOC_LAT_LONG_FROM_MAP_FAILURE,
  payload: { failureMessage },
});

// Update Incident Address from Map
export const setIncAddressFromMapRequest = ( address: string ) => ({
  type: types.SET_INC_ADDRESS_FROM_MAP,
  payload: { address },
});