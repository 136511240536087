import { Box, TextField, Tooltip, Autocomplete } from "@mui/material";
import React, { FC } from "react";
import { AllUserInfoModel } from "../../../reducers/states/all-user-info";

interface ViewEditUserAutocompleteProps {
  props: {
    id: string;
    correlatedId: string;
    label: string;
    tooltip?: string;
    initialValue: string;
    isEdit: boolean;
    characterLimit?: number;
    enableUserAutocomplete?: boolean;
    userOptions: AllUserInfoModel[];
    handleInputChange: (newValue: string, field: string) => void;
  };
}

const ViewEditUserAutocomplete: FC<ViewEditUserAutocompleteProps> = ({ props }) => {
  const [fieldCharCount, setFieldCharCount] = React.useState(
    props.initialValue.length ?? 0
  );
  const characterLimit = props.characterLimit ?? 240;

  return (
    <>
      <Box m={1} style={{ maxHeight: "36px" }}>
        <Tooltip title={props.tooltip ?? ""}>
          <Autocomplete
              disablePortal
              readOnly={!props.isEdit}
              freeSolo={true}
              isOptionEqualToValue={(options, value) => (typeof value === "object" && typeof options === "object") ? options.label === value.label : options === value}
              value={props.initialValue ?? {} as AllUserInfoModel}
              onInputChange={(event: any, value) => {
                  // Take the text while input is being added in case a user 
                  // needs to manually add someone not in the dropdown list
                  props.handleInputChange(value, props.id);
                  if(value){
                    setFieldCharCount(value.length);
                  }
                  else{
                    setFieldCharCount(0);
                  }
                }
              }
              onChange={(_: any, value: AllUserInfoModel | string | null) => {
                if (value !== null && typeof value === "string") {
                  // If a user is found from existing users, use this information from the AllUserInfoModel
                  if(value && value !== "") {
                    props.handleInputChange(value, props.id);
                  }
                  
                  // If a correlated Id is found (as in the agency for the user), also update that field as well
                  if(props.correlatedId){
                    props.handleInputChange(value, props.correlatedId);
                  }
                }else if (value?.label) {
                  // If a user is found from existing users, use this information from the AllUserInfoModel
                  if(value?.label && value.label !== null && value.label !== ""){
                    props.handleInputChange(value.label, props.id);
                  }
                  
                  // If a correlated Id is found (as in the agency for the user), also update that field as well
                  if(props.correlatedId){
                    props.handleInputChange(value?.agency!, props.correlatedId);
                  }
                }
              }}
              
              id={props.id}
              options={props.userOptions}
              renderInput={(params) => <TextField
                {...params}
                id={props.id}
                label={
                  props.label +
                  (props.isEdit ? ` ${fieldCharCount}/${characterLimit}` : "")
                }
                size="small"
                margin={"dense"}
                variant={props.isEdit ? "outlined" : "standard"}
                type="text"
                fullWidth
                InputLabelProps={{
                  shrink: props.isEdit || (!!props.initialValue),
                }}
              />
              }
          />
        </Tooltip>
      </Box>
    </>
  );
};

export default ViewEditUserAutocomplete;
