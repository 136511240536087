import { AllUserInfoModel } from "../../reducers/states/all-user-info";
import { types } from "../types";

export const getAllUserInfoRequest = () => ({
  type: types.GET_ALL_USER_INFO,
  payload: { },
});

export const getAllUserInfoSuccess = (allUserInfo: AllUserInfoModel[]) => ({
  type: types.GET_ALL_USER_INFO_SUCCESS,
  payload: { allUserInfo: allUserInfo },
});

export const getAllUserInfoFailure = (getAllUserInfoFailureMessage: string) => ({
  type: types.GET_ALL_USER_INFO_FAILURE,
  payload: { getAllUserInfoFailureMessage },
});

