import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, FC } from "react";
import { customTheme } from "../../../..";
import { Agency } from "../../../../enums/agency";

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    paddingRight: "0px",
    marginRight: "0px",
  },
  notAvailable: {
    color: theme.palette.error.main,
  },
}));

interface MobilityStrategyFilterProps {
  props: {
    agency: string;
    agencyFilters: string[];
    handleSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
}

const MobilityStrategyAgencyFilter: FC<MobilityStrategyFilterProps> = ({ props }) => {
  const classes = useStyles();

  const getChipColor = (agency: string) => {
    switch (agency) {
      case Agency.WSP: {
        return customTheme.agency.WSP;
      }
      case Agency.KCM: {
        return customTheme.agency.KCM;
      }
      case Agency.SFD: {
        return customTheme.agency.SFD;
      }
      case Agency.SPD: {
        return customTheme.agency.SPD;
      }
      case Agency.Port: {
        return customTheme.agency.PORT;
      }
      case Agency.NWSA: {
        return customTheme.agency.NWSA;
      }
      case Agency.SDOT: {
        return customTheme.agency.SDOT;
      }
      case Agency.WSDOT: {
        return customTheme.agency.WSDOT;
      }
      case Agency.ST: {
        return customTheme.agency.ST;
      }
      case "Dark": {
        return customTheme.agency.Dark;
      }
      default: {
        return customTheme.agency.Neutral;
      }
    }
  }

  return (
    <>
      <Box ml={1}>
        <FormControlLabel
          className={classes.filter}
          control={<Checkbox
            name={props.agency}
            checked={props.agencyFilters.includes(props.agency)}
            onChange={props.handleSwitchChange}
            checkedIcon={<Chip style={{ backgroundColor: getChipColor(props.agency), color: 'white' }} variant="filled" label={props.agency} />}
            icon={<Chip
              variant="outlined"
              label={props.agency} />} />} label={""}        />
      </Box>
    </>
  );
};

export default MobilityStrategyAgencyFilter;
