import  { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { NavBar } from "./components";
import { default as Routes } from "./Routes";
import { Auth } from "aws-amplify";
import { AuthContext, AuthState } from "./core/auth-context";
import ErrorBoundary from "./utils/error-boundary";
import WebSocketProvider from "./websocket";
import { useNavigate } from "react-router-dom";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { PageIdFormatEnum } from "aws-rum-web/dist/cjs/orchestration/Orchestration";
import { RumConfig } from "./core";

export let awsRum: AwsRum;

function App() {
  const navigate = useNavigate();

  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    userGroups: [""],
    cognitoUsername: "",
    email: "",
  });
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const getInteractionId = (event: Event): string => {
    const eventPath = event.composedPath() as Element[];
    for (const element of eventPath) {
      if (element.hasAttribute && element.hasAttribute("data-rum-id")) {
        return element.getAttribute("data-rum-id") as string;
      }
    }
    return "";
  };

  useEffect(() => {
    async function onLoad(): Promise<void> {
      try {
        const userSession = await Auth.currentSession();
        if (userSession.isValid()) {
          const userGroups = userSession.getAccessToken().decodePayload()[
            "cognito:groups"
          ] ?? [""];
          const email = userSession.getIdToken().payload["custom:sawEmail"];
          const agency = userSession.getIdToken().payload["custom:agency"];

          setAuthState({
            isAuthenticated: true,
            userGroups: userGroups,
            cognitoUsername: userSession.getAccessToken().payload["username"],
            email: email,
          });

          try {
            const config: AwsRumConfig = {
              allowCookies: true,
              endpoint: RumConfig.RUM_ENDPOINT,
              guestRoleArn: RumConfig.RUM_ROLE_ARN,
              identityPoolId: RumConfig.RUM_IDENTITY_POOL,
              sessionEventLimit: 0, // Set to 0 for unlimited recording. Set to > 0 to limit only that many events (including X-Ray) recorded per session.
              sessionSampleRate: 1,
              sessionAttributes: {
                userRoles: userGroups.join(","),
                agency: agency,
              },
              telemetries: [
                "errors",
                "performance",
                "http",
                [
                  "interaction",
                  {
                    enableMutationObserver: true,
                    events: [{ event: "click", cssLocator: '[data-rum-id]' }],
                    interactionId: getInteractionId,
                  },
                ]
              ],
              enableXRay: true,
              pageIdFormat: PageIdFormatEnum.PathAndHash,
              disableAutoPageView: true,
            };

            const APPLICATION_ID: string = RumConfig.RUM_ID!;
            const APPLICATION_VERSION: string = RumConfig.RUM_VERSION;
            const APPLICATION_REGION: string = RumConfig.RUM_REGION!;

            awsRum = new AwsRum(
              APPLICATION_ID,
              APPLICATION_VERSION,
              APPLICATION_REGION,
              config
            );
          } catch (error) {
            // Ignore errors thrown during CloudWatch RUM web client initialization
            console.log("RUM Config failed: ", error);
          }
        } else {
          setAuthState({
            isAuthenticated: false,
            userGroups: [""],
            cognitoUsername: "",
            email: "",
          });
          navigate("/login");
        }
      } catch (e) {
        if (e !== "No current user") {
          setAuthState({
            isAuthenticated: false,
            userGroups: [""],
            cognitoUsername: "",
            email: "",
          });
          navigate("/login");
        }
      }
      setIsAuthenticating(false);
    }

    onLoad();
  }, [navigate]);

  if (isAuthenticating) {
    return <NavBar />;
  }
  return (
    <>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <WebSocketProvider>
          <NavBar />
          <Box id="main-app-box" m={1}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Box>
        </WebSocketProvider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
