import {
  Alert,
  Box,
  Button,
  Grid,
  LinearProgress,
  Snackbar,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  IncidentDispatch,
  UpdateIncidentDispatchRequest,
  VccIncidentModel,
} from "../../../reducers/states/vcc-incident";
import {
  setCurrentIncidentGeoRequest, setMapEditIncLocationRequest, updateViewportRequest,
} from "../../../actions";
import { UTCNowUtil } from "../../../utils/date-utils";
import { IncidentStatus } from "../../../enums/incident-status";
import UpdateFailureModal from "../update-failure-modal";
import IncidentForm from "./incident-form";
import { WebSocketContext } from "../../../websocket";
import useUserPermissions from "../../../hooks/user-permissions";
import { RoleAction } from "../../../enums/permission-actions";
import { useDispatch } from "react-redux";
import ConfirmCaptureModal from "../../common/confim-capture-modal";
import ConfirmationModal from "../../common/confirmation-modal";

const useStyles = makeStyles((theme: Theme) => ({
  vccField: {
    fontWeight: 600,
  },
  vccItalic: {
    fontStyle: "italic",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

interface VccIncidentDetailProps {
  props: {
    vccIncident: VccIncidentModel;
    currentUserEmail: string;
    isIncUpdateAvailable: boolean;
    showOverwriteError: boolean;
    handleIsEdit: (edit: boolean) => void;
    handleSaveIncident: (incident: VccIncidentModel, associatedIdRequests: UpdateIncidentDispatchRequest[]) => void;
    handleGetLatestIncInfo: () => void;
  };
}

const VccIncidentDetail: FC<VccIncidentDetailProps> = ({ props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [incident, setIncident] = useState({} as VccIncidentModel);
  const [associatedIdRequests, setAssociatedIdRequests] = useState([] as UpdateIncidentDispatchRequest[]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingAndEmphasis, setIsLoadingAndEmphasis] = useState(false);
  const [isSavingAllowed, setIsSavingAllowed] = useState(true);
  const [deleteIncDisabled] = useUserPermissions(RoleAction.DELETE_INCIDENT);
  const [isIncUpdateFailureModelOpen, setIsIncUpdateFailureModalOpen] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [displayConfirmCapture, setDisplayConfirmCapture] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState({
    title: "",
    message: "",
    choices: {} as Object,
  });
  const [confirmAction, setConfirmAction] = useState(
    () => (confirmReason?: string) => handleCloseConfirm
  );
  const [cancelAction, setCancelAction] = useState(
    () => () => handleCancelIncident
  );

  // TODO: Need to update/regression test websocket with new SAWId
  const { setWebSocketIncId } = React.useContext(WebSocketContext);

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsLoadingAndEmphasis(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  const updateIsEdit = useCallback((edit: boolean) => {
    setIsEdit(edit);
    props.handleIsEdit(edit);

    // Ensure the map incident location control is closed if no longer editing
    if(!edit){
      dispatch(setMapEditIncLocationRequest(edit));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show loading animation if new data is loaded
  useEffect(() => {
    setIsLoadingAndEmphasis(true);
    showLoadingAnimation();
    updateIsEdit(false);
  }, [props.vccIncident.updatedDate, showLoadingAnimation, updateIsEdit]);

  // Update webSocket Id if the incident id change
  useEffect(() => {
    setWebSocketIncId(incident.id);
  }, [incident.id, setWebSocketIncId]);

  useEffect(() => {
    if (props.isIncUpdateAvailable && !isEdit && !isIncUpdateFailureModelOpen) {
      props.handleGetLatestIncInfo();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.isIncUpdateAvailable,
    isEdit,
    isIncUpdateFailureModelOpen,
    props.handleGetLatestIncInfo,
  ]);

  useEffect(() => {
    if (props.showOverwriteError) {
      setIsIncUpdateFailureModalOpen(true);
    }
  }, [props.showOverwriteError]);

  useEffect(() => {
    setIncident(() => ({ ...props.vccIncident }));
  }, [props.vccIncident]);

  function getUpdatedIncident(closeReason: string, shouldDelete: boolean): VccIncidentModel {
    let updatedVccIncident: VccIncidentModel = incident;
    let regex = /[^a-zA-Z0-9/!%"'‘’“”~@#$^*()_+=[\]{}|\\,.? :-]/g;
    updatedVccIncident.incidentDetails = updatedVccIncident.incidentDetails?.replace(regex, '');
    updatedVccIncident.responseDetails = updatedVccIncident.responseDetails?.replace(regex, '');
    updatedVccIncident.laneCount = updatedVccIncident.laneCount?.replace(regex, '');
    updatedVccIncident.laneDirectionAndBlockage = updatedVccIncident.laneDirectionAndBlockage?.replace(regex, '');
    updatedVccIncident.laneRoadwayDescription = updatedVccIncident.laneRoadwayDescription?.replace(regex, '');
    updatedVccIncident.laneOtherInformation = updatedVccIncident.laneOtherInformation?.replace(regex, '');

    if (shouldDelete) {
      updatedVccIncident.status = IncidentStatus.DELETED;
      updatedVccIncident.deletedComment = closeReason.replace(regex, '');
    }
    else{
      updatedVccIncident.closedComment = closeReason.replace(regex, '');
    }
    if (updatedVccIncident.estClearTime !== props.vccIncident.estClearTime) {
      updatedVccIncident.estClearUpdatedBy = props.currentUserEmail;
      updatedVccIncident.estClearUpdatedDate = UTCNowUtil();
    }
    
    updatedVccIncident.updatedBy = props.currentUserEmail;
    updatedVccIncident.updatedDate = UTCNowUtil();
    updatedVccIncident.mostRecentUpdatedDate = props.vccIncident.updatedDate;
    return updatedVccIncident;
  }

  // Form Editing
  const handleEdit = () => {
    if (
      incident.status === IncidentStatus.CLOSED ||
      incident.status === IncidentStatus.DELETED
    ) {
      setDisplayConfirmation(true);
      setConfirmationMessage({
        title: "Re-Open VCC Incident",
        message:
          "VCC Incidents must be re-opened to edit. Are you sure you want to re-open this VCC Incident?",
        choices: {},
      });
      setCancelAction(() => () => handleReOpenCancel());
      setConfirmAction(() => () => handleReOpenConfirm());
    }
    updateIsEdit(!isEdit);
  };

  const handleInputChange = (e: any, field: string) => {
    let value: string = e as string;
    return setIncident((current) => ({ ...current, [field]: value }));
  };

  const handleUpdateAssociatedDispatches = (dispatches: IncidentDispatch[], associatedEventRequestIds: UpdateIncidentDispatchRequest[]) => {
    setIncident((current) => ({
      ...current,
      dispatchEvents: dispatches,
    }));

    setAssociatedIdRequests(associatedEventRequestIds);
  };

  const handleGeocoderSelect = (latitude?: number, longitude?:  number, placeName?: string) => {
    // Update the incident for the next save
    setIncident((current) => ({ ...current, "latitude": latitude, "longitude": longitude, "location": placeName ?? "" }));
    
    // Show the updated location on the map and zoom out to see new location
    dispatch(setCurrentIncidentGeoRequest({...incident, "latitude": latitude, "longitude": longitude }));
    dispatch(updateViewportRequest());
  }

  const handleCancelIncident = () => {
    updateIsEdit(false);
    setIsIncUpdateFailureModalOpen(false);
    setIsSavingAllowed(true);
    setAssociatedIdRequests([]);
    props.handleGetLatestIncInfo();
  };

  const handleSaveIncident = () => {
    if (props.isIncUpdateAvailable || !isSavingAllowed) {
      setIsIncUpdateFailureModalOpen(true);
    } else {
      if (incident.status === IncidentStatus.CLOSED) {
        updateIsEdit(false);
        setDisplayConfirmCapture(true);
        setConfirmationMessage({
          title: "Close VCC Incident",
          message: "Please enter a reason to indicate why this VCC Incident is being closed",
          choices: {
            NORMAL: "Normal congestion patterns and regional activity",
            CLEARED: "Roadway cleared",
            TRANSFERRED: "Incident management transferred to EOC",
            OTHER: "Other",
          },
        });
        setCancelAction(() => () => handleCloseCancel());
        setConfirmAction(() => (confirmReason: string) => handleCloseConfirm(confirmReason));
      } else {
        updateIsEdit(false);
        props.handleSaveIncident(getUpdatedIncident("", false), associatedIdRequests);
        setAssociatedIdRequests([]);
      }
    }
  };

  // Closed and deleted workflows
  const handleDeleteButton = () => {
    updateIsEdit(false);
    setDisplayConfirmCapture(true);
    setConfirmationMessage({
      title: "Delete VCC Incident",
      message: "Please enter a reason to indicate why this VCC Incident is being deleted",
      choices: {
        CREATED_IN_ERROR: "Incident created in error",
        DUPLICATE: "Incident superseded by another Incident Model",
        OTHER: "Other",
      },
    });
    setCancelAction(() => () => handleDeleteCancel());
    setConfirmAction(() => (deletedReason: string) => handleDeleteConfirm(deletedReason));
  };

  const handleCloseCancel = () => {
    setDisplayConfirmCapture(false);
    updateIsEdit(true);
  };

  const handleCloseConfirm = (closeReason: string) => {
    setDisplayConfirmCapture(false);
    updateIsEdit(false);
    setWebSocketIncId(incident.id);
    props.handleSaveIncident(getUpdatedIncident(closeReason, false), associatedIdRequests);
    setAssociatedIdRequests([]);
  };

  const handleReOpenCancel = () => {
    updateIsEdit(false);
    setDisplayConfirmation(false);
  };

  const handleReOpenConfirm = () => {
    updateIsEdit(true);
    setDisplayConfirmation(false);
    setIncident((current) => ({ ...current, status: IncidentStatus.OPEN }));
  };

  const handleDeleteCancel = () => {
    setDisplayConfirmCapture(false);
    setIncident((current) => ({ ...current }));
    updateIsEdit(true);
  };

  const handleDeleteConfirm = (deletedReason: string) => {
    setDisplayConfirmCapture(false);
    updateIsEdit(false);
    props.handleSaveIncident(getUpdatedIncident(deletedReason, true), associatedIdRequests);
    setAssociatedIdRequests([]);
  };

  // Overwrite message workflows
  const handleCloseIsUpdatedAlert = () => {
    setIsEdit(false);
    setIsIncUpdateFailureModalOpen(false);
    setIsSavingAllowed(true);
    props.handleGetLatestIncInfo();
  };

  const handleDiscardChangesAndRefresh = () => {
    setIsIncUpdateFailureModalOpen(false);
    setIsSavingAllowed(true);
    props.handleGetLatestIncInfo();
  };

  const handleCloseAndContinueEdit = () => {
    setIsSavingAllowed(false);
    setIsIncUpdateFailureModalOpen(false);
    setIsEdit(true);
  };

  return (
    <>
      <Box m={1}>{isLoadingAndEmphasis && <LinearProgress />}</Box>
      <Box ml={1} mr={1} height="100%">
        <Grid container style={{ height: "100%" }}>
          <IncidentForm
            props={{
              incident: incident,
              associatedIdRequests: associatedIdRequests,
              isEdit: isEdit,
              showEmphasizedText: isLoadingAndEmphasis,
              handleInputChange: handleInputChange,
              handleEdit: handleEdit,
              handleUpdateAssociatedDispatches: handleUpdateAssociatedDispatches,
              handleGeocoderSelect: handleGeocoderSelect,
              handleCancelInc: handleCancelIncident,
              handleSaveInc: handleSaveIncident,
            }}
          />
        </Grid>
      </Box>
      <Box className={classes.fab}>
        <Button
          variant="contained"
          onClick={handleDeleteButton}
          disabled={!isEdit || deleteIncDisabled}
          color="inherit"
          size="small"
        >
          Delete VCC Incident
        </Button>
      </Box>
      <ConfirmationModal 
        props={{
          isOpen: displayConfirmation,
          title: confirmationMessage.title,
          message: confirmationMessage.message,
          cancelText: "Cancel",
          confirmText: "Confirm",
          handleCancel: cancelAction,
          handleConfirm: confirmAction,
        }} 
      />
      <ConfirmCaptureModal 
        props={{
          isOpen: displayConfirmCapture,
          title: confirmationMessage.title,
          message: confirmationMessage.message,
          choices: confirmationMessage.choices,
          cancelText: "Cancel",
          confirmText: "Confirm",
          handleCancel: cancelAction,
          handleConfirm: confirmAction,
        }} 
      />
      <UpdateFailureModal
        props={{
          isOpen: isIncUpdateFailureModelOpen,
          recordTypeContent: "VCC Incident",
          handleCloseAndDiscard: handleDiscardChangesAndRefresh,
          handleCloseAndEdit: handleCloseAndContinueEdit,
        }}
      />
      <Snackbar
        open={(props.isIncUpdateAvailable && isEdit) || !isSavingAllowed}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={handleCloseIsUpdatedAlert}
            >
              Refresh
            </Button>
          }
        >
          Updated just now - Refresh is required before saving updates
        </Alert>
      </Snackbar>
    </>
  );
};

export default VccIncidentDetail;
