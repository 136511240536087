import { Box, Grid } from "@mui/material";
import React, { FC } from "react";
import { UserInfoModel } from "../../../../reducers/states/user-info";

interface UserInfoProps {
  props: {
    userInfo: UserInfoModel;
  };
}

const UserInfo: FC<UserInfoProps> = ({ props }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box m={0.5}>
            Name:{" "}
            {(props.userInfo.firstName ? props.userInfo.firstName : "") +
              " " +
              (props.userInfo.lastName ? props.userInfo.lastName : "")}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={0.5}>Agency: {props.userInfo.agency}</Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={0.5}>Phone: {props.userInfo.phoneNumber}</Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={0.5}>Email: {props.userInfo.email}</Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UserInfo;
