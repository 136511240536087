import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOpenIncidentsRequest } from "../../../actions";
import { DispatchEventFeedModel, IRootReducer } from "../../../reducers";
import { ShortIdUtil } from "../../../utils/id-utils";
import IncidentCard from "./incident-card";

interface DashboardIncidentsProps {
  props: {
    selectedIncId?: string;
    dispatchAddtlInfo: DispatchEventFeedModel;
  };
}

const DashboardIncidents: FC<DashboardIncidentsProps> = ({ props }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dashCardBoxHeight, setDashCardBoxHeight] =
    useState("calc(100% - 32px");
  const gridHeaderRef = useRef<any>(null);

  const openIncidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.openIncidents
  );

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (gridHeaderRef.current) {
      var heightToUse =
        "calc(100% - (" + gridHeaderRef.current.clientHeight + "px ))";
      setDashCardBoxHeight(heightToUse);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    showLoadingAnimation();
  }, [openIncidentStore, showLoadingAnimation]);

  const handleIncidentPaging = () => {
    dispatch(getOpenIncidentsRequest(true));
  }

  return (
    <>
      <Grid container style={{ height: "85vh" }}>
        <Grid item xs={12} ref={gridHeaderRef}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h6">Active VCC Incidents</Typography>
          </Box>
          {isLoading && (
            <Box m={1}>
              <LinearProgress />
            </Box>
          )}
        </Grid>
        <Grid item style={{ height: "100%" }}>
          <Box
            pr={1}
            style={{
              height: dashCardBoxHeight,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {openIncidentStore.data?.length ? (
              openIncidentStore.data.map((incident) => (
                <Box mb={1} key={ShortIdUtil(incident.id)}>
                  <IncidentCard
                    key={incident.id}
                    props={{
                      vccIncident: incident,
                      dispatchAddtlInfo: props.dispatchAddtlInfo,
                    }}
                  />
                </Box>
              ))
            ) : (
              <Box m={1} display="flex" justifyContent="center">
                <Typography variant="h6">
                  There are currently no active VCC Incidents
                </Typography>
              </Box>
            )}
          </Box>
          {openIncidentStore.pagingKeys && (
            <Box mt={1} mb={1} mr={1} style={{ float: "right" }}>
              <Button variant="contained" color="inherit" size="small" onClick={handleIncidentPaging}>
                Load More Incidents
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardIncidents;
