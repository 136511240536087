import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  IconButton,
  Tooltip,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDispatchFeedEventRequest } from "../../../../actions";
import { DispatchEventFeedModel } from "../../../../reducers/states/dispatch-event-feed-state";
import {
  IncidentDispatch,
  UpdateIncidentDispatchRequest,
  VccIncidentModel,
} from "../../../../reducers/states/vcc-incident";
import AdditionalDetailsModal from "../../../integrated-dispatch-feed/additional-details-modal";
import AssociatedDispatch from "./associated-event";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddAssociatedDispatchModal from "./add-modal";
import { IRootReducer } from "../../../../reducers";
import { UpdateAction } from "../../../../enums/incident-update";

interface AssociatedEventsProps {
  props: {
    fromIncidentPage?: boolean;
    dispatchAddtlInfo?: DispatchEventFeedModel;
    incident: VccIncidentModel;
    associatedIdRequests?: UpdateIncidentDispatchRequest[];
    isEdit: boolean;
    handleUpdateAssociatedDispatches: (dispatches: IncidentDispatch[], associatedEventRequestIds: UpdateIncidentDispatchRequest[]) => void;
    handleAddDispatches: (dispatches: DispatchEventFeedModel[]) => void;
    handleRemoveDispatch: (dispatchId: string, removeReason: string) => void;
  };
}
const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    color: theme.agency.WSP,
    padding: "3px",
    "margin-left": "10px",
  },
}));

const AssociatedEvents: FC<AssociatedEventsProps> = ({ props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [incident, setIncident] = useState({} as VccIncidentModel);
  const [incDispatches, setIncDispatches] = useState([] as IncidentDispatch[]);
  const [addtlDetailsOpen, setaddtlDetailsOpen] = useState(false);
  const [addDispatchesOpen, setAddDispatchesOpen] = useState(false);

  const dispatchStore = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.dispatchEvent
  );

  useEffect(() => {
    if(props.incident){
      setIncident(props.incident);
    }
  }, [props.incident])

  useEffect(() => {
    if(incident.dispatchEvents?.length > 1){
      // If multiple associated dispatches, sort by most recent and status
      let openEvents = incident.dispatchEvents.filter(e => e.isOpen);
      let closedEvents = incident.dispatchEvents.filter(e => !e.isOpen);
      let sortedOpenEvents = openEvents.sort(
        (d1, d2) =>
          new Date(d2.startTime).getTime() - new Date(d1.startTime).getTime()
      )

      setIncDispatches([...sortedOpenEvents, ...closedEvents]);
    }
    else{
      setIncDispatches(incident.dispatchEvents);
    }
  }, [incident.dispatchEvents])

  const handleAddDispatches = (dispatches: DispatchEventFeedModel[]) => {
    // Capture the new dispatches to add to the incident
    let associatedDispatches: IncidentDispatch[] = dispatches.map(
      (dispatch) => ({
        id: dispatch.eventNumber,
        agency: dispatch.agency,
        eventType: dispatch.eventType,
        location: dispatch.location,
        latitude: dispatch.latitude,
        longitude: dispatch.longitude,
        activeUnitCount: dispatch.activeUnitCount,
        startTime: dispatch.eventDate,
        isOpen: dispatch.status === "OPEN",
        lastUpdateTime: dispatch.lastUpdateDt,
      })
    );

    // Update the current incident with the latest des
    if(incident.dispatchEvents){
      associatedDispatches = [...incident.dispatchEvents, ...associatedDispatches];
    }

    // Track the new AssociatedIdRequests in case an event is removed before saving
    let newAssociatedIdRequests: UpdateIncidentDispatchRequest[] = dispatches.reduce(
      function (results: UpdateIncidentDispatchRequest[], r) {
        results.push({
          id: r.eventNumber,
          updateAction: UpdateAction.ADD,
        });
        return results;
      },
      []
    );

    // Update the incident on the Parent component
    props.handleUpdateAssociatedDispatches(associatedDispatches, newAssociatedIdRequests)
  };

  const handleRemoveDispatch = (dispatchId: string, removeReason: string) => {
    // Update the current list of associated dispatch events
    let updatedAssociatedEvents: IncidentDispatch[] = incident.dispatchEvents.filter(
        (item) => item.id !== dispatchId
      );
    
    // Track removal reasons if removing an event that was previously saved
    const currentAssociatedRequestIds = props.associatedIdRequests ?? [] as UpdateIncidentDispatchRequest[];
    let updatedAssociatedRequestIds = currentAssociatedRequestIds;
    if (removeReason === "local_dissociate") {
      // If an event is added locally but not saved, remove it from the current list
      updatedAssociatedRequestIds = currentAssociatedRequestIds.filter((i) => i.id !== dispatchId);
    } else {
      // If an event is saved already, capture the dispatch event as a request object 
      // to make an API on incident save to update the dispatch event
      let newAssociatedId: UpdateIncidentDispatchRequest = {
        id: dispatchId,
        updateAction: UpdateAction.REMOVE,
        removeReason: removeReason,
      };
      updatedAssociatedRequestIds = [...currentAssociatedRequestIds, newAssociatedId];
    }

    // Update the incident on the Parent component
    props.handleUpdateAssociatedDispatches(updatedAssociatedEvents, updatedAssociatedRequestIds)
  };

  const handleAdditionalDetailsOpen = (incidentDispatchId: string) => {
    dispatch(getDispatchFeedEventRequest(incidentDispatchId));
    setaddtlDetailsOpen(true);
  };

  return (
    <>
      <Box m={1}>
        <Grid container alignItems="center">
          <Typography variant="subtitle1">
            Associated Dispatch Events
          </Typography>
          {props.isEdit && (
            <Tooltip title="Add Associated Dispatches">
              <IconButton
                className={classes.addButton}
                onClick={() => setAddDispatchesOpen(true)}
                size="large"
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Paper
          variant="outlined"
          style={{ overflow: "auto" }}
        >
          {incDispatches?.length > 0 ? (
            <List aria-label="list of associated dispatch events" dense>
              {incDispatches.map(
                (dispatch: IncidentDispatch) => (
                  <AssociatedDispatch
                    key={dispatch.id}
                    props={{
                      incidentDispatch: dispatch,
                      fromIncidentPage: props.fromIncidentPage,
                      isEdit: props.isEdit,
                      associatedIdRequests: props.associatedIdRequests ?? [] as UpdateIncidentDispatchRequest[],
                      handleAdditionalDetailsOpen: handleAdditionalDetailsOpen,
                      handleRemoveDispatch: handleRemoveDispatch,
                    }}
                  />
                )
              )}
            </List>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%", backgroundColor: "lightgray" }}
            >
              {" "}
              <Box>No Associated Dispatch Events</Box>
            </Grid>
          )}
        </Paper>
      </Box>
      <AddAssociatedDispatchModal
        props={{
          isOpen: addDispatchesOpen,
          incident: incident,
          handleClose: () => setAddDispatchesOpen(false),
          handleAddDispatches: handleAddDispatches,
        }}
      />
      <AdditionalDetailsModal
        props={{
          isOpen: addtlDetailsOpen,
          idfEvent: dispatchStore,
          handleClose: () => setaddtlDetailsOpen(false),
        }}
      />
    </>
  );
};

export default AssociatedEvents;