import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import React, { FC, useEffect, useState } from "react";
  
  interface CustomSelectInputProps {
    props: {
        isOpen: boolean,
        title: string,
        fieldName: string,
        handleUpdate: (inputUpdate: string) => void,
        handleCancel: () => void;
    };
  }
  
  const CustomSelectInput: FC<CustomSelectInputProps> = ({ props }) => {
    const [customInput, setCustomInput] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        customInput !== "" ? setSubmitDisabled(false) : setSubmitDisabled(true);
    }, [customInput]) 
  
    const handleInputChange = (e: any) => {
        setCustomInput(e)
    }

    return (
      <>
        <Dialog
          open={props.isOpen}
          onClose={props.handleCancel}
          fullWidth
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
        >
          <Box>
            <Grid container justifyContent="center">
              <Grid item xs={11}>
                <Box mt={1} mb={0}>
                  <DialogTitle id="form-dialog-title">
                      {props.title}
                  </DialogTitle>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box mt={1.5} mb={0}>
                  <IconButton onClick={props.handleCancel}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      id={"customSelectInput-" + props.fieldName}
                      variant="outlined"
                      autoFocus
                      required
                      fullWidth
                      margin="dense"
                      label={props.fieldName}
                      type="string"
                      inputProps={{ maxLength: 300 }}
                      defaultValue=""
                      onChange={(e) =>
                        handleInputChange(e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
  
                <DialogActions>
                  <Button
                    onClick={props.handleCancel}
                    color="inherit"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => props.handleUpdate(customInput)}
                    color="primary"
                    variant="contained"
                    disabled={submitDisabled}
                  >
                    Update
                  </Button>
                </DialogActions>
              </DialogContent>
            </Grid>
          </Box>
        </Dialog>
      </>
    );
  };
  
  export default CustomSelectInput;
  