import { Box, TextField, Tooltip } from "@mui/material";
import React, { FC, useState } from "react";

interface ViewEditTextFieldProps {
  props: {
    id: string;
    label: string;
    tooltip?: string;
    initialValue?: string;
    isEdit: boolean;
    characterLimit?: number;
    multiline?: boolean;
    adornment?: JSX.Element;
    handleInputChange?: (newValue: string, field: string) => void;
  };
}

const ViewEditTextField: FC<ViewEditTextFieldProps> = ({ props }) => {
  const [fieldCharCount, setFieldCharCount] = useState(
    props.initialValue?.length ?? 0
  );
  const characterLimit = props.characterLimit ?? 240;
  return (
    <>
      <Box m={1} style={{ maxHeight: "36px" }}>
        <Tooltip title={props.tooltip ?? ""}>
          <TextField
            id={props.id}
            label={
              props.label +
              (props.isEdit ? ` ${fieldCharCount}/${characterLimit}` : "")
            }
            size="small"
            margin={"dense"}
            variant={props.isEdit ? "outlined" : "standard"}
            type="text"
            fullWidth
            multiline={props.multiline}
            maxRows={4}
            onChange={(e) => {
              let length =
                e.target.value.length > 0 ? e.target.value.length : 0;
              setFieldCharCount(length);
              if(props.handleInputChange) props.handleInputChange(e.target.value, e.target.id)
            }}
            value={props.initialValue || ""}
            inputProps={{
              maxLength: characterLimit,
            }}
            InputProps={{
              readOnly: !props.isEdit,
              endAdornment: props.adornment
            }}
            InputLabelProps={{
              shrink: props.isEdit || (props.initialValue ? true : false),
            }}
          />
        </Tooltip>
      </Box>
    </>
  );
};

export default ViewEditTextField;
