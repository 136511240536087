import React, { useCallback, useEffect } from "react";
import { DispatchEventFeedGrid } from "../../components";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../reducers";
import { UTCFromTimeFrameUtil, UTCNowUtil } from "../../utils/date-utils";
import {
  getDispatchFeedEventsRequest,
  getFeedStatusRequest,
  getNoteworthyEventsRequest,
  getPinnedDispatchEventsRequest,
} from "../../actions";
import { FeedView } from "../../enums/feed-view";
import { awsRum } from "../..";
import { RUMPage } from "../../enums/rum";

const IntegratedDispatchFeedPage = () => {
  const dispatch = useDispatch();

  const timeFrame = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.timeFrame
  );
  const feedView = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.feedView
  );
  const showOpenDispatchesOnly = useSelector(
    (state: IRootReducer) =>
      state.dispatchEventFeedReducer.showOpenDispatchesOnly
  );
  const region = useSelector(
    (state: IRootReducer) =>
      state.dispatchEventFeedReducer.region
  );
  const area = useSelector(
    (state: IRootReducer) =>
      state.dispatchEventFeedReducer.area
  );

  useEffect(() => {
    try{
      awsRum.recordPageView(RUMPage.FEED);;
    }
    catch{
      // Swallow rum errors
    }
  }, [])

  const getFeedAndFeedStatus = useCallback(
    (fromTimeStamp: Date, toTimeStamp: Date) => {
      dispatch(getPinnedDispatchEventsRequest(fromTimeStamp, toTimeStamp));
      dispatch(getFeedStatusRequest());
      switch (feedView) {
        case FeedView.ALL:
          dispatch(
            getDispatchFeedEventsRequest(
              fromTimeStamp,
              toTimeStamp,
              showOpenDispatchesOnly,
              region,
              area,
              false, // set map data
            )
          );
          break;
        case FeedView.NOTEWORTHY:
          dispatch(getNoteworthyEventsRequest());
          break;
      }
    },
    [dispatch, feedView, showOpenDispatchesOnly, region, area]
  );

  // Update timeFrame dep entities: dispatch events, refresh timer
  useEffect(() => {
    // Set up timer for grid
    const refreshGrid = () => {
      getFeedAndFeedStatus(
        UTCFromTimeFrameUtil(timeFrame),
        UTCNowUtil()
      );
    };
    let gridTimer = setInterval(refreshGrid, 45000);
    
    // If the timer is valid, clear the current timer, fetch the latest, then reset the timer
    if (timeFrame !== 0) {
      clearInterval(gridTimer);
      getFeedAndFeedStatus(
        UTCFromTimeFrameUtil(timeFrame),
        UTCNowUtil()
      );
      gridTimer = setInterval(refreshGrid, 45000);
    }
    
    // useEffect cleanup - turn off the timer when this component is closed
    return () => clearInterval(gridTimer);
  }, [getFeedAndFeedStatus, timeFrame, feedView, showOpenDispatchesOnly]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DispatchEventFeedGrid props={{}}/>
        </Grid>
      </Grid>
    </>
  );
};

export default IntegratedDispatchFeedPage;
