import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import { rootReducer } from "../../reducers";
import rootSaga from "../../sagas";

const sagaMiddleware = createSagaMiddleware();

const composedEnhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, composedEnhancer);

  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
