import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ActionStatus } from "../../../enums/action-status";

const useStyles = makeStyles((theme: Theme) => ({
  planned: {
    marginRight: 10,
    color: theme.palette.secondary.main,
  },
  complete: {
    marginRight: 10,
    color:"green",
  },
  inProgress: {
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  removed: {
    marginRight: 10,
    color: theme.marker.ClosedDispatchEventIFD,
  },
  listItem: {
    flex: "1 1 55%",
  },
}));

type ActionStatusProps = {
  value: string;
};
export const ActionStatusTextColor = ({ value }: ActionStatusProps) => {
  const classes = useStyles();

  switch (value) {
    case ActionStatus.COMPLETE: {
      return (
        <div className={classes.complete}> {ActionStatus.COMPLETE} </div>
      );
    }
    case ActionStatus.PLANNED: {
      return <div  className={classes.planned} >{ActionStatus.PLANNED}</div>;
    }
    case ActionStatus.IN_PROGRESS: {
      return (
        <div className={classes.inProgress} >{ActionStatus.IN_PROGRESS}</div>
      );
    }
    case ActionStatus.REMOVED: {
      return <div  className={classes.removed} >{ActionStatus.REMOVED}</div>;
    }
    default: {
      return <div>{value}</div>;
    }
  }
};
