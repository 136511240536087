import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import React, { FC, useEffect, useState } from "react";
import { IncidentTalkingPoint } from "../../../../reducers/states/vcc-incident";
import moment from "moment";
import useUserInfo from "../../../../hooks/user-info";
import { NameOrEmailUtil } from "../../../../utils/user-info-utils";
import { ParsePhoneNumberUtil } from "../../../../utils/string-utils";
import HistoryModal from "../../../incident-model/incident-history";
import { useDispatch } from "react-redux";
import { getTalkingPointHistoryRequest } from "../../../../actions";
import { EpochMilliStringToSecondsUtil } from "../../../../utils/date-utils";
import TalkingPointAttachmentsModal from "../talking-point-attachments-modal";

interface TalkingPointCardProps {
  props: {
    talkingPoint: IncidentTalkingPoint;
    incidentId: string;
    isEditHidden: boolean;
    handleEdit: (talkingPoint: IncidentTalkingPoint) => void;
  };
}

const TalkingPointCard: FC<TalkingPointCardProps> = ({ props }) => {
  const dispatch = useDispatch();
  const [updatedBy, setUpdatedBy] = useUserInfo();
  const [historyOpen, setHistoryOpen] = useState(false);
  
  useEffect(() => {
    props.talkingPoint.updatedBy
      ? setUpdatedBy(props.talkingPoint.updatedBy)
      : setUpdatedBy(props.talkingPoint.createdBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.talkingPoint.updatedBy, props.talkingPoint.createdBy]);

  const handleGetHistory = (talkingPointId: string) => {
    dispatch(getTalkingPointHistoryRequest(props.incidentId, talkingPointId));
    setHistoryOpen(true);
  };

  return (
    <>
      <Box m={1} key={props.talkingPoint.id}>
        <Paper>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={1}> 
                  {!props.isEditHidden && (
                    <IconButton onClick={() => props.handleEdit(props.talkingPoint)}>
                        <EditIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={4}>
                    <Box m={1}>
                        <Typography>{props.talkingPoint.message}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box m={1}>
                        <Typography>{props.talkingPoint.agency}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title={"PIO Phone: " + ParsePhoneNumberUtil(props.talkingPoint.pioPhoneNumber)}>
                      <Box m={1}>
                          <Typography>{NameOrEmailUtil(updatedBy)}</Typography>
                      </Box>
                    </Tooltip>
                </Grid>
                <Grid item xs={2}>
                    <Box m={1}>
                        <Typography>{moment.unix(EpochMilliStringToSecondsUtil(props.talkingPoint.updatedDate)).format("HH:mm:ss")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box m={1}>
                        <TalkingPointAttachmentsModal props={{
                          incidentId: props.incidentId,
                          talkingPointId: props.talkingPoint.id
                        }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => handleGetHistory(props.talkingPoint.id)}>
                    <HistoryIcon />
                  </IconButton>
                </Grid>
            </Grid>
        </Paper>
      </Box>
      <HistoryModal
        props={{
          isOpen: historyOpen,
          recordType: "Talking Point",
          handleClose: () => setHistoryOpen(false),
        }}
      />
    </>
  );
};

export default TalkingPointCard;
