import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { IncidentTalkingPoint } from "../../../../reducers/states/vcc-incident";
import { ShortIdUtil } from "../../../../utils/id-utils";
import { AgencyChip } from "../../../common/agency-chip";
import UserAutocomplete from "../../../common/user-info/user-autocomplete";
import { AllUserInfoModel } from "../../../../reducers/states/all-user-info";
import { CleanPhoneNumberUtil, ParsePhoneNumberUtil, ValidatePhoneNumberUtil } from "../../../../utils/string-utils";
import { IRootReducer } from "../../../../reducers";
import { IncidentStoreError, IncidentStoreSuccess } from "../../../../enums/store-messages/incident";
import { createTalkingPointRequest, getTalkingPointRequest, getTalkingPointsRequest, updateTalkingPointRequest, uploadTalkingPointFileRequest } from "../../../../actions";
import UpdateFailureModal from "../../../incident-model/update-failure-modal";
import ConfirmationModal from "../../../common/confirmation-modal";
import { FilesPrepareResponse, importFilesUtil, S3FileData } from "../../../../utils/import-utils";
import UploadRulesModal, { UploadErrorMessage } from "../../../incident-model/upload-rules-modal";

interface AddEditTalkingPointProps {
  props: {
    isOpen: boolean;
    isAddTalkingPoint: boolean;
    incidentId: string;
    handleClose: () => void;
  };
}

const AddEditTalkingPointModal: FC<AddEditTalkingPointProps> = ({ props }) => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [talkingPoint, setTalkingPoint] = useState({} as IncidentTalkingPoint);
  const [currentLink, setCurrentLink] = useState("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [isEdited, setIsEdited ] = useState(false);
  const [editedConfirmation, setEditedConfirmation] = useState(false);
  const [isOverwriteError, setIsOverwriteError] = useState(false);
  const [isRefreshIsRequired, setIsRefreshRequired] = useState(false);
  const [fileImportData, setFileImportData] = useState([] as S3FileData[]);
  const [errorRuleOpen, setErrorRuleOpen] = useState(false);
  const [importErrors, setImportErrors] = useState([] as UploadErrorMessage[]);

  const incidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer
  );

  const resetForm = useCallback(() => {
    setTalkingPoint({
      id: "",
      message: "",
      agency: "",
      pioOnCall: "",
      pioPhoneNumber: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
      links: [] as string[],
      fileIds: [] as string[]
    } as IncidentTalkingPoint);
    setFileImportData([] as S3FileData[]);
    setIsEdited(false);
    setEditedConfirmation(false);
  }, []);

  const resetAndCloseForm = useCallback(() => {
    dispatch(getTalkingPointsRequest(props.incidentId));
    setIsOverwriteError(false);
    setIsRefreshRequired(false);
    resetForm();
    props.handleClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm])

  useEffect(() => {
    if (talkingPoint.message !== "" && talkingPoint.pioOnCall !== "" && ValidatePhoneNumberUtil(talkingPoint.pioPhoneNumber) && isEdited) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [talkingPoint.pioOnCall, talkingPoint.pioPhoneNumber, talkingPoint.message, isEdited]);

  useEffect(() => {
    setTalkingPoint({...incidentStore.talkingPoint});
    setIsOverwriteError(false);
    setIsRefreshRequired(false);
  }, [incidentStore.talkingPoint])

  useEffect(() => {
    if(incidentStore.successMessage){
      switch(incidentStore.successMessage){
        case IncidentStoreSuccess.TALKING_POINT_SAVE:
          setSuccessMessage(IncidentStoreSuccess.TALKING_POINT_SAVE);
          resetAndCloseForm();
          break;
        case IncidentStoreSuccess.TALKING_POINT_UPDATE:
          setSuccessMessage(IncidentStoreSuccess.TALKING_POINT_UPDATE);
          resetAndCloseForm();
          break;
      }
    }
  }, [incidentStore.successMessage, resetAndCloseForm]);

  useEffect(() => {
    if(incidentStore.failureMessage){
      switch(incidentStore.failureMessage){
        case IncidentStoreError.TALKING_POINT_SAVE:
          setErrorMessage(IncidentStoreError.TALKING_POINT_SAVE);
          break;
        case IncidentStoreError.TALKING_POINT_UPDATE:
          setErrorMessage(IncidentStoreError.TALKING_POINT_UPDATE);
          break;
        case IncidentStoreError.TALKING_POINT_OVERWRITE_ERROR:
          setIsOverwriteError(true)
          break;
      }
    }
  }, [incidentStore.failureMessage]);

  const handleInputChange = (e: any, field: string) => {
    let value: string = e as string;
    setTalkingPoint((current) => ({ ...current, [field]: value }));
    setIsEdited(true);
  };

  const handleUserAutocomplete = (userSelection: AllUserInfoModel) => {
    let updatedTalkingPoint = talkingPoint;
    talkingPoint.pioOnCall = userSelection.email;
    if( userSelection.agency !== undefined && userSelection.agency !== ""){
      updatedTalkingPoint.agency = userSelection.agency
    }
    // Clear the phone number to have the user enter in a new number for an updated PIO
    updatedTalkingPoint.pioPhoneNumber = "";
    if(userSelection.phoneNumber !== undefined && userSelection.phoneNumber !== ""){
      updatedTalkingPoint.pioPhoneNumber = userSelection.phoneNumber;
    }
    
    setTalkingPoint({...updatedTalkingPoint});
    setIsEdited(true);
  }

  const handleSelectAgency = (event: SelectChangeEvent<string>) => {
    const newAgency = event.target.value;
    setTalkingPoint((current) => ({ ...current, agency: newAgency }));
    setIsEdited(true);
  };

  const handleFileImport = async (event: any) => {
    let fileList = event.target.files as FileList;
    let result: FilesPrepareResponse = await importFilesUtil(fileList);
    if (result.importErrors && result.importErrors.length > 0) {
      setImportErrors(result.importErrors);
      setErrorRuleOpen(true);
    } else {
      setFileImportData(result.importData);
      setIsEdited(true);
    }
  };

  const handleClearFileData = (filePreviewToRemove: string) => {
    setFileImportData(
      fileImportData.filter((f) => f.imgPreview !== filePreviewToRemove)
    );
    setIsEdited(true);
  };

  const handleRemoveFile = (fileId: string) => {
    let updatedFileIds = talkingPoint.fileIds.filter((f) => f !== fileId);
    setTalkingPoint((current) => ({ ...current, fileIds: updatedFileIds }));
    setIsEdited(true);
  }
  
  const handleLinkInput = (e: any) => {
    let value: string = e as string;
    setCurrentLink(value);
    setIsEdited(true);
  }

  const handleAddLink = () => {
    if(talkingPoint.links){
      let updatedLinks = [...talkingPoint.links, currentLink];
      setTalkingPoint((current) => ({ ...current, links: updatedLinks }));
    }
    else{
      setTalkingPoint((current) => ({ ...current, links: [currentLink] }));
    }

    setCurrentLink("")
    setIsEdited(true);
  }

  const handleRemoveLink = (link: string) => {
    let updatedLinks = talkingPoint.links.filter((l) => l !== link);
    setTalkingPoint((current) => ({ ...current, links: updatedLinks }));
    setIsEdited(true);
  }

  const handleCancel = () => {
    if(isEdited && !isRefreshIsRequired){
      setEditedConfirmation(true);
    }
    else{
      resetAndCloseForm();
    }
  };

  const handleContinueEditing = () => {
    setIsOverwriteError(false);
    setIsRefreshRequired(true);
  }

  const handleRefreshForm = () => {
    dispatch(getTalkingPointRequest(props.incidentId, talkingPoint.id));
  }

  const handleCloseErrorModal = () => {
    setImportErrors([]);
    setErrorRuleOpen(false);
  };

  const handleSubmit = () => {
    let addEditTalkingPoint = { ...talkingPoint };
    addEditTalkingPoint.pioPhoneNumber = CleanPhoneNumberUtil(addEditTalkingPoint.pioPhoneNumber);

    let regex = /[^a-zA-Z0-9/!%"'‘’“”~@#$^*()_+=[\]{}|\\,.? :-]/g;
    addEditTalkingPoint.message = addEditTalkingPoint.message.replace(regex, '');

    // Handle uploading files to S3 and Creating or Editing the outreach
    if (fileImportData && fileImportData.length > 0) {
      let newFileIds = fileImportData.map((f) => f.id);
      if(addEditTalkingPoint.fileIds && addEditTalkingPoint.fileIds.length > 0){
        addEditTalkingPoint.fileIds = [...addEditTalkingPoint.fileIds, ...newFileIds];
      }
      else{
        addEditTalkingPoint.fileIds = newFileIds;
      }
      
      if(props.isAddTalkingPoint){
        addEditTalkingPoint.id = uuidv4();
        dispatch(createTalkingPointRequest(props.incidentId, addEditTalkingPoint));
      }
      else{
        dispatch(updateTalkingPointRequest(props.incidentId, addEditTalkingPoint));
      }

      // For now, just dispatch multiple upload requests
      fileImportData.forEach((f: S3FileData) =>
        dispatch(
          uploadTalkingPointFileRequest(
            props.incidentId,
            addEditTalkingPoint.id,
            f.id,
            f.blobData,
            f.fileType
          )
        )
      );
    } else {
      if(props.isAddTalkingPoint){
        addEditTalkingPoint.id = uuidv4();
        dispatch(createTalkingPointRequest(props.incidentId, addEditTalkingPoint));
      }
      else{
        dispatch(updateTalkingPointRequest(props.incidentId, addEditTalkingPoint));
      }
    }
  };

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Box mt={1} mb={0}>
                <DialogTitle id="form-dialog-title">
                {props.isAddTalkingPoint ? "Create " : "Edit "} Talking Point for: {ShortIdUtil(props.incidentId)}
                </DialogTitle>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={3}
              alignItems="center"
              justifyContent="flex-end"
            ></Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <TextField
                    id="message"
                    variant="outlined"
                    autoFocus
                    required
                    margin="dense"
                    label="Talking Point Description"
                    helperText="A description of your talking points in 150 words or less. All users should attach their talking points for consistency of workflow."
                    type="string"
                    fullWidth
                    rows={3}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    value={talkingPoint.message || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, e.target.id)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <UserAutocomplete props={{
                      inputId: "pioOnCall",
                      inputLabel: "On Call PIO",
                      initialValue: talkingPoint.pioOnCall ?? "",
                      handleSelection: handleUserAutocomplete
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Review: Pipe in the phone number if it exists on the user from autocomplete */}
                  <TextField
                    id="pioPhoneNumber"
                    variant="outlined"
                    required
                    margin="dense"
                    label="Phone Number"
                    type="string"
                    fullWidth
                    multiline={true}
                    inputProps={{ maxLength: 300 }}
                    value={ParsePhoneNumberUtil(talkingPoint.pioPhoneNumber) || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, e.target.id)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel>Agency</InputLabel>
                    <Select
                      value={talkingPoint.agency ?? ""}
                      onChange={handleSelectAgency}
                      required
                      label="Agency"
                    >
                      <MenuItem value={""}>(None)</MenuItem>
                      <MenuItem value={"KCM"}>
                        <AgencyChip props={{ agency: "KCM" }} />
                      </MenuItem>
                      <MenuItem value={"NWSA"}>
                        <AgencyChip props={{ agency:"NWSA" }} />
                      </MenuItem>
                      <MenuItem value={"Port"}>
                        <AgencyChip props={{ agency:"Port" }} />
                      </MenuItem>
                      <MenuItem value={"SDOT"}>
                        <AgencyChip props={{ agency:"SDOT" }} />
                      </MenuItem>
                      <MenuItem value={"SFD"}>
                        <AgencyChip props={{ agency:"SFD" }} />
                      </MenuItem>
                      <MenuItem value={"SPD"}>
                        <AgencyChip props={{ agency:"SPD" }} />
                      </MenuItem>
                      <MenuItem value={"ST"}>
                        <AgencyChip props={{ agency:"ST" }} />
                      </MenuItem>
                      <MenuItem value={"WSDOT"}>
                        <AgencyChip props={{ agency:"WSDOT" }} />
                      </MenuItem>
                      <MenuItem value={"WSP"}>
                        <AgencyChip props={{ agency:"WSP" }} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      Add Links: 
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    {currentLink !== "" && 
                      <Box mt={1} fontStyle="italic" fontSize="12px">Click the checkmark to confirm your link</Box>
                    }
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid container item xs={12}>
                      <Grid item xs={10}>
                        <TextField
                          id="links"
                          variant="outlined"
                          margin="dense"
                          label="Link"
                          type="string"
                          fullWidth
                          inputProps={{ maxLength: 300 }}
                          value={currentLink || ""}
                          onChange={(e) =>
                            handleLinkInput(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Box mt={2}>
                          <IconButton color="primary" disabled={currentLink === ""} onClick={handleAddLink}>
                            <CheckIcon />  
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                    {(talkingPoint.links && talkingPoint.links.length > 0) && talkingPoint.links.map((link, index) => (
                      <Grid container item xs={12}>
                        <Grid item xs={10}>
                          <Box >
                            <Typography variant="body2"><Box fontStyle="italic" fontSize="12px" display="inline">{link}</Box></Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title="Click to remove link">
                            <Box mt={.5}>
                              <IconButton onClick={() => handleRemoveLink(link)}>
                                <CloseIcon />  
                              </IconButton>
                            </Box>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={10}>
                    <Box mt={1}>
                      Add Files:
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    {/* Review: Could make a reusable image/file picker to share with notes*/}
                    <Box mt={0} pt={0}>
                      <input
                        accept="*"
                        multiple
                        style={{ display: "none" }}
                        id="talking-point-file-picker"
                        name="talking-point-file-picker"
                        type="file"
                        onChange={handleFileImport}
                      />
                      <Tooltip title="Click to add an attachment">
                        <label htmlFor="talking-point-file-picker">
                          <IconButton component="span" size="large">
                            <AttachFileIcon />
                          </IconButton>
                        </label>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {fileImportData &&
                      fileImportData.length > 0 &&
                      fileImportData.map((f) => (
                        <Box m={1} key={f.id}>
                          <img
                            src={f.imgPreview}
                            alt={f.id}
                            style={{ maxHeight: "30px", maxWidth: "30px" }}
                          />
                          <Tooltip title="Click to remove attachment">
                            <IconButton
                              onClick={() => handleClearFileData(f.imgPreview)}
                              style={{ marginTop: "-10px" }}
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ))}
                  </Grid>
                  <Grid item xs={12}>
                    {talkingPoint.fileIds &&
                      talkingPoint.fileIds.length > 0 &&
                      talkingPoint.fileIds.map((fileId) => (
                        <Grid container item xs={12}>
                          <Grid item xs={10}>
                            <Box mt={1}>
                              <Typography variant="body2"><Box fontStyle="italic" fontSize="12px" display="inline">{fileId}</Box></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip title="Click to remove attachment">
                              <Box mt={0}>
                                <IconButton onClick={() => handleRemoveFile(fileId)}>
                                  <CloseIcon />  
                                </IconButton>
                              </Box>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={handleCancel}
                  color="inherit"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  disabled={submitBtnDisabled || isRefreshIsRequired}
                >
                  {props.isAddTalkingPoint ? "Create " : "Edit "} Talking Point
                </Button>
              </DialogActions>
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
      <ConfirmationModal 
        props={{
          isOpen: editedConfirmation,
          title: "Unsaved Changes",
          message: "You have unsaved changes, do you want to discard them?",
          cancelText: "Discard",
          confirmText: "Continue Editing",
          handleCancel: resetAndCloseForm,
          handleConfirm: () => setEditedConfirmation(false),
        }} 
      />
      <UpdateFailureModal props={{
          isOpen: isOverwriteError,
          recordTypeContent: "Talking Point",
          handleCloseAndDiscard: handleRefreshForm,
          handleCloseAndEdit: handleContinueEditing
        }}
      />
      <UploadRulesModal
        props={{
          isOpen: errorRuleOpen,
          importErrors: importErrors,
          handleClose: handleCloseErrorModal,
        }}
      />
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}:{" "}
          {ShortIdUtil(props.incidentId)}
        </Alert>
      </Snackbar>
      <Snackbar
          open={isRefreshIsRequired}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={handleRefreshForm}
              >
                Refresh
              </Button>
            }
          >
            Refresh is required before saving updates
          </Alert>
        </Snackbar>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}:{" "}
          {ShortIdUtil(props.incidentId)}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddEditTalkingPointModal;
