import React from "react";
import RedirectPrompt from "../../../components/redirect-prompt";

export default function UnauthorizedPage() {
  return (
    <RedirectPrompt
      props={{
        Title: "Unauthorized",
        Explanation:
          "Please reach out to your administrator to access the requested page",
        RedirectText: "Return to Home",
        RedirectTo: "",
      }}
    ></RedirectPrompt>
  );
}
