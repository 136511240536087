import { Box, Divider, List, ListItem, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import UserInfoTooltipHook from "../../user-info/user-info-click-tooltip-hook";

interface DataGridUserInfoCellProps {
  props: {
    users: string[];
  };
}

const DataGridUserInfoCell: FC<DataGridUserInfoCellProps> = ({ props }) => {
  return (
    <>
      <Paper>
        <List>
          {props.users.map((user: string, index: number) => (
            <Box key={user}>
              <ListItem >
                <Typography key={user} variant="body2" style={{ padding: 8 }}>
                  {user}
                </Typography>
                <UserInfoTooltipHook props={{ email: user }} />
              </ListItem>
              {index !== props.users.length - 1 && (
                <Box m={1}>
                  <Divider />
                </Box>
              )}
            </Box>
          ))}
        </List>
      </Paper>
    </>
  );
};

export default DataGridUserInfoCell;
