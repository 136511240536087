import { Box, Grid, Snackbar, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsForUserRequest, getUserInfoRequest, updateUserProfileRequest } from "../../../actions";
import UserGroups from "../../../components/user-management/groups";
import ProfileDetails from "../../../components/user-management/user-profile";
import ProfileActions from "../../../components/user-management/user-profile-actions";
import { UserInfoStoreError, UserInfoStoreSuccess } from "../../../enums/store-messages/user-info";
import { IRootReducer, UserModel } from "../../../reducers";
import { CleanPhoneNumberUtil } from "../../../utils/string-utils";

export default function UserProfilePage() {
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({} as UserModel);
  const [userCreatedDateMillis, setUserCreatedDateMillis] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const userGroupsStore = useSelector(
    (state: IRootReducer) => state.adminReducer.groupsForUser
  );
  
  const successMessageStore = useSelector(
    (state: IRootReducer) => state.userInfoReducer.successMessage
  );

  const failureMessageStore = useSelector(
    (state: IRootReducer) => state.userInfoReducer.failureMessage
  );

  const userDetailsStore = useSelector(
    (state: IRootReducer) => state.userInfoReducer.userInfo
  );

  useEffect(() => {
    async function onLoad(): Promise<void> {
      try {
        var user = await Auth.currentAuthenticatedUser();
        dispatch(getUserInfoRequest(user.attributes["custom:vccEmail"]));
        if(user.signInUserSession.idToken.payload.identities[0].dateCreated){
          setUserCreatedDateMillis(user.signInUserSession.idToken.payload.identities[0].dateCreated)
        }

      } catch (e) {
        setErrorMessage("Failed to Load User Profile");
      }
    }
    onLoad();
  }, [dispatch, userDetails.cognitoUsername]);

  useEffect(() => {
    if (userDetailsStore) {
      setUserDetails({
        cognitoUsername: userDetailsStore.cognitoUsername,
        firstName: userDetailsStore.firstName ?? "",
        lastName: userDetailsStore.lastName ?? "",
        email: userDetailsStore.email ?? "",
        agency: userDetailsStore.agency ?? "",
        phoneNumber: userDetailsStore.phoneNumber ?? ""
      } as UserModel);
      if (userDetailsStore.email){
        dispatch(getGroupsForUserRequest(userDetailsStore.email ?? ""));
      }
  }
  }, [dispatch, userDetailsStore]);

  useEffect(() => {
    if (successMessageStore) {
      switch (successMessageStore) {
        case UserInfoStoreSuccess.UPDATE_USER_PROFILE:
          setSuccessMessage(UserInfoStoreSuccess.UPDATE_USER_PROFILE);
          const updateUser = async () => {
            var user = await Auth.currentAuthenticatedUser();
            dispatch(getUserInfoRequest(user.attributes["custom:vccEmail"]));
          };
          updateUser();
          break;
      }
    }
  }, [dispatch, successMessageStore]);

  useEffect(() => {
    if (failureMessageStore) {
      switch (failureMessageStore) {
        case UserInfoStoreError.UPDATE_USER_PROFILE:
          setErrorMessage(UserInfoStoreError.UPDATE_USER_PROFILE);
          break;
        }
    }
  }, [failureMessageStore]);

  // Review: This needs to be updated to a new route locked down to indivual to a user's cognitoUsername
    // For now, instituting work around of having user's email an admin to be updated on the admin page
 const handleUpdateUserProfile = async (updatedUserInfo: UserModel) => {
   try {
     let cleanNumber = userDetails.phoneNumber;
     if (updatedUserInfo.phoneNumber) {
       cleanNumber = CleanPhoneNumberUtil(updatedUserInfo.phoneNumber);
     }
     dispatch(
       updateUserProfileRequest(
         userDetails.cognitoUsername,
         updatedUserInfo.firstName,
         updatedUserInfo.lastName,
         cleanNumber
       )
     );
     var user = await Auth.currentAuthenticatedUser();
     Auth.updateUserAttributes(user, {
      'given_name': updatedUserInfo.firstName,
      'family_name': updatedUserInfo.lastName,
      'phone_number': updatedUserInfo.phoneNumber
     });
    } catch (e) {
     setErrorMessage("User Profile Update Failed");
   }
 };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4">User Profile</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Box m={1}>
            <ProfileDetails
              props={{
                user: userDetails,
                userCreateDate: userCreatedDateMillis,
                updateUserProfile: handleUpdateUserProfile,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={1}>
            <ProfileActions />
          </Box>
          <Box m={1}>
            <UserGroups
              props={{
                userSelection: userDetails,
                userGroups: userGroupsStore
              }}/>
          </Box>
        </Grid>
        <Grid item xs={6}>
          
        </Grid>
      </Grid>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={7000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
