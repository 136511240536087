import { Auth } from "aws-amplify";
import { FC, useCallback, useEffect, useState } from "react";
import { createContext } from "react";
import { useDispatch } from "react-redux";
import ReconnectingWebSocket from "reconnecting-websocket";
import { createIncidentNotif, initIncidentNotifs } from "../actions";
import { IncidentStatus } from "../enums/incident-status";
import { LocalStorage } from "../enums/local-storage";
import { VccNotification } from "../reducers/states/vcc-incident";
import {
  EntityChangeEventModel,
  EntityChangeType,
  VCCFieldChangeDetail,
} from "../reducers/states/ws-event";
import { UTCNowUtil } from "../utils/date-utils";
import { ShortIdUtil } from "../utils/id-utils";

const WS_URL = process.env.REACT_APP_VCC_APP_WS;

export type WebSocketContextType = {
  setWebSocketIncId: (id: string) => void;
};
export const WebSocketContext = createContext<WebSocketContextType>({
  setWebSocketIncId: () => null,
});

const WebSocketProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [ws, setWs] = useState<ReconnectingWebSocket | null>(null);

  // Sam Review: Figure out this use case - want to selectively set what page the user is on
  // Right now we set it to mute events, and now can use when on a specific page
  // Options:
  // Make this more robust, require every container to set this value
  // Have the websocket pusher outer selectively send updates
  const [incId, setIncId] = useState("");

  const store: WebSocketContextType = {
    setWebSocketIncId: (id) => setIncId(id),
  };

  const initNewWSConnection = useCallback( async () => {
    try {
      var currentUser = await Auth.currentSession();
      setWs(
        new ReconnectingWebSocket(
          WS_URL + "?token=" + currentUser.getAccessToken().getJwtToken() + "&idtoken=" + currentUser.getIdToken().getJwtToken(),
          "",
          { maxRetries: 10 }
        )
      );
    } catch (e) {
      console.log("No user");
    }
  }, [])

  // Initialize the WS Connection and reload any previous notifs not acknowledged yet by the user
  useEffect(() => {
    initNewWSConnection();

    let existingNotifs: string | null = localStorage.getItem(
      LocalStorage.INC_NOTIFICATIONS
    );
    if (existingNotifs) {
      let parsedNotifs: VccNotification[] = JSON.parse(existingNotifs);
      dispatch(initIncidentNotifs(parsedNotifs));
    }
  }, [dispatch, initNewWSConnection]);

  const sendIncCreatedNotif = useCallback( (id: string) => {
      dispatch(
        createIncidentNotif({
          id: id,
          message: "Incident " + ShortIdUtil(id) + " has been launched",
          timeStamp: UTCNowUtil(),
        } as VccNotification)
      );
  }, [dispatch]);

  const sendIncClosedNotif = useCallback( (id: string) => {
    dispatch(
      createIncidentNotif({
        id: id,
        message: "Incident " + ShortIdUtil(id) + " has been closed",
        timeStamp: UTCNowUtil(),
      } as VccNotification)
    );
  }, [dispatch]);

  const sendIncMessageNotif = useCallback( (id: string, message: string) => {
    dispatch(
      createIncidentNotif({
        id: id,
        message: "VCC IM " + ShortIdUtil(id) + " " + message,
        timeStamp: UTCNowUtil(),
      } as VccNotification)
    );
  }, [dispatch]);

  const sendGeneralMessageNotif = useCallback( (id: string, message: string) => {
    dispatch(
      createIncidentNotif({
        id: id,
        message: message,
        timeStamp: UTCNowUtil(),
      } as VccNotification)
    );
  }, [dispatch]);

  

  const sendIncModifiedNotif = useCallback( (
    id: string,
    fieldDisplayName: string,
    isPlural: boolean
  ) => {
    dispatch(
      createIncidentNotif({
        id: id,
        message:
          fieldDisplayName +
          " of VCC IM " +
          ShortIdUtil(id) +
          (isPlural ? " have " : " has ") +
          "been updated",
        timeStamp: UTCNowUtil(),
      } as VccNotification)
    );
  }, [dispatch]);

  const sendIncModifiedNotifs = useCallback( (
    id: string,
    status: string,
    diffs: VCCFieldChangeDetail[]
  ) => {
    if (status === IncidentStatus.CLOSED) {
      let statusDiff = diffs.find((diff) => diff.FieldName === "Status");
      if (statusDiff && statusDiff?.OldValue !== IncidentStatus.CLOSED) {
        sendIncClosedNotif(id);
      }
    } else {
      diffs.forEach((diff) => {
        if (
          diff.FieldName === "Status" ||
          diff.FieldName === "Location" ||
          diff.FieldName === "IncidentType" ||
          diff.FieldName === "EstClearTime" ||
          diff.FieldName === "IncidentCommander" ||
          diff.FieldName === "LaneCount" ||
          diff.FieldName === "LaneDirectionAndBlockage" ||
          diff.FieldName === "LaneRoadwayDescription" ||
          diff.FieldName === "LaneOtherInformation"
        ) {
          sendIncModifiedNotif(
            id,
            diff.FieldDisplayName ?? diff.FieldName,
            false
          );
        } else if (diff.FieldName === "Factors") {
          sendIncModifiedNotif(
            id,
            diff.FieldDisplayName ?? diff.FieldName,
            true
          );
        }
      });
    }
  }, [sendIncClosedNotif, sendIncModifiedNotif]);

  // Main Websocket handling logic
  useEffect(() => {
    if (ws) {
      ws.onmessage = function (event) {
        try {
          // Review: This comes from a step function, place in the CASE below
          let wsEvent: EntityChangeEventModel = JSON.parse(event.data);
          if (wsEvent.Message != null) {
            if (wsEvent.MessageType === "GENERAL"){
              sendGeneralMessageNotif(wsEvent.Id, wsEvent.Message);
            }else{
              sendIncMessageNotif(wsEvent.Id, wsEvent.Message);
            }
          } else {
            switch (wsEvent.EntityType) {
              case "INCIDENT":
                // Don't show notif if user is already viewing the incident
                if (wsEvent.Id !== incId) {
                  if (wsEvent.VCCChangeType === EntityChangeType.INSERT) {
                    sendIncCreatedNotif(wsEvent.Id);
                  } else if (wsEvent.VCCChangeType === EntityChangeType.MODIFY) {
                    sendIncModifiedNotifs(
                      wsEvent.Id,
                      wsEvent.Status,
                      wsEvent.FieldChanges
                    );
                  }
                }
                break;
            }
          }
        } catch (e) {
          // Add error on listen Error
        }
      };
    }
  }, [incId, sendIncCreatedNotif, sendIncMessageNotif, sendGeneralMessageNotif, sendIncModifiedNotifs, ws])

  // WS Error Handling and Reconnection
  if (ws) {
    ws.onerror = async function () {
      ws.close();
      await initNewWSConnection();
    }
  }

  return (
    <WebSocketContext.Provider value={store}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
