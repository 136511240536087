import React from "react";
import RedirectPrompt from "../../../components/redirect-prompt";

export default function NotFoundPage() {
  return (
    <RedirectPrompt
      props={{
        Title: "Not Found",
        Explanation: "Sorry, page not found!",
        RedirectText: "Return to Home",
        RedirectTo: "",
      }}
    ></RedirectPrompt>
  );
}
