import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

export function renderCellExpand(
  params: any,
  jsx?: JSX.Element,
  alwaysExpand?: boolean
) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
      jsx={jsx}
      alwaysExpand={alwaysExpand}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

function isOverflown(element: any) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

interface GridCellExpanderProps {
  width: number;
  value?: string;
  jsx?: JSX.Element;
  alwaysExpand?: boolean;
}

const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpanderProps
) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown =
      isOverflown(cellValue.current) || props.alwaysExpand!;
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ maxWidth: width + 800, zIndex: 1500}}
        >
          <Paper>
            {props.jsx ? (
              props.jsx
            ) : (
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            )}
          </Paper>
        </Popper>
      )}
    </Box>
  );
});
