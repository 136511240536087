import { Box, Grid, Button, Chip, Badge, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import {
  clearCurrentDispatchEvent,
  getDispatchFeedEventRequest,
  setSelectedEventIdsRequest,
} from "../../../../../../actions";
import { Agency } from "../../../../../../enums/agency";
import { DispatchEventFeedModel, FeedStatus } from "../../../../../../reducers";
import { DictionaryFromArrayUtil } from "../../../../../../utils/array-utils";
import { ShortIdUtil } from "../../../../../../utils/id-utils";
import { TruncateSourceIdUtil } from "../../../../../../utils/string-utils";
import AdditionalDetailsModal from "../../../../../integrated-dispatch-feed/additional-details-modal";
import { VccIncidentModel } from "../../../../../../reducers/states/vcc-incident";

const useStyles = makeStyles((theme: Theme) => ({
  chipWSP: {
    backgroundColor: theme.agency.WSP,
    color: "white",
  },
  chipKCM: {
    backgroundColor: theme.agency.KCM,
    color: "white",
  },
  chipSFD: {
    backgroundColor: theme.agency.SFD,
    color: "white",
  },
  chipSPD: {
    backgroundColor: theme.agency.SPD,
    color: "white",
  },
  badge: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export interface DispatchGridRow {
  id: string;
  eventNumber: string;
  eventStartDate: string;
  lastUpdateDate: string;
  agency: string;
  location?: string;
  latitude?: number;
  longitude?: number;
  eventType?: string;
  associatedIncId?: string;
}

interface AssociatedDispatchesGridProps {
  props: {
    dispatchEvents: DispatchEventFeedModel[];
    incident: VccIncidentModel;
    timeFrame: number;
    dispatchEvent: DispatchEventFeedModel;
    feedStatus: FeedStatus[];
    title?: string;
    handleClose: () => void;
    handleAddDispatches: (dispatches: DispatchEventFeedModel[]) => void;
  };
}

const AssociatedDispatchesGrid: FC<AssociatedDispatchesGridProps> = ({
  props,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [gridData, setGridData] = useState([] as DispatchGridRow[]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0
  })
  const [addtlDetailsOpen, setAddtlDetailsOpen] = useState(false);
  const [addtlDetails, setAddtlDetails] = useState(
    {} as DispatchEventFeedModel
  );
  const [selectedEvents, setSelectedEvents] = useState(
    [] as DispatchEventFeedModel[]
  );
  const [gridHeight, setGridHeight] = useState("calc(100% - 175px");

  const handleAddDispatches = (dispatches: DispatchEventFeedModel[]) => {
    props.handleAddDispatches(dispatches);
    props.handleClose();
  };
  // Init Grid Height based on current window size
  useEffect(() => {
    var heightToUse = "calc(100% - 100px)";
    setGridHeight(heightToUse ?? "calc(100% - 275px");
  }, []);

  // Init map selected - clear selection on initial load
  useEffect(() => {
    dispatch(
      setSelectedEventIdsRequest(DictionaryFromArrayUtil([] as string[]))
    );
  }, [dispatch]);

  // Update additional details
  useEffect(() => {
    setAddtlDetails(props.dispatchEvent);
  }, [props.dispatchEvent]);

  // Update gridData from latest dispatch events set
  useEffect(() => {
    let apiRows: DispatchGridRow[] = props.dispatchEvents.reduce(function (
      results: DispatchGridRow[],
      r,
      index
    ) {
      if (
        r.associatedIncId === "" &&
        (props.incident.dispatchEvents === undefined ||
          !props.incident.dispatchEvents.some((de) => de.id === r.eventNumber))
      ) {
        results.push({
          id: index + r.agency,
          eventNumber: r.eventNumber,
          eventStartDate: r.eventDate,
          lastUpdateDate: r.lastUpdateDt,
          agency: r.agency,
          location: r.location,
          latitude: r.latitude,
          longitude: r.longitude,
          eventType: r.eventType,
          associatedIncId: r.associatedIncId,
        });
      }
      return results;
    },
    []);
    setGridData([...apiRows]);

    // Review: Check the height of the grid in case the screen resizes
    var heightToUse = "calc(100% - 64px)";
    setGridHeight(heightToUse ?? "calc(100% - 175px");
  }, [props.dispatchEvents, props.incident.dispatchEvents]);

  const handleSelection = (rowIds: GridRowSelectionModel) => {
    let selectedRowsEventNumbers: string[] = gridData
      .filter((d) => rowIds.includes(d.id))
      .map((s) => s.eventNumber);
    let selectedEvents: DispatchEventFeedModel[] = props.dispatchEvents.filter(
      (d) => selectedRowsEventNumbers.includes(d.eventNumber)
    );

    setSelectedEvents(selectedEvents);
  };

  const handleAdditionalDetailsOpen = (id: string) => {
    dispatch(clearCurrentDispatchEvent());
    dispatch(getDispatchFeedEventRequest(id));
    setAddtlDetailsOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: "eventNumber",
      headerName: "Event Number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const sourceId = params.row.eventNumber;
        const agency = params.row.agency;
        const associatedIncId = params.row.associatedIncId;
        return (
          <Box>
            <Badge
              style={{ top: "10px" }}
              badgeContent={associatedIncId ? 1 : 0}
              classes={{ badge: classes.badge }}
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Button
                style={{ top: "-10px" }}
                type="button"
                color="primary"
                onClick={() => handleAdditionalDetailsOpen(sourceId as string)}
              >
                {TruncateSourceIdUtil(sourceId as string, agency as string)}
              </Button>
            </Badge>
          </Box>
        );
      },
    },
    {
      field: "eventStartDate",
      headerName: "Event Start Time",
      flex: 0.75,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const startDate = params.row.eventStartDate;
        return <Box>{moment(startDate as string).format("HH:mm:ss")}</Box>;
      },
    },
    {
      field: "lastUpdateDate",
      headerName: "Last Updated Time",
      flex: 0.75,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const updatedDate = params.row.lastUpdateDate;
        return <Box>{moment(updatedDate as string).format("HH:mm:ss")}</Box>;
      },
    },
    {
      field: "agency",
      headerName: "Agency",
      filterable: false,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      flex: 0.45,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.row.agency;
        switch (value) {
          case Agency.WSP: {
            return <Chip label={Agency.WSP} className={classes.chipWSP} />;
          }
          case Agency.KCM: {
            return <Chip label={Agency.KCM} className={classes.chipKCM} />;
          }
          case Agency.SFD: {
            return <Chip label={Agency.SFD} className={classes.chipSFD} />;
          }
          case Agency.SPD: {
            return <Chip label={Agency.SPD} className={classes.chipSPD} />;
          }
          default: {
            return <div>{value}</div>;
          }
        }
      },
    },
    { field: "eventType", headerName: "Event Type", flex: 1.25 },
    { field: "location", headerName: "Location", flex: 1.25 },
    {
      field: "associatedIncId",
      headerName: "VCC Incident",
      flex: 1.1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.row.associatedIncId;
        return (
          <Box m={1}>
            <Link to={"/incident-model/" + value}>
              {value ? ShortIdUtil(value.toString()) : ""}
            </Link>
          </Box>
        );
      },
    },
  ];

  return (
    <Box style={{ height: "80vh" }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={12} style={{ height: "100%" }}>
          <Box style={{ height: gridHeight }}>
            <DataGrid
              rows={gridData}
              columns={columns}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              checkboxSelection
              disableRowSelectionOnClick
              density="compact"
              disableColumnSelector={true}
              onRowSelectionModelChange={(selection: GridRowSelectionModel) =>
                handleSelection(selection)
              }
            />
          </Box>
          <Box paddingY={2}>
            <Grid container justifyContent="flex-end" style={{ gap: 15 }}>
              <Button
                variant="contained"
                color="inherit"
                onClick={props.handleClose}
              >
                Cancel
              </Button>
              <Button
                disabled={selectedEvents.length === 0}
                variant="contained"
                color="primary"
                onClick={() => handleAddDispatches(selectedEvents)}
              >
                Add
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <AdditionalDetailsModal
        props={{
          isOpen: addtlDetailsOpen,
          idfEvent: addtlDetails,
          hideCreate: true,
          handleClose: () => setAddtlDetailsOpen(false),
        }}
      />
    </Box>
  );
};

export default AssociatedDispatchesGrid;
