import { Auth } from "aws-amplify";
import { takeEvery, call, put, fork } from "redux-saga/effects";

import * as actions from "../../actions";
import { types, updateUserProfilePayload, updateUserReadyOpGroupsPayload } from "../../actions";
import * as api from "../../api";
import { UserInfoStoreError, UserInfoStoreSuccess } from "../../enums/store-messages/user-info";
import { NotificationGroup, UserInfoModel, UserNotifGroups } from "../../reducers/states/user-info";
import { IsResponseValidUtil } from "../../utils/api-utils";

export function* getUserInfo(action: any): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getUserInfo, action.payload.email);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: UserInfoModel = result.data;
        yield put(actions.getUserInfoSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getUserInfoFailure(UserInfoStoreError.GET_USER_INFO)
        );
      }
    } else {
      yield put(actions.getUserInfoFailure(UserInfoStoreError.GET_USER_INFO));
    }
  } catch (e) {
    // Future: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchGetUserInfoEvent() {
  yield takeEvery(actions.types.GET_USER_INFO, getUserInfo);
}


export function* getAllReadyOpGroups(): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getAllReadyOpGroups);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: NotificationGroup[] = result.data;
        yield put(actions.getAllReadyOpGroupsSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getAllReadyOpGroupsFailure(UserInfoStoreError.GET_ALL_READYOP_GROUPS)
        );
      }
    } else {
      yield put(actions.getAllReadyOpGroupsFailure(UserInfoStoreError.GET_ALL_READYOP_GROUPS));
    }
  } catch (e) {
    // Future: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchGetAllReadyOpGroups() {
  yield takeEvery(actions.types.GET_ALL_READYOP_GROUPS, getAllReadyOpGroups);
}

export function* getUserReadyOpGroups(): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const email = currentUser.getIdToken().payload["custom:vccEmail"];
    const result: any = yield call(api.getUserReadyOpGroups, email);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        let apiResultPayload: UserNotifGroups[] = result.data;
        yield put(actions.getUserReadyOpGroupsSuccess(apiResultPayload));
      } else {
        yield put(
          actions.getUserReadyOpGroupsFailure(UserInfoStoreError.GET_USER_READYOP_GROUPS)
        );
      }
    } else {
      yield put(actions.getUserReadyOpGroupsFailure(UserInfoStoreError.GET_USER_READYOP_GROUPS));
    }
  } catch (e) {
    // Future: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchGetUserReadyOpGroups() {
  yield takeEvery(actions.types.GET_USER_READYOP_GROUPS, getUserReadyOpGroups);
}

export function* updateUserReadyOpGroups(action: {
  type: typeof types.UPDATE_USER_READYOP_GROUPS;
  payload: updateUserReadyOpGroupsPayload;
}): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const email = currentUser.getIdToken().payload["custom:vccEmail"];
    const result: any = yield call(api.updateUserReadyOpGroups, email, action.payload.groupIds);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        yield put(actions.updateUserReadyOpGroupsSuccess(UserInfoStoreSuccess.UPDATE_USER_READYOP_GROUPS));
      } else {
        yield put(
          actions.updateUserReadyOpGroupsFailure(UserInfoStoreError.UPDATE_USER_READYOP_GROUPS)
        );
      }
    } else {
      yield put(actions.updateUserReadyOpGroupsFailure(UserInfoStoreError.UPDATE_USER_READYOP_GROUPS));
    }
  } catch (e) {
    // Future: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchUpdateUserReadyOpGroups() {
  yield takeEvery(actions.types.UPDATE_USER_READYOP_GROUPS, updateUserReadyOpGroups);
}

export function* updateUserProfile(action: {
  type: typeof types.UPDATE_USER_PROFILE;
  payload: updateUserProfilePayload;
}): Generator<any, void, unknown> {
  try {
    const currentUser: any = yield Auth.currentSession();
    const cognitoUsername = currentUser.getAccessToken().payload["username"];
    const result: any = yield call(api.updateUserProfile, cognitoUsername, action.payload.givenName, action.payload.familyName, action.payload.phoneNumber);
    if (result && result.status === 200) {
      if (IsResponseValidUtil(result.data)) {
        yield call(actions.getAllUserInfoRequest);
        yield put(actions.updateUserProfileSuccess(UserInfoStoreSuccess.UPDATE_USER_PROFILE));
      } else {
        yield put(actions.updateUserProfileFailure(UserInfoStoreError.UPDATE_USER_PROFILE));
      }
    } else {
      yield put(actions.updateUserProfileFailure(UserInfoStoreError.UPDATE_USER_PROFILE));
    }
  } catch (e) {
    console.log(e);
  }
}
function* watchUpdateUserProfile() {
  yield takeEvery(actions.types.UPDATE_USER_PROFILE, updateUserProfile);
}


const userInfoSagas = [
  fork(watchGetUserInfoEvent),
  fork(watchGetAllReadyOpGroups),
  fork(watchGetUserReadyOpGroups),
  fork(watchUpdateUserReadyOpGroups),
  fork(watchUpdateUserProfile),
];

export default userInfoSagas;
