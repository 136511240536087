import { AnyAction } from "redux";
import { types } from "../../actions";
import { IDispatchEventFeedState } from "../interfaces";
import { DispatchEventFeedModel, PagingKeys, PagingResponse, PinEvent, TMCLog } from "../states/dispatch-event-feed-state";

export const initialDispatchState: IDispatchEventFeedState = {
  dispatchEvent: {} as DispatchEventFeedModel,
  dispatchEvents: {pagingKeys: {} as PagingKeys, data: [] as DispatchEventFeedModel[]} as PagingResponse<DispatchEventFeedModel[]>,
  pinnedEvent: {} as PinEvent,
  pinnedEvents: [],
  tmcLog: {} as TMCLog,
  feedStatus: [],
  timeFrame: 5,
  feedView: "All",
  showOpenDispatchesOnly: true,
  region: "",
  area: "I5",
  successMessage: "",
  failureMessage: "",
};

function dispatchEventFeedReducer(
  state = initialDispatchState,
  action: AnyAction
): IDispatchEventFeedState {
  switch (action.type) {
    case types.GET_DISPATCH_FEED_EVENT_SUCCESS:
      return {
        ...state,
        dispatchEvent: action.payload.dispatchFeedEvent,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_DISPATCH_FEED_EVENTS_SUCCESS:
      return {
        ...state,
        dispatchEvents: action.payload.dispatchFeedEvents,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_DISPATCH_FEED_EVENTS_FAILURE:
      return {
        ...state,
        dispatchEvents: {} as PagingResponse<DispatchEventFeedModel[]>,
        successMessage: "",
        failureMessage: action.payload.getDispatchFeedFailureMessage,
      };
    case types.GET_NOTEWORTHY_DISPATCH_EVENTS_SUCCESS:
      return {
        ...state,
        dispatchEvents: { data: action.payload.dispatchFeedEvents } as PagingResponse<DispatchEventFeedModel[]>,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_NOTEWORTHY_DISPATCH_EVENTS_FAILURE:
      return {
        ...state,
        dispatchEvents: {} as PagingResponse<DispatchEventFeedModel[]>,
        successMessage: "",
        failureMessage: action.payload.getNoteworthyEventsFailureMessage,
      };
    case types.CLEAR_DISPATCH_EVENT_FEED:
      return {
        ...state,
        dispatchEvents: {} as PagingResponse<DispatchEventFeedModel[]>,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_TMC_LOG_SUCCESS:
      return {
        ...state,
        tmcLog: action.payload.tmcLog,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_TMC_LOG_FAILURE:
      return {
        ...state,
        tmcLog: {} as TMCLog,
        failureMessage: action.payload.failureMessage,
        successMessage: "",
      };
    case types.CLEAR_CURRENT_TMC_LOG:
      return {
        ...state,
        tmcLog: {} as TMCLog,
        failureMessage: "",
        successMessage: "",
      };
    case types.GET_PINNED_DISPATCH_EVENT_SUCCESS:
      return {
        ...state,
        pinnedEvent: action.payload.pinnedEvent,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_PINNED_DISPATCH_EVENT_FAILURE:
      return {
        ...state,
        pinnedEvent: {} as PinEvent,
        failureMessage: action.payload.getPinnedEventFailureMessage,
        successMessage: "",
      };
    case types.GET_PINNED_DISPATCH_EVENTS_SUCCESS:
      return {
        ...state,
        pinnedEvents: action.payload.pinnedEvents,
        successMessage: "",
        failureMessage: "",
      };
    case types.GET_PINNED_DISPATCH_EVENTS_FAILURE:
      return {
        ...state,
        pinnedEvents: [],
        failureMessage: action.payload.getPinnedEventsFailureMessage,
        successMessage: "",
      };
    case types.PIN_DISPATCH_EVENT_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.pinEventSuccessMessage,
        failureMessage: "",
      };
    case types.PIN_DISPATCH_EVENT_FAILURE:
      return {
        ...state,
        pinnedEvents: [],
        failureMessage: action.payload.pinEventFailureMessage,
        successMessage: "",
      };
    case types.REMOVE_PIN_DISPATCH_EVENT_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.removePinEventSuccessMessage,
        failureMessage: "",
      };
    case types.REMOVE_PIN_DISPATCH_EVENT_FAILURE:
      return {
        ...state,
        pinnedEvents: [],
        failureMessage: action.payload.removePinEventFailureMessage,
        successMessage: "",
      };
    case types.CLEAR_CURRENT_DISPATCH_EVENT:
      return {
        ...state,
        dispatchEvent: {} as DispatchEventFeedModel,
      };
    case types.GET_DISPATCH_FEED_STATUS_SUCCESS:
      return {
        ...state,
        feedStatus: action.payload.feedStatus,
      };
    case types.SET_TIME_FRAME_SUCCESS:
      return {
        ...state,
        timeFrame: action.payload.newTimeFrame,
      };
    case types.SET_FEED_VIEW_SUCCESS:
      return {
        ...state,
        feedView: action.payload.newFeedView,
      };
    case types.SET_SHOW_OPEN_DISPATCHES_ONLY_SUCCESS:
      return {
        ...state,
        showOpenDispatchesOnly: action.payload.showOpenOnly,
      };
    case types.SET_FEED_AREA_SUCCESS:
      return {
        ...state,
        area: action.payload.area,
      };
    case types.SET_FEED_REGION_SUCCESS:
      return {
        ...state,
        region: action.payload.region,
      };
    default:
      return state;
  }
}

export default dispatchEventFeedReducer;
