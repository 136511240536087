import {
    Box,
    Grid,
  } from "@mui/material";
import React, { FC } from "react";
import ScheduledOutreachGrid from "../scheduled-outreach";
import ApprovedTalkingPoints from "../approved-talking-points";
  
  interface PioTabProps {
    props: {
      incidentId: string;
      isEditEnabled: boolean;
    };
  }

  const PioTab: FC<PioTabProps> = ({ props }) => {
    return (
      <>
        <Box m={1} style={{ height: "100%" }}>
          <Box style={{ height: "100%" }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid item xs={12} style={{ height: "50%" }}>
                <ScheduledOutreachGrid props={{
                  incidentId: props.incidentId,
                  isEditEnabled: props.isEditEnabled
                }}/>
              </Grid>
              <Grid item xs={12} style={{ height: "50%" }}>
                <ApprovedTalkingPoints props={{
                  incidentId: props.incidentId,
                  isEditEnabled: props.isEditEnabled
                }} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };
  
  export default PioTab;
  