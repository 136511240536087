import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Theme,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuthContext } from "../../core/auth-context";
import ProfileMenu from "./profile-menu";
import MenuIcon from "@mui/icons-material/Menu";
import Overview from "./overview";
import NotificationMenu from "./notification-menu";
import useUserPermissions from "../../hooks/user-permissions";
import { RoleAction } from "../../enums/permission-actions";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flexGrow: 1,
  },
  navButton: {
    variant: "contained",
    color: "primary",
  },
  navTab: {
    position: "relative",
    color: "white",
    textDecoration: "none",
  },
  activeTab: {
    fontWeight: "bold",
    borderBottom: "2px solid " + theme.palette.secondary.main,
  },
  menuTab: {
    position: "relative",
    color: "black",
    textDecoration: "none",
  },
  activeMenuTab: {
    fontWeight: "bold",
    color: "black",
    borderBottom: "2px solid " + theme.palette.secondary.main,
  },
  titleTab: {
    position: "relative",
    color: "white",
    textDecoration: "none",
    fontWeight: "bold",
  },
  preProdWarning: {
    color: theme.palette.error.main,
  },
}));

const NavBar: FC = () => {
  const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [viewRecordsMgmt] = useUserPermissions(RoleAction.VIEW_RECORDS_MGMT);
  const { authState, setAuthState } = useAuthContext();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  async function handleLogout(): Promise<any> {
    try {
      await Auth.signOut();
      setAuthState({
        isAuthenticated: false,
        userGroups: [""],
        cognitoUsername: "",
        email: "",
      });
      window.open("/#/login", "_self");
    } catch (e) {
      // Review: Error handling
    }
  }

  return (
    <>
      <AppBar position="static">
        <Container maxWidth={false}>
          <Toolbar>
            <Button className={classes.navButton}>
              <NavLink className={classes.titleTab} to={"/dashboard"}>
                <Box pt={1}>
                  <Typography variant="h5">VCC</Typography>
                </Box>
              </NavLink>
            </Button>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "none",
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="VCC Navigation Menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "block",
                    xl: "none",
                  },
                }}
              >
                <Box m={1} pt={0.5} className={classes.title}>
                  <MenuItem disableRipple>
                    <Button disableRipple className={classes.navButton}>
                      <NavLink
                        className={({ isActive }) => {
                          const linkClasses = [classes.menuTab];
                          if (isActive) {
                            linkClasses.push(classes.activeMenuTab);
                          }
                          return linkClasses.join(" ");
                        }}
                        to={"/feed"}
                      >
                        Feed
                      </NavLink>
                    </Button>
                  </MenuItem>
                  <Divider />
                  {!viewRecordsMgmt && (
                    <>
                      <MenuItem disableRipple>
                        <Button disableRipple className={classes.navButton}>
                          <NavLink
                            className={({ isActive }) => {
                              const linkClasses = [classes.menuTab];
                              if (isActive) {
                                linkClasses.push(classes.activeMenuTab);
                              }
                              return linkClasses.join(" ");
                            }}
                            to={"/records-management"}
                          >
                            Records Management
                          </NavLink>
                        </Button>
                      </MenuItem>
                      <Divider />
                    </>
                  )}
                  <MenuItem disableRipple>
                    <Button disableRipple className={classes.navButton}>
                      <NavLink
                        className={({ isActive }) => {
                          const linkClasses = [classes.menuTab];
                          if (isActive) {
                            linkClasses.push(classes.activeMenuTab);
                          }
                          return linkClasses.join(" ");
                        }}
                        to={"/dashboard"}
                      >
                        Dashboard
                      </NavLink>
                    </Button>
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "flex",
                },
              }}
            >
              <Box m={1} pt={0.5} className={classes.title}>
                <Button className={classes.navButton}>
                  <NavLink
                    className={({ isActive }) => {
                      const linkClasses = [classes.navTab];
                      if (isActive) {
                        linkClasses.push(classes.activeTab);
                      }
                      return linkClasses.join(" ");
                    }}
                    to={"/feed"}
                  >
                    Feed
                  </NavLink>
                </Button>
                {!viewRecordsMgmt && (
                  <Button className={classes.navButton}>
                    <NavLink
                      className={({ isActive }) => {
                        const linkClasses = [classes.navTab];
                        if (isActive) {
                          linkClasses.push(classes.activeTab);
                        }
                        return linkClasses.join(" ");
                      }}
                        to={"/records-management"}
                    >
                      Records Management
                    </NavLink>
                  </Button>
                )}
                <Button className={classes.navButton}>
                  <NavLink
                   className={({ isActive }) => {
                    const linkClasses = [classes.navTab];
                    if (isActive) {
                      linkClasses.push(classes.activeTab);
                    }
                    return linkClasses.join(" ");
                  }}
                   to={"/dashboard"}
                  >
                    Dashboard
                  </NavLink>
                </Button>
              </Box>
            </Box>
            <Box ml={2}>
            { authState.isAuthenticated && 
                <NotificationMenu />
            }
            </Box>
            <Box ml={2}>
              <ProfileMenu
                props={{
                  isAuthenticated: authState.isAuthenticated,
                  handleLogout: handleLogout,
                }}
              />
            </Box>
            <Overview />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default NavBar;
