import {
  Box,
  Grid,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { awsRum, customTheme } from "../..";
import {
  getDispatchFeedEventRequest,
  getDispatchFeedEventsRequest,
  getTrafficCamLayerRequest,
  getVccIncidentActionRequest,
  getVccIncidentActionsRequest,
  getVccIncidentNotesRequest,
  getVccIncidentRequest,
  isIncidentUpdateAvailable,
  updateVccIncident,
  updateViewportRequest,
  getMapAnnotationsRequest,
  getOpenIncidentsRequest,
  getOutreachesRequest,
  getTalkingPointsRequest,
} from "../../actions";
import ActionGrid from "../../components/collaboration/action-grid";
import ModifyActionModal from "../../components/collaboration/modify-action-modal";
import IncidentNotes from "../../components/incident-model/vcc-incident-detail/incident-notes";
import VccMap from "../../components/map/vcc-map";
import { MapLayers, MarkerTypes } from "../../enums/map";
import { RUMPage } from "../../enums/rum";
import {
  IncidentStoreError,
  IncidentStoreSuccess,
} from "../../enums/store-messages/incident";
import { IRootReducer } from "../../reducers";
import { MarkerData } from "../../reducers/states/map";
import {
  IncidentAction,
  UpdateIncidentDispatchRequest,
  VccIncidentModel,
} from "../../reducers/states/vcc-incident";
import { ShortIdUtil } from "../../utils/id-utils";
import VccIncidentDetail from "../../components/incident-model/vcc-incident-detail";
import { IncidentStatus } from "../../enums/incident-status";
import { UTCFromTimeFrameUtil, UTCToOneMinuteAheadUtil } from "../../utils/date-utils";
import AdditionalDetailsModal from "../../components/integrated-dispatch-feed/additional-details-modal";
import IncidentSummaryAccordion from "../../components/incident-model/incident-summary-accordion";
import { IncidentTab } from "../../enums/incident-tabs";
import PioTab from "../../components/pio/pio-tab";
import { useAuthContext } from "../../core";

export default function IncidentDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{ incid: string }>();
  const [tabIndex, setTabIndex] = React.useState(IncidentTab.DETAILS_TAB);
  const [isEdit, setIsEdit] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modifyActionOpen, setModifyActionOpen] = useState(false);
  const [notesCount, setNotesCount] = useState(0);
  const [mobilityStrategyCount, setMobilityStrategyCount] = useState(0);
  const [keyMessagesCount, setKeyMessagesCount] = useState(0);
  const [idfDetailsOpen, setIdfDetailsOpen] = useState(false);
  const [isOverwriteError, setIsOverwriteError] = useState(false);
  const [isIncidentEditable, setIsIncidentEditable] = useState(true);

  const { authState } = useAuthContext();

  const mapStore = useSelector((state: IRootReducer) => state.mapReducer);
  const incidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer
  );
  const eventStore = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer
  );
  const actionStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.actions
  );
  const storeNotes = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.notes
  );
  const pioOutreachStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.outreaches
  );
  const pioTalkingPointStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.talkingPoints
  );

  useEffect(() => {
    try{
      awsRum.recordPageView(RUMPage.COLLAB_SPACE);
    }
    catch{
      // Swallow rum errors
    }
    
  }, [])
  useEffect(() => {
    setMobilityStrategyCount(actionStore.length);
  }, [actionStore]);
  useEffect(() => {
    setNotesCount(storeNotes.length);
  }, [storeNotes]);
  useEffect(() => {
    setKeyMessagesCount(pioOutreachStore.length + pioTalkingPointStore.length)
  }, [pioOutreachStore.length, pioTalkingPointStore.length])

  useEffect(() => {
    dispatch(getTrafficCamLayerRequest()); // Review: Move this into the map init
    dispatch(
      updateViewportRequest(
        incidentStore.incident.latitude,
        incidentStore.incident.longitude,
        14
      )
    );
  }, [
    dispatch,
    incidentStore.incident.latitude,
    incidentStore.incident.longitude,
  ]);

  useEffect(() => {
    if (incidentStore.incident.status === IncidentStatus.CLOSED || incidentStore.incident.status === IncidentStatus.DELETED){
      setIsIncidentEditable(false);
    }else{
      setIsIncidentEditable(true);
    }
    }, [
    incidentStore.incident.status,
  ]);

  // Future: WebSocket implementation: Update the verbiage to INC_UPDATE_AVAIL since websockets already told us the update is ready
  // And remove this useEffect, and instead only listen for INC_UPDATE_AVAIL changes to the store
  useEffect(() => {
    // Set up a timer for the tab:
    const checkForIncidentUpdates = () => {
      dispatch(
        isIncidentUpdateAvailable(
          params.incid!,
          incidentStore.incident.updatedDate
        )
      );
    };
    let latestTimer = setInterval(checkForIncidentUpdates, 15000);

    // If the depedencies update from their redux store defaults, clear and restart the timer
    if((params.incid && params.incid !== "") || (incidentStore.incident.updatedDate && incidentStore.incident.updatedDate !== "")){
      clearInterval(latestTimer);
      latestTimer = setInterval(checkForIncidentUpdates, 15000);
    }

    // On component close, clear the timer
    return () => clearInterval(latestTimer);
  }, [dispatch, params.incid, incidentStore.incident.updatedDate]);

  // Initiatlize Incident related tab datad
  useEffect(() => {
    dispatch(getVccIncidentRequest(params.incid!));
    dispatch(getVccIncidentNotesRequest(params.incid!));
    dispatch(getMapAnnotationsRequest(params.incid!));
    dispatch(getVccIncidentActionsRequest(params.incid!));
    dispatch(getOutreachesRequest(params.incid!));
    dispatch(getTalkingPointsRequest(params.incid!));
  }, [dispatch, params.incid]);

  // Initialize Map Specific Data
  useEffect(() => {
    dispatch(getOpenIncidentsRequest());
    dispatch(
      getDispatchFeedEventsRequest(
        UTCFromTimeFrameUtil(120),
        UTCToOneMinuteAheadUtil(),
        true,
        "",
        "",
        true, // do set map data
      )
    );

    }, [dispatch]);

  useEffect(() => {
    if (incidentStore.successMessage) {
      switch (incidentStore.successMessage) {
        case IncidentStoreSuccess.INCIDENT_UPDATE:
        case IncidentStoreSuccess.INCIDENT_SAVE:
          setSuccessMessage(incidentStore.successMessage);
          dispatch(getVccIncidentRequest(params.incid!));
          dispatch(getVccIncidentNotesRequest(params.incid!));
          break;
        case IncidentStoreSuccess.NOTES_SAVE:
          setSuccessMessage(incidentStore.successMessage);
          dispatch(getVccIncidentNotesRequest(params.incid!));
          break;
      }
    } else if (incidentStore.failureMessage) {
      switch (incidentStore.failureMessage) {
        case IncidentStoreError.VCC_INCIDENT_OVERWRITE_ERROR:
          setIsOverwriteError(true);
          break;
        case IncidentStoreError.VCC_INCIDENT_GET:
        case IncidentStoreError.VCC_INCIDENTS_GET:
        case IncidentStoreError.VCC_NOTES_GET:
          setErrorMessage(incidentStore.failureMessage);
          setIsOverwriteError(false);
          break;
        case IncidentStoreError.VCC_INCIDENT_UPDATE:
          setErrorMessage(incidentStore.failureMessage);
          setIsOverwriteError(false);
          dispatch(getVccIncidentRequest(params.incid!));
          dispatch(getVccIncidentNotesRequest(params.incid!));
          break;
      }
    } else {
      setIsOverwriteError(false);
    }
  }, [
    dispatch,
    params.incid,
    incidentStore.successMessage,
    incidentStore.failureMessage,
  ]);

  // Traffic cam layer
  useEffect(() => {
    dispatch(getTrafficCamLayerRequest());
  }, [dispatch]);

  useEffect(() => {
    if (incidentStore.successMessage) {
      switch (incidentStore.successMessage) {
        case IncidentStoreSuccess.COLLAB_ACTION_SAVE:
        case IncidentStoreSuccess.COLLAB_ACTION_MODIFY:
          setSuccessMessage(incidentStore.successMessage);
          dispatch(getVccIncidentActionsRequest(params.incid!));
          break;
      }
    }
  }, [dispatch, params.incid, incidentStore.successMessage]);

  useEffect(() => {
    if (incidentStore.failureMessage) {
      switch (incidentStore.failureMessage) {
        case IncidentStoreError.VCC_INCIDENT_ACTIONS_SAVE:
        case IncidentStoreError.VCC_INCIDENT_ACTIONS_GET:
        case IncidentStoreError.VCC_INCIDENT_ACTIONS_MODIFY:
          setErrorMessage(incidentStore.failureMessage);
          dispatch(getVccIncidentActionsRequest(params.incid!));
          break;
      }
    }
  }, [dispatch, incidentStore.failureMessage, params.incid]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    // Check for edits before switching between tabs
    if (isEdit) {
      const res = window.confirm("You may have unsaved changes, do you want to switch tabs?");
      if (res === true) {
        setTabIndex(newValue);
        setIsEdit(false);
      }
    } else {
      setTabIndex(newValue);
    }
  };

  const handleSaveIncident = (
    vccIncident: VccIncidentModel,
    associatedIds: UpdateIncidentDispatchRequest[]
  ) => {
    dispatch(updateVccIncident(vccIncident, associatedIds));
    if (
      vccIncident.status === IncidentStatus.CLOSED ||
      vccIncident.status === IncidentStatus.DELETED
    )
    navigate("/dashboard");
  };

  const handleGetLatestIncInfo = () => {
    dispatch(getVccIncidentRequest(params.incid!));
    dispatch(getVccIncidentNotesRequest(params.incid!));
  };

  const handleOpenModify = (incidentAction: IncidentAction) => {
    dispatch(
      getVccIncidentActionRequest(
        params.incid!,
        incidentAction.id,
        incidentAction.timeAdded
      )
    );
    setModifyActionOpen(true);
  };

  const handleMarkerOnClick = (markerType: string, markerId: string): void => {
    awsRum.recordEvent("incident-model-map-marker-click", {
      markerType: markerType,
      markerId: markerId
    });

    switch (markerType) {
      case MarkerTypes.DISPATCH:
        dispatch(getDispatchFeedEventRequest(markerId));
        setIdfDetailsOpen(true);
        break;
      case MarkerTypes.INCIDENT:
        navigate(`/incident-model/${markerId}`);
        break;
    }
  };

  // Format map marker data
  const markerDataSets: MarkerData[] = [
    {
      category: MapLayers.DISPATCH_EVENTS,
      geoData: mapStore.dispatchGeo,
      color: customTheme.marker.DispatchEvent!,
    },
    {
      category: MapLayers.VCC_INCIDENTS,
      geoData: mapStore.incidentGeo,
      color: customTheme.marker.ActiveIncident!,
    },
    {
      category: MapLayers.ASSOC_DISPATCH_EVENTS,
      geoData: mapStore.associatedDispatchGeo,
      color: customTheme.marker.SelectedIncidentDispatches!,
    },
    {
      category: MapLayers.CURRENT_INC,
      geoData: mapStore.currentIncidentGeo,
      color: customTheme.marker.SelectedIncident!,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Box m={1} mb={1}>
            <VccMap 
              props={{
                markerData: markerDataSets,
                initLayers: [MapLayers.ALL_ANNOTATIONS],
                initMarkerSets:[MapLayers.CURRENT_INC, MapLayers.ASSOC_DISPATCH_EVENTS],
                allAvailableLayers: [MapLayers.ALL_ANNOTATIONS, MapLayers.CAMERA_LAYER, MapLayers.TRAFFIC_LAYER, MapLayers.HIGHWAY_ALERTS_DATA],
                allAvailableMarkerSets: [MapLayers.CURRENT_INC, MapLayers.ASSOC_DISPATCH_EVENTS, MapLayers.DISPATCH_EVENTS, MapLayers.VCC_INCIDENTS],
                incId: params.incid,
                showAnnotateTools: (incidentStore.incident.status !== IncidentStatus.CLOSED && incidentStore.incident.status !== IncidentStatus.DELETED ),
                markerOnClick: handleMarkerOnClick
              }}
            />
          </Box>
        </Grid>
        <Grid item container xs={12} md={7} style={{ height: "71vh" }}>
          <Grid item xs={12} md={12}>
            <IncidentSummaryAccordion props={{vccIncident: incidentStore.incident}} />
          </Grid>
          <Grid item xs={12} md={12} mb={1} style={{ height: "100%" }}>
            <Box ml={1} mr={1} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                aria-label="Incident-details-tabs"
              >
                <Tab
                  label="Details"
                  data-rum-id={`details-tab__${params.incid}`}
                  {...a11yProps(IncidentTab.DETAILS_TAB)}
                />
                <Tab
                  label={"Notes (" + notesCount + ")"}
                  data-rum-id={`notes-tab__${params.incid}`}
                  {...a11yProps(IncidentTab.NOTES_TAB)}
                />
                <Tab
                  label={"Mobility Strategies (" + mobilityStrategyCount + ")"}
                  data-rum-id={`mobility-strategies-tab__${params.incid}`}
                  {...a11yProps(IncidentTab.MOBILITY_STRATEGIES_TAB)}
                />
                <Tab
                  label={"Public Information Hub (" + keyMessagesCount + ")"}
                  data-rum-id={`public-information-hub-tab__${params.incid}`}
                  {...a11yProps(IncidentTab.KEY_MESSAGES_TAB)}
                />
              </Tabs>
            </Box>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", overflow: "auto" }}
              style={{ height: "100%" }}
            >
              <TabPanel value={tabIndex} index={IncidentTab.DETAILS_TAB}>
                <Grid item xs={12} id="incident-detail" style={{ height: "50%" }}>
                  <VccIncidentDetail
                    props={{
                      vccIncident: incidentStore.incident,
                      currentUserEmail: authState.email ?? "",
                      isIncUpdateAvailable: incidentStore.isIncUpdateAvailable,
                      showOverwriteError: isOverwriteError,
                      handleIsEdit: (edit) => setIsEdit(edit),
                      handleSaveIncident: handleSaveIncident,
                      handleGetLatestIncInfo: handleGetLatestIncInfo,
                    }}
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <Grid item xs={12} id="notes" style={{ height: "50%" }}>
                  <IncidentNotes props={{
                      currentUser: authState.email ?? "", 
                      isEditEnabled: isIncidentEditable,
                      handleIsEdit: (edit) => setIsEdit(edit),
                    }} 
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <Grid item xs={12} id="mobility-strategies-grid" style={{ height: "68vh" }}>
                  <ActionGrid
                    props={{
                      handleModify: handleOpenModify,
                      isEditEnabled: isIncidentEditable
                    }}
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                <Grid item xs={12} id="key-strategies" style={{ height: "68vh" }}>
                  <PioTab props={{
                      incidentId: params.incid!,
                      isEditEnabled: isIncidentEditable
                    }}
                  />
                </Grid>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <ModifyActionModal
        props={{
          isOpen: modifyActionOpen,
          incidentId: incidentStore.incident.id,
          handleClose: () => setModifyActionOpen(false),
        }}
      />
      <AdditionalDetailsModal
        props={{
          isOpen: idfDetailsOpen,
          idfEvent: eventStore.dispatchEvent,
          handleClose: () => setIdfDetailsOpen(false),
        }}
      />
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={7000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}: {ShortIdUtil(incidentStore.incident.id)}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}: {ShortIdUtil(incidentStore.incident.id)}
        </Alert>
      </Snackbar>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}