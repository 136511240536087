export enum MapStoreError {
    SET_VCC_INCIDENT_GEO = "Error formatting VCC Incident map data",
    SET_CURRENT_VCC_INCIDENT_GEO = "Error formating current VCC Incident map data",
    SET_DISPATCH_GEO = "Error formatting dispatch event map data",
    SET_SELECTED_IDS = "Error formating selected id event map data",
    SET_ASSOCIATED_DISPATCH_GEO = "Error formating associated dispatch event map data",
    CREATE_MAP_ANNOTATION = "Error creating new map annotation",
    UPDATE_MAP_ANNOTATION = "Error updating map annotation",
    DELETE_MAP_ANNOTATION = "Error removing map annotation",
    GET_MAP_ANNOTATIONS = "Error retrieving map annotations",
    GET_TRAVELER_INFORMATION_ALERT_DATA = "Error retrieving Traveler Information Data",
    GET_AGENCY_DEFAULT_VIEW_FAILURE = "Error retrieving Default View",

  }
  
export enum MapStoreSuccess {
  CREATE_MAP_ANNOTATION = "Successfully saved new map annotation",
  UPDATE_MAP_ANNOTATION = "Successfully updated map annotation",
  DELETE_MAP_ANNOTATION = "Successfully removed map annotation",
}