import {
    Box,
    Button,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Typography,
  } from "@mui/material";
  import { makeStyles } from "@mui/styles";
  import React, { FC, useEffect, useState } from "react";
import { MapLayers } from "../../../enums/map";
  
const useStyles = makeStyles(() => ({
	legendContainer: {
			display: "flex",
			justifyContent: "center",
			bottom: "0",
			position: "absolute",
			marginBottom: "75px",
			zIndex: 1,
		},
}));
  
interface AnnotationControlPanelProps {
	props: {
            incId?: string;
			drawnFeatures: GeoJSON.Feature[];
			isDoneSaving: boolean;
			handleAddPerimeter: () => void;
			handleAddDetour: () => void;
			handleAddBlockage: () => void;
			handleSave: () => void;
			handleEdit: () => void;
			handleCancel: () => void;
	};
}

const AnnotationControlPanel: FC<AnnotationControlPanelProps> = ({ props }) => {
    const classes = useStyles();
    const [activeInput, setActiveInput] = useState("");
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [cancelDisabled, setCanceledDisabled] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if(props.drawnFeatures.length > 0){
            setSaveDisabled(false);
            setCanceledDisabled(false);
        }
        else{
            setSaveDisabled(true);
            setCanceledDisabled(true);
        }
    }, [props.drawnFeatures])

    useEffect(() => {
        setIsSaving(false);
    }, [props.isDoneSaving])

    const handleAnnotation = (e: any) => {
        setActiveInput(e.target.id);

        switch(e.target.id){
            case MapLayers.ANNOTATIONS_PERIMETER:
                props.handleAddPerimeter();
                break;
            case MapLayers.ANNOTATIONS_DETOUR:
                props.handleAddDetour();
                break;
            case MapLayers.ANNOTATIONS_BLOCKAGE:
                props.handleAddBlockage();
                break;
        }
    }

    const handleCancel = () => {
        setActiveInput("");
        setCanceledDisabled(true);
        props.handleCancel();
    }

    const handleSave = () => {
        setIsSaving(true);
        setActiveInput("");
        setSaveDisabled(true);
        props.handleSave();
    }

    return (
      <>
        <Box component={Paper} m={1} mb={6} ml={7} className={classes.legendContainer}>
            <Grid container>
                {isSaving && (
                    <Grid item xs={12}>
                        <Box m={1}><LinearProgress /></Box>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box m={1}><Typography variant={"h6"}>Add Map Annotations</Typography></Box>
                </Grid>
                <Grid container item xs={12} md={12} lg={12} xl={6}>
                    <Grid item xs={12}>
                        <Box m={1}><Typography variant="overline">Scene Annotations</Typography></Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box m={1}>
                            <Button
                                id={MapLayers.ANNOTATIONS_PERIMETER}
                                variant="contained"
                                size="small"
                                color={activeInput === MapLayers.ANNOTATIONS_PERIMETER ? 'primary' : 'inherit'}
                                onClick={handleAnnotation}
                                data-rum-id={`annotate-map-perimeter__${props.incId}`}
                            >
                                Add Perimeter
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={12} lg={12} xl={6}>
                    <Grid item xs={12}>
                        <Box m={1}><Typography variant="overline">Traffic Annotations</Typography></Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box m={1}>
                            <Button
                                id={MapLayers.ANNOTATIONS_DETOUR}
                                variant="contained"
                                size="small"
                                color={activeInput === MapLayers.ANNOTATIONS_DETOUR ? 'primary' : 'inherit'}
                                onClick={handleAnnotation}
                                data-rum-id={`annotate-map-detour__${props.incId}`}
                            >
                                Draw Detour
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box m={1}>
                            <Button
                                id={MapLayers.ANNOTATIONS_BLOCKAGE}
                                variant="contained"
                                size="small"
                                color={activeInput === MapLayers.ANNOTATIONS_BLOCKAGE ? 'primary' : 'inherit'}
                                onClick={handleAnnotation}
                                data-rum-id={`annotate-map-blockage__${props.incId}`}
                            >
                                Draw Closure
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box m={1}><Divider /></Box>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end">
                    <Grid item >
                        <Box m={1}>
                            <Button 
                                variant="contained"
                                color='inherit' 
                                size="small"
                                onClick={props.handleEdit}
                                data-rum-id={`annotate-map-edit__${props.incId}`}
                            >
                                Edit
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item >
                        <Box m={1}>
                            <Button
                                variant="contained"
                                color='inherit'
                                size="small"
                                disabled={cancelDisabled}
                                onClick={handleCancel}
                                data-rum-id={`annotate-map-cancel__${props.incId}`}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item >
                        <Box m={1}>
                            <Button
                                variant="contained"
                                size="small"
                                disabled={saveDisabled}
                                onClick={handleSave}
                                data-rum-id={`annotate-map-save__${props.incId}`}
                            >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
      </>
    );
  };
  
  export default AnnotationControlPanel;
  