import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Help } from "@mui/icons-material";
import React, { FC, useEffect, useState } from "react";
import DashboardOverview from "./dash-overview";
import IDFOverview from "./idf-overview";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  closeButton: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}));

const Overview: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [showIcon, setShowIcon] = useState(true);
  const [openOverview, setOpenOverview] = useState(false);

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
      case "/feed":
        setShowIcon(true);
        break;
      default:
        setShowIcon(false);
    }
  }, [location.pathname]);

  function showOverview() {
    switch (location.pathname) {
      case "/dashboard":
        return <DashboardOverview />;
      case "/feed":
        return <IDFOverview />;
    }
  }

  return (
    <>
      {showIcon && (
        <IconButton
          onClick={() => setOpenOverview((prevOpen) => !prevOpen)}
          size="large"
        >
          <Help style={{ color: "white" }} />
          <Drawer
            anchor="right"
            open={openOverview}
            onClose={() => setOpenOverview((prevOpen) => !prevOpen)}
          >
            <Box maxWidth={"350px"}>{showOverview()}</Box>
            <IconButton
              disableRipple
              className={classes.closeButton}
              size="large"
            >
              <Tooltip title="Close Help pane">
                <ChevronRightIcon />
              </Tooltip>
            </IconButton>
          </Drawer>
        </IconButton>
      )}
    </>
  );
};

export default Overview;
