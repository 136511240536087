import { AnyAction } from "redux";

import { types } from "../../actions";
import { IGeoJsonState } from "../interfaces";

export const initialGeoState: IGeoJsonState = {
  geoJson: {} as GeoJSON.FeatureCollection<GeoJSON.Geometry>,
  getGeoJsonFailureMessage: "",
};

function geoJsonReducer(
  state = initialGeoState,
  action: AnyAction
): IGeoJsonState {
  switch (action.type) {
    case types.GET_TRAFFIC_CAM_GEO_JSON_SUCCESS:
      return {
        ...state,
        geoJson: action.payload.geoJson,
        getGeoJsonFailureMessage: "",
      };
    case types.GET_TRAFFIC_CAM_GEO_JSON_FAILURE:
      return {
        ...state,
        getGeoJsonFailureMessage: action.payload.getGeoJsonFailureMessage,
      };
    default:
      return state;
  }
}

export default geoJsonReducer;
