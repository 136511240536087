import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoRequest } from "../../actions";
import { IRootReducer } from "../../reducers";
import { UserInfoModel } from "../../reducers/states/user-info";

function useUserInfo() {
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState({} as UserInfoModel);
  const store = useSelector((state: IRootReducer) => state.userInfoReducer);

  useEffect(() => {
    if (userEmail && userEmail !== "SYSTEM" && store.userInfo.email?.toLocaleLowerCase() === userEmail.toLocaleLowerCase()){
      setUserInfo(store.userInfo);
    }
  }, [store.userInfo, userEmail]);

 const fetchUser = (email: string) => {
   if (email) {
     if (email !== "SYSTEM") {
       let cachedUser = store.userCache.find((u) => u.email?.toLowerCase() === email.toLowerCase());
       if (cachedUser) {
         setUserInfo(cachedUser);
       } else {
         // Update the current username and dispatch to get the full details
         setUserEmail(email);
         dispatch(getUserInfoRequest(email));

         // In between response from the dispatch, set email passed in until more details are retrieved
         setUserInfo({ email: email, userCreateDate: 0 } as UserInfoModel);
       }
     } else {
       setUserInfo({ email: email, userCreateDate: 0 } as UserInfoModel);
     }
   }
 };

  return [userInfo, fetchUser] as const;
}

export default useUserInfo;
