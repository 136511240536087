import React, { FC } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  redirectBox: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  explanationBox: {
    overflow: "hidden",
  },
  redirectButton: {
    float: "right",
  },
}));

interface RedirectProps {
  props: {
    Title: string;
    Explanation: string;
    RedirectText: string;
    RedirectTo: string;
  };
}

const RedirectPrompt: FC<RedirectProps> = ({ props }) => {
  const classes = useStyles();

  return (
    <Box width="40%" className={classes.redirectBox}>
      <Typography variant="h3">{props.Title}</Typography>
      <Paper>
        <Box p={1} className={classes.explanationBox}>
          <Typography variant="h6">{props.Explanation}</Typography>
          <Button
            className={classes.redirectButton}
            component={Link}
            to={"/" + props.RedirectTo}
            variant="contained"
            color="primary"
          >
            {props.RedirectText}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default RedirectPrompt;
