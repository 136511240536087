import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDispatchFeed,
  initFeedView,
  initShowOpenOnly,
  initTimeFrame,
  setFeedViewRequest,
  setShowOpenOnlyRequest,
  setTimeFrameRequest,
  initFeedAreaAndRegion,
} from "../../../../actions";
import { DispatchEventFeedModel, IRootReducer } from "../../../../reducers";
import CreateIncident from "../../../incident-model/create-incident-model";
import MoreActionsMenu from "../../more-actions-menu";
import PinEventModal from "../../pin-event-modal";
import AgencyFilters from "../agency-filters";

interface IDFGridHeaderProps {
  props: {
    mostRecentSelectedEvent: DispatchEventFeedModel;
    selectedIDFEvents: DispatchEventFeedModel[];
    selectedPinnedEvents: DispatchEventFeedModel[];
    isDashDisplay?: boolean;
    agencyFilters: string[];
    handleAgencySelect: (event: ChangeEvent<HTMLInputElement>) => void;
  };
}

const IDFGridHeader: FC<IDFGridHeaderProps> = ({ props }) => {
  const dispatch = useDispatch();
  const [currentDatetime, setCurrentDateTime] = useState(
    moment().format("LL, HH:mm:ss")
  );


  const timeFrame = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.timeFrame
  );
  const feedView = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.feedView
  );
  const showOpenDispatchesOnly = useSelector(
    (state: IRootReducer) =>
      state.dispatchEventFeedReducer.showOpenDispatchesOnly
  );

  // Init Filter Options
  useEffect(() => {
    dispatch(initTimeFrame());
    dispatch(initFeedView());
    dispatch(initShowOpenOnly());
    dispatch(initFeedAreaAndRegion());
  }, [dispatch]);

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentDateTime(moment().format("LL, HH:mm:ss"));
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);


  const handleTimeChange = (event: SelectChangeEvent<number>) => {
    const newTimeFrame = event.target.value as number;
    dispatch(setTimeFrameRequest(newTimeFrame));
  };

  const handleViewChange = (event: SelectChangeEvent<string>) => {
    const newView = event.target.value as string;
    dispatch(clearDispatchFeed());
    dispatch(setFeedViewRequest(newView));
  };

  const handleShowOpenOnly = (event: any) => {
    dispatch(setShowOpenOnlyRequest(event.target.checked));
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={12}>
            <Box m={1}>
              <Typography variant="subtitle1">{currentDatetime}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <Grid item>
            <Box m={1}>
              <CreateIncident
                props={{
                  mostRecentSelectedEvent: props.mostRecentSelectedEvent,
                  selectedEvents: [...props.selectedIDFEvents, ...props.selectedPinnedEvents]
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box m={1}>
              <PinEventModal
                props={{
                  selectedIDFEvents: props.selectedIDFEvents,
                  selectedPinnedEvents: props.selectedPinnedEvents,
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box pl={1}>
              <MoreActionsMenu
                props={{
                  isDashDisplay: props.isDashDisplay,
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <AgencyFilters
            props={{
              agencyFilters: props.agencyFilters,
              handleAgencySelect: props.handleAgencySelect,
            }}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Displays only open dispatch events when toggled">
            <Box ml={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Open Only"
                    checked={showOpenDispatchesOnly}
                    onChange={handleShowOpenOnly}
                    checkedIcon={<Chip color={"primary"} variant="filled" label="Open Only"/>} 
                    icon={
                      <Chip
                        variant="outlined"
                        label="Open Only"
                      />
                    }
                  />
                }
                label=""
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item>
          <Box display="flex">
            <Grid container>
              <Grid item>
                <Box m={1}><Typography variant="body1">Time Frame: </Typography></Box>
              </Grid>
            </Grid>
            <Grid item>
              <Box display="flex" mt={.5}>
                <FormControl>
                  <Select
                    value={timeFrame}
                    onChange={handleTimeChange}
                    variant="standard"
                  >
                    <MenuItem value={5} data-rum-id="timeframe-5min">5 minutes</MenuItem>
                    <MenuItem value={10} data-rum-id="timeframe-10min">10 minutes</MenuItem>
                    <MenuItem value={30} data-rum-id="timeframe-30min">30 minutes</MenuItem>
                    <MenuItem value={60} data-rum-id="timeframe-1hr">1 hour</MenuItem>
                    <MenuItem value={120} data-rum-id="timeframe-2hr">2 hours</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box ml={1} display="flex">
            <Grid container>
              <Grid item>
                <Box m={1}><Typography variant="body1">View: </Typography></Box>
              </Grid>
            </Grid>
            <Grid item>
            <Box display="flex" mt={.5}>
                <FormControl>
                  <Select
                    value={feedView}
                    onChange={handleViewChange}
                    variant="standard"
                  >
                    <MenuItem data-rum-id="feed-view-all" value={"All"}>All</MenuItem>
                    <MenuItem data-rum-id="feed-view-noteworthy" value={"Noteworthy"}>Noteworthy</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default IDFGridHeader;
