import {
  Alert,
  Box,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import UpdatePasswordModal from "../forgot-password";
import EditNotificationPreferences from "../edit-notifications";
import { useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { UserInfoStoreError, UserInfoStoreSuccess } from "../../../enums/store-messages/user-info";

const ProfileActions: FC = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const store = useSelector(
    (state: IRootReducer) => state.userInfoReducer
  );

  useEffect(() => {
    switch (store.successMessage){
      case UserInfoStoreSuccess.UPDATE_USER_READYOP_GROUPS:
        setSuccessMessage(store.successMessage);
        break;
    }
  }, [store.successMessage]);

  useEffect(()=> {
    switch(store.failureMessage){
      case UserInfoStoreError.GET_USER_INFO:
      case UserInfoStoreError.GET_ALL_READYOP_GROUPS:
      case UserInfoStoreError.GET_USER_READYOP_GROUPS:
      case UserInfoStoreError.UPDATE_USER_READYOP_GROUPS:
        setErrorMessage(store.failureMessage);
        break;  
    }
  }, [store.failureMessage])

  return (
    <>
      <Box mt={1}>
        <Paper variant="outlined">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Box mt={1} ml={1}>
                <Typography variant={"subtitle1"}>Profile Actions</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={1}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                m={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <UpdatePasswordModal />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                m={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <EditNotificationPreferences />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                m={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography>
                  To request support, please email:{" "}
                  <a href="mailto:vccs@wsdot.wa.gov" target="_top">
                    vccs@wsdot.wa.gov
                  </a>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={1}>
                <Divider />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfileActions;
