import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../../../reducers";
import { createAndInviteUserRequest } from "../../../../actions";
import { UserGroups } from "../../../../enums/user-groups";
import {
  CleanPhoneNumberUtil,
  ValidateEmailUtil,
  ValidatePhoneNumberUtil,
} from "../../../../utils/string-utils";

import { useAuthContext } from "../../../../core/auth-context";


interface InviteUserProps {
  props: {
    setInvitedEmail: (invitedEmail: string) => void;
  };
}

const InviteUser: FC<InviteUserProps> = ({ props }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [agency, setAgency] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userRoles, setUserRoles] = useState([UserGroups.VCC_BASIC as string]);
  const [inviteBtnDisabled, setInviteBtnDisabled] = useState(true);
  const { authState } = useAuthContext();
  const [agencyReadonly, setAgencyReadOnly] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state: IRootReducer) => state.adminReducer);


  useEffect(() => {
    if (
      email !== "" &&
      phoneNumber !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      agency !== ""
    ) {
      if (ValidateEmailUtil(email) && ValidatePhoneNumberUtil(phoneNumber)) {
        setInviteBtnDisabled(false);
      } else {
        setInviteBtnDisabled(true);
      }
    } else if (
      email !== "" &&
      phoneNumber === "" &&
      firstName !== "" &&
      lastName !== "" &&
      agency !== ""
    ) {
      if (ValidateEmailUtil(email)) {
        setInviteBtnDisabled(false);
      } else {
        setInviteBtnDisabled(true);
      }
    } else {
      setInviteBtnDisabled(true);
    }
  }, [agency, email, firstName, lastName, phoneNumber]);

  const handleOpen = () => {

    // console.log(store);

    // console.log(authState);
    // // console.log(authState.userGroups.toString());

    if(authState.userGroups.toString().includes("vcc-agency-user-admin") && !authState.userGroups.toString().includes("vcc-site-admin"))
    {
      setAgency(store.userAgency);
      setAgencyReadOnly(true);
    }


    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setEmail("");
    setAgency("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setAgencyReadOnly(false);

  };

  const handleInviteAndClose = () => {
    let cleanNumber = phoneNumber;
    if (phoneNumber !== "") {
      cleanNumber = CleanPhoneNumberUtil(phoneNumber);
    }


    dispatch(
      createAndInviteUserRequest(
        email,
        firstName,
        lastName,
        cleanNumber,
        agency,
        userRoles,
        (authState.email?? "").toLowerCase(),
      )
    );

    props.setInvitedEmail(email);
    setOpen(false);
  };

  const handleSelectAgency = (event: SelectChangeEvent<string>) => {
    const newAgency = event.target.value as string;
    setAgency(newAgency);
  };

  const handleRoleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    var newRoles = [...userRoles];
    if (
      event.target.checked === true &&
      newRoles.indexOf(event.target.id) === -1
    ) {
      newRoles.push(event.target.id);
    } else {
      newRoles = newRoles.filter((r) => r !== event.target.id);
    }

    setUserRoles(newRoles);
  };

  return (
    <>
      <Tooltip title="Invite a new user to the VCC">
        <Button
          variant="contained"
          color="inherit"
          onClick={handleOpen}
          style={{ minWidth: "150px", color: "black" }}
        >
          Invite User
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Invite User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter an email and user information to invite a new user to the VCC
          </DialogContentText>
          <TextField
            autoFocus
            required
            variant="outlined"
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            variant="outlined"
            margin="dense"
            id="firstName"
            label="First Name"
            type="string"
            fullWidth
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            required
            variant="outlined"
            margin="dense"
            id="lastName"
            label="Last Name"
            type="string"
            fullWidth
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="phoneNumber"
            label="Phone Number"
            type="phoneNumber"
            fullWidth
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <FormControl required fullWidth variant="outlined" margin="dense">
            <InputLabel>Agency</InputLabel>
            <Select
              fullWidth
              value={agency}
              readOnly={agencyReadonly}
              onChange={handleSelectAgency}
              label="Agency"
            >
              <MenuItem value={"KCM"}>KCM</MenuItem>
              <MenuItem value={"NWSA"}>NWSA</MenuItem>
              <MenuItem value={"Port"}>Port</MenuItem>
              <MenuItem value={"SDOT"}>SDOT</MenuItem>
              <MenuItem value={"SFD"}>SFD</MenuItem>
              <MenuItem value={"SPD"}>SPD</MenuItem>
              <MenuItem value={"ST"}>ST</MenuItem>
              <MenuItem value={"WSDOT"}>WSDOT</MenuItem>
              <MenuItem value={"WSP"}>WSP</MenuItem>
              <MenuItem value={"WSP-North"}>WSP-North</MenuItem>
            </Select>
          </FormControl>
          <Box m={1}>
            <Divider />
          </Box>
          <Box m={1}>
            <FormGroup>
              <InputLabel>User Roles</InputLabel>
              <Grid container>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        id={UserGroups.VCC_BASIC}
                      />
                    }
                    label="Basic User"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows viewing dashboard, feed, collaboration spaces, and open incident models pages. Also allows viewing all map layers, details of other users, and all notifications. Allows updating the user's own profile and notification preferences.">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={UserGroups.INC_CONTRIBUTOR}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Incident Contributor"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows user to add notes to Incident Models">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={UserGroups.INC_MANAGER}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Incident Manager"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to create, edit, and close incidents. Allows for viewing and re-opening closed incidents, but not deleted incidents. If a user is set as an Incident Manager, they should also be set as an Incident Contributor as well.">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={UserGroups.INC_RECORDS_MANAGER}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Incident Records Manager"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to view Incident Model Records Management page, to view and finalize reports">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          userRoles.indexOf(UserGroups.PIO) >
                          -1
                        }
                        id={UserGroups.PIO}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Public Information Officer"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to view Incident Model Records Management page, to view and finalize reports">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={UserGroups.AGENCY_ADMIN}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Agency User Administrator"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to view Admin page, add and edit any user, and update user roles">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
          <DialogActions>
            <Button onClick={handleCancel} color="inherit" variant="contained">
              Cancel
            </Button>
            <Button
              disabled={inviteBtnDisabled}
              onClick={handleInviteAndClose}
              color="primary"
              variant="contained"
            >
              Invite
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteUser;
