import { Box, Divider, Grid, Paper, Tooltip,ListItem, Typography } from "@mui/material";
import React from "react";
import { FC, useEffect, useState } from "react";
import { UserModel} from "../../../reducers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

  interface UserProps {
    props: {
      userSelection: UserModel;
      userGroups: string[]
    };
  }

  const UserGroups: FC<UserProps> = ({ props }) => {
    const [userGroups, setUserGroups] = useState([] as string[])

    useEffect(() => {
      setUserGroups(props.userGroups);
    }, [props.userGroups]);
  
    function getDescription(role: string){
        switch(role){
          case "vcc-inc-mgr":
            return "Incident Manager";
          case "vcc-site-admin":
            return "Site Administrator";
          case "vcc-basic":
            return "Basic User";
          case "vcc-inc-pio":
            return "Public Information Officer";
          case "vcc-records-mgr":
            return "Incident Records Manager";
          case "vcc-inc-contrib":
            return "Incident Contributor";
          default:
            return "temp"
        }
    }
    function getToolTip(role: string){
      switch(role){
        case "vcc-inc-mgr":
          return "Allows users to create, edit, close incidents, and create and edit mobility strategies. Allows for viewing and re-opening closed incidents, but not deleted incidents. If a user is set as an Incident Manager, they should also be set as an Incident Contributor as well.";
        case "vcc-site-admin":
          return "Allows users to view Admin page, add and edit any user, and update user roles.";
        case "vcc-basic":
          return "Allows viewing dashboard, feed, collaboration spaces, and open incident models pages. Also allows viewing all map layers, details of other users, and all notifications. Allows updating the user's own profile and notification preferences.";
        case "vcc-inc-pio":
          return "Allows users to create, edit, close Public Information Officer actions Recommended to set PIO's as Incident Managers as well.";
        case "vcc-records-mgr":
          return "Allows users to view Incident Model Records Management page, to view and finalize reports.";
        case "vcc-inc-contrib":
          return "Allows user to add notes to Incident Models.";
        default:
          return ""
      }
  }

    return (
      <>
        <Box mt={1}>
        <Paper variant="outlined">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Box mt={1} ml={1} mb={0.5}>
                <Typography variant={"subtitle1"}>Roles and Permissions</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={1}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                m={0}
                display="flex"
                flexDirection="column"
                
              >
                {userGroups &&
                  userGroups.map((group: string, index) => (
                        <Box key={group}>
                          <ListItem>
                            <Grid item xs={11}>
                              <Typography key={group} variant="subtitle2" style={{ alignItems:"left", padding: 1}}>
                                {getDescription(group)}
                              </Typography>
                            </Grid>
                            
                            <Grid item xs={1}>
                              <Tooltip  title={getToolTip(group)}>
                              <InfoOutlinedIcon style={{alignItems:"right"}}color="action" />
                              </Tooltip>
                            </Grid>
                            
                          </ListItem>
                        </Box>
                  ))}
                </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      </>
    );
  };
  
  export default UserGroups;
  