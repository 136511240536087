import { AnyAction } from "redux";
import { IAllUserInfoState } from "..";
import { types } from "../../actions";
import { AllUserInfoModel } from "../states/all-user-info";

// Review: This redux flow should be merged in with Admin and UserInfo
export const initialAllUserInfoState: IAllUserInfoState = {
  allUserInfo: [] as AllUserInfoModel[],
  getAllUserInfoFailureMessage: "",
};

function allUserInfoReducer(
  state = initialAllUserInfoState,
  action: AnyAction
): IAllUserInfoState {
  switch (action.type) {
    case types.GET_ALL_USER_INFO_SUCCESS:
      return {
        ...state,
        allUserInfo: action.payload.allUserInfo,
      };
    case types.GET_ALL_USER_INFO_FAILURE:
      return {
        ...state,
        getAllUserInfoFailureMessage: action.payload.getAllUserInfoFailureMessage,
      };
    default:
      return state;
  }
}

export default allUserInfoReducer;
