import { Box, Grid } from "@mui/material";
import React, { FC } from "react";
import FactorChip from "../../../incident-factor/factor-chip";

interface IncidentCardFactorProps {
  props: {
    selectedIncFactors: string[];
  };
}

const IncidentCardFactors: FC<IncidentCardFactorProps> = ({ props }) => {
  function displayFactors() {
    if (props.selectedIncFactors?.length) {
      return (
        <>
          {props.selectedIncFactors.slice(0, 2).map((factor, index) => (
            <Grid item xs key={factor + index}>
              <FactorChip
                props={{
                  label: factor,
                  isSelected: true,
                }}
              />
            </Grid>
          ))}
          {props.selectedIncFactors?.length - 2 > 0 && (
            <Grid item xs>
              <Box>+{props.selectedIncFactors?.length - 2}</Box>
            </Grid>
          )}
        </>
      );
    } else {
      return (
        <Grid item xs>
          <Box
            display="flex"
            justifyContent="center"
            mt={1}
            fontStyle="italic"
            fontSize="12px"
          >
            No Factors
          </Box>
        </Grid>
      );
    }
  }

  return (
    <>
      <Grid container direction="row" alignItems="center">
        {displayFactors()}
      </Grid>
    </>
  );
};

export default IncidentCardFactors;
