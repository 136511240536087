import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Popper,
} from "@mui/material";
import MenuList from "@mui/material/MenuList";
import { AccountCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import React, { FC, useRef, useState, useEffect } from "react";
import useUserPermissions from "../../../hooks/user-permissions";
import { RoleAction } from "../../../enums/permission-actions";

interface ProfileMenuProps {
  props: {
    isAuthenticated: boolean;
    handleLogout: () => void;
  };
}

const ProfileMenu: FC<ProfileMenuProps> = ({ props }) => {
  const [openUserManagement, setOpenUserManagement] = useState(false);
  const [viewAdmin] = useUserPermissions(RoleAction.VIEW_ADMIN);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(openUserManagement);

  useEffect(() => {
    if (prevOpen.current === true && openUserManagement === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = openUserManagement;
  }, [openUserManagement]);

  const handleToggle = () => {
    setOpenUserManagement((prevOpen) => !prevOpen);
  };

  const handleClose = (event: TouchEvent | globalThis.MouseEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenUserManagement(false);
  };

  const handleOnClickClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenUserManagement(false);
  };

  return (
    <>
      {props.isAuthenticated && (
        <IconButton
          ref={anchorRef}
          edge="start"
          aria-label="menu"
          onClick={handleToggle}
          size="large"
        >
          <AccountCircle style={{ color: "white" }} />
          <Popper
            style={{ zIndex: 2500 }}
            open={openUserManagement}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={openUserManagement}
                      id="menu-list-grow"
                    >
                      {!viewAdmin && (
                        <MenuItem
                          component={Link}
                          to={"/admin"}
                          onClick={handleOnClickClose}
                        >
                          Admin
                        </MenuItem>
                      )}
                      <MenuItem
                        component={Link}
                        to={"/user-profile"}
                        onClick={handleOnClickClose}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/login"}
                        onClick={props.handleLogout}
                      >
                        Sign Out
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </IconButton>
      )}
    </>
  );
};

export default ProfileMenu;
