import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  CleanPhoneNumberUtil,
  ParsePhoneNumberUtil,
  ValidatePhoneNumberUtil,
} from "../../../utils/string-utils";
import moment from "moment";
import { UserModel } from "../../../reducers";

interface ProfileDetailsProps {
  props: {
    user: UserModel;
    userCreateDate: string;
    updateUserProfile: (updatedUserInfo: UserModel) => void;
  };
}

// Review: This needs to be updated to a new route locked down to indivual to a user's cognitoUsername
  // For now, instituting work around of having user's email an admin to be updated on the admin page
const ProfileDetails: FC<ProfileDetailsProps> = ({ props }) => {
  const [userDetails, setUserDetails] = useState({} as UserModel);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    setUserDetails(props.user);
  }, [props.user]);

  useEffect(() => {
    if (userDetails.phoneNumber) {
      ValidatePhoneNumberUtil(userDetails.phoneNumber)
        ? setIsSaveDisabled(false)
        : setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [userDetails.phoneNumber]);

  const handleInputChange = (e: any, field: string) => {
    let value: string = e as string;
    return setUserDetails((current) => ({ ...current, [field]: value }));
  };

  const handleSave = () => {
    if (userDetails.phoneNumber) {
      let cleanedUser = {
        ...userDetails,
        phoneNumber: CleanPhoneNumberUtil(userDetails.phoneNumber),
      };
      props.updateUserProfile(cleanedUser);
    } else {
      props.updateUserProfile(userDetails);
    }
    setIsEdit(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setUserDetails(props.user);
  };

  return (
    <>
      <Box mt={1}>
        <Paper variant="outlined">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Box mt={1} ml={1}>
                <Typography variant={"subtitle1"}>Profile Details</Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box mt={1} ml={1}>
                <Typography variant={"body2"} style={{ fontStyle: "italic" }}>
                  Invited to the VCC on:{" "}
                  {props.userCreateDate
                    ? moment(props.userCreateDate).format("LL, HH:mm:ss")
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={1}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box m={1}>
                <TextField
                  margin="dense"
                  id="firstName"
                  label="First Name"
                  type="string"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  onChange={(e) =>
                    handleInputChange(e.target.value, e.target.id)
                  }
                  value={userDetails.firstName || ""}
                  disabled={!isEdit}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box m={1}>
                <TextField
                  margin="dense"
                  id="lastName"
                  label="Last Name"
                  type="string"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  onChange={(e) =>
                    handleInputChange(e.target.value, e.target.id)
                  }
                  value={userDetails.lastName || ""}
                  disabled={!isEdit}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box m={1}>
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="string"
                  variant="outlined"
                  fullWidth
                  value={userDetails.email || ""}
                  disabled={true}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box m={1}>
                <Tooltip title="You cannot change your email. If you need to change it please submit a support request.">
                  <InfoIcon />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box m={1}>
                <TextField
                  margin="dense"
                  id="agency"
                  label="Agency"
                  type="string"
                  variant="outlined"
                  fullWidth
                  value={userDetails.agency || ""}
                  disabled={true}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box m={1}>
                <Tooltip title="You cannot change your agency. If you need to change it please submit a support request.">
                  <InfoIcon />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box m={1}>
                <TextField
                  margin="dense"
                  id="phoneNumber"
                  label="Phone Number"
                  type="string"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) =>
                    handleInputChange(e.target.value, e.target.id)
                  }
                  value={ParsePhoneNumberUtil(userDetails.phoneNumber) || ""}
                  disabled={!isEdit}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box m={1}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box
                m={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography>
                  To request updating user details, please email:{" "}
                  <a href="mailto:vccs@wsdot.wa.gov" target="_top">
                    vccs@wsdot.wa.gov
                  </a>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <Box m={1}>
                    {!isEdit ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsEdit((value) => !value)}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={handleCancel}
                        disabled={!isEdit}
                      >
                        {" "}
                        Cancel
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid item>
                  <Box m={1}>
                    <Button
                      variant="contained"
                      onClick={handleSave}
                      color="primary"
                      disabled={!isEdit || isSaveDisabled}
                    >
                      {" "}
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default ProfileDetails;
