import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Typography,
  } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIncAddressFromMapRequest } from "../../../actions";
import { IRootReducer } from "../../../reducers";
import VccReverseGeocoder from "../reverse-geocoder";
  
const useStyles = makeStyles(() => ({
	legendContainer: {
			display: "flex",
			justifyContent: "center",
			bottom: "0",
			position: "absolute",
			marginBottom: "75px",
			zIndex: 1,
		},
}));
  
interface IncLocationControlPanelProps {
	props: {
			handleCancel: (latitude: number, longitude: number, address: string) => void;
			handleConfirm: () => void;
	};
}

const IncLocationControlPanel: FC<IncLocationControlPanelProps> = ({ props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initialLatLong, setInitialLatLong] = useState({} as {latitude: number, longitude: number});
  const [initialAddress, setInitialAddress] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true);

	const incidentStore = useSelector((state: IRootReducer) => state.vccIncidentReducer.incident);

	useEffect(() => {
		if(incidentStore.latitude && incidentStore.longitude){
			setInitialLatLong({latitude: incidentStore.latitude, longitude: incidentStore.longitude});
		}
		setInitialAddress(incidentStore.location);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
			if(initialLatLong.latitude !== incidentStore.latitude && initialLatLong.longitude !== incidentStore.longitude){
				setSaveDisabled(false);
			}
			else{
				setSaveDisabled(true);
			}
	}, [initialLatLong, incidentStore.latitude, incidentStore.longitude])

	const handleUpdateTextAddress = (placeName: string) => {
		dispatch(setIncAddressFromMapRequest(placeName));
	}

	const handleCancel = () => {
		props.handleCancel(initialLatLong.latitude, initialLatLong.longitude, initialAddress);
	}

	const handleUseLocation = () => {
		setSaveDisabled(true);
		props.handleConfirm();
	}

	return (
		<>
			<Box component={Paper} m={1} mb={6} ml={7} className={classes.legendContainer}>
				<Grid container>
					<Grid item xs={12}>
							<Box m={1}><Typography variant={"h6"}>Set Incident Location</Typography></Box>
					</Grid>
					<Grid item xs={12}>
							<Box m={1}><Typography variant={"body1"}>Click a location on the map to set a new Incident Location</Typography></Box>
					</Grid>
					<Grid item xs={12}>
							<Box m={1}>
								<VccReverseGeocoder props={{
									latLong: {latitude: incidentStore.latitude, longitude: incidentStore.longitude},
									isEdit: true,
									handleUpdateTextAddress: handleUpdateTextAddress,
								}}/>
							</Box>
					</Grid>
					<Grid item xs={12}>
							<Box m={1}><Divider /></Box>
					</Grid>
					<Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end">
							<Grid item >
									<Box m={1}>
											<Button
													variant="contained"
													color='inherit'
													size="small"
													onClick={handleCancel}
											>
													Cancel
											</Button>
									</Box>
							</Grid>
							<Grid item >
									<Box m={1}>
											<Button
													variant="contained"
													size="small"
													color="primary"
													disabled={saveDisabled}
													onClick={handleUseLocation}
											>
													Use Selected Location
											</Button>
									</Box>
							</Grid>
					</Grid>
				</Grid>
			</Box>
		</>
   );
  };
  
  export default IncLocationControlPanel;
  