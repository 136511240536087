import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, InputLabel, Tooltip } from "@mui/material";
import React from "react";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { NotifMediums } from "../../../enums/notif-types";
import { getAllReadyOpGroupsRequest, getUserReadyOpGroupsRequest, updateUserReadyOpGroupsRequest } from "../../../actions";
import { NotificationGroup } from "../../../reducers/states/user-info";

  const EditNotificationPreferences: FC = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [userNotifGroups, setUserNotifGroups] = useState([] as string[])
    const [saveDisabled, setSaveDisabled] = useState(true);
  
    const userInfoStore = useSelector((state: IRootReducer) => state.userInfoReducer);
  
    useEffect( () => {
      if(userInfoStore.userReadyOpGroups){
        setUserNotifGroups(userInfoStore.userReadyOpGroups.map(g => g.id))
      }
    }, [userInfoStore.userReadyOpGroups]);
  
    const handleOpen = () => {
      dispatch(getUserReadyOpGroupsRequest())
      dispatch(getAllReadyOpGroupsRequest());
      setOpen(true);
    };
  
    const handleNotifGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
      var newGroups = [...userNotifGroups]
      if( event.target.checked === true && newGroups.indexOf(event.target.id) === -1){
        newGroups.push(event.target.id);
      }
      else{
        newGroups = newGroups.filter((g) => g !== event.target.id);
      }

      setUserNotifGroups(newGroups);
      setSaveDisabled(false);
    };
  
    const handleCancel = () => {
      setOpen(false);
      setUserNotifGroups([]);
      setSaveDisabled(true);
    };
  
    const handleSave = () => {
        dispatch(updateUserReadyOpGroupsRequest(userNotifGroups))
        setOpen(false);
        setUserNotifGroups([]);
        setSaveDisabled(true);
    }
  
    return (
      <>
        <Tooltip title="Edit Notification Preferences within the VCC">
          <Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleOpen}
              style={{ minWidth: "150px", color: "black" }}
            >
              Edit Notification Preferences
            </Button>
          </Box>
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleCancel}
          fullWidth
          maxWidth="md"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit Notification Preferences</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <Box m={1}>
                  <FormGroup>
                    <InputLabel>Notify Me About:</InputLabel>
                    <Grid container>
                        {( userInfoStore.allReadyOpGroups ) && (
                           userInfoStore.allReadyOpGroups.map((notifGroup: NotificationGroup) => (
                            <Grid item xs={12}>
                              <FormControlLabel key={notifGroup.id} control={
                                <Checkbox 
                                  checked={userNotifGroups.includes(notifGroup.id)} 
                                  id={notifGroup.id}
                                  onChange={handleNotifGroupChange}
                                  />
                              }
                                label={notifGroup.name}
                              />
                            </Grid>
                           )
                        ))}
                    </Grid>
                  </FormGroup>
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box m={1}>
                  <FormGroup>
                    <InputLabel>Send Notifications Via:</InputLabel>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={true} id={NotifMediums.EMAIL} /> // Review: Defaulting to always on since this is the only form of notifs right now
                          }
                          label="Email"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleCancel} color="inherit" variant="contained">
                Cancel
              </Button>
              <Button
                disabled={saveDisabled}
                onClick={handleSave}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default EditNotificationPreferences;
  