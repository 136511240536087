export enum DispatchFeedStoreError {
  DISPATCH_EVENT_FEED_GET = "Error loading Dispatch Events",
  DISPATCH_EVENTS_PIN_GET = "Error loading pinned Dispatch Events",
  DISPATCH_EVENT_PIN_GET = "Error loading pinned Dispatch Event",
  DISPATCH_EVENT_PIN_SAVE = "Error pinning Dispatch Events",
  DISPATCH_EVENT_PIN_REMOVE = "Error removing pinned Dispatch Events",
  NOTEWORTHY_EVENT_FEED_GET = "Error loading Noteworthy Dispatch Events",
  TMC_LOG_GET = "Error retrieving TMC Log",
}

export enum DispatchFeedStoreSuccess {
  DISPATCH_EVENT_PIN_SAVE = "Successfully pinned Dispatch Events",
  DISPATCH_EVENT_PIN_REMOVE = "Successfully removed pinned Dispatch Events"
}
