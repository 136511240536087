import { PagingResponse } from "../../reducers";
import {
  Note,
  VccHistory,
  UpdateIncidentDispatchRequest,
  VccIncidentModel,
  VccNotification,
  IncidentAction,
  IncidentTalkingPoint,
  IncidentOutreach,
} from "../../reducers/states/vcc-incident";
import { types } from "../types";

// Get Single Incident
export const getVccIncidentRequest = (id: string) => ({
  type: types.GET_VCC_INCIDENT,
  payload: { id },
});
export const getVccIncidentSuccess = (vccIncident: VccIncidentModel) => ({
  type: types.GET_VCC_INCIDENT_SUCCESS,
  payload: { vccIncident },
});
export const getVccIncidentFailure = (getIncidentFailureMessage: string) => ({
  type: types.GET_VCC_INCIDENT_FAILURE,
  payload: { getIncidentFailureMessage },
});

// Get Open Incidents
export interface getOpenIncidentsPayload {
  pageResponse?: boolean;
}
export const getOpenIncidentsRequest = (pageResponse?: boolean) => ({
  type: types.GET_OPEN_INCIDENTS,
  payload: { pageResponse },
});
export const getOpenIncidentsSuccess = (openIncidents: PagingResponse<VccIncidentModel[]>) => ({
  type: types.GET_OPEN_INCIDENTS_SUCCESS,
  payload: { openIncidents },
});
export const getOpenIncidentsFailure = (
  getIncidentsFailureMessage: string
) => ({
  type: types.GET_OPEN_INCIDENTS_FAILURE,
  payload: { getIncidentsFailureMessage },
});

// Get Closed Incidents
export interface getClosedAndDeletedIncidentsPayload {
  pageResponse?: boolean;
}
export const getClosedAndDeletedIncidentsRequest = (pageResponse?: boolean) => ({
  type: types.GET_CLOSED_DELETED_INCIDENTS,
  payload: { pageResponse },
});
export const getClosedAndDeletedIncidentsSuccess = (
  closedIncidents: PagingResponse<VccIncidentModel[]>,
  deletedIncidents: PagingResponse<VccIncidentModel[]>
) => ({
  type: types.GET_CLOSED_DELETED_INCIDENTS_SUCCESS,
  payload: { closedIncidents, deletedIncidents },
});
export const getClosedAndDeletedIncidentsFailure = (
  getClosedIncidentsFailureMessage: string
) => ({
  type: types.GET_CLOSED_DELETED_INCIDENTS_FAILURE,
  payload: { getClosedIncidentsFailureMessage },
});

// Create VCC Incident
export const createVccIncidentRequest = (vccIncident: VccIncidentModel) => ({
  type: types.CREATE_VCC_INCIDENT,
  payload: { vccIncident },
});
export const createVccIncidentSuccess = (createSuccessMessage: string) => ({
  type: types.CREATE_VCC_INCIDENT_SUCCESS,
  payload: { createSuccessMessage },
});
export const createVccIncidentFailure = (createFailureMessage: string) => ({
  type: types.CREATE_VCC_INCIDENT_FAILURE,
  payload: { createFailureMessage },
});

// Update VCC Incident
export const updateVccIncident = (
  incident: VccIncidentModel,
  associatedIds: UpdateIncidentDispatchRequest[]
) => ({
  type: types.UPDATE_VCC_INCIDENT,
  payload: { incident, associatedIds },
});
export const updateVccIncidentSuccess = (updateSuccessMessage: string) => ({
  type: types.UPDATE_VCC_INCIDENT_SUCCESS,
  payload: { updateSuccessMessage },
});
export const updateVccIncidentFailure = (updateFailureMessage: string) => ({
  type: types.UPDATE_VCC_INCIDENT_FAILURE,
  payload: { updateFailureMessage },
});

// Get VCC Incident Report
export const downloadVccIncidentReport = (
  id: string,
  fileDisplayName?: string
) => ({
  type: types.DOWNLOAD_VCC_INCIDENT_REPORT,
  payload: { id, fileDisplayName },
});
export const getIncidentReportFailure = (
  getIncidentReportFailureMessage: string
) => ({
  type: types.DOWNLOAD_VCC_INCIDENT_REPORT_FAILURE,
  payload: { getIncidentReportFailureMessage },
});

// Finalize VCC Incident
export const finalizeVccIncidentReport = (
  incidentId: string,
  token: string
) => ({
  type: types.FINALIZE_VCC_INCIDENT_REPORT,
  payload: { incidentId, token },
});
export const finalizeVccIncidentReportSuccess = (
  finalizeSuccessMessage: string,
  incidentId: string,
  email: string
) => ({
  type: types.FINALIZE_VCC_INCIDENT_REPORT_SUCCESS,
  payload: { finalizeSuccessMessage, incidentId, email },
});
export const finalizeVccIncidentReportFailure = (
  finalizeFailureMessage: string
) => ({
  type: types.FINALIZE_VCC_INCIDENT_REPORT_FAILURE,
  payload: { finalizeFailureMessage },
});

// Get VCC Notes
export const getVccIncidentNotesRequest = (incidentId: string) => ({
  type: types.GET_VCC_INCIDENT_NOTES,
  payload: { incidentId },
});
export const getVccIncidentNotesSuccess = (notes: Note[]) => ({
  type: types.GET_VCC_INCIDENT_NOTES_SUCCESS,
  payload: { notes },
});
export const getVccIncidentNotesFailure = (getNotesFailureMessage: string) => ({
  type: types.GET_VCC_INCIDENT_NOTES_FAILURE,
  payload: { getNotesFailureMessage },
});

// Create VCC Note
export const createVccIncidentNoteRequest = (note: Note, id: string) => ({
  type: types.CREATE_VCC_INCIDENT_NOTE,
  payload: { note, id },
});
export const createVccIncidentNoteSuccess = (
  createNoteSuccessMessage: string
) => ({
  type: types.CREATE_VCC_INCIDENT_NOTE_SUCCESS,
  payload: { createNoteSuccessMessage },
});
export const createVccIncidentNoteFailure = (
  createNoteFailureMessage: string
) => ({
  type: types.CREATE_VCC_INCIDENT_NOTE_FAILURE,
  payload: { createNoteFailureMessage },
});

// Get All VCC Actions - Collaboration Space
export const getVccIncidentActionsRequest = (incidentId: string) => ({
  type: types.GET_VCC_INCIDENT_ACTIONS,
  payload: { incidentId },
});
export const getVccIncidentActionsSuccess = (
  incidentActions: IncidentAction[]
) => ({
  type: types.GET_VCC_INCIDENT_ACTIONS_SUCCESS,
  payload: { incidentActions },
});
export const getVccIncidentActionsFailure = (
  getActionsFailureMessage: string
) => ({
  type: types.GET_VCC_INCIDENT_ACTIONS_FAILURE,
  payload: { getActionsFailureMessage },
});

// Get Single VCC Action - Collaboration Space
export interface getVccActionPayload {
  incidentId: string,
  actionId: string,
  timeAdded: string | Date,
}
export const getVccIncidentActionRequest = (incidentId: string, actionId: string, timeAdded: string | Date) => ({
  type: types.GET_VCC_INCIDENT_ACTION,
  payload: { incidentId, actionId, timeAdded } as getVccActionPayload,
});
export const getVccIncidentActionSuccess = (
  incidentAction: IncidentAction
) => ({
  type: types.GET_VCC_INCIDENT_ACTION_SUCCESS,
  payload: { incidentAction },
});
export const getVccIncidentActionFailure = (
  getActionFailureMessage: string
) => ({
  type: types.GET_VCC_INCIDENT_ACTION_FAILURE,
  payload: { getActionFailureMessage },
});

// Create VCC Action - Collaboration Space
export interface actionLogPayload {
  incidentId: string;
  incidentAction: IncidentAction;
}
export const createVccIncidentActionRequest = (
  incidentId: string,
  incidentAction: IncidentAction
) => ({
  type: types.CREATE_VCC_INCIDENT_ACTION,
  payload: { incidentId, incidentAction } as actionLogPayload,
});
export const createVccIncidentActionSuccess = (
  createIncidentActionSuccessMessage: string
) => ({
  type: types.CREATE_VCC_INCIDENT_ACTION_SUCCESS,
  payload: { createIncidentActionSuccessMessage },
});
export const createVccIncidentActionFailure = (
  createIncidentActionFailureMessage: string
) => ({
  type: types.CREATE_VCC_INCIDENT_ACTION_FAILURE,
  payload: { createIncidentActionFailureMessage },
});

// Modify VCC Action - Collaboration Space
export const modifyVccIncidentActionRequest = (
  incidentId: string,
  incidentAction: IncidentAction
) => ({
  type: types.MODIFY_VCC_INCIDENT_ACTION,
  payload: { incidentId, incidentAction } as actionLogPayload,
});
export const modifyVccIncidentActionSuccess = (
  modifyIncidentActionSuccessMessage: string
) => ({
  type: types.MODIFY_VCC_INCIDENT_ACTION_SUCCESS,
  payload: { modifyIncidentActionSuccessMessage },
});
export const modifyVccIncidentActionFailure = (
  modifyIncidentActionFailureMessage: string
) => ({
  type: types.MODIFY_VCC_INCIDENT_ACTION_FAILURE,
  payload: { modifyIncidentActionFailureMessage },
});

// Get VCC Incident History
export const getVccIncidentHistoryRequest = (id: string) => ({
  type: types.GET_INC_HISTORY,
  payload: { id },
});
export const getVccIncidentHistorySuccess = (incHistory: VccHistory[]) => ({
  type: types.GET_INC_HISTORY_SUCCESS,
  payload: { incHistory },
});
export const getVccIncidentHistoryFailure = (
  getIncHistoryFailureMessage: string
) => ({
  type: types.GET_INC_HISTORY_FAILURE,
  payload: { getIncHistoryFailureMessage },
});

// Clear VCC Incident History - redux field only
export const clearCurrentVccIncidentHistory = () => ({
  type: types.CLEAR_CURRENT_INC_HISTORY,
  payload: {},
});

// Check if VCC Incident Update is available
export const isIncidentUpdateAvailable = (
  id: string,
  currentUpdateDate: string | Date
) => ({
  type: types.IS_INC_UPDATE_AVAILABLE,
  payload: { id, currentUpdateDate },
});
export const isIncidentUpdateAvailableSuccess = (
  isIncUpdateAvailable: boolean
) => ({
  type: types.IS_INC_UPDATE_AVAILABLE_SUCCESS,
  payload: { isIncUpdateAvailable },
});
export const isIncidentUpdateAvailableFailure = (
  isIncUpdateAvailableFailureMessage: string
) => ({
  type: types.IS_INC_UPDATE_AVAILABLE_FAILURE,
  payload: { isIncUpdateAvailableFailureMessage },
});

// Upload image to VCC Incident Note
export interface uploadImgRequestPayload {
  incId: string;
  fileId: string;
  blobData: Blob;
  fileType: string;
}
export const uploadIncidentImageRequest = (
  incId: string,
  fileId: string,
  blobData: Blob,
  fileType: string
) => ({
  type: types.UPLOAD_INC_IMAGE,
  payload: { incId, fileId, blobData, fileType } as uploadImgRequestPayload,
});
export const uploadIncidentImageSuccess = (
  uploadIncImageSuccessMessage: string
) => ({
  type: types.UPLOAD_INC_IMAGE_SUCCESS,
  payload: { uploadIncImageSuccessMessage },
});
export const uploadIncidentImageFailure = (
  uploadIncImageFailureMessage: string
) => ({
  type: types.UPLOAD_INC_IMAGE_FAILURE,
  payload: { uploadIncImageFailureMessage },
});

// Download file on VCC Note
export const downloadNoteFileRequest = (incId: string, imgId: string) => ({
  type: types.DOWNLOAD_NOTE_FILE,
  payload: { incId, imgId },
});
export const downloadNoteFileSuccess = (downloadPresignedUrl: string) => ({
  type: types.DOWNLOAD_NOTE_FILE_SUCCESS,
  payload: { downloadPresignedUrl },
});
export const downloadNoteFileFailure = (
  failureMessage: string
) => ({
  type: types.DOWNLOAD_NOTE_FILE_FAILURE,
  payload: { failureMessage },
});

// Clear image presigned url - redux field only
export const clearPresignedUrl = () => ({
  type: types.CLEAR_PRESIGNED_URL,
  payload: {},
});

// Get Talking Point
export const getTalkingPointRequest = (incId: string, talkingPointId: string) => ({
  type: types.GET_TALKING_POINT,
  payload: { incId, talkingPointId }
});
export const getTalkingPointSuccess = (
  talkingPoint: IncidentTalkingPoint
) => ({
  type: types.GET_TALKING_POINT_SUCCESS,
  payload: { talkingPoint },
});
export const getTalkingPointFailure = (
  failureMessage: string
) => ({
  type: types.GET_TALKING_POINT_FAILURE,
  payload: { failureMessage },
});

// Get Talking Points
export const getTalkingPointsRequest = (incId: string) => ({
  type: types.GET_TALKING_POINTS,
  payload: { incId }
});
export const getTalkingPointsSuccess = (
  talkingPoints: IncidentTalkingPoint[]
) => ({
  type: types.GET_TALKING_POINTS_SUCCESS,
  payload: { talkingPoints },
});
export const getTalkingPointsFailure = (
  failureMessage: string
) => ({
  type: types.GET_TALKING_POINTS_FAILURE,
  payload: { failureMessage },
});

// Create Talking Point
export interface talkingPointPayload {
  incId: string;
  talkingPoint: IncidentTalkingPoint;
}
export const createTalkingPointRequest = (
  incId: string,
  talkingPoint: IncidentTalkingPoint
) => ({
  type: types.CREATE_TALKING_POINT,
  payload: { incId, talkingPoint } as talkingPointPayload,
});
export const createTalkingPointSuccess = (
  successMessage: string
) => ({
  type: types.CREATE_TALKING_POINT_SUCCESS,
  payload: { successMessage },
});
export const createTalkingPointFailure = (
  failureMessage: string
) => ({
  type: types.CREATE_TALKING_POINT_FAILURE,
  payload: { failureMessage },
});

// Update Talking Point
export const updateTalkingPointRequest = (
  incId: string,
  talkingPoint: IncidentTalkingPoint
) => ({
  type: types.UPDATE_TALKING_POINT,
  payload: { incId, talkingPoint } as talkingPointPayload,
});
export const updateTalkingPointSuccess = (
  successMessage: string
) => ({
  type: types.UPDATE_TALKING_POINT_SUCCESS,
  payload: { successMessage },
});
export const updateTalkingPointFailure = (
  failureMessage: string
) => ({
  type: types.UPDATE_TALKING_POINT_FAILURE,
  payload: { failureMessage },
});

// Get Talking Point History
export interface talkingPointHistoryPayload {
  incId: string;
  talkingPointId: string;
}
export const getTalkingPointHistoryRequest = (
  incId: string,
  talkingPointId: string
) => ({
  type: types.GET_TALKING_POINT_HISTORY,
  payload: { incId, talkingPointId } as talkingPointHistoryPayload,
});
export const getTalkingPointHistorySuccess = (
  talkingPointHistory: VccHistory[]
) => ({
  type: types.GET_TALKING_POINT_HISTORY_SUCCESS,
  payload: { talkingPointHistory },
});
export const getTalkingPointHistoryFailure = (
  failureMessage: string
) => ({
  type: types.GET_TALKING_POINT_HISTORY_FAILURE,
  payload: { failureMessage },
});

// TalkingPoint file upload
export interface uploadTalkingPointFilePayload {
  incId: string;
  talkingPointId: string;
  fileId: string;
  blobData: Blob;
  fileType: string;
}
export const uploadTalkingPointFileRequest = (
  incId: string,
  talkingPointId: string,
  fileId: string,
  blobData: Blob,
  fileType: string
) => ({
  type: types.UPLOAD_TALKING_POINT_FILE,
  payload: { incId, talkingPointId, fileId, blobData, fileType } as uploadTalkingPointFilePayload,
});
export const uploadTalkingPointFileSuccess = (
  successMessage: string
) => ({
  type: types.UPLOAD_TALKING_POINT_FILE_SUCCESS,
  payload: { successMessage },
});
export const uploadTalkingPointFileFailure = (
  failureMessage: string
) => ({
  type: types.UPLOAD_TALKING_POINT_FILE_FAILURE,
  payload: { failureMessage },
});

// TalkingPoint file download
export interface downloadTalkingPointFilePayload {
  incId: string;
  talkingPointId: string;
  fileId: string;
}
export const downloadTalkingPointFileRequest = (incId: string, talkingPointId: string, fileId: string) => ({
  type: types.DOWNLOAD_TALKING_POINT_FILE,
  payload: { incId, talkingPointId, fileId } as downloadTalkingPointFilePayload,
});
export const downloadTalkingPointFileSuccess = (downloadPresignedUrl: string) => ({
  type: types.DOWNLOAD_TALKING_POINT_FILE_SUCCESS,
  payload: { downloadPresignedUrl },
});
export const downloadTalkingPointFileFailure = (
  failureMessage: string
) => ({
  type: types.DOWNLOAD_TALKING_POINT_FILE_FAILURE,
  payload: { failureMessage },
});

// Get Scheduled Outreach
export const getOutreachRequest = (incId: string, outreachId: string) => ({
  type: types.GET_OUTREACH,
  payload: { incId, outreachId }
});
export const getOutreachSuccess = (
  outreach: IncidentOutreach
) => ({
  type: types.GET_OUTREACH_SUCCESS,
  payload: { outreach },
});
export const getOutreachFailure = (
  failureMessage: string
) => ({
  type: types.GET_OUTREACH_FAILURE,
  payload: { failureMessage },
});

// Get Outreaches for incident
export const getOutreachesRequest = (incId: string) => ({
  type: types.GET_OUTREACHES,
  payload: { incId }
});
export const getOutreachesSuccess = (
  outreaches: IncidentOutreach[]
) => ({
  type: types.GET_OUTREACHES_SUCCESS,
  payload: { outreaches },
});
export const getOutreachesFailure = (
  failureMessage: string
) => ({
  type: types.GET_OUTREACHES_FAILURE,
  payload: { failureMessage },
});

// Create Outreach
export interface outreachPayload {
  incId: string;
  outreach: IncidentOutreach;
}
export const createOutreachRequest = (
  incId: string,
  outreach: IncidentOutreach
) => ({
  type: types.CREATE_OUTREACH,
  payload: { incId, outreach } as outreachPayload,
});
export const createOutreachSuccess = (
  successMessage: string
) => ({
  type: types.CREATE_OUTREACH_SUCCESS,
  payload: { successMessage },
});
export const createOutreachFailure = (
  failureMessage: string
) => ({
  type: types.CREATE_OUTREACH_FAILURE,
  payload: { failureMessage },
});

// Update Outreach
export const updateOutreachRequest = (
  incId: string,
  outreach: IncidentOutreach
) => ({
  type: types.UPDATE_OUTREACH,
  payload: { incId, outreach } as outreachPayload,
});
export const updateOutreachSuccess = (
  successMessage: string
) => ({
  type: types.UPDATE_OUTREACH_SUCCESS,
  payload: { successMessage },
});
export const updateOutreachFailure = (
  failureMessage: string
) => ({
  type: types.UPDATE_OUTREACH_FAILURE,
  payload: { failureMessage },
});

// Outreach file upload
export interface uploadOutreachFilePayload {
  incId: string;
  outreachId: string;
  fileId: string;
  blobData: Blob;
  fileType: string;
}
export const uploadOutreachFileRequest = (
  incId: string,
  outreachId: string,
  fileId: string,
  blobData: Blob,
  fileType: string
) => ({
  type: types.UPLOAD_OUTREACH_FILE,
  payload: { incId, outreachId, fileId, blobData, fileType } as uploadOutreachFilePayload,
});
export const uploadOutreachFileSuccess = (
  successMessage: string
) => ({
  type: types.UPLOAD_OUTREACH_FILE_SUCCESS,
  payload: { successMessage },
});
export const uploadOutreachFileFailure = (
  failureMessage: string
) => ({
  type: types.UPLOAD_OUTREACH_FILE_FAILURE,
  payload: { failureMessage },
});

// Outreach file download
export interface downloadOutreachFilePayload {
  incId: string;
  outreachId: string;
  fileId: string;
}
export const downloadOutreachFileRequest = (incId: string, outreachId: string, fileId: string) => ({
  type: types.DOWNLOAD_OUTREACH_FILE,
  payload: { incId, outreachId, fileId } as downloadOutreachFilePayload,
});
export const downloadOutreachFileSuccess = (downloadPresignedUrl: string) => ({
  type: types.DOWNLOAD_OUTREACH_FILE_SUCCESS,
  payload: { downloadPresignedUrl },
});
export const downloadOutreachFileFailure = (
  failureMessage: string
) => ({
  type: types.DOWNLOAD_OUTREACH_FILE_FAILURE,
  payload: { failureMessage },
});

// Outreach history
export interface outreachHistoryPayload {
  incId: string;
  outreachId: string;
}
export const getOutreachHistoryRequest = (
  incId: string,
  outreachId: string
) => ({
  type: types.GET_OUTREACH_HISTORY,
  payload: { incId, outreachId } as outreachHistoryPayload,
});
export const getOutreachHistorySuccess = (
  outreachHistory: VccHistory[]
) => ({
  type: types.GET_OUTREACH_HISTORY_SUCCESS,
  payload: { outreachHistory },
});
export const getOutreachHistoryFailure = (
  failureMessage: string
) => ({
  type: types.GET_OUTREACH_HISTORY_FAILURE,
  payload: { failureMessage },
});

// Create VCC Incident Notification
export const createIncidentNotif = (notification: VccNotification) => ({
  type: types.CREATE_INC_NOTIF,
  payload: { notification },
});
export const createIncidentNotifSuccess = (incidentNotif: VccNotification) => ({
  type: types.CREATE_INC_NOTIF_SUCCESS,
  payload: { incidentNotif },
});
export const createIncidentNotifFailure = (
  incidentNotifFailureMessage: string
) => ({
  type: types.CREATE_INC_NOTIF_FAILURE,
  payload: { incidentNotifFailureMessage },
});

// Initialize VCC Incident Notifications
export const initIncidentNotifs = (incidentNotifs: VccNotification[]) => ({
  type: types.INIT_INC_NOTIFS,
  payload: { incidentNotifs },
});

// Clear all VCC Incident Notifications
export const clearAllNotifs = () => ({
  type: types.CLEAR_ALL_NOTIFS,
  payload: {},
});
export const clearAllNotifsSuccess = () => ({
  type: types.CLEAR_ALL_NOTIFS_SUCCESS,
  payload: {},
});
export const clearAllNotifsFailure = (
  clearAllNotifsFailureMessage: string
) => ({
  type: types.CLEAR_ALL_NOTIFS_FAILURE,
  payload: { clearAllNotifsFailureMessage },
});

// Clear singel VCC Incident Notification
export const clearNotifRequest = (incidentNotif: VccNotification) => ({
  type: types.CLEAR_NOTIF,
  payload: { incidentNotif },
});
export const clearNotifSuccess = (incidentNotif: VccNotification) => ({
  type: types.CLEAR_NOTIF_SUCCESS,
  payload: { incidentNotif },
});
export const clearNotifFailure = (clearNotifFailureMessage: string) => ({
  type: types.CLEAR_NOTIF_FAILURE,
  payload: { clearNotifFailureMessage },
});

// Clear VCC Incident Banner
export const clearBannerNotif = (id: string) => ({
  type: types.CLEAR_BANNER_NOTIF,
  payload: { id },
});
