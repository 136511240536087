import { Chip, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Agency } from "../../../enums/agency";

const useStyles = makeStyles((theme: Theme) => ({
  chipWSP: {
    marginRight: 10,
    backgroundColor: theme.agency.WSP,
    color: "white",
  },
  chipKCM: {
    marginRight: 10,
    backgroundColor: theme.agency.KCM,
    color: "white",
  },
  chipSFD: {
    marginRight: 10,
    backgroundColor: theme.agency.SFD,
    color: "white",
  },
  chipSPD: {
    marginRight: 10,
    backgroundColor: theme.agency.SPD,
    color: "white",
  },
  chipNeutral: {
    marginRight: 10,
    backgroundColor: theme.agency.Neutral,
    color: "black",
  },
  chipClosed: {
    marginRight: 10,
    backgroundColor: theme.marker.ClosedDispatchEventIFD,
    color: "white",
  },
  listItem: {
    flex: "1 1 55%",
  },
  removeButton: {
    color: theme.agency.SFD,
    padding: "3px",
    "margin-left": "10px",
  },
}));

interface ChipProps {
  props: {
    agency: string;
    isClosed?: boolean;
  };
}

export const AgencyChip = ({ props }: ChipProps) => {
  const classes = useStyles();
  if (props.agency === "") {
    return <div>{props.agency}</div>;
  }
  if(props.isClosed){
    return <Chip label={props.agency} className={classes.chipClosed} />
  }
  else{
    switch (props.agency) {
      case Agency.WSP: {
        return <Chip label={Agency.WSP} className={classes.chipWSP} />;
      }
      case Agency.KCM: {
        return <Chip label={Agency.KCM} className={classes.chipKCM} />;
      }
      case Agency.SFD: {
        return <Chip label={Agency.SFD} className={classes.chipSFD} />;
      }
      case Agency.SPD: {
        return <Chip label={Agency.SPD} className={classes.chipSPD} />;
      }
      default: {
        return <Chip label={props.agency} className={classes.chipNeutral} />;
      }
    }
  }
};
