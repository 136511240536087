import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
  } from "@mui/material";
  import AttachFileIcon from "@mui/icons-material/AttachFile";
  import CheckIcon from '@mui/icons-material/Check';
  import CloseIcon from "@mui/icons-material/Close";
  import { Alert } from "@mui/material";
  import React, { FC, useCallback, useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { v4 as uuidv4 } from "uuid";
  import { IncidentOutreach } from "../../../../reducers/states/vcc-incident";
  import { ShortIdUtil } from "../../../../utils/id-utils";
  import { AgencyChip } from "../../../common/agency-chip";
  import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
  import { IRootReducer } from "../../../../reducers";
  import { IncidentStoreError, IncidentStoreSuccess } from "../../../../enums/store-messages/incident";
  import { createOutreachRequest, getOutreachesRequest, getOutreachRequest, updateOutreachRequest, uploadOutreachFileRequest } from "../../../../actions";
  import UpdateFailureModal from "../../../incident-model/update-failure-modal";
  import ConfirmationModal from "../../../common/confirmation-modal";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { PioAudience, PioStatus, PioRecipient } from "../../../../enums/pio";
import CustomSelectInput from "../../../common/custom-select-input";
import { FilesPrepareResponse, importFilesUtil, S3FileData } from "../../../../utils/import-utils";
import UploadRulesModal, { UploadErrorMessage } from "../../../incident-model/upload-rules-modal";
  
  interface AddEditOutreachProps {
    props: {
      isOpen: boolean;
      isAddOutreach: boolean;
      incidentId: string;
      handleClose: () => void;
    };
  }

  const AddEditScheduledOutreachModal: FC<AddEditOutreachProps> = ({ props }) => {
    const dispatch = useDispatch();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [outreach, setOutreach] = useState({} as IncidentOutreach);
    const [currentLink, setCurrentLink] = useState("");
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
    const [isEdited, setIsEdited ] = useState(false);
    const [editedConfirmation, setEditedConfirmation] = useState(false);
    const [isOverwriteError, setIsOverwriteError] = useState(false);
    const [isRefreshIsRequired, setIsRefreshRequired] = useState(false);
    const [fileImportData, setFileImportData] = useState([] as S3FileData[]);
    const [errorRuleOpen, setErrorRuleOpen] = useState(false);
    const [importErrors, setImportErrors] = useState([] as UploadErrorMessage[]);
  
    const incidentStore = useSelector(
      (state: IRootReducer) => state.vccIncidentReducer
    );
  
    const resetForm = useCallback(() => {
      setOutreach({
        id: "",
        message: "",
        agency: "",
        audience: "",
        recipient: "",
        status: "",
        scheduledTime: "",
        isRecurring: "No",
        links: [] as string[],
        fileIds: [] as string[],
        createdBy: "",
        createdDate: "",
        updatedBy: "",
        updatedDate: "",
        ccId: "",
      } as IncidentOutreach);
      setFileImportData([] as S3FileData[]);
      setCurrentLink("");
      setIsEdited(false);
      setEditedConfirmation(false);
    }, []);
  
    const resetAndCloseForm = useCallback(() => {
      dispatch(getOutreachesRequest(props.incidentId));
      setIsOverwriteError(false);
      setIsRefreshRequired(false);
      resetForm();
      props.handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetForm])
  
    useEffect(() => {
      if (outreach.message !== "" 
        && isEdited) {
        setSubmitBtnDisabled(false);
      } else {
        setSubmitBtnDisabled(true);
      }
    }, [outreach.message, isEdited]);
  
    useEffect(() => {
      setOutreach({...incidentStore.outreach});
      setIsOverwriteError(false);
      setIsRefreshRequired(false);
    }, [incidentStore.outreach])
  
    useEffect(() => {
      if(incidentStore.successMessage){
        switch(incidentStore.successMessage){
          case IncidentStoreSuccess.OUTREACH_SAVE:
            setSuccessMessage(IncidentStoreSuccess.OUTREACH_SAVE);
            resetAndCloseForm();
            break;
          case IncidentStoreSuccess.OUTREACH_UPDATE:
            setSuccessMessage(IncidentStoreSuccess.OUTREACH_UPDATE);
            resetAndCloseForm();
            break;
        }
      }
    }, [incidentStore.successMessage, resetAndCloseForm]);
  
    useEffect(() => {
      if(incidentStore.failureMessage){
        switch(incidentStore.failureMessage){
          case IncidentStoreError.OUTREACH_SAVE:
            setErrorMessage(IncidentStoreError.OUTREACH_SAVE);
            break;
          case IncidentStoreError.OUTREACH_UPDATE:
            setErrorMessage(IncidentStoreError.OUTREACH_UPDATE);
            break;
          case IncidentStoreError.OUTREACH_OVERWRITE_ERROR:
            setIsOverwriteError(true)
            break;
          case IncidentStoreError.OUTREACH_GET:
            setErrorMessage(IncidentStoreError.OUTREACH_GET);
            break;
        }
      }
    }, [incidentStore.failureMessage]);
  
    const handleInputChange = (e: any, field: string) => {
      let value: string = e as string;
      setOutreach((current) => ({ ...current, [field]: value }));
      setIsEdited(true);
    };

    const handleSelectChange = (event: SelectChangeEvent<string>, field: string) => {
      const value = event.target.value as string;
      setOutreach((current) => ({ ...current, [field]: value }));
      setIsEdited(true);
    };
  
    const handleSelectAgency = (event: SelectChangeEvent<string>) => {
      const newAgency = event.target.value as string;
      setOutreach((current) => ({ ...current, agency: newAgency }));
      setIsEdited(true);
    };

    const handleFileImport = async (event: any) => {
      var fileList = event.target.files as FileList;
      let result: FilesPrepareResponse = await importFilesUtil(fileList);
      if (result.importErrors && result.importErrors.length > 0) {
        setImportErrors(result.importErrors);
        setErrorRuleOpen(true);
      } else {
        setFileImportData(result.importData);
        setIsEdited(true);
      }
    };

    const handleClearFileData = (filePreviewToRemove: string) => {
      setFileImportData(
        fileImportData.filter((f) => f.imgPreview !== filePreviewToRemove)
      );
      setIsEdited(true);
    };

    const handleRemoveFile = (fileId: string) => {
      let updatedFileIds = outreach.fileIds.filter((f) => f !== fileId);
      setOutreach((current) => ({ ...current, fileIds: updatedFileIds }));
      setIsEdited(true);
    }
    
    const handleLinkInput = (e: any) => {
      let value: string = e as string;
      setCurrentLink(value);
      setIsEdited(true);
    }

    const handleAddLink = () => {
      if(outreach.links){
        let updatedLinks = [...outreach.links, currentLink];
        setOutreach((current) => ({ ...current, links: updatedLinks }));
      }
      else{
        setOutreach((current) => ({ ...current, links: [currentLink] }));
      }

      setCurrentLink("")
      setIsEdited(true);
    }

    const handleRemoveLink = (link: string) => {
      let updatedLinks = outreach.links.filter((l) => l !== link);
      setOutreach((current) => ({ ...current, links: updatedLinks }));
      setIsEdited(true);
    }
  
    const handleCancel = () => {
      if(isEdited && !isRefreshIsRequired){
        setEditedConfirmation(true);
      }
      else{
        resetAndCloseForm();
      }
    };
  
    const handleContinueEditing = () => {
      setIsOverwriteError(false);
      setIsRefreshRequired(true);
    }
  
    const handleRefreshForm = () => {
      dispatch(getOutreachRequest(props.incidentId, outreach.id));
    }
  
    const handleCloseErrorModal = () => {
      setImportErrors([]);
      setErrorRuleOpen(false);
    };

    const handleSubmit = () => {
      let addEditOutreach = { ...outreach };

      // TimePicker sets this field to null if user clears it out, set to empty string on save
      if(!addEditOutreach.scheduledTime){
        addEditOutreach.scheduledTime = "";
      }
      let regex = /[^a-zA-Z0-9/!%"'‘’“”~@#$^*()_+=[\]{}|\\,.? :-]/g;
      addEditOutreach.message = addEditOutreach.message.replace(regex, '');

      // Handle uploading files to S3 and Creating or Editing the outreach
      if (fileImportData && fileImportData.length > 0) {
        let newFileIds = fileImportData.map((f) => f.id);
        if(addEditOutreach.fileIds && addEditOutreach.fileIds.length > 0){
          addEditOutreach.fileIds = [...addEditOutreach.fileIds, ...newFileIds];
        }
        else{
          addEditOutreach.fileIds = newFileIds;
        }
        
        if(props.isAddOutreach){
          addEditOutreach.id = uuidv4();
          dispatch(createOutreachRequest(props.incidentId, addEditOutreach));
        }
        else{
          dispatch(updateOutreachRequest(props.incidentId, addEditOutreach));
        }

        // For now, just dispatch multiple upload requests
        fileImportData.forEach((f: S3FileData) =>
          dispatch(
            uploadOutreachFileRequest(
              props.incidentId,
              addEditOutreach.id,
              f.id,
              f.blobData,
              f.fileType
            )
          )
        );
      } else {
        // Create or update an outreach without any additional files attached
        if(props.isAddOutreach){
          addEditOutreach.id = uuidv4();
          dispatch(createOutreachRequest(props.incidentId, addEditOutreach));
        }
        else{
          dispatch(updateOutreachRequest(props.incidentId, addEditOutreach));
        }
      }
    };
  
    return (
      <>
        <Dialog
          open={props.isOpen}
          onClose={handleCancel}
          fullWidth
          maxWidth="lg"
          aria-labelledby="form-dialog-title"
        >
          <Box>
            <Grid container justifyContent="center">
              <Grid item xs={8}>
                <Box mt={1} mb={0}>
                  <DialogTitle id="form-dialog-title">
                  {props.isAddOutreach ? "Create " : "Edit "} Scheduled Outreach for: {ShortIdUtil(props.incidentId)}
                  </DialogTitle>
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={4}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box mt={1.5} mb={0} mr={1}>
                  <IconButton onClick={handleCancel}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <TextField
                        id="message"
                        variant="outlined"
                        autoFocus
                        required
                        margin="dense"
                        label="Message"
                        type="string"
                        rows={4}
                        multiline
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        value={outreach.message || ""}
                        onChange={(e) =>
                          handleInputChange(e.target.value, e.target.id)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <FormControl fullWidth variant="outlined" margin="dense">
                          <InputLabel>Audience</InputLabel>
                          <Select
                            value={outreach.audience ?? ""}
                            onChange={(event: SelectChangeEvent<string>) => handleSelectChange(event, "audience")}
                            label="Audience"
                          >
                            <MenuItem value={""}>(None)</MenuItem>
                            <MenuItem value={PioAudience.EXECUTIVE_BRIEFING}>
                              {PioAudience.EXECUTIVE_BRIEFING}
                            </MenuItem>
                            <MenuItem value={PioAudience.INTERVIEW_REQUEST}>
                              {PioAudience.INTERVIEW_REQUEST}
                            </MenuItem>
                            <MenuItem value={PioAudience.MEDIA_BRIEFING}>
                              {PioAudience.MEDIA_BRIEFING}
                            </MenuItem>
                            <MenuItem value={PioAudience.PRESS_RELEASE}>
                              {PioAudience.PRESS_RELEASE}
                            </MenuItem>
                            <MenuItem value={PioAudience.OTHER}>
                              {PioAudience.OTHER}
                            </MenuItem>
                            { !(Object.values(PioAudience).includes(outreach.audience as PioAudience)) && (
                              <MenuItem value={outreach.audience}>{outreach.audience}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                      <CustomSelectInput props={{
                        isOpen: outreach.audience === "Other",
                        title: "Enter Audience",
                        fieldName: "Audience",
                        handleUpdate: (inputUpdate: string) => handleInputChange(inputUpdate, "audience"),
                        handleCancel: () => handleInputChange("","audience"),
                      }} />
                    </Grid>
                    <Grid container item xs={6}>
                      <Grid item xs={12}>
                        <Box mt={1}>
                          Add Links: 
                        </Box>
                      </Grid>
                      <Grid item xs={10}>
                        {currentLink !== "" && 
                          <Box mt={1} fontStyle="italic" fontSize="12px">Click the checkmark to confirm your link</Box>
                        }
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid container item xs={12}>
                          <Grid item xs={10}>
                            <TextField
                              id="links"
                              variant="outlined"
                              margin="dense"
                              label="Link"
                              type="string"
                              fullWidth
                              inputProps={{ maxLength: 300 }}
                              value={currentLink || ""}
                              onChange={(e) =>
                                handleLinkInput(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Box mt={2}>
                              <IconButton color="primary" disabled={currentLink === ""} onClick={handleAddLink}>
                                <CheckIcon />  
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                        {(outreach.links && outreach.links.length > 0) && outreach.links.map((link, index) => (
                          <Grid container item xs={12}>
                            <Grid item xs={10}>
                              <Box >
                                <Typography variant="body2"><Box fontStyle="italic" fontSize="12px" display="inline">{link}</Box></Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={1}>
                              <Tooltip title="Click to remove link">
                                <Box mt={.5}>
                                  <IconButton onClick={() => handleRemoveLink(link)}>
                                    <CloseIcon />  
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid container item xs={6}>
                      <Grid item xs={10}>
                        <Box mt={1}>
                          Add Files:
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        {/* Review: Could make a reusable image/file picker to share with notes*/}
                        <Box mt={0} pt={0}>
                          <input
                            accept="*"
                            multiple
                            style={{ display: "none" }}
                            id="outreach-file-picker"
                            name="outreach-file-picker"
                            type="file"
                            onChange={handleFileImport}
                          />
                          <Tooltip title="Click to add an attachment">
                            <label htmlFor="outreach-file-picker">
                              <IconButton component="span" size="large">
                                <AttachFileIcon />
                              </IconButton>
                            </label>
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        {fileImportData &&
                          fileImportData.length > 0 &&
                          fileImportData.map((f) => (
                            <Box m={1} key={f.id}>
                              <img
                                src={f.imgPreview}
                                alt={f.id}
                                style={{ maxHeight: "30px", maxWidth: "30px" }}
                              />
                              <Tooltip title="Click to remove attachment">
                                <IconButton
                                  onClick={() => handleClearFileData(f.imgPreview)}
                                  style={{ marginTop: "-10px" }}
                                  size="large"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ))}
                      </Grid>
                      <Grid item xs={12}>
                        {outreach.fileIds &&
                          outreach.fileIds.length > 0 &&
                          outreach.fileIds.map((fileId) => (
                            <Grid container item xs={12}>
                              <Grid item xs={10}>
                                <Box mt={1}>
                                  <Typography variant="body2"><Box fontStyle="italic" fontSize="12px" display="inline">{fileId}</Box></Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={1}>
                                <Tooltip title="Click to remove attachment">
                                  <Box mt={0}>
                                    <IconButton onClick={() => handleRemoveFile(fileId)}>
                                      <CloseIcon />  
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} xs={6}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel>Status</InputLabel>
                        <Select
                          value={outreach.status ?? ""}
                          onChange={(event: SelectChangeEvent<string>) => handleSelectChange(event, "status")}
                          label="Status"
                        >
                          <MenuItem value={""}>(None)</MenuItem>
                          <MenuItem value={PioStatus.COMPLETE}>
                            {PioStatus.COMPLETE}
                          </MenuItem>
                          <MenuItem value={PioStatus.IN_PROGRESS}>
                            {PioStatus.IN_PROGRESS}
                          </MenuItem>
                          <MenuItem value={PioStatus.REQUESTED}>
                            {PioStatus.REQUESTED}
                          </MenuItem>
                          <MenuItem value={PioStatus.SCHEDULED}>
                            {PioStatus.SCHEDULED}
                          </MenuItem>
                          <MenuItem value={PioStatus.OTHER}>
                            {PioStatus.OTHER}
                          </MenuItem>
                          { !(Object.values(PioStatus).includes(outreach.status as PioStatus)) && (
                            <MenuItem value={outreach.status}>{outreach.status}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <CustomSelectInput props={{
                        isOpen: outreach.status === "Other",
                        title: "Enter Status",
                        fieldName: "Status",
                        handleUpdate: (inputUpdate: string) => handleInputChange(inputUpdate, "status"),
                        handleCancel: () => handleInputChange("","status"),
                      }} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel>Agency</InputLabel>
                        <Select
                          value={outreach.agency ?? ""}
                          onChange={handleSelectAgency}
                          label="Agency"
                        >
                          <MenuItem value={""}>(None)</MenuItem>
                          <MenuItem value={"KCM"}>
                            <AgencyChip props={{ agency: "KCM" }} />
                          </MenuItem>
                          <MenuItem value={"NWSA"}>
                            <AgencyChip props={{ agency:"NWSA" }} />
                          </MenuItem>
                          <MenuItem value={"Port"}>
                            <AgencyChip props={{ agency:"Port" }} />
                          </MenuItem>
                          <MenuItem value={"SDOT"}>
                            <AgencyChip props={{ agency:"SDOT" }} />
                          </MenuItem>
                          <MenuItem value={"SFD"}>
                            <AgencyChip props={{ agency:"SFD" }} />
                          </MenuItem>
                          <MenuItem value={"SPD"}>
                            <AgencyChip props={{ agency:"SPD" }} />
                          </MenuItem>
                          <MenuItem value={"ST"}>
                            <AgencyChip props={{ agency:"ST" }} />
                          </MenuItem>
                          <MenuItem value={"WSDOT"}>
                            <AgencyChip props={{ agency:"WSDOT" }} />
                          </MenuItem>
                          <MenuItem value={"WSP"}>
                            <AgencyChip props={{ agency:"WSP" }} />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel>Recipient</InputLabel>
                        <Select
                          value={outreach.recipient ?? ""}
                          onChange={(event: SelectChangeEvent<string>) => handleSelectChange(event, "recipient")}
                          label="Recipient"
                        >
                          <MenuItem value={""}>(None)</MenuItem>
                          <MenuItem value={PioRecipient.EXECUTIVES}>
                            {PioRecipient.EXECUTIVES}
                          </MenuItem>
                          <MenuItem value={PioRecipient.ELECTED_OFFICIALS}>
                            {PioRecipient.ELECTED_OFFICIALS}
                          </MenuItem>
                          <MenuItem value={PioRecipient.GEN_PUBLIC}>
                            {PioRecipient.GEN_PUBLIC}
                          </MenuItem>
                          <MenuItem value={PioRecipient.NATIONAL_MEDIA}>
                            {PioRecipient.NATIONAL_MEDIA}
                          </MenuItem>
                          <MenuItem value={PioRecipient.LOCAL_MEDIA}>
                            {PioRecipient.LOCAL_MEDIA}
                          </MenuItem>
                          <MenuItem value={PioRecipient.OTHER}>
                            {PioRecipient.OTHER}
                          </MenuItem>
                          { !(Object.values(PioRecipient).includes(outreach.recipient as PioRecipient)) && (
                            <MenuItem value={outreach.recipient}>{outreach.recipient}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <CustomSelectInput props={{
                        isOpen: outreach.recipient === "Other",
                        title: "Enter Recipient",
                        fieldName: "Recipient",
                        handleUpdate: (inputUpdate: string) => handleInputChange(inputUpdate, "recipient"),
                        handleCancel: () => handleInputChange("","recipient"),
                      }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Box mt={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            label="Scheduled Time"
                            ampm={false}
                            value={outreach.scheduledTime || ""}
                            onChange={(e) =>
                              handleInputChange(e, "scheduledTime")
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel>Recurring</InputLabel>
                        <Select
                          value={outreach.isRecurring || "No"}
                          onChange={(event: SelectChangeEvent<string>) => handleSelectChange(event, "isRecurring")}
                          label="Recurring"
                        >
                          <MenuItem value={""}>(None)</MenuItem>
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    onClick={handleCancel}
                    color="inherit"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={submitBtnDisabled || isRefreshIsRequired}
                  >
                    {props.isAddOutreach ? "Create " : "Edit "} Scheduled Outreach
                  </Button>
                </DialogActions>
              </DialogContent>
            </Grid>
          </Box>
        </Dialog>
        <ConfirmationModal 
          props={{
            isOpen: editedConfirmation,
            title: "Unsaved Changes",
            message: "You have unsaved changes, do you want to discard them?",
            cancelText: "Discard",
            confirmText: "Continue Editing",
            handleCancel: resetAndCloseForm,
            handleConfirm: () => setEditedConfirmation(false),
          }} 
        />
        <UpdateFailureModal props={{
            isOpen: isOverwriteError,
            recordTypeContent: "Scheduled Outreach",
            handleCloseAndDiscard: handleRefreshForm,
            handleCloseAndEdit: handleContinueEditing
          }}
        />
        <UploadRulesModal
          props={{
            isOpen: errorRuleOpen,
            importErrors: importErrors,
            handleClose: handleCloseErrorModal,
          }}
        />
        <Snackbar
          open={errorMessage !== ""}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setErrorMessage("")}
        >
          <Alert onClose={() => setErrorMessage("")} severity="error">
            {errorMessage}:{" "}
            {ShortIdUtil(props.incidentId)}
          </Alert>
        </Snackbar>
        <Snackbar
            open={isRefreshIsRequired}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="warning"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={handleRefreshForm}
                >
                  Refresh
                </Button>
              }
            >
              Refresh is required before saving updates
            </Alert>
          </Snackbar>
        <Snackbar
          open={successMessage !== ""}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setSuccessMessage("")}
        >
          <Alert onClose={() => setSuccessMessage("")} severity="success">
            {successMessage}:{" "}
            {ShortIdUtil(props.incidentId)}
          </Alert>
        </Snackbar>
      </>
    );
  };
  
  export default AddEditScheduledOutreachModal;
  