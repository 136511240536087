import {
  Box,
  InputLabel,
  Select,
  Tooltip,
  FormControl,
  OutlinedInput,
  Input,
  Icon,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { FC, useState } from "react";

interface ViewEditSelectProps {
  props: {
    id: string;
    label: string;
    tooltip?: string;
    initialValue?: string;
    isEdit: boolean;
    handleInputChange: (newValue: any, field: string) => void;
  };
}

const ViewEditSelect: FC<ViewEditSelectProps> = ({ props, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltip = (isOpen: boolean) => {
    setTooltipOpen(isOpen);
  };

  return (
    <Box m={1} style={{ maxHeight: "36px" }}>
      <Tooltip open={tooltipOpen} title={props.tooltip ?? ""}>
        <FormControl
          variant={props.isEdit ? "outlined" : "standard"}
          margin="dense"
          size="small"
          fullWidth
        >
          <InputLabel
            shrink={props.isEdit || (props.initialValue ? true : false)}
          >
            {props.label}
          </InputLabel>
          <Select
            onMouseEnter={() => {
              handleTooltip(true);
            }}
            onMouseLeave={() => {
              handleTooltip(false);
            }}
            onOpen={() => {
              handleTooltip(false);
            }}
            onChange={(e) => props.handleInputChange(e.target.value, props.id)}
            label={props.label}
            value={props.initialValue || ""}
            fullWidth
            inputProps={{ 
              readOnly: !props.isEdit 
            }}
            input={
              props.isEdit ? (
                <OutlinedInput notched={true} label={props.label} />
              ) : (
                <Input />
              )
            }
            IconComponent={props.isEdit ? ArrowDropDownIcon : Icon }
          >
            {children}
          </Select>
        </FormControl>
      </Tooltip>
    </Box>
  );
};

export default ViewEditSelect;
