import {
  Box,
  Grid,
  TextField,
  IconButton,
  Button,
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../../../reducers";
import { getAllUserInfoRequest } from "../../../../actions";
import { AllUserInfoModel } from "../../../../reducers/states/all-user-info";

interface CollaboratorsInputProps {
  props: {
    collaborators: string[];
    handleCollaboratorChange: (value: string[]) => void;
    handleLeadChange: (index: number) => void;
  };
}
const CollaboratorsInput: FC<CollaboratorsInputProps> = ({ props }) => {
  const dispatch = useDispatch();
  const [leadIndex, setLeadIndex] = useState(0);
  const userOptions = useSelector( (state: IRootReducer) => state.allUserInfoReducer.allUserInfo);
  
  useEffect(() => {
    dispatch(getAllUserInfoRequest());
  },[dispatch])

  const addValue = () => {
    props.handleCollaboratorChange([...props.collaborators, ""]);
  };
  const handleValueChange = (index: number, e: string) => {
    const updatedValues = props.collaborators.map((value, i) => {
      if (i === index) {
        return e;
      } else {
        return value;
      }
    });
    props.handleCollaboratorChange(updatedValues);
  };
  const deleteValue = (item: string) => {
    props.handleCollaboratorChange(
      props.collaborators.filter((j) => j !== item)
    );
  };
  const getUserModel = (email: string) => {
    let option = null;
    userOptions.forEach((user: AllUserInfoModel) => {
      if(user.email === email)
      {
        option = user;
      }
        
    })
    return option
  }

  const handleLeadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let index = Number(event.target.value) ?? 0;
    setLeadIndex(index);

    props.handleLeadChange(index);
  };

  return (
    <Paper variant="outlined" style={{ marginTop: 1 }}>
      <Box m={1}>
        <Grid container justifyContent="space-between">
          <Grid item xs={10}>
            Collaborators
          </Grid>{" "}
          <Grid item xs={2}>
            Lead
          </Grid>
        </Grid>
      </Box>
      <Box>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={leadIndex}
          name="collab-radio-buttons-group"
          value={leadIndex}
          onChange={handleLeadChange}
        >
          {props.collaborators &&
            props.collaborators.map((item, index) => (
              <Box m={1} key={"item" + index} style={{ height: "55px" }}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={10}>
                    <Autocomplete
                      disablePortal
                      onChange={(event: any, newValue: AllUserInfoModel | null) => {
                        if (newValue != null){
                          handleValueChange(index, newValue!.email!);
                        }
                      }}
                      id={"collaborator" + index}
                      options={userOptions}
                      defaultValue={getUserModel(item)}
                      renderInput={(params) => <TextField {...params} variant="outlined" margin="dense" fullWidth label="Collaborator Name" />
                    }
                    />
                  </Grid>
                  <Grid container item xs={2} justifyContent="flex-start">
                    <FormControlLabel
                      value={index}
                      control={<Radio />}
                      label=""
                      style={{ marginRight: "1px" }}
                    />
                    {index > 0 && (
                      <Grid>
                        <IconButton
                          onClick={() => deleteValue(item)}
                          aria-label="delete"
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
        </RadioGroup>
        {props.collaborators && props.collaborators.length < 5 && (
          <Button onClick={addValue} color="primary">
            Add
          </Button>
        )}
      </Box>
    </Paper>
  );
};
export default CollaboratorsInput;