import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  Paper,
  Popper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SyncIcon from "@mui/icons-material/Sync";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import MenuList from "@mui/material/MenuList";
import { Link } from "react-router-dom";
import React, { FC, useRef, useState, useEffect, useCallback } from "react";
import { UTCFromTimeFrameUtil, UTCToOneMinuteAheadUtil } from "../../../utils/date-utils";
import {
  getDispatchFeedEventsRequest,
  getFeedStatusRequest,
  getNoteworthyEventsRequest,
  getPinnedDispatchEventsRequest,
} from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { FeedView } from "../../../enums/feed-view";

const useStyles = makeStyles(() => ({
  moreActionsMenu: {
    maxWidth: "150px",
    position: "relative",
    left: "-70px",
  },
}));

interface MoreActionsMenuProps {
  props: {
    isDashDisplay?: boolean;
  };
}

const MoreActionsMenu: FC<MoreActionsMenuProps> = ({ props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  const timeFrame = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.timeFrame
  );
  const feedView = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer.feedView
  );
  const showOpenDispatchesOnly = useSelector(
    (state: IRootReducer) =>
      state.dispatchEventFeedReducer.showOpenDispatchesOnly
  );

  const region = useSelector(
    (state: IRootReducer) =>
      state.dispatchEventFeedReducer.region
  );
  const area = useSelector(
    (state: IRootReducer) =>
      state.dispatchEventFeedReducer.area
  );

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: TouchEvent | globalThis.MouseEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleOnClickClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleFeedRefresh = useCallback(
    (fromTimeStamp: Date, toTimeStamp: Date) => {
      dispatch(getPinnedDispatchEventsRequest(fromTimeStamp, toTimeStamp));
      dispatch(getFeedStatusRequest());
      switch (feedView) {
        case FeedView.ALL:
          dispatch(
            getDispatchFeedEventsRequest(
              fromTimeStamp,
              toTimeStamp,
              showOpenDispatchesOnly,
              region,
              area,
              false // set map data
            )
          );
          break;
        case FeedView.NOTEWORTHY:
          dispatch(getNoteworthyEventsRequest());
          break;
      }
    },
    [dispatch, feedView, showOpenDispatchesOnly, region, area]
  );

  return (
    <>
      <IconButton
        ref={anchorRef}
        edge="start"
        aria-label="menu"
        onClick={handleOpen}
        size="large"
      >
        <MoreVertIcon />
        <Popper
          style={{ zIndex: 2500 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.moreActionsMenu}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {props.isDashDisplay && (
                      <MenuItem
                        component={Link}
                        to={"/feed"}
                        onClick={handleOnClickClose}
                      >
                        <Grid container>
                          <Grid item xs={11}>
                            <Box mr={2}>Fullscreen</Box>
                          </Grid>
                          <Grid item xs={1}>
                            <ListItemIcon>
                              <LaunchOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() =>
                        handleFeedRefresh(
                          UTCFromTimeFrameUtil(timeFrame),
                          UTCToOneMinuteAheadUtil()
                        )
                      }
                    >
                      <Grid container>
                        <Grid item xs={11}>
                          <Box mr={2}>Refresh</Box>
                        </Grid>
                        <Grid item xs={1}>
                          <ListItemIcon>
                            <SyncIcon fontSize="small" />
                          </ListItemIcon>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </IconButton>
    </>
  );
};

export default MoreActionsMenu;
