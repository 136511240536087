import axios from "axios";
import { PagingKeys } from "../../reducers";
import {
  UpdateIncidentDispatchRequest,
  Note,
  VccIncidentModel,
  IncidentAction,
  IncidentTalkingPoint,
  IncidentOutreach,
} from "../../reducers/states/vcc-incident";

// VCC Incident - Base
export const getVccIncident = (id: string): any => {
  return axios.get("/Incidents/v1/" + id);
};
export const downloadIncidentReport = (
  id: string,
  fileDisplayName?: string
): any => {
  if (!fileDisplayName) return axios.get(`/Incidents/v1/${id}/report`);
  return axios.get(`/Incidents/v1/${id}/report`, {
    params: { fileDisplayName: fileDisplayName },
  });
};
export const getVccIncidents = (status: string, pageSize: string, pagingKeys: PagingKeys): any => {
  let queryParams: {
    status: string;
    pageSize: string;
    pk1: string;
    sk1: string;
    pk2: string;
    sk2: string;
  } = {
    status,
    pageSize,
    pk1: pagingKeys.pk1,
    sk1: pagingKeys.sk1,
    pk2: pagingKeys.pk2,
    sk2: pagingKeys.sk2,
  }
  return axios.get("/Incidents/v2", { params: queryParams });
};
export const createVccIncident = (vccIncident: VccIncidentModel): any => {
  return axios.post("/Incidents/v1", vccIncident);
};
export const updateVccIncident = (
  incident: VccIncidentModel,
  associatedIds: UpdateIncidentDispatchRequest[]
) => {
  return axios.put("/Incidents/v1/" + incident.id, {
    incident: incident,
    incidentDispatchUpdates: associatedIds,
  });
};

// Notes
export const createVccIncidentNote = (note: Note, id: string): any => {
  return axios.post("/Incidents/v1/" + id + "/notes", note);
};

export const getVccIncidentNotes = (incidentId: string) => {
  return axios.get("/Incidents/v1/" + incidentId + "/notes");
};

// Mobility Strategies/CollabActions
export const getVccIncidentAction = (incidentId: string, actionId: string, timeAdded: string | Date) => {
  let queryParams: {
    timeAdded: string | Date;
  } = {
    timeAdded
  };

  return axios.get("/Incidents/v1/" + incidentId + "/collaboration/" + actionId, { params: queryParams });
};
export const getVccIncidentActions = (incidentId: string) => {
  return axios.get("/Incidents/v1/" + incidentId + "/collaboration");
};
export const createVccIncidentAction = (
  incidentAction: IncidentAction,
  id: string
): any => {
  return axios.post("/Incidents/v1/" + id + "/collaboration", incidentAction);
};
export const modifyVccIncidentAction = (
  incidentAction: IncidentAction,
  id: string
): any => {
  return axios.put("/Incidents/v1/" + id + "/collaboration/" + incidentAction.id, incidentAction);
};

// PIO - Talking Points
export const getTalkingPoint = (incId: string, talkingPointId: string) : any => {
  return axios.get("/Incidents/v1/" + incId + "/talkingpoints/" + talkingPointId);
}
export const getTalkingPoints = (incId: string) : any => {
  return axios.get("/Incidents/v1/" + incId + "/talkingpoints");
}
export const createTalkingPoint = (
  talkingPoint: IncidentTalkingPoint,
  incId: string
): any => {
  return axios.post("/Incidents/v1/" + incId + "/talkingpoints", talkingPoint);
};
export const updateTalkingPoint = (
  talkingPoint: IncidentTalkingPoint,
  incId: string
): any => {
  return axios.put("/Incidents/v1/" + incId + "/talkingpoints/" + talkingPoint.id, talkingPoint);
};
export const getTalkingPointHistory = (incId: string, talkingPointId: string) => {
  return axios.get("/Incidents/v1/" + incId + "/talkingpoints/" + talkingPointId + "/history");
}
export const getUploadTalkingPointFilePresignedUrl = (id: string, talkingPointId: string, fileId: string): any => {
  return axios.put("/Incidents/v1/" + id + "/talkingpoints/" + talkingPointId + "/files", { fileId });
};
export const getDownloadTalkingPointFilePresignedUrl = (id: string, talkingPointId: string, fileId: string): any => {
  return axios.get("/Incidents/v1/" + id + "/talkingpoints/" + talkingPointId + "/files", { params: { fileId } });
};

// PIO - Scheduled Outreach
export const getOutreach = (incId: string, outreachId: string) : any => {
  return axios.get("/Incidents/v1/" + incId + "/outreach/" + outreachId);
}
export const getOutreaches = (incId: string) : any => {
  return axios.get("/Incidents/v1/" + incId + "/outreach");
}
export const createOutreach = (
  outreach: IncidentOutreach,
  incId: string
): any => {
  return axios.post("/Incidents/v1/" + incId + "/outreach", outreach);
};
export const updateOutreach = (
  outreach: IncidentOutreach,
  incId: string
): any => {
  return axios.put("/Incidents/v1/" + incId + "/outreach/" + outreach.id, outreach);
};
export const getOutreachHistory = (incId: string, outreachId: string) => {
  return axios.get("/Incidents/v1/" + incId + "/outreach/" + outreachId + "/history");
}
export const getUploadOutreachFilePresignedUrl = (id: string, outreachId: string, fileId: string): any => {
  return axios.put("/Incidents/v1/" + id + "/outreach/" + outreachId + "/files", { fileId });
};
export const getDownloadOutreachFilePresignedUrl = (id: string, outreachId: string, fileId: string): any => {
  return axios.get("/Incidents/v1/" + id + "/outreach/" + outreachId + "/files", { params: { fileId } });
};

// S3 Presigned Url
export const getImgUploadPresignedUrl = (id: string, imgId: string): any => {
  return axios.put("/Incidents/v1/" + id + "/images", { imgId });
};
export const getImgDownloadPresignedUrl = (id: string, imgId: string): any => {
  return axios.get("/Incidents/v1/" + id + "/images", { params: { imgId } });
};
export const uploadToS3 = async (
  presignedUrl: string,
  blobData: any,
  fileType: string
): Promise<any> => {
  var instance = axios.create();
  return instance.put(presignedUrl, blobData, {
    headers: { "Content-Type": fileType },
  });
};

// Incident History
export const getVccIncidentHistory = (incidentId: string) => {
  return axios.get("/Incidents/v1/" + incidentId + "/history");
};

// Records Retention
export const finalizeIncidentReport = (
  incidentId: string,
  email: string,
  token: string
) => {
  return axios.post("Incidents/v1/" + incidentId + "/report/finalize", {
    email: email,
    recordsRetentionWorkflowToken: token,
  });
};
