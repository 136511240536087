import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import React, { FC } from "react";
import { VccNotification } from "../../../../reducers/states/vcc-incident";

interface NotificationItemProps {
  props: {
    notification: VccNotification;
    handleClearNotification: (notifId: string) => void;
  };
}

const NotificationItem: FC<NotificationItemProps> = ({ props }) => {
  return (
    <>
      <ListItem>
        <Grid container>
          <Grid item xs={9}>
            <Box ml={1} mr={1}>
              <ListItemText primary={props.notification.message} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box ml={1} mr={1}>
              <Link
                style={{ fontSize: "12px" }}
                to={"/incident-model/" + props.notification.id}
                onClick={() => props.handleClearNotification(props.notification.id)}
              >
                Go to Incident
              </Link>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <ListItemSecondaryAction>
              <Box mb={2}>
                <IconButton
                  edge="end"
                  aria-label="clear"
                  onClick={() => props.handleClearNotification(props.notification.id)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </ListItemSecondaryAction>
          </Grid>
          <Grid item xs={12}>
            <Box m={1}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default NotificationItem;
