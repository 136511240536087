import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC } from "react";

export interface CameraData {
  location: string;
  imgUrl: string;
}

interface CameraProps {
  props: {
    isOpen: boolean;
    cameraData: CameraData;
    handleClose: () => void;
  };
}

const CameraModal: FC<CameraProps> = ({ props }) => {
  return (
    <>
      <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth>
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={11}>
              <Box mt={1} mb={0}>
                <DialogTitle>
                  Camera Feed: {props.cameraData.location}{" "}
                </DialogTitle>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={props.handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent dividers>
              <Box m={1} display="flex" justifyContent="center">
                <img
                  src={props.cameraData.imgUrl}
                  style={{maxHeight: '300px'}}
                  alt={"traffic camera at " + props.cameraData.location}
                />
              </Box>
              <Box m={1}>
                <Button
                  style={{ float: "right" }}
                  onClick={() =>
                    window.open(
                      props.cameraData.imgUrl,
                      "_blank"
                    )
                  }
                >
                  Full Size
                </Button>
              </Box>
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default CameraModal;
