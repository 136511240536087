import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  redirectBox: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  explanationBox: {
    overflow: "hidden",
  },
  redirectButton: {
    float: "right",
  },
}));

export default function ErrorPage() {
  const classes = useStyles();

  return (
    <Box width="40%" className={classes.redirectBox}>
      <Typography variant="h3">Error</Typography>
      <Paper>
        <Box p={1} className={classes.explanationBox}>
          <Typography variant="h6">Hmmm, something when wrong</Typography>
          <Button
            className={classes.redirectButton}
            variant="contained"
            color="primary"
            onClick={() => window.open(`/#/`, "_blank")}
          >
            Return to home
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
