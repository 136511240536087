export enum IncidentStoreError {
  // Incident
  VCC_INCIDENT_SAVE = "Error Saving VCC Incident",
  VCC_INCIDENT_UPDATE = "Error Updating VCC Incident",
  VCC_INCIDENT_OVERWRITE_ERROR = "Overwrite Error",
  VCC_INCIDENT_GET = "Error loading VCC Incident",
  VCC_INCIDENT_REPORT_DOWNLOAD = "Error downloading VCC Incident Report",
  VCC_INCIDENT_REPORT_FINALIZE = "Error finalizing VCC Incident Report",
  VCC_INCIDENTS_GET = "Error loading VCC Incidents",
  IS_INC_UPDATE_AVAIL_GET = "Error checking if VCC Incident updates are available",
  NEW_INC_CREATED_FAILURE = "Error processing a new incident notification",
  INIT_NEW_INC_NOTIFS_FAILURE = "Error initializing new incident notifications",
  CLEAR_ALL_NOTIFS_FAILURE = "Error clearing notifications",
  CLEAR_NOTIF_FAILURE = "Error clearing a notification",

  // Note
  VCC_NOTES_SAVE = "Error saving Note",
  VCC_NOTES_GET = "Error loading VCC Incident Notes",
  VCC_NOTES_IMAGE_GET = "Error downloading image",
  VCC_NOTES_IMAGE_UPLOAD = "Error uploading image",

  // Mobility Strategies/Collaboration Actions
  VCC_INCIDENT_ACTION_GET = "Error Loading VCC Incident Action",
  VCC_INCIDENT_ACTIONS_GET = "Error Loading VCC Incident Actions",
  VCC_INCIDENT_ACTIONS_SAVE = "Error Saving Incident Action",
  VCC_INCIDENT_ACTIONS_MODIFY = "Error Modifying Incident Action",
  VCC_INCIDENT_ACTIONS_OVERWRITE_ERROR = "Overwrite Error While Modifying Incident Action",

  // Talking Points
  TALKING_POINT_GET = "Error Loading VCC Incident Talking Point",
  TALKING_POINTS_GET = "Error Loading VCC Incident Talking Points",
  TALKING_POINT_SAVE = "Error Saving Incident Talking Point",
  TALKING_POINT_UPDATE = "Error Modifying Incident Talking Point",
  TALKING_POINT_OVERWRITE_ERROR = "Overwrite Error While Modifying Incident Talking Point",
  TALKING_POINT_HISTORY_GET = "Error retrieving history for Talking Point",
  TALKING_POINT_FILE_GET = "Error downloading file for Talking Point",
  TALKING_POINT_FILE_UPLOAD = "Error uploading file for Talking Point",

  // Scheduled Outreach
  OUTREACH_GET = "Error Loading VCC Incident Scheduled Outreach",
  OUTREACHES_GET = "Error Loading VCC Incident Scheduled Outreaches",
  OUTREACH_SAVE = "Error Saving Incident Scheduled Outreach",
  OUTREACH_UPDATE = "Error Modifying Incident Scheduled Outreach",
  OUTREACH_OVERWRITE_ERROR = "Overwrite Error While Modifying Incident Scheduled Outreach",
  OUTREACH_HISTORY_GET = "Error retrieving history for Scheduled Outreach",
  OUTREACH_FILE_GET = "Error downloading file for outreach",
  OUTREACH_FILE_UPLOAD = "Error uploading file for outreach",
  
  // History
  VCC_HISTORY_GET = "Error loading VCC Incident History",
}

export enum IncidentStoreSuccess {
  // Incident
  INCIDENT_SAVE = "Successfully saved VCC Incident",
  INCIDENT_UPDATE = "Successfully updated VCC Incident",
  
  // Notes
  NOTES_SAVE = "Successfully saved Note",
  NOTES_IMAGE_SAVE = "Successfully upload image",
  
  // Collaboration Action / Mobility Strategies
  COLLAB_ACTION_SAVE = "Successfully created Incident Action",
  COLLAB_ACTION_MODIFY = "Successfully updated Incident Action",

  // Talking Points
  TALKING_POINT_SAVE = "Successfully created Incident Talking Point",
  TALKING_POINT_UPDATE = "Successfully updated Incident Talking Point",
  TALKING_POINT_FILE_SAVE = "Successfully uploaded file to Talking Point",

  // Scheduled Outreach
  OUTREACH_SAVE = "Successfully created Incident Scheduled Outreach",
  OUTREACH_UPDATE = "Successfully updated Incident Scheduled Outreach",
  OUTREACH_FILE_SAVE = "Successfully uploaded file to outreach",

  // Records Retention
  VCC_INCIDENT_REPORT_FINALIZE = "Successfully Finalized Incident!",

}
