import {
Alert,
Box,
Button,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
DialogTitle,
Divider,
FormControl,
Grid,
IconButton,
InputLabel,
MenuItem,
Paper,
Select,
Snackbar,
TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVccIncidentActionRequest, getVccIncidentActionsRequest, modifyVccIncidentActionRequest } from "../../../actions";
import { useAuthContext } from "../../../core/auth-context";
import {
IncidentAction,
} from "../../../reducers/states/vcc-incident";
import { AgencyChip } from "../../common/agency-chip";
import { ActionStatus } from "../../../enums/action-status";
import { ActionStatusChip } from "../action-status-chip";
import { ActionType } from "../../../enums/action-type";
import CollaboratorsInput from "../add-action-modal/collaborators-input";
import { ShortIdUtil } from "../../../utils/id-utils";
import { IRootReducer } from "../../../reducers";
import { IncidentStoreError, IncidentStoreSuccess } from "../../../enums/store-messages/incident";
import UpdateFailureModal from "../../incident-model/update-failure-modal";
import { IsTimePlannedValidUtil } from "../../../utils/date-utils";
import ConfirmationModal from "../../common/confirmation-modal";

interface ModifyActionProps {
  props: {
    isOpen: boolean;
    incidentId: string;
    handleClose: () => void;
  };
}

const ModifyActionModal: FC<ModifyActionProps> = ({ props }) => {
  const [errorExists, setErrorExists] = useState(false);
  const [action, setAction] = useState({} as IncidentAction);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [isEdited, setIsEdited ] = useState(false);
  const [editedConfirmation, setEditedConfirmation] = useState(false);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [isOverwriteError, setIsOverwriteError] = useState(false);
  const [isRefreshIsRequired, setIsRefreshRequired] = useState(false);
  const { authState } = useAuthContext();
  const dispatch = useDispatch();
  
  const incidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer
  );

  const resetForm = useCallback(() => {
    setAction({
      id: "",
      description: "",
      type: "",
      agency: "",
      createdBy: "",
      lead: authState.email,
      status: "",
      timeAdded: "",
      fromTimePlanned: "",
      toTimePlanned: "",
      collaborators: [],
      mentions: "",
      removalReason: "",
      ccId: ""
    } as IncidentAction);
    setIsEdited(false);
    setRemoveConfirmation(false);
    setEditedConfirmation(false);
  }, [authState.email]);

  const resetAndCloseForm = useCallback(() => {
    dispatch(getVccIncidentActionsRequest(props.incidentId));
    setIsOverwriteError(false);
    setIsRefreshRequired(false);
    resetForm();
    props.handleClose();
  }, [dispatch, props, resetForm])

  const handleCollaboratorChange = useCallback(
    (value: string[]) => {
      setIsEdited(true);
      setAction((current) => ({ ...current, collaborators: value }));
    },
    [setAction]
  );
  
  const handleLeadChange = useCallback(
    (index: number) => {
      setIsEdited(true);
      setAction((current) => ({
        ...current,
        lead: action.collaborators[index],
      }));
    },
    [action.collaborators]
  );

  useEffect(() => {
    setAction(incidentStore.action);
    setIsOverwriteError(false);
    setIsRefreshRequired(false);
  }, [incidentStore.action])

  useEffect(() => {
    if (action.description !== "") {
      if (action.status === ActionStatus.PLANNED){
        if(IsTimePlannedValidUtil(action.fromTimePlanned, action.toTimePlanned)){
          setSubmitBtnDisabled(false);
        }
        else{
          setSubmitBtnDisabled(true);
        }
      }
      else{
        setSubmitBtnDisabled(false);
      }
    }
    else {
      setSubmitBtnDisabled(true);
    }
  }, [action.agency, action.description, action.fromTimePlanned, action.status, action.toTimePlanned]);

  useEffect(() => {
    if (action.status === ActionStatus.PLANNED && action.fromTimePlanned === "" && action.toTimePlanned === "") {
      let currentTime = moment();
      setAction((current) => (
        { ...current,
          "fromTimePlanned": currentTime.format("HH:mm"),
          "toTimePlanned": currentTime.add(1, "hours").format("HH:mm")
         }));
    } else if(action.status !== ActionStatus.PLANNED) {
      setAction((current) => (
        { ...current, 
          "fromTimePlanned": "", 
          "toTimePlanned": ""
        }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action.status]);

  useEffect(() => {
    setAction((current) => ({
      ...current,
      collaborators: [authState.email ?? ""],
      lead: authState.email ?? "",
    }));
  }, [authState.email]);

  useEffect(() => {
    if(incidentStore.successMessage){
      switch(incidentStore.successMessage){
        case IncidentStoreSuccess.COLLAB_ACTION_MODIFY:
          resetForm();
          props.handleClose();
          break;
      }
    }
  }, [incidentStore.successMessage, props, resetForm]);

  useEffect(() => {
    if(incidentStore.failureMessage){
      switch(incidentStore.failureMessage){
        case IncidentStoreError.VCC_INCIDENT_ACTIONS_OVERWRITE_ERROR:
          setIsOverwriteError(true)
          break;
      }
    }
  }, [incidentStore.failureMessage]);

  const handleCloseErrorAlert = () => {
    setErrorExists(false);
  };
  const handleInputChange = (e: any, field: string) => {
    let value: string = e as string;
    setIsEdited(true);
    setAction((current) => ({ ...current, [field]: value }));
  };
  const handleCancel = () => {
    if(isEdited && !isRefreshIsRequired){
      setEditedConfirmation(true);
    }
    else{
      resetAndCloseForm();
    }
  };

  const handleRemove = () => {
    let modifiedAction = { ...action, status: ActionStatus.REMOVED };
    let regex = /[^a-zA-Z0-9/!%"'‘’“”~@#$^*()_+=[\]{}|\\,.? :-]/g;
    modifiedAction.removalReason = modifiedAction.removalReason.replace(regex, '');
    dispatch(modifyVccIncidentActionRequest(props.incidentId, modifiedAction));
  };

  const handleContinueEditing = () => {
    setIsOverwriteError(false);
    setIsRefreshRequired(true);
  }

  const handleRefreshForm = () => {
    dispatch(getVccIncidentActionRequest(props.incidentId, action.id, action.timeAdded));
  }

  const handleModifyAndClose = () => {
    let modifiedAction = { ...action, removalReason: "" };
    dispatch(modifyVccIncidentActionRequest(props.incidentId, modifiedAction));
  };

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={11}>
              <Box mt={1} mb={0}>
                <DialogTitle id="form-dialog-title">
                  Modify Action for VCC Incident:{" "}
                  {ShortIdUtil(props.incidentId)}
                </DialogTitle>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={6} direction="column">
                  <TextField
                    id="description"
                    variant="outlined"
                    autoFocus
                    required
                    margin="dense"
                    label="Action Description"
                    type="string"
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 300 }}
                    value={action.description || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, e.target.id)
                    }
                  />
                  <CollaboratorsInput
                    props={{
                      collaborators: action.collaborators,
                      handleCollaboratorChange: handleCollaboratorChange,
                      handleLeadChange: handleLeadChange,
                    }}
                  />
                </Grid>
                <Grid container item xs={12} md={6} direction="column">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {action.status === ActionStatus.REMOVED? (
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            id="status"
                            fullWidth
                            value={action.status || ActionStatus.PLANNED}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "status")
                            }
                            label="Status"
                          >
                            <MenuItem value={ActionStatus.PLANNED}>
                              <ActionStatusChip
                                value={ActionStatus.PLANNED}
                              ></ActionStatusChip>
                            </MenuItem>
                            <MenuItem value={ActionStatus.COMPLETE}>
                              <ActionStatusChip
                                value={ActionStatus.COMPLETE}
                              ></ActionStatusChip>
                            </MenuItem>
                            <MenuItem value={ActionStatus.IN_PROGRESS}>
                              <ActionStatusChip
                                value={ActionStatus.IN_PROGRESS}
                              ></ActionStatusChip>
                            </MenuItem>
                            <MenuItem value={ActionStatus.REMOVED}>
                              <ActionStatusChip
                                value={ActionStatus.REMOVED}
                              ></ActionStatusChip>
                            </MenuItem>
                          </Select>
                        </FormControl>
                        ):(
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            id="status"
                            fullWidth
                            value={action.status || ActionStatus.PLANNED}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "status")
                            }
                            label="Status"
                          >
                            <MenuItem value={ActionStatus.PLANNED}>
                              <ActionStatusChip
                                value={ActionStatus.PLANNED}
                              ></ActionStatusChip>
                            </MenuItem>
                            <MenuItem value={ActionStatus.COMPLETE}>
                              <ActionStatusChip
                                value={ActionStatus.COMPLETE}
                              ></ActionStatusChip>
                            </MenuItem>
                            <MenuItem value={ActionStatus.IN_PROGRESS}>
                              <ActionStatusChip
                                value={ActionStatus.IN_PROGRESS}
                              ></ActionStatusChip>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel>Agency</InputLabel>
                        <Select
                          value={action.agency ?? ""}
                          onChange={(e) =>
                            handleInputChange(e.target.value, "agency")
                          }
                          label="Agency"
                        >
                          <MenuItem value={""}>(None)</MenuItem>
                          <MenuItem value={"KCM"}>
                            <AgencyChip props={{ agency: "KCM" }} />
                          </MenuItem>
                          <MenuItem value={"NWSA"}>
                            <AgencyChip props={{ agency:"NWSA" }} />
                          </MenuItem>
                          <MenuItem value={"Port"}>
                            <AgencyChip props={{ agency:"Port" }} />
                          </MenuItem>
                          <MenuItem value={"SDOT"}>
                            <AgencyChip props={{ agency:"SDOT" }} />
                          </MenuItem>
                          <MenuItem value={"SFD"}>
                            <AgencyChip props={{ agency:"SFD" }} />
                          </MenuItem>
                          <MenuItem value={"SPD"}>
                            <AgencyChip props={{ agency:"SPD" }} />
                          </MenuItem>
                          <MenuItem value={"ST"}>
                            <AgencyChip props={{ agency:"ST" }} />
                          </MenuItem>
                          <MenuItem value={"WSDOT"}>
                            <AgencyChip props={{ agency:"WSDOT" }} />
                          </MenuItem>
                          <MenuItem value={"WSP"}>
                            <AgencyChip props={{ agency:"WSP" }} />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel>Mobility Strategy Type</InputLabel>
                    <Select
                      id="type"
                      fullWidth
                      value={action.type || ActionType.TRAFFIC_FLOW_MANAGEMENT}
                      onChange={(e) =>
                        handleInputChange(e.target.value, "type")
                      }
                      label="Mobility Strategy Type"
                    >
                      <MenuItem value={ActionType.TRAFFIC_FLOW_MANAGEMENT}>
                        {ActionType.TRAFFIC_FLOW_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.EQUIPMENT_MANAGEMENT}>
                        {ActionType.EQUIPMENT_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.MESSAGE_SIGN_MANAGEMENT}>
                        {ActionType.MESSAGE_SIGN_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.LANE_MANAGEMENT}>
                        {ActionType.LANE_MANAGEMENT}
                      </MenuItem>
                      <MenuItem value={ActionType.OTHER}>
                        {ActionType.OTHER}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="createdBy"
                    label="Added By"
                    type="string"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={authState.email ?? ""}
                  />
                  {action.status === ActionStatus.PLANNED && (
                    <Paper variant="outlined">
                      <Box m={1}>
                        If Status is Planned - Enter Planned Time Frame
                      </Box>
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                label="From"
                                ampm={false}
                                value={action.fromTimePlanned || ""}
                                onChange={(e) =>
                                  handleInputChange(e, "fromTimePlanned")
                                }
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              label="To"
                              ampm={false}
                              value={action.toTimePlanned || ""}
                              onChange={(e) =>
                                handleInputChange(e, "toTimePlanned")
                              }
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  )}
                </Grid>
                <Grid item xs={12}><Box m={1}><Divider /></Box></Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={() => setRemoveConfirmation(true)}
                  color="inherit"
                  variant="contained"
                  disabled={incidentStore.action.status === ActionStatus.COMPLETE}
                >
                  Remove
                </Button>
                <Box style={{flex: '1 0 0'}} />
                <Button
                  onClick={handleCancel}
                  color="inherit"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleModifyAndClose}
                  color="primary"
                  variant="contained"
                  disabled={submitBtnDisabled || isRefreshIsRequired}
                >
                  Modify Action
                </Button>
              </DialogActions>
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
      {/* Review: Should create a reusable confirm with input modal*/}
      <Dialog open={removeConfirmation}>
        <DialogTitle id="remove-form-dialog-title">
          Remove Action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to remove this action?</DialogContentText>
          <Box m={1}>
            <TextField
              id="removalReason"
              variant="outlined"
              margin="dense"
              label="Removal Reason"
              type="string"
              fullWidth
              inputProps={{ maxLength: 100 }}
              value={action.removalReason}
              onChange={(e) =>
                handleInputChange(e.target.value, e.target.id)
              }
            />
          </Box>
          <DialogActions>
            <Button onClick={() => setRemoveConfirmation(false)} color="inherit" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleRemove} color="primary" variant="contained" disabled={action.removalReason === ""}>
              Remove
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ConfirmationModal 
        props={{
          isOpen: editedConfirmation,
          title: "Unsaved Changes",
          message: "You have unsaved changes, do you want to discard them?",
          cancelText: "Discard",
          confirmText: "Continue Editing",
          handleCancel: resetAndCloseForm,
          handleConfirm: () => setEditedConfirmation(false),
        }} 
      />        
      <UpdateFailureModal props={{
          isOpen: isOverwriteError,
          recordTypeContent: "Collaboration Action",
          handleCloseAndDiscard: handleRefreshForm,
          handleCloseAndEdit: handleContinueEditing
        }}
      />
      <Snackbar
        open={errorExists}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseErrorAlert}
      >
        <Alert onClose={handleCloseErrorAlert} severity="error">
          Error Modifying Action for VCC Incident:{" "}
          {ShortIdUtil(props.incidentId)}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isRefreshIsRequired}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={handleRefreshForm}
            >
              Refresh
            </Button>
          }
        >
          Refresh is required before saving updates
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModifyActionModal;