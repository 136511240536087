import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridColDef,
  GridLoadingOverlay,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { renderCellExpand } from "../../common/data-grid/cell-expander";
import DataGridGeneralTextCell from "../../common/data-grid/general-text-cell";

interface HistoryProps {
  props: {
    isOpen: boolean;
    recordType: string;
    handleClose: () => void;
  };
}

export interface HistoryGridRow {
  id: string;
  displayName: string;
  updatedBy: string;
  updatedDate: string | Date;
  oldValue: string;
  newValue: string;
}

const HistoryModal: FC<HistoryProps> = ({ props }) => {
  const [gridData, setGridData] = useState([] as HistoryGridRow[]);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0
  })

  const incHistory = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.history
  );

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsGridLoading(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setIsGridLoading(true);
    let apiRows: HistoryGridRow[] = incHistory.reduce(function (
      results: HistoryGridRow[],
      r,
      index
    ) {
      if (r.fieldName !== "DispatchEvents" && r.fieldName !== "GeoJsonString") {
        results.push({
          id: r.fieldName + index,
          displayName: r.displayName,
          updatedBy: r.updatedBy,
          updatedDate: r.updatedDate,
          oldValue: r.oldValue,
          newValue: r.newValue,
        });
      }
      if (r.fieldName === "GeoJsonString"){
        results.push({
          id: r.fieldName + index,
          displayName: getMapItemHistoryDisplayName(r.newValue),
          updatedBy: r.updatedBy,
          updatedDate: r.updatedDate,
          oldValue: getMapItemHistoryDetails(r.oldValue),
          newValue: getMapItemHistoryDetails(r.newValue),
        });
      }

      return results;
    },
    []);
    setGridData(
      [...apiRows].sort(
        (d1, d2) =>
          new Date(d2.updatedDate).getTime() -
          new Date(d1.updatedDate).getTime()
      )
    );
    showLoadingAnimation();
  }, [incHistory, showLoadingAnimation]);

  function getMapItemHistoryDetails(geoJsonString: string){
    if(geoJsonString !== ""){
      let parsedGeo: GeoJSON.Feature = JSON.parse(geoJsonString);
      return parsedGeo.properties?.description;
    }
    else{
      return "";
    }
    
  }

  function getMapItemHistoryDisplayName(geoJsonString: string){
    if(geoJsonString !== ""){
      let parsedGeo: GeoJSON.Feature = JSON.parse(geoJsonString);
      return parsedGeo.properties?.vccType;
    }
    else{
      return "Map Item";
    }
    
  }

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: "Field",
      flex: 1,
      filterable: false,
      renderCell: (params: GridRenderCellParams) =>
        renderCellExpand(
          params,
          <DataGridGeneralTextCell props={{ text: params.row.displayName as string }} />,
          false
        ),
    },
    {
      field: "oldValue",
      headerName: "Previous Value",
      flex: 1,
      filterable: false,
      renderCell: (params: GridRenderCellParams) =>
        renderCellExpand(
          params,
          <DataGridGeneralTextCell props={{ text: params.row.oldValue as string }} />,
          false
        ),
    },
    {
      field: "newValue",
      headerName: "Updated Value",
      flex: 1,
      filterable: false,
      renderCell: (params: GridRenderCellParams) =>
        renderCellExpand(
          params,
          <DataGridGeneralTextCell props={{ text: params.row.newValue as string }} />,
          false
        ),
    },
    {
      field: "updatedBy",
      headerName: "VCC Member",
      flex: 1,
      filterable: false,
    },
    {
      field: "updatedDate",
      headerName: "Updated Time",
      flex: 1,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const updatedDate = params.row.updatedDate;
        return <Box>{moment(updatedDate as string).fromNow()}</Box>;
      },
    }
  ];

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        fullWidth
        maxWidth="lg"
      >
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={11}>
              <Box mt={1} mb={0}>
                <DialogTitle>{props.recordType} History</DialogTitle>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box mt={1.5} mb={0}>
                <IconButton onClick={props.handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <DialogContent dividers>
              <Box style={{ height: "400px" }}>
                <Paper variant="outlined" style={{ height: "100%" }}>
                  <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12} style={{ height: "90%" }}>
                      <Box style={{ height: "100%" }}>
                        <DataGrid
                          rows={gridData}
                          columns={columns}
                          paginationModel={paginationModel}
                          onPaginationModelChange={setPaginationModel}
                          disableRowSelectionOnClick
                          density={"standard"}
                          disableColumnSelector={true}
                          components={{
                            LoadingOverlay: GridLoadingOverlay,
                          }}
                          loading={isGridLoading}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </DialogContent>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default HistoryModal;
