import {
  Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
  } from "@mui/material";
  import React, { FC, useEffect, useState } from "react";
  
  interface AnnotateModalProps {
    props: {
      isOpen: boolean;
      handleSetPerimeterDetails: (radius: number, note: string) => void;
      handleClose: () => void;
    };
  }
  
  const PerimeterDetailsModal: FC<AnnotateModalProps> = ({ props }) => {
    const [radius, setRadius] = useState(.5);
    const [note, setNote] = useState("");

    useEffect(() => {
    }, [props.isOpen])
  
    const handleRadiusChange = (e: any) => {
        let value: number = e as number;
        return setRadius(value);
      };

    const handleNoteChange = (e: any) => {
      let value: string = e as string;
      return setNote(value);
    };

    const handleCancel = () => {
      props.handleClose();
    };
  
    const handleConfirm = () => {
      props.handleSetPerimeterDetails(radius, note);
      setNote("");
      setRadius(.5)
    };
  
    return (
      <>
        <Dialog
          open={props.isOpen}
          onClose={handleCancel}
          aria-labelledby="annotate-notes-dialog-title"
        >
          <DialogTitle id="formn-notate-notes-dialogtitle">
            Set Perimeter Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter details and then choose location on map
            </DialogContentText>
            <Box m={1}>
              <TextField
                variant="outlined"
                margin="dense"
                id="radius"
                type="number"
                label="Radius (miles)"
                onChange={(e) => handleRadiusChange(e.target.value)}
              />
            </Box>
            <Box m={1}>
              <TextField
                variant="outlined"
                margin="dense"
                id="details"
                label="Notes"
                defaultValue={""}
                onChange={(e) => handleNoteChange(e.target.value)}
              />
            </Box>
            <DialogActions>
              <Button onClick={handleCancel} data-rum-id="annotate-perimeter-cancel" color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default PerimeterDetailsModal;
  