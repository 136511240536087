import { takeEvery, put, fork } from "redux-saga/effects";
import * as actions from "../../actions";
import { IsResponseValidUtil } from "../../utils/api-utils";
import trafficCamGeoData from "../../assets/trafficCamGeo.json";

// const TRAFFIC_CAM_URL = process.env.REACT_APP_CAMERA_FEED_URL;

export function* getGeoJson(action: any): Generator<any, void, unknown> {
  try {
    // TODO: Update to pull from API vs stored file
    // @ts-ignore
    const result: GeoJSON.FeatureCollection<GeoJSON.Geometry> =
      trafficCamGeoData;
    // const result: any = yield call(api.getGeoJsonFile, action.payload.sourceUrl);
    if (result) {
      if (IsResponseValidUtil(result)) {
        let apiResultPayload: GeoJSON.FeatureCollection<GeoJSON.Geometry> =
          result;
        yield put(actions.getGeoJsonSuccess(apiResultPayload));
      }
    } else {
      yield put(
        actions.getGeoJsonFailure("Error fetching traffic camera geoJson file")
      );
    }
  } catch (e) {
    //TODO: Implement proper logging and error handling
    console.log(e);
  }
}
function* watchGeoJsonEvent() {
  yield takeEvery(actions.types.GET_TRAFFIC_CAM_GEO_JSON, getGeoJson);
}

const geoJsonSagas = [fork(watchGeoJsonEvent)];

export default geoJsonSagas;
