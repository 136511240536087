import { Navigate, HashRouterProps } from "react-router-dom";

export interface ProtectedRouteProps extends HashRouterProps {
  isAuthenticated: boolean;
  unAuthenticatedPath: string;
  unAuthorizedPath: string;
  jwtGroups: string[];
  allowedGroups: string[];
}

export const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
  let redirectPath: string = "";
  if (!props.isAuthenticated) {
    redirectPath = props.unAuthenticatedPath;
  }

  if (props.allowedGroups.length > 0) {
    if (!props.jwtGroups?.some((r) => props.allowedGroups.includes(r)))
      redirectPath = props.unAuthorizedPath;
  }

  return (
    redirectPath ? <Navigate to={{ pathname: redirectPath }} replace /> : <>{props.children}</>
  );
};
