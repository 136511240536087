export enum IncidentStatus {
  OPEN = "Open",
  INCIDENT_VERIFIED = "Incident Verified",
  RESPONSE_DISPATCHED = "Response Dispatched",
  RESPONSE_ON_SCENE = "Response On Scene",
  CM_STRATEGY_IMPLEMENTED = "CM Strategy Implemented",
  ROADWAY_CLEARED = "Roadway Cleared",
  INCIDENT_CLEARED = "Incident Cleared",
  NORMAL_FLOW = "Normal Flow",
  CLOSED = "Closed",
  DELETED = "Deleted",
}

export enum IncidentActionStatus {
  OPEN = "Open",
}
