import { Box, Button, Divider, Grid, IconButton, Paper, Theme } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRenderCellParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from '@mui/icons-material/Edit';
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { renderCellExpand } from "../../common/data-grid/cell-expander";
import DataGridUserInfoCell from "../../common/data-grid/user-info";
import { IncidentAction } from "../../../reducers/states/vcc-incident";
import { ActionStatus } from "../../../enums/action-status";
import { Agency } from "../../../enums/agency";
import MobilityStrategyAgencyFilters from "../mobility-strategy-filter";
import { ActionStatusTextColor } from "../action-status-text-color";
import TimeDisplay from "../../common/time-display";
import AddActionModal from "../add-action-modal";
import useUserPermissions from "../../../hooks/user-permissions";
import { RoleAction } from "../../../enums/permission-actions";


const useStyles = makeStyles((theme: Theme) => ({
  removedAction: {
    color: theme.marker.ClosedDispatchEventIFD,
  },
  chipRemoved: {
    backgroundColor: theme.marker.ClosedDispatchEventIFD,
    color: "white",
  },
}));
export const defaultAgencyFilter = [Agency.WSP, Agency.SFD, Agency.SPD, Agency.KCM, Agency.WSDOT, Agency.SDOT, Agency.Port, Agency.ST, Agency.NWSA, ""];
export const defaultStatusFilter = [ActionStatus.IN_PROGRESS, ActionStatus.PLANNED, ActionStatus.COMPLETE, ActionStatus.REMOVED];
export const openOnlyStatusFilter = [ActionStatus.IN_PROGRESS, ActionStatus.PLANNED];
interface ActionGridRow {
  id: string;
  description: string;
  type: string;
  agency: string;
  createdBy: string;
  lead?: string;
  status?: string;
  timeAdded: string | Date;
  fromTimePlanned?: string | Date;
  toTimePlanned?: string | Date;
  collaborators: string[];
  mentions: string;
}

interface ActionGridProps {
  props: {
    handleModify: (row: IncidentAction) => void;
    isEditEnabled: boolean;
  }
}

const ActionGrid: FC<ActionGridProps> = ({props}) => {
  const classes = useStyles();
  const [roleDenied] = useUserPermissions(RoleAction.ADD_AND_EDIT_MODBILITY_STRATEGIES);
  const addEditEnabled = !roleDenied && props.isEditEnabled;
  const [gridData, setGridData] = useState([] as ActionGridRow[]);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [agencyFilters, setAgencyFilters] = useState(defaultAgencyFilter);
  const [statusFilters, setStatusFilters] = useState(defaultStatusFilter);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0
  })

  const incidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.incident
  );

  const actionStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer.actions
  );

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsGridLoading(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setIsGridLoading(true);
    if (actionStore.length > 0) {
      let gridRows: ActionGridRow[] = actionStore.reduce(function (
        results: ActionGridRow[],
        r
      ) {
        if (agencyFilters.includes(r.agency as Agency) && statusFilters.includes(r.status as ActionStatus)) {
          results.push({
            id: r.id,
            description: r.description,
            type: r.type,
            createdBy: r.createdBy,
            lead: r.lead,
            agency: r.agency,
            status: r.status,
            timeAdded: r.timeAdded,
            fromTimePlanned: r.fromTimePlanned,
            toTimePlanned: r.toTimePlanned,
            collaborators: r.collaborators,
            mentions: r.mentions,
          });
        }
        return results;
      },
      []);

      setGridData(gridRows);
    }
    showLoadingAnimation();
  }, [showLoadingAnimation, actionStore, agencyFilters, statusFilters]);

  function setArrayGridCell(field: string, params: GridValueSetterParams) {
    var value = params.value!.toString();
    return { ...params.row, [field]: value.split(",") };
  }

  const handleModifyAction = (actionId: string) => {
    var incidentAction: IncidentAction = actionStore.find(a => a.id === actionId)!;
    props.handleModify(incidentAction)
  }

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedFilters = [...agencyFilters];
    if (event.target.checked === false) {
      let index = updatedFilters.indexOf(event.target.name as Agency);
      if (index > -1) updatedFilters.splice(index, 1);
    } else {
      updatedFilters.push(event.target.name as Agency);
    }

    setAgencyFilters(updatedFilters);
  };

  const handleStatusSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setStatusFilters(defaultStatusFilter);
    } else {
      setStatusFilters(openOnlyStatusFilter);
    }
    
  };

  const columns: GridColDef[] = [
    {
      field: "editAction",
      headerName: "Edit",
      filterable: false,
      flex: .25,
      renderCell: (params: GridRenderCellParams<ActionGridRow>) => {
        return (
          <Box>
            <IconButton onClick={() => handleModifyAction(params.row.id)}>
              {params.row.status === ActionStatus.REMOVED ? <EditIcon  style={{color: '#C0C0C0'}}/> : <EditIcon style={{ color: 'black'}}/> }
            </IconButton>
          </Box>
        )
      }
    },
    
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      renderCell: renderCellExpand,
    },
    {
      field: "timePlanned",
      headerName: "Time Planned",
      flex: 1,
      valueGetter: (params: GridRenderCellParams<ActionGridRow>) =>
      params.row.fromTimePlanned ? params.row.fromTimePlanned + " - " + params.row.toTimePlanned : "",
    },
    {
      field: "createdBy",
      headerName: "Added By",
      flex: 1,
      renderCell: (params: GridRenderCellParams<ActionGridRow>) =>
        renderCellExpand(
          params,
          <DataGridUserInfoCell props={{ users: [params.row.createdBy] }} />,
          true
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<ActionGridRow>) =>{
        return (
          <ActionStatusTextColor value={params.row.status ?? ''}></ActionStatusTextColor>
              
        )
      }
    },
    
    {
      field: "agency",
      headerName: "Agency",
      flex: 0.65,
    },
    {
      field: "collaborators",
      headerName: "Collaborators",
      flex: 1,
      renderCell: (params: GridRenderCellParams<ActionGridRow>) =>
        renderCellExpand(
          params,
          <DataGridUserInfoCell props={{ users: params.row.collaborators }} />,
          true
        ),
      valueGetter: (params: GridValueGetterParams) =>
        params.row.collaborators.join(", "),
      valueSetter: (params: GridValueSetterParams) =>
        setArrayGridCell("collaborators", params),
    },
  ];

  return (
    <>
      <Box m={1} display="flex" style={{height: "100%"}}>
        <Grid container style={{height: "100%"}}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Grid container item xs={12}>
                <Grid container item xs={6} alignItems="center">
                  <Grid item xs={12}>
                    <Box m={1}>
                      <TimeDisplay />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end">
                  {addEditEnabled && (
                    <Grid item>
                      <Box m={1} mr={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ alignSelf: "center" }}
                          size="small"
                          onClick={() => setAddActionOpen(true)}
                        >
                          Add Strategy
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <MobilityStrategyAgencyFilters
              props={{
                agencyFilters: agencyFilters,
                handleSwitchChange: handleSwitchChange,
                handleStatusSwitchChange: handleStatusSwitchChange,
              }}
            />
          </Grid>
          <Grid item xs={12} style={{height: "85%"}}>
            <DataGrid
              loading={isGridLoading}
              density="compact"
              rows={gridData}
              columns={columns}
              getRowClassName={(params) =>
                params.row.status === "REMOVED" ? classes.removedAction : ""
              }
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableRowSelectionOnClick
              columnVisibilityModel={{
                editAction: addEditEnabled,
              }}
              components={{
                NoRowsOverlay: () => (
                  <GridOverlay>
                    No actions added - select "Add Strategy" to begin coordination of
                    Congestion Management Plan.
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>No filtered strategy results</GridOverlay>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <AddActionModal
        props={{
          isOpen: addActionOpen,
          incidentId: incidentStore.id,
          handleClose: () => setAddActionOpen(false),
        }}
      />
    </>
  );
};

export default ActionGrid;
