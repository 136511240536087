import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import AdminGrid from "../../components/admin/admin-grid";
import { awsRum } from "../..";
import { RUMPage } from "../../enums/rum";

const AdminPage = () => {
  useEffect(() => {
    try{
      awsRum.recordPageView(RUMPage.ADMIN);
    }
    catch{
      // Swallow rum errors
    }
    
  }, [])
  
  return (
    <>
      <Typography variant="h3">Admin</Typography>
      <AdminGrid />
    </>
  );
};

export default AdminPage;
