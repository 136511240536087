import { UploadErrorMessage } from "../../components/incident-model/upload-rules-modal";

export interface S3FileData {
  id: string;
  blobData: Blob;
  imgPreview: string;
  fileType: string;
}

export interface FilesPrepareResponse {
  importData: S3FileData[],
  importErrors: UploadErrorMessage[]
}

export const importFilesUtil = async (fileList: FileList): Promise<FilesPrepareResponse> => {
  var fileListArray = Array.from(fileList);

  // Set the upload data
  let importData = [] as S3FileData[];
  let importErrors = [] as UploadErrorMessage[];
  for (var i = 0; i < fileListArray.length; i++) {
    // Check for 25mb limit and file type
    if (fileListArray[i].size > 25000000) {
      importErrors.push({
        errorMessage: "Max file size for uploads is 25mb",
        errorFile: fileListArray[i].name,
      });
    } else if (
      ![
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/tiff",
        "image/heic",
        "image/gif",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ].includes(fileListArray[i].type)
    ) {
      importErrors.push({
        errorMessage:
          "Unsupported file type. Accepted formats: .png | .jpg | .jpeg | .tiff | .heic | .gif | .pdf | .xlsx | .xls | .doc | .docx" + fileListArray[i].type,
        errorFile: fileListArray[i].name,
      });
    } else {
      // Read the file and convert to blob format
      let readFileResult = await readFileUtil(fileListArray[i]);
      let binary = atob(readFileResult.split(",")[1]);
      let array = [];
      for (var j = 0; j < binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      let blobData = new Blob([new Uint8Array(array)], {
        type: fileListArray[i].type,
      });
      importData = [
        ...importData,
        {
          id: fileListArray[i].name,
          blobData: blobData,
          imgPreview: URL.createObjectURL(fileListArray[i]),
          fileType: fileListArray[i].type,
        },
      ];
    }
  }

  return {importData, importErrors} as FilesPrepareResponse;
};

const readFileUtil = async (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });
};

export const isImageFileUtil = (fileId: string): boolean => {
  if(fileId.match(/\.(jpg|jpeg|png|gif|tiff|heic|gif)$/i)){
    return true;
  }
  
  return false;
}