import { Agency } from "../../enums/agency";

export function isNWRegionSelectedUtil(previousAgencies: string[], updatedAgencies: string[]) {
	// If WSP_NW is newly selected return true
	if(!previousAgencies.includes(Agency.WSP_NW) && updatedAgencies.includes(Agency.WSP_NW)){
		return true;
	}

	return false;
}


export function isI5AreaSelectedUtil(previousAgencies: string[], updatedAgencies: string[]) {
	// If I5 is newly selected and NW is not selected, return true
	if(!previousAgencies.includes(Agency.WSP_I5) && updatedAgencies.includes(Agency.WSP_I5) && !updatedAgencies.includes(Agency.WSP_NW)){
		return true;
	}
	else if(previousAgencies.includes(Agency.WSP_NW) && !updatedAgencies.includes(Agency.WSP_NW) && updatedAgencies.includes(Agency.WSP_I5)){
		return true;
	}

	return false;
}

export function noAreaOrRegionSelectedUtil(updatedAgencies: string[]) {
	// If I5 is newly selected and NW is not selected, return true
	if(!updatedAgencies.includes(Agency.WSP_I5) && !updatedAgencies.includes(Agency.WSP_NW)){
		return true;
	}

	return false;
}

export function isWSPSelectedUtil(selectedAgencies: string[], eventAgency: string) {
	// If I5 is newly selected and NW is not selected, return true
	if(eventAgency === Agency.WSP){
		if(selectedAgencies.includes(Agency.WSP_I5) || selectedAgencies.includes(Agency.WSP_NW)){
			return true;
		}
	}

	return false;
}