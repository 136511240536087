import { useContext, createContext } from "react";

export interface AuthState {
  isAuthenticated: boolean;
  userGroups: string[];
  cognitoUsername: string | undefined;
  email: string | undefined;
}
export type AuthContextType = {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
};

export const AuthContext = createContext<AuthContextType>({
  authState: {
    isAuthenticated: false,
    userGroups: [""],
    cognitoUsername: "",
    email: "",
  },
  setAuthState: () => console.warn("no auth provider"),
});

export function useAuthContext() {
  return useContext(AuthContext);
}
