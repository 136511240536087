import { NotificationGroup, UserInfoModel, UserNotifGroups } from "../../reducers/states/user-info";
import { types } from "../types";

// Get User Info Request - for displaying names in the app
export interface getUserInfoPayload {
  email: string;
}
export const getUserInfoRequest = (email: string) => ({
  type: types.GET_USER_INFO,
  payload: { email } as getUserInfoPayload,
});
export const getUserInfoSuccess = (userInfo: UserInfoModel) => ({
  type: types.GET_USER_INFO_SUCCESS,
  payload: { userInfo },
});
export const getUserInfoFailure = (failureMessage: string) => ({
  type: types.GET_USER_INFO_FAILURE,
  payload: { failureMessage },
});

// Get all ReadyOp Notification Groups
export const getAllReadyOpGroupsRequest = () => ({
  type: types.GET_ALL_READYOP_GROUPS,
  payload: {},
});
export const getAllReadyOpGroupsSuccess = (notifGroups: NotificationGroup[] ) => ({
  type: types.GET_ALL_READYOP_GROUPS_SUCCESS,
  payload: { notifGroups },
});
export const getAllReadyOpGroupsFailure = (failureMessage: string ) => ({
  type: types.GET_ALL_READYOP_GROUPS_FAILURE,
  payload: { failureMessage },
});

// Get a User's ReadyOp Notification Groups
export const getUserReadyOpGroupsRequest = () => ({
  type: types.GET_USER_READYOP_GROUPS,
  payload: {},
});
export const getUserReadyOpGroupsSuccess = (userNotifGroups: UserNotifGroups[] ) => ({
  type: types.GET_USER_READYOP_GROUPS_SUCCESS,
  payload: { userNotifGroups },
});
export const getUserReadyOpGroupsFailure = (failureMessage: string ) => ({
  type: types.GET_USER_READYOP_GROUPS_FAILURE,
  payload: { failureMessage },
});

// Update User's Notifications in ReadyOp
export interface updateUserReadyOpGroupsPayload {
  groupIds: string[];
}
export const updateUserReadyOpGroupsRequest = (groupIds: string[]) => ({
  type: types.UPDATE_USER_READYOP_GROUPS,
  payload: { groupIds } as updateUserReadyOpGroupsPayload,
});
export const updateUserReadyOpGroupsSuccess = (successMessage: string ) => ({
  type: types.UPDATE_USER_READYOP_GROUPS_SUCCESS,
  payload: { successMessage },
});
export const updateUserReadyOpGroupsFailure = (failureMessage: string ) => ({
  type: types.UPDATE_USER_READYOP_GROUPS_FAILURE,
  payload: { failureMessage },
});

