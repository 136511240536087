import { Box, Button, Grid, Paper, TextField, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { VccIncidentModel } from "../../../../../reducers/states/vcc-incident";
import useUserInfo from "../../../../../hooks/user-info";
import { NameOrEmailUtil } from "../../../../../utils/user-info-utils";
import { ShortIdUtil } from "../../../../../utils/id-utils";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  over90Card: {
    backgroundColor: theme.palette.secondary.main,
  },
  systemGeneratedCard: {
    backgroundColor: "#C0181C",
  },
}));

interface IncidentCardHeaderProps {
  props: {
    vccIncident: VccIncidentModel;
  };
}

const IncidentCardHeader: FC<IncidentCardHeaderProps> = ({ props }) => {
  const navigate = useNavigate();
  const [isOver90, setIsOver90] = useState(false);
  const [isSystemGenerated, setIsSystemGenerated] = useState(false);
  const [createdBy, setCreatedBy] = useUserInfo();
  const classes = useStyles();

  useEffect(() => {
    setCreatedBy(props.vccIncident.createdBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vccIncident.createdBy]);

  useEffect(() => {
    // Display if older than 90 minutes
    if (moment().diff(props.vccIncident.createdDate) > 5400000) {
      setIsOver90(true);
    }
  }, [props.vccIncident.createdDate]);

  useEffect(() => {
    // Display if older than 90 minutes
    if (props.vccIncident.createdBy === "SYSTEM" && (props.vccIncident.updatedBy === "" || props.vccIncident.status === "Open")) {
      setIsSystemGenerated(true);
    } else {
      setIsSystemGenerated(false);
    }
  }, [props.vccIncident.createdBy, props.vccIncident.updatedBy, props.vccIncident.status]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        {isSystemGenerated && (
          <Grid item xs={12}>
            <Paper className={classes.systemGeneratedCard}>
              <Box
                m={1}
                display="flex"
                justifyContent="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                SYSTEM generated VCC Incident - VERIFICATION REQUIRED
              </Box>
            </Paper>
          </Grid>
        )}
        {isOver90 && (
          <Grid item xs={12}>
            <Paper className={classes.over90Card}>
              <Box
                m={1}
                display="flex"
                justifyContent="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                Open for over 90 minutes
              </Box>
            </Paper>
          </Grid>
        )}
        <Grid item xs>
          <Button
            type="button"
            color="primary"
            size="small"
            onClick={() => navigate("/incident-model/" + props.vccIncident.id)}
          >
            <Typography style={{ display: "inline-block" }} variant="h6">
              {ShortIdUtil(props.vccIncident.id)}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Typography
            style={{ display: "inline-block", fontSize: "12px" }}
            variant="subtitle1"
          >
            <Box
              ml={1}
              mr={1}
              fontStyle="italic"
              fontSize="12px"
              display="inline"
            >
              Created by:
            </Box>
            <Box ml={1} mr={1}>
              {NameOrEmailUtil(createdBy)} on{" "}
              {moment(props.vccIncident.startTime).format("LL, HH:mm:ss")}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            id="status"
            label="Status"
            size="small"
            margin="dense"
            variant="standard"
            type="text"
            fullWidth
            multiline
            maxRows={4}
            value={props.vccIncident.status || ""}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default IncidentCardHeader;
